import { Box, Button, styled, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { SvgDeskHappy } from "../../../../../images";

const StyledBox = styled(Box)<{ transparent?: boolean }>(
  ({ theme, transparent }) => ({
    borderRadius: "8px",
    backgroundColor: transparent
      ? "transparent"
      : theme.palette.shades.greyUltimateLight,
    padding: theme.spacing(3),
    textAlign: "center",
    marginBottom: theme.spacing(3),
  })
);

interface StyledMessageProps {
  icon: React.ReactNode;
  subtitle: string;
  big?: boolean;
}

const StyledMessage: React.FC<React.PropsWithChildren<StyledMessageProps>> = ({
  icon,
  subtitle,
  big,
  children,
}) => (
  <StyledBox transparent={big}>
    {icon}

    <Typography
      variant={big ? "h4" : "subtitle1"}
      color="green.regular"
      sx={{ mb: 1 }}
    >
      {subtitle}
    </Typography>

    {children}
  </StyledBox>
);

interface UpcomingBookingsEmptyProps {
  svgHeight?: number;
  svgWidth?: number;
  big?: boolean;
  description?: string;
  hideButton?: boolean;
}

export const UpcomingBookingsEmpty: React.FC<UpcomingBookingsEmptyProps> = ({
  svgHeight = 70,
  svgWidth = 70,
  big,
  description,
  hideButton,
}) => {
  const { t } = useTranslation("dashboard");

  return (
    <Box sx={{ paddingX: 2, paddingY: 2.5 }}>
      <StyledMessage
        subtitle={t("no-upcoming.title")}
        big={big}
        icon={<SvgDeskHappy width={svgWidth} height={svgHeight} />}
      >
        <Typography variant={big ? "body1" : "body2"}>
          <Trans
            t={t}
            i18nKey={description ? description : "no-upcoming.description"}
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/book"
          sx={(theme) => ({
            mt: 1,
            ...(hideButton && { display: "none" }),
          })}
        >
          {t("heading.choose-desk")}
        </Button>
      </StyledMessage>
    </Box>
  );
};
