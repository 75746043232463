import {
  Button,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { Check, MoreHorizontal } from "react-feather";
import { useTranslation } from "react-i18next";
import { Space } from "../../../../../../assets/domain";
import { Spaces } from "../../../state";

const AllRoomsMenuItem = styled(MenuItem)(({ theme }) => ({
  minWidth: 170,
  border: "1px dashed",
  borderColor: theme.palette.shades.greyLight,
}));

export interface SpacesMenuProps {
  spaces: Spaces;
  selectedSpace: Space | null;
  onChangeSpace: (space: Space | null) => void;
}

export const SpacesMenu: React.FC<SpacesMenuProps> = ({
  spaces,
  selectedSpace,
  onChangeSpace,
}) => {
  const { t } = useTranslation("booking");
  const popupState = usePopupState({
    variant: "popover",
    popupId: "roomFilterMenu",
  });

  return (
    <>
      <Button
        variant="iconLinkOutlined"
        sx={{ flex: "0 0 auto" }}
        {...bindTrigger(popupState)}
      >
        <MoreHorizontal size={16} />
      </Button>
      <Menu disableAutoFocusItem {...bindMenu(popupState)}>
        <AllRoomsMenuItem
          onClick={() => onChangeSpace(null)}
          selected={selectedSpace === null}
        >
          {selectedSpace === null && (
            <ListItemIcon>
              <Check size={16} />
            </ListItemIcon>
          )}
          <ListItemText primary={<strong>{t("filter.all-rooms")}</strong>} />
        </AllRoomsMenuItem>
        {spaces.groupedByFloor.flatMap(([floor, floorSpaces]) => [
          <ListSubheader key={floor.id}>{floor.description}</ListSubheader>,
          floorSpaces.map((space) => {
            const itemSelected = selectedSpace?.id === space.id;
            return (
              <MenuItem
                key={space.id}
                selected={itemSelected}
                onClick={() => {
                  onChangeSpace(space);
                  popupState.close();
                }}
              >
                {itemSelected && (
                  <ListItemIcon>
                    <Check size={16} />
                  </ListItemIcon>
                )}
                <ListItemText primary={space.description} />
              </MenuItem>
            );
          }),
        ])}
      </Menu>
    </>
  );
};
