import { useQuery, UseQueryResult } from "react-query";
import { QueryObserverOptions } from "react-query/types/core/types";
import { getFloors } from "../api";
import { Floor } from "../domain";

export function useFloorsQuery(
  organizationId: string,
  filters?: { locationId?: string },
  options?: QueryObserverOptions<Floor[], Error, Floor[], Floor[], any[]>
): UseQueryResult<Floor[]> {
  return useQuery(
    ["floors", organizationId, filters?.locationId],
    () => getFloors(organizationId, filters),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
}
