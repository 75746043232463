import React from "react";
import { useTranslation } from "react-i18next";
import { Space } from "../../../../../assets/domain";
import { useSpaceImages } from "../../../../../gallery/hooks";
import { useGallerySelection } from "../../../../../gallery/state";
import { SpaceOverview } from "../../../../domain";
import { PresenceListSectionHeader } from "./PresenceListSubsectionHeader";

export interface PresenceListSpaceHeaderProps {
  overview: SpaceOverview;
}

const PresenceListSpaceHeaderComponent: React.FC<{
  space: Space;
}> = React.memo(({ space }) => {
  const { t } = useTranslation("booking-details");
  const { data: spacesWithImages } = useSpaceImages();
  const { onSelectSpaceWithImages } = useGallerySelection();
  let spaceWithImages;
  if (spacesWithImages) {
    spaceWithImages = spacesWithImages.find(
      (element) => element.id === space.id
    );
  }
  return (
    <PresenceListSectionHeader
      title={`${t("label.space")}:`}
      subtitle={space.description}
      spaceWithImages={spaceWithImages}
      onSelectSpaceWithImages={onSelectSpaceWithImages}
    />
  );
});

export const PresenceListSpaceHeader: React.FC<
  PresenceListSpaceHeaderProps
> = ({ overview }) => {
  return <PresenceListSpaceHeaderComponent space={overview.space} />;
};
