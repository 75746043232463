import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { SearchBookingParam } from "../../api";
import { SearchTab, useSearchState } from "../../state";

interface SearchTabButtonProps {
  content: string;
  tabType: SearchTab;
  icon: React.ReactNode;
  selected: boolean;
  filteredOptions: {
    [x: string]: SearchBookingParam[];
  };
}

export const SearchTabButton: React.FC<SearchTabButtonProps> = ({
  content,
  tabType,
  icon,
  selected,
  filteredOptions,
}) => {
  const { onChangeTab } = useSearchState();

  const options = filteredOptions[tabType];

  return (
    <Button
      sx={{
        margin: "0px 8px",
        padding: "8px 2px",
        borderBottom: "2px solid",
        color: selected ? "black" : "shades.grey",
        borderRadius: 0,
        borderColor: selected ? "green.regular" : "transparent",
        transform: "translateY(1px)",
        gap: "0",
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        if (options.length) onChangeTab(tabType);
      }}
    >
      {icon}
      <Typography
        variant="body2"
        sx={{
          margin: "0px 4px",
        }}
      >
        {content}
      </Typography>
      <Box
        sx={{
          height: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "shades.greyUltimateLight",
          borderRadius: "4px",
          padding: "0px 4px",
        }}
      >
        <Typography variant="overline" color="shades.grey">
          {options.length}
        </Typography>
      </Box>
    </Button>
  );
};
