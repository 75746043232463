import { Box, DialogContent, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtError, AmtKnownError, ErrorCodes } from "../../../../../../api";
import { AmtAlert, AmtDialog } from "../../../../../../components";
import {
  useCurrentUser,
  useOrganizationMembership,
} from "../../../../../authentication/hooks";
import { BookingStatus } from "../../../../api";
import { FormikBookForAutocomplete } from "../../../../components";
import { GroupMember } from "../../../../domain";
import {
  useBookingGroups,
  useCreateBookingMutation,
  useCreateGroupBookingMutation,
} from "../../../../hooks";
import {
  DeskBookingRequest,
  useBookingForm,
  UseBookingFormResult,
  useSelection,
} from "../../state";
import {
  BookFormAvatar,
  BookingInfoDisplay,
  CapacityWidget,
  DialogActions,
  DialogTitle,
  FieldContainer,
  SelectedMemberAvatar,
} from "./components";
import { FormikCheckbox } from "./components/InviteCheckbox";
import { BookFormValues } from "./types";

type FormValues = BookFormValues;

export interface SingleBookFormMutationValues {
  bookFor: GroupMember;
  isInvitation: boolean;
}

export interface GroupBookFormMutationValues {
  bookFor: GroupMember[];
  isInvitation: boolean;
}

function isBookingUserConflictError(err: AmtError): boolean {
  return (
    err instanceof AmtKnownError &&
    err.contains(ErrorCodes.BOOKING_USER_CONFLICT)
  );
}

function checkSelectedUserIsValidWhenEditing(
  form: UseBookingFormResult,
  bookFor: GroupMember[],
  editUser: GroupMember | undefined,
  isInvitation: boolean
) {
  if (!form.isEditing) {
    return true;
  }

  if (!form.target || !form.source) {
    return true;
  }

  const isSameDesk = form.source.equals(form.target);
  const isEditingSameUser =
    bookFor.length === 1 && bookFor[0].id === editUser?.id;
  const wasInvitation = form.source.booking.status === BookingStatus.PENDING;
  const isSameType = wasInvitation === isInvitation;

  return !isSameDesk || !isEditingSameUser || !isSameType;
}

export const BookFormDialog: React.FC = () => {
  const { t } = useTranslation("booking");
  const form = useBookingForm();
  const createBooking = useCreateBookingMutation();
  const createGroupBooking = useCreateGroupBookingMutation();
  const { selectedDesk } = useSelection();

  if (!form.target) {
    throw new Error("Invalid usage of BookFormDialog");
  }

  const { date } = form.target;
  const { isOpen, closeDialog } = form;

  const validate = useMemoizedFn(
    async (values: FormValues): Promise<FormikErrors<FormValues>> => {
      const errors: FormikErrors<FormValues> = {};

      if (values.bookFor.length === 0) {
        errors.bookFor = "";
      }
      return errors;
    }
  );

  const doSubmit = useMemoizedFn((values: FormValues) => {
    const isInvitation = values.isInvitation;
    return values.bookFor.length > 1
      ? createGroupBooking.mutateAsync({
          bookFor: values.bookFor,
          isInvitation,
        })
      : createBooking.mutateAsync({
          bookFor: values.bookFor[0],
          isInvitation,
        });
  });

  const handleSubmit = useMemoizedFn(
    (values: FormValues, formik: FormikHelpers<FormValues>) => {
      return doSubmit(values).catch((err: AmtError) => {
        if (isBookingUserConflictError(err)) {
          formik.setFieldError(
            "bookFor",
            t("form.feedback.user-conflict-error")
          );
        } else {
          formik.setFieldError("bookFor", t("form.feedback.unknown-error"));
        }
      });
    }
  );

  const currentUser = useCurrentUser();
  const { organization } = useOrganizationMembership();
  const { data: organizationGroups } = useBookingGroups(
    organization.id,
    date,
    form.isEditing ? form.source.bookingId : null
  );

  const initialUserId = form.isEditing
    ? form.source.booking.bookedFor.id
    : currentUser.id;
  const preferredInitialValue: GroupMember | undefined =
    organizationGroups?.allMembers?.find((it) => {
      if (
        selectedDesk?.isDedicated &&
        !selectedDesk.dedicatedFor.includes(initialUserId)
      )
        return false;
      return it.user.id === initialUserId;
    });
  const initialValue = preferredInitialValue?.bookableForPeriod
    ? preferredInitialValue
    : undefined;
  const editUser = form.isEditing ? preferredInitialValue : undefined;

  const isDeskBooking = form.target instanceof DeskBookingRequest;
  const isMultiselect = !form.isEditing;

  return (
    <AmtDialog open={isOpen} onClose={closeDialog} closeButton>
      <Formik
        enableReinitialize
        initialValues={{
          bookFor: initialValue ? [initialValue] : [],
          isInvitation: false,
        }}
        validate={validate}
        validateOnMount
        // @ts-ignore
        initialTouched={{ bookFor: true }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, isValidating, values }) => {
          const capacity =
            !form.isEditing && form.target instanceof DeskBookingRequest
              ? form.target.capacity
              : null;
          const capacityReached =
            capacity?.reachesCapacity(values.bookFor.length) ?? false;
          const isGroupBooking = values.bookFor.length > 1;
          const canBeInvitation =
            values.bookFor.filter((it) => it.user.id !== currentUser.id)
              .length > 0;
          if (!canBeInvitation) {
            values.isInvitation = false;
          }
          const isUserValidWhenEditing = checkSelectedUserIsValidWhenEditing(
            form,
            values.bookFor,
            editUser,
            values.isInvitation
          );
          return (
            <DialogContent>
              <DialogTitle
                isDeskBooking={isDeskBooking}
                isEditing={form.isEditing}
                isGroup={isGroupBooking}
              />
              <Form style={{ margin: 0 }}>
                <BookingInfoDisplay
                  dedicatedDeskSelected={selectedDesk?.isDedicated}
                />
                <FieldContainer>
                  <CapacityWidget />
                  <Typography variant="subtitle2">
                    {t("form.book-for")}:
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {values.bookFor.length === 0 && (
                      <BookFormAvatar src={editUser?.user.avatarImageUrlSrc} />
                    )}
                    {values.bookFor.length > 0 && (
                      <SelectedMemberAvatar bookFor={values.bookFor} />
                    )}
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        flexGrow: 1,
                      }}
                    >
                      <Field
                        name="bookFor"
                        component={FormikBookForAutocomplete}
                        multiple={isMultiselect}
                        autoFocus
                        capacityReached={capacityReached}
                        placeholder={
                          isMultiselect && values.bookFor.length === 0
                            ? t("form.book-for-placeholder")
                            : undefined
                        }
                      />
                    </Box>
                  </Box>
                </FieldContainer>
                {canBeInvitation && (
                  <Field
                    name="isInvitation"
                    component={FormikCheckbox}
                    type="checkbox"
                  />
                )}
                {canBeInvitation && values.isInvitation && (
                  <AmtAlert
                    text={t("form.feedback.invite-warning")}
                    title={""}
                    variant="error"
                  />
                )}
                <DialogActions
                  onCancel={closeDialog}
                  isSubmitting={isSubmitting}
                  isValid={isValid && !isValidating && isUserValidWhenEditing}
                  isDeskBooking={isDeskBooking}
                  isEditing={form.isEditing}
                />
              </Form>
            </DialogContent>
          );
        }}
      </Formik>
    </AmtDialog>
  );
};
