import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import {
  Calendar as CalendarIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  User as UserIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { Icon, Info, Label, Row } from "../global/styles";
import { useDateFns } from "../hooks";
import { Desk, Floor, Location, Space } from "../modules/assets/domain";
import { User } from "../modules/authentication/domain";
import { BookingDate } from "../modules/booking/domain";
import { ConferenceDateRange } from "../modules/conference/domain/ConferenceDateRange";

export interface BookingInfoProps {
  sx?: SxProps<Theme>;
  date: BookingDate | ConferenceDateRange;
  location?: Location;
  floor?: Floor;
  space?: Space;
  desk?: Desk;
  bookedBy?: User;
}

export const BookingInfo: React.FC<BookingInfoProps> = ({
  sx,
  date,
  location,
  floor,
  space,
  desk,
  bookedBy,
}) => {
  const { formatRange } = useDateFns();
  const { t } = useTranslation("booking-details");

  return (
    <Box sx={sx}>
      <Row>
        <Icon>
          <CalendarIcon size={16} />
        </Icon>
        <Info data-testid="dialog--booking-date">
          <strong>{formatRange(date)}</strong>
        </Info>
      </Row>
      {location && (
        <Row>
          <span>
            <Icon>
              <HomeIcon size={16} />
            </Icon>
          </span>
          <Info data-testid="dialog--location">
            <span>
              <strong>{location.name}</strong>
              <br />
              {location.address.addressLine1}, {location.address.city}
            </span>
          </Info>
        </Row>
      )}
      {floor && (
        <Row>
          <Icon>
            <LayersIcon size={16} />
          </Icon>
          <Info data-testid="dialog--floor">{floor.description}</Info>
        </Row>
      )}
      {space && (
        <Row>
          <Icon />
          <Info data-testid="dialog--space">
            <Label>
              {desk ? t("label.space") : t("label.conference-room")}:
            </Label>
            {space.description}
          </Info>
        </Row>
      )}
      {desk && (
        <Row>
          <Icon />
          <Info data-testid="dialog--desk">
            <Label>{t("label.desk")}:</Label>
            <strong>{desk.identifier}</strong>
          </Info>
        </Row>
      )}
      {bookedBy && (
        <Row>
          <Icon>
            <UserIcon size={16} />
          </Icon>
          <Info data-testid="dialog--booked-by">
            <Label>{t("label.booked-by")}:</Label>
            {bookedBy.name.full}
          </Info>
        </Row>
      )}
    </Box>
  );
};
