import { addDays, isBefore, isSameDay, isWeekend } from "date-fns";

export class ConferenceDateRange {
  private _days: Date[] | null = null;

  protected constructor(
    public readonly startDate: Date,
    public readonly endDate: Date
  ) {
    if (isBefore(endDate, startDate)) {
      this.endDate = this.startDate;
    }
  }

  get workingDays(): Date[] {
    if (this._days !== null) {
      return this._days;
    }
    this._days = this.calculateWorkingDays();
    return this._days;
  }

  equals(other: ConferenceDateRange): boolean {
    return (
      isSameDay(this.startDate, other.startDate) &&
      isSameDay(this.endDate, other.endDate)
    );
  }

  public static of(startDate: Date, endDate?: Date) {
    return new ConferenceDateRange(startDate, endDate ?? startDate);
  }

  private calculateWorkingDays(): Date[] {
    let date = this.startDate;
    const result: Date[] = [];

    while (isBefore(date, this.endDate) || isSameDay(date, this.endDate)) {
      if (!isWeekend(date)) {
        result.push(date);
      }
      date = addDays(date, 1);
    }
    return result;
  }
}
