import { useCreation } from "ahooks";
import { useOrganizationMembership } from "../../../../modules/authentication/hooks";
import { useAdminGroups } from "../../../hooks/useAdminGroups";
import { GroupMember } from "../../asset-management/domain";

export const useAdminOptions = (
  value: GroupMember | undefined
): GroupMember[] => {
  const { organization } = useOrganizationMembership();
  const { data: organizationGroups } = useAdminGroups(organization.id);

  return useCreation(() => {
    if (!organizationGroups) return [];
    return organizationGroups.allMembers;
  }, [organizationGroups?.groups, value]);
};
