import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Home } from "react-feather";
import { AmtInputAdornment } from "../../../../../components";
import { trackLocationSelected } from "../../../../../tracking";
import { Location } from "../../../../assets/domain";

export interface LocationPickerProps {
  location: Location;
  locations: Location[];
  onChange: (newLocation: Location) => void;
}

export const LocationPicker: React.FC<LocationPickerProps> = React.memo(
  ({ location, locations, onChange }) => {
    return (
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <AmtInputAdornment placement="start">
          <Home size={16} />
        </AmtInputAdornment>
        <TextField
          fullWidth
          select
          className="MuiInput-leftAdornment"
          value={location.id}
          onChange={(event) => {
            const newLocation = locations.find(
              (it) => it.id === event.target.value
            );
            if (newLocation) {
              trackLocationSelected(newLocation, "bookings-filter");
              onChange(newLocation);
            }
          }}
          SelectProps={{
            renderValue: () => (
              <ListItemText
                primary={`${location.address.cityShort} | ${location.name}`}
              />
            ),
          }}
          data-testid="book--filters--location"
        >
          {locations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              <ListItemText
                primary={location.name}
                secondary={`${location.address.addressLine1}, ${location.address.city}`}
              />
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  }
);
