import { useQuery, UseQueryResult } from "react-query";
import { getLocationsSortedByFavorite } from "../api";
import { Location } from "../domain";

export function useLocationsSortedByFavoriteQuery(
  organizationMembershipId: string
): UseQueryResult<Location[]> {
  return useQuery(
    ["sorted-favorite-locations", organizationMembershipId],
    () => getLocationsSortedByFavorite(organizationMembershipId),
    {
      refetchOnMount: "always",
    }
  );
}
