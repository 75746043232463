import clsx from "clsx";
import * as d3 from "d3";
import {
  trackFloorChange,
  trackOpenGallery,
  trackSelectDesk,
} from "../../../../tracking";
import { Floor } from "../../../assets/domain";
import { CurrentUser } from "../../../authentication/domain";
import {
  BookingDate,
  DeskAvailability,
  DeskInventoryFilters,
  DeskOverview,
} from "../../../booking/domain";
import { ConferenceRoom } from "../../../conference/domain";
import { SpaceWithImages } from "../../../gallery/domain";
import {
  addDefinitions,
  getBBox,
  GSelection,
  IsDeskDisabledCallback,
  OnChangeFloorCallback,
  OnSelectConferenceRoomCallback,
  OnSelectDeskOverviewCallback,
  OnSelectSpaceWithImagesCallback,
  OnZoomCallback,
  PinSelection,
  SVGSelection,
  Zoom,
} from "../assets/FloorPlanD3Renderer";
import {
  GallerySvg,
  PinMouseoverOutlineSvg,
  PinSvg,
  PinSvgAvatarSize,
} from "../assets/FloorPlanD3Renderer/assets";
import { PinSvgClock } from "../assets/FloorPlanD3Renderer/assets/PinSvgClock";
import { PinSvgGift } from "../assets/FloorPlanD3Renderer/assets/PinSvgGift";
import { FloorPlanStyles } from "../assets/FloorPlanD3Renderer/styles";
import "../assets/FloorPlanD3Renderer/styles.css";
import { FloorPlanTooltipsHandle } from "../assets/Tooltips";
import noAvatarSrc from "../assets/User.svg";

const DATA_PIN_DESK_ID = "data-pin-desk-id";
const DATA_FLOOR_ID = "data-floor-id";
const DATA_PIN_SPACE_ID = "data-pin-space-id";
const DATA_SPACE_IMAGES_BUTTON_ID = "data-space-images-button-id";

function deskSelector(deskId: string): string {
  return `[data-desk-id="${deskId}"]`;
}

function deskPinSelector(deskId: string): string {
  return `[data-pin-desk-id="${deskId}"]`;
}

function spaceSelector(spaceId: string): string {
  return `[data-space-id="${spaceId}"]`;
}

function spacePinSelector(spaceId: string): string {
  return `[data-pin-space-id="${spaceId}"]`;
}

function spaceImageSelector(spaceId: string): string {
  return `[data-space-images-id="${spaceId}"]`;
}

export interface FloorPlanRendererOptions {
  interactive?: boolean;
  tooltips?: FloorPlanTooltipsHandle;
}

export enum PinType {
  DESK,
  CONFERENCE,
}

export class FloorPlanD3Renderer {
  private destroyed: boolean = false;
  private $svg: SVGSelection | null = null;
  private $image: GSelection | null = null;
  private zoom: Zoom | null = null;
  private _bookings: DeskOverview[] = [];
  private _conferenceRooms: ConferenceRoom[] = [];
  private _spacesWithImages: SpaceWithImages[] = [];
  private _selectedInventoryFilters: DeskInventoryFilters[] | null = null;
  private date: BookingDate = BookingDate.of(new Date());
  private selectedDeskId: string | null = null;
  private selectedConferenceRoomId: string | null = null;
  private selectedSpaceWithImagesId: string | null = null;
  private selectedSpaceId: string | null = null;
  private editingDeskId: string | null = null;
  public floors: Floor[] = [];
  public isBackoffice?: boolean;
  public onChangeFloor?: OnChangeFloorCallback;
  public onSelectDesk?: OnSelectDeskOverviewCallback;
  public onSelectConferenceRoom?: OnSelectConferenceRoomCallback;
  public onSelectSpaceWithImages?: OnSelectSpaceWithImagesCallback;
  public onZoom?: OnZoomCallback;
  public isDeskDisabled: IsDeskDisabledCallback = () => false;
  private readonly maxScale: number = 2;
  private readonly tooltips?: FloorPlanTooltipsHandle;
  private readonly styles: FloorPlanStyles;
  private readonly options: FloorPlanRendererOptions = {
    interactive: false,
  };

  constructor(
    div: HTMLDivElement,
    imageUrl: string,
    private currentUser: CurrentUser,
    options: FloorPlanRendererOptions = {}
  ) {
    this.options = { ...this.options, ...options };

    const $div = d3.select(div)!!;
    this.styles = new FloorPlanStyles(this.options.tooltips ?? null);
    this.tooltips = this.options.tooltips;

    d3.xml(imageUrl).then((data) => {
      if (this.destroyed) {
        return;
      }

      div.append(data.documentElement);
      this.$svg = $div
        .selectChild<SVGElement>("svg")!!
        .attr("viewBox", null)
        .attr("width", null)
        .attr("height", null)
        .on("click", () => {
          this.onSelectDesk?.(null);
          this.onSelectConferenceRoom?.(null);
        });

      this.$image = this.$svg!!.selectChild("g");

      this.addDefinitions();
      this.drawPins(PinType.DESK);
      this.drawPins(PinType.CONFERENCE);
      this.drawGalleryButtons();
      this.drawFloorNavigationButtons();
      this.setupZoom();

      const preselectedDeskIsOnThisFloor = this._bookings.find(
        (it) => it.desk.id === this.selectedDeskId
      );
      const preselectedConferenceRoomIsOnThisFloor = this._conferenceRooms.find(
        (it) => it.id === this.selectedConferenceRoomId
      );
      const preselectedSpaceIsOnThisFloor = this._bookings.find(
        (it) => it.space.id === this.selectedSpaceId
      );
      if (
        this.options.interactive &&
        this.selectedDeskId &&
        preselectedDeskIsOnThisFloor
      ) {
        this.doFocusDesk(this.selectedDeskId, false);
      } else if (
        this.options.interactive &&
        this.selectedConferenceRoomId &&
        preselectedConferenceRoomIsOnThisFloor
      ) {
        this.doFocusConferenceRoom(this.selectedConferenceRoomId, false);
      } else if (
        this.options.interactive &&
        this.selectedSpaceId &&
        preselectedSpaceIsOnThisFloor
      ) {
        this.doFocusSpace(this.selectedSpaceId, false);
      } else {
        this.zoomToFit();
      }
    });
  }

  tearDown() {
    this.destroyed = true;
    this.$svg?.remove();
  }

  private addDefinitions() {
    if (!this.$svg) {
      return;
    }

    addDefinitions(this.$svg);
  }

  private setupZoom() {
    const container = this.$svg?.node();
    if (container) {
      this.zoom = new Zoom(container, {
        interactive: this.options.interactive ?? false,
        maxScale: this.maxScale,
      });
      this.zoom.onZoom = (event) => {
        this.onZoom?.(event);
      };
    }
  }

  zoomToFit() {
    this.zoom?.zoomToFit();
  }

  private zoomToEl(el: SVGGraphicsElement, animate: boolean) {
    this.zoom?.zoomToEl(el, animate);
  }

  setBookings(bookings: DeskOverview[], date: BookingDate) {
    this._bookings = bookings;
    this.date = date;
    // this.drawPins();
    this.drawPins(PinType.DESK);
  }

  setConferenceRooms(conferenceRooms: ConferenceRoom[], date: BookingDate) {
    this._conferenceRooms = conferenceRooms;
    this.date = date;
    // this.drawConferencePins();
    this.drawPins(PinType.CONFERENCE);
  }

  setSpacesWithImages(spacesWithImages: SpaceWithImages[]) {
    this._spacesWithImages = spacesWithImages;
    this.drawGalleryButtons();
  }

  setSelectedInventoryFilters(
    selectedInventoryFilters: DeskInventoryFilters[]
  ) {
    this._selectedInventoryFilters = selectedInventoryFilters;
    this.drawPins(PinType.DESK);
  }

  get bookings(): DeskOverview[] {
    return this._bookings;
  }

  focusSpace(spaceId: string, animate: boolean = true) {
    if (spaceId === this.selectedSpaceId) {
      return;
    }

    this.doFocusSpace(spaceId, animate);
  }

  private doFocusSpace(spaceId: string, animate: boolean = true) {
    this.selectedSpaceId = spaceId;

    if (!this.$image) {
      return;
    }

    const desks = this._bookings.filter((it) => it.space.id === spaceId);
    if (desks.length === 0) {
      return;
    }

    const elements = desks
      .map((overview) =>
        this.$svg?.select(deskPinSelector(overview.desk.id)).node()
      )
      .filter((it) => !!it) as SVGGElement[];

    if (elements.length) {
      this.zoom?.zoomToElements(elements, animate);
    }
  }

  unfocusSpace() {
    if (!this.selectedSpaceId) {
      return;
    }

    this.selectedSpaceId = null;

    if (!this.selectedDeskId) {
      this.zoomToFit();
    }
  }

  focusDesk(deskId: string, animate: boolean = true) {
    if (this.selectedDeskId === deskId) {
      return;
    }

    this.doFocusDesk(deskId, animate);
  }

  private doFocusDesk(deskId: string, animate: boolean = true) {
    this.selectedDeskId = deskId;
    this.styles.selectedElementId = deskId;

    if (!this.$image) {
      return;
    }

    this.drawPins(PinType.DESK);
    this.$image.select(deskPinSelector(deskId)).call((selection) => {
      const node = selection.node();
      if (node) {
        this.zoomToEl(node as SVGGraphicsElement, animate);
      }
    });
  }

  focusConferenceRoom(conferenceRoomId: string, animate: boolean = true) {
    if (this.selectedConferenceRoomId === conferenceRoomId) {
      return;
    }

    this.doFocusConferenceRoom(conferenceRoomId, animate);
  }

  doFocusConferenceRoom(conferenceRoomId: string, animate: boolean = true) {
    this.selectedConferenceRoomId = conferenceRoomId;
    this.styles.selectedElementId = conferenceRoomId;

    if (!this.$image) {
      return;
    }

    this.drawPins(PinType.CONFERENCE);
    this.$image.select(spacePinSelector(conferenceRoomId)).call((selection) => {
      const node = selection.node();
      if (node) {
        this.zoomToEl(node as SVGGraphicsElement, animate);
      }
    });
  }

  focusSpaceWithImages(spaceWithImagesId: string, animate: boolean = true) {
    if (this.selectedSpaceWithImagesId === spaceWithImagesId) {
      return;
    }

    this.doFocusSpaceWithImages(spaceWithImagesId, animate);
  }

  doFocusSpaceWithImages(spaceWithImagesId: string, animate: boolean = true) {
    this.selectedSpaceWithImagesId = spaceWithImagesId;
    this.styles.selectedElementId = spaceWithImagesId;

    if (!this.$image) {
      return;
    }

    this.drawGalleryButtons();
    this.$image
      .select(spacePinSelector(spaceWithImagesId))
      .call((selection) => {
        const node = selection.node();
        if (node) {
          this.zoomToEl(node as SVGGraphicsElement, animate);
        }
      });
  }

  unfocusDesk() {
    this.selectedDeskId = null;
    this.styles.selectedElementId = null;
    this.drawPins(PinType.DESK);
  }

  unfocusConferenceRoom() {
    this.selectedConferenceRoomId = null;
    this.styles.selectedElementId = null;
    this.drawPins(PinType.CONFERENCE);
  }

  unfocusSpaceWithImages() {
    this.selectedSpaceId = null;
    this.styles.selectedElementId = null;
    this.drawGalleryButtons();
  }

  setEditingDeskId(editingDeskId: string | null): void {
    if (this.editingDeskId === editingDeskId) {
      return;
    }
    this.editingDeskId = editingDeskId;
    this.drawPins(PinType.DESK);
  }

  private handleSelectDesk(desk: DeskOverview) {
    trackSelectDesk(desk, "floor-plan");
    this.onSelectDesk?.(desk);
  }

  private handleSelectConferenceRoom(conferenceRoom: ConferenceRoom) {
    this.onSelectConferenceRoom?.(conferenceRoom);
  }

  private handleSelectSpaceWithImages(spaceWithImages: SpaceWithImages) {
    trackOpenGallery("Floorplan button");
    this.onSelectSpaceWithImages?.(spaceWithImages);
  }

  private handleChangeFloor(floorId: string) {
    const floor = this.floors.find((it) => it.id === floorId);
    if (!floor || !this.onChangeFloor) {
      return;
    }

    trackFloorChange(floor, "floor-plan-stairs");
    this.onChangeFloor(floor);
  }

  private drawPins(pinType: PinType) {
    if (
      !this.$image ||
      (pinType === PinType.DESK && !this._bookings) ||
      (pinType === PinType.CONFERENCE && !this._conferenceRooms)
    ) {
      return;
    }

    const $self = this;

    this.$image
      .selectAll(
        `[${pinType === PinType.DESK ? DATA_PIN_DESK_ID : DATA_PIN_SPACE_ID}]`
      )
      .data<DeskOverview | ConferenceRoom>(
        pinType === PinType.DESK ? this._bookings : this._conferenceRooms,
        (d) =>
          d instanceof DeskOverview
            ? (d as DeskOverview).desk.id
            : (d as ConferenceRoom).space.id
      )
      .join(
        // @ts-ignore
        function (enter) {
          return enter
            .append("g")
            .attr("class", "amt-floor-plan--pin")
            .attr(
              pinType === PinType.DESK ? DATA_PIN_DESK_ID : DATA_PIN_SPACE_ID,
              (d) =>
                (d instanceof DeskOverview && d.desk.id) ||
                (d instanceof ConferenceRoom && d.space.id)
            )
            .append("g")
            .each(function fillOutline() {
              this.innerHTML = PinMouseoverOutlineSvg;
            })
            .attr("transform", function (d) {
              return $self.getPinTransform(this, d);
            })
            .on("dblclick", function (e) {
              e.stopPropagation();
            })
            .on("click", function (e, d) {
              e.stopPropagation();
              if (
                this.getAttribute("aria-disabled") === "false" &&
                d instanceof DeskOverview
              ) {
                $self.handleSelectDesk(d);
              } else if (
                this.getAttribute("aria-disabled") === "false" &&
                d instanceof ConferenceRoom
              ) {
                $self.handleSelectConferenceRoom(d);
              }
            })
            .on("mousedown", function (e) {
              e.stopPropagation();
            })
            .on("mouseenter", function (_, d) {
              $self.styles.applyPinHover(this, d);
            })
            .on("mouseleave", function (_, d) {
              $self.styles.removePinHover(this);
            })
            .call((el) => {
              el.append("g").each(function drawPin(d) {
                if (this.innerHTML) {
                  return;
                }

                let selector = "";
                if (d instanceof DeskOverview)
                  selector = deskSelector(d.desk.id);
                if (d instanceof ConferenceRoom)
                  selector = spaceSelector(d.space.id);

                const node = $self.$image?.select(selector).node();
                if (!node) {
                  return;
                }

                if (d instanceof DeskOverview) this.innerHTML = PinSvg;
                if (d instanceof ConferenceRoom) {
                  // You should never do this - it's just a quick fix. Everytime a new "benefit" gets added, a new check statement must be added,
                  // and it's not dynamic AT ALL - currently, adding a new entity just for this case would be overengineering, since there probably won't
                  // be any new apartments added in the near future
                  if (d.name === "Mala kuća - apartman")
                    this.innerHTML = PinSvgGift;
                  else this.innerHTML = PinSvgClock;
                }
              });
            });
        },
        function (update) {
          return update.select("g");
        },
        function (exit) {
          exit.remove();
        }
      )
      .attr("aria-disabled", (d) =>
        d instanceof DeskOverview ? $self.isDeskDisabled(d) : false
      )
      .attr("class", (d) => {
        const bookedFor = d instanceof DeskOverview && d.bookings[0]?.bookedFor;
        const bookedForCurrentUser =
          bookedFor && bookedFor?.isUser($self.currentUser);
        const bookedForSomeoneElse = bookedFor && !bookedForCurrentUser;
        const isConference = d instanceof ConferenceRoom;
        return clsx(
          "amt-floor-plan--pin-inner",
          !isConference && {
            "amt-floor-plan--pin-booked-self":
              d.date.isSingleDay && bookedForCurrentUser,
            "amt-floor-plan--pin-booked-other":
              d.date.isSingleDay && bookedForSomeoneElse,
            "amt-floor-plan--pin-desk-available": d.availability.canBook,
            "amt-floor-plan--pin-desk-partially-available":
              d.date.isDateRange &&
              (d.availability === DeskAvailability.PARTIALLY_AVAILABLE ||
                d.availability === DeskAvailability.PARTIALLY_DEDICATED),
            "amt-floor-plan--pin-desk-not-available":
              (d.date.isDateRange || d.isDisabled) &&
              d.availability === DeskAvailability.NOT_AVAILABLE,
            "amt-floor-plan--pin-selected":
              d.desk.id === $self.selectedDeskId ||
              d.desk.id === $self.editingDeskId,
            "amt-floor-plan--pin-dedicated": d.isDedicated,
            "amt-floor-plan--pin-invite":
              d.date.isSingleDay && d.availability === DeskAvailability.PENDING,
          },
          isConference && "amt-floor-plan--pin-conference-room"
        );
      })
      .call((el) => {
        if (pinType === PinType.DESK) this.drawAvatar(el);
        if (pinType === PinType.DESK) this.jumpingAnimation(el);
      });
  }

  private drawGalleryButtons() {
    if (!this.$image || !this._spacesWithImages) {
      return;
    }

    const $self = this;

    this.$image
      .selectAll(`[${DATA_SPACE_IMAGES_BUTTON_ID}]`)
      .data<SpaceWithImages>(
        this._spacesWithImages,
        (d) => (d as SpaceWithImages).id
      )
      .join(
        // @ts-ignore
        function (enter) {
          return enter
            .append("g")
            .attr("class", "amt-floor-plan--pin")
            .attr(DATA_SPACE_IMAGES_BUTTON_ID, (d) => d.id)
            .append("g")
            .each(function fillOutline() {
              this.innerHTML = PinMouseoverOutlineSvg;
            })
            .attr("transform", function (d) {
              return $self.getPinTransform(this, d);
            })
            .on("dblclick", function (e) {
              e.stopPropagation();
            })
            .on("click", function (e, d) {
              e.stopPropagation();
              if (this.getAttribute("aria-disabled") === "false") {
                $self.handleSelectSpaceWithImages(d);
              }
            })
            .on("mousedown", function (e) {
              e.stopPropagation();
            })
            .on("mouseenter", function (_, d) {
              $self.styles.applyPinHover(this, d);
            })
            .on("mouseleave", function (_, d) {
              $self.styles.removePinHover(this);
            })
            .call((el) => {
              el.append("g").each(function drawGalleryButtons(d) {
                if (this.innerHTML) {
                  return;
                }

                let selector = spaceImageSelector(d.id);

                const node = $self.$image?.select(selector).node();
                if (!node) {
                  return;
                }
                this.innerHTML = GallerySvg;
              });
            });
        },
        function (update) {
          return update.select("g");
        },
        function (exit) {
          exit.remove();
        }
      )
      .attr("aria-disabled", (d) =>
        d instanceof DeskOverview ? $self.isDeskDisabled(d) : false
      )
      .attr("class", (d) => {
        return clsx("amt-floor-plan--pin-inner");
      });
  }

  private drawFloorNavigationButtons() {
    const $self = this;
    if (!$self.$image) {
      return;
    }

    function styleReactive() {
      $self
        .$image!.selectAll<SVGGraphicsElement, unknown>(`[${DATA_FLOOR_ID}]`)
        .attr("class", "amt-floor-plan--stairs")
        .on("mousedown", function (e) {
          e.stopPropagation();
          const $this = d3.select(this);
          const floorId = $this.attr(DATA_FLOOR_ID);
          if (floorId?.length) {
            $self.handleChangeFloor(floorId);
          }
        })
        .on("mouseenter", function handleHover() {
          const floorId = d3.select(this).attr(DATA_FLOOR_ID);
          const floor = $self.floors.find((it) => it.id === floorId);
          if (floor) {
            $self.tooltips?.showFloor(this, floor);
          }
        })
        .on("mouseleave", function handleMouseLeave() {
          $self.tooltips?.hide();
        });
    }

    function styleDisabled() {
      $self
        .$image!.selectAll(`[${DATA_FLOOR_ID}]`)
        .attr("class", "amt-floor-plan--stairs-disabled");
    }

    if ($self.onChangeFloor) {
      styleReactive();
    } else {
      styleDisabled();
    }
  }

  /**
   * Position the pin above desk number.
   */
  private getPinTransform = (
    el: SVGGraphicsElement,
    d: DeskOverview | ConferenceRoom | SpaceWithImages
  ) => {
    const marginY = 4;

    // const $text = this.$image!.select<SVGGraphicsElement>(
    //   deskSelector(d.desk.id)
    // );

    let $text;
    if (d instanceof DeskOverview) {
      $text = this.$image!.select<SVGGraphicsElement>(deskSelector(d.desk.id));
    } else if (d instanceof ConferenceRoom) {
      $text = this.$image!.select<SVGGraphicsElement>(
        spaceSelector(d.space.id)
      );
    } else {
      $text = this.$image!.select<SVGGraphicsElement>(spaceImageSelector(d.id));
    }

    const textNode = $text.node();
    if (!textNode) {
      return null;
    }

    const pinBBox = getBBox(el, false, this.$image?.node());
    const textBBox = getBBox(textNode, false, this.$image?.node());

    const pinHalfWidth = pinBBox.width / 2;
    const pinHalfHeight = pinBBox.height / (d instanceof DeskOverview ? 2 : 8);
    const cx = textBBox.x + textBBox.width / 2;
    const cy =
      textBBox.y -
      pinBBox.height / (d instanceof DeskOverview ? 2 : 8) -
      marginY;

    return `translate(${cx}, ${cy}) translate(-${pinHalfWidth}, -${pinHalfHeight})`;
  };

  /**
   * Draw Avatar for users or empty pins
   * @param el
   */
  private drawAvatar = (el: PinSelection) => {
    el.select(".avatar-placeholder")
      .selectAll("image")
      .data(
        (
          d: DeskOverview | ConferenceRoom
        ): (DeskOverview | ConferenceRoom)[] => {
          if (
            d instanceof DeskOverview &&
            d.availability !== DeskAvailability.PENDING &&
            d.availability !== DeskAvailability.DISABLED
          )
            return d.date.isSingleDay && d.bookings.length > 0 ? [d] : [];
          else return [];
        }
      )
      .join("image")
      .attr("xlink:href", (d: DeskOverview | ConferenceRoom) => {
        if (d instanceof DeskOverview)
          return d.bookings[0]?.bookedFor.avatarImageUrl ?? noAvatarSrc;
        return null;
      })
      .attr("clip-path", "url(#avatar-clip)")
      .attr("width", PinSvgAvatarSize)
      .attr("height", PinSvgAvatarSize);
  };

  private jumpingAnimation(selection: PinSelection) {
    const jumpDuration = 600;
    const jumpHeight = -15;
    const delayBetweenJumps = 500;

    if (!selection.node()) {
      return;
    }

    const allSelectedDeskIds = this._selectedInventoryFilters
      ? this._selectedInventoryFilters.flatMap((filter) => filter.deskIds)
      : [];

    selection
      .filter((d) => {
        return (
          d instanceof DeskOverview &&
          allSelectedDeskIds.includes(d.desk.id) &&
          !d.isDisabled
        );
      })
      .transition()
      .ease(d3.easeCubicOut)
      .duration(jumpDuration)
      .attr("transform", (d) => {
        const originalTransform = this.getPinTransform(
          selection.node() as SVGGraphicsElement,
          d
        );
        if (!originalTransform) {
          return null;
        }
        const jumpTransform = `translate(0, ${jumpHeight})`;
        return `${originalTransform} ${jumpTransform}`;
      })
      .transition()
      .ease(d3.easeBounceOut)
      .duration(jumpDuration)
      .attr("transform", (d) =>
        this.getPinTransform(selection.node() as SVGGraphicsElement, d)
      )
      .transition()
      .duration(delayBetweenJumps)
      .on("end", () => this.jumpingAnimation(selection));
  }
}
