import { Box, Typography } from "@mui/material";
import React from "react";
import { SvgDeskSad } from "../../../images";

export interface FullPageErrorProps {
  subtitle?: string;
  message: string;
  actions?: React.ReactNode;
}

export const FullPageError: React.FC<FullPageErrorProps> = ({
  subtitle = "Ooops!",
  message,
  actions,
}) => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <SvgDeskSad />
        <Typography
          variant="subtitle1"
          color="errors.regular"
          sx={{ marginY: 1 }}
        >
          {subtitle}
        </Typography>
        <Typography variant="body2">{message}</Typography>
        {actions}
      </Box>
    </Box>
  );
};
