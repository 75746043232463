import { useQuery, UseQueryResult } from "react-query";
import { getAdminGroups } from "../api";
import { useAddAdminForm } from "../modules/admin-management/state";
import { OrganizationGroups } from "../modules/asset-management/domain";

export function useAdminGroups(
  organizationId: string
): UseQueryResult<OrganizationGroups> {
  const form = useAddAdminForm();
  return useQuery(
    ["admin-groups", organizationId, form.isOpen],
    () => getAdminGroups(organizationId),
    {
      refetchOnWindowFocus: false,
    }
  );
}
