import { useQuery, UseQueryResult } from "react-query";
import { getDedicatedGroup } from "../api/getDedicatedGroups";
import { OrganizationGroups } from "../modules/asset-management/domain";
import { useAssetForm } from "../modules/asset-management/state";

export function useDedicatedGroups(
  organizationId: string
): UseQueryResult<OrganizationGroups> {
  const form = useAssetForm();
  return useQuery(
    ["dedicated-groups", organizationId, form.desk?.desk.id, form.isOpen],
    () => getDedicatedGroup(organizationId, form.desk?.desk.id),
    {
      refetchOnWindowFocus: false,
    }
  );
}
