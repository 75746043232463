import { Components } from "@mui/material";

export const MuiToolbar: Components["MuiToolbar"] = {
  styleOverrides: {
    root: {
      "@media(min-width: 0px)": {
        minHeight: 56,
        height: 56,
        padding: "8px 16px 8px 16px",
      },
    },
  },
};
