import { useSearchParams } from "react-router-dom";
import { useSearchParamsQuery } from "../../../../layout/hooks/useSearchParamsQuery";

export interface SelectedItem {
  id: string;
  name: string;
}

export const useSelectedItems = () => {
  const { data: searchBookingParams } = useSearchParamsQuery();
  const [searchParams] = useSearchParams();

  const users = searchBookingParams?.users ?? [];
  const teams = searchBookingParams?.teams ?? [];
  const locations = searchBookingParams?.locations ?? [];

  const userIds = searchParams.getAll("userIds") ?? [];
  const teamIds = searchParams.getAll("teamIds") ?? [];
  const locationIds = searchParams.getAll("locationIds") ?? [];

  const selectedUsers = users.filter((user) => userIds.includes(user.id));
  const selectedTeams = teams.filter((team) => teamIds.includes(team.id));
  const selectedLocations = locations.filter((location) =>
    locationIds.includes(location.id)
  );

  return {
    selectedUsers: selectedUsers.map(({ id, name }) => ({ id, name })),
    selectedTeams: selectedTeams.map(({ id, name }) => ({ id, name })),
    selectedLocations: selectedLocations.map(({ id, name }) => ({ id, name })),
  };
};
