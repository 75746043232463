export const PinSvgAvatarSize = 32;

export const PinSvgGift = `
    <g class="hover-shadow">
        <path d="M34.9599 33.2745L34.2121 32.6105L34.1983 32.626L34.1851 32.6422L34.9599 33.2745ZM5.04018 33.2745L5.81491 32.6422L5.80175 32.6261L5.78792 32.6105L5.04018 33.2745ZM17.4706 48.5052L18.2685 47.9024L18.2572 47.8874L18.2453 47.8729L17.4706 48.5052ZM22.5294 48.5052L21.7547 47.8729L21.7428 47.8874L21.7315 47.9024L22.5294 48.5052ZM39 20C39 24.84 37.1916 29.2552 34.2121 32.6105L35.7076 33.9384C38.9995 30.2313 41 25.348 41 20H39ZM20 1C30.4934 1 39 9.50659 39 20H41C41 8.40202 31.598 -1 20 -1V1ZM1 20C1 9.50659 9.50659 1 20 1V-1C8.40202 -1 -1 8.40202 -1 20H1ZM5.78792 32.6105C2.80841 29.2552 1 24.84 1 20H-1C-1 25.3481 1.00048 30.2313 4.29244 33.9385L5.78792 32.6105ZM18.2453 47.8729L5.81491 32.6422L4.26545 33.9068L16.6959 49.1375L18.2453 47.8729ZM21.7315 47.9024C21.1044 48.7324 20.4829 49 20 49C19.5171 49 18.8956 48.7324 18.2685 47.9024L16.6727 49.108C17.5514 50.271 18.7064 51 20 51C21.2936 51 22.4486 50.271 23.3273 49.108L21.7315 47.9024ZM34.1851 32.6422L21.7547 47.8729L23.3041 49.1375L35.7346 33.9068L34.1851 32.6422Z" fill="#5C1AEA" mask="url(#path-1-inside-1_2185_6147)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z" fill="white"/>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="currentColor"
            d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z"
        />
    </g>
    <path
        class="pin-path"
        stroke-width="4"
        stroke-linecap="round"
        stroke="transparent" 
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="transparent"
        d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z"
    />
    <g class="hover-rotate">
        <circle cx="20" cy="20" r="16" fill="white"/>
        <g class="icon--conference-room">
            <path d="M25.3333 20V26.6667H14.6666V20" stroke="#5C1AEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M26.6667 16.6667H13.3334V20H26.6667V16.6667Z" stroke="#5C1AEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 26.6667V16.6667" stroke="#5C1AEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 16.6666H17C16.558 16.6666 16.1341 16.4911 15.8215 16.1785C15.509 15.8659 15.3334 15.442 15.3334 15C15.3334 14.558 15.509 14.134 15.8215 13.8215C16.1341 13.5089 16.558 13.3333 17 13.3333C19.3334 13.3333 20 16.6666 20 16.6666Z" stroke="#5C1AEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 16.6666H23C23.442 16.6666 23.866 16.4911 24.1785 16.1785C24.4911 15.8659 24.6667 15.442 24.6667 15C24.6667 14.558 24.4911 14.134 24.1785 13.8215C23.866 13.5089 23.442 13.3333 23 13.3333C20.6667 13.3333 20 16.6666 20 16.6666Z" stroke="#5C1AEA" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </g>
`;
