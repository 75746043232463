import { Box } from "@mui/material";
import React from "react";
import { AmtLoaderSvg } from "./AmtLoaderSvg";

export const AmtCenteredLoader: React.FC = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AmtLoaderSvg />
    </Box>
  );
};
