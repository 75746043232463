import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

export const DeskNameCaption: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      className="AmtDeskIdentifier--Caption"
      variant="overline"
      sx={(theme) => ({
        color: theme.palette.shades.grey,
        display: "block",
      })}
    >
      {children}
    </Typography>
  );
};
