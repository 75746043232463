import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { formatISO } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { CustomDateRange } from "../domain";

export interface DateNavigation {
  onChangeDate(date: CustomDateRange): void;
}

function applyDate(searchParams: URLSearchParams, newDate: CustomDateRange) {
  searchParams.set(
    "date",
    formatISO(newDate.startDate, { representation: "date" })
  );

  if (newDate.isDateRange) {
    searchParams.set(
      "date-end",
      formatISO(newDate.endDate, { representation: "date" })
    );
  } else {
    searchParams.delete("date-end");
  }
}

export function useDateNavigation(): DateNavigation {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeDate = useMemoizedFn((newDate: CustomDateRange) => {
    const updated = new URLSearchParams(searchParams);
    applyDate(updated, newDate);
    setSearchParams(updated);
  });

  return {
    onChangeDate,
  };
}
