import { Box, styled } from "@mui/material";
import React from "react";
import { BoxCenter } from "../../../../../global/components";
import { DeskStatus } from "../../domain";

interface StatusIconProps {
  deskStatus: DeskStatus;
  confirmation?: boolean;
}

interface LineProps {
  width: string;
  height: string;
  rotate?: boolean;
}

const Line = styled(Box)<LineProps>(({ theme, width, height, rotate }) => ({
  width: width,
  height: height,
  backgroundColor: theme.palette.shades.dark,
  borderRadius: "1px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: `translate(-50%, -50%) ${rotate ? "rotate(45deg)" : ""}`,
}));

const Dot = styled(Box)<{ confirmation?: boolean }>(
  ({ theme, confirmation }) => ({
    width: confirmation ? "12px" : "9px",
    height: confirmation ? "12px" : "9px",
    backgroundColor: theme.palette.orange.regular,
    borderRadius: "50%",
    border: `${confirmation ? "2.5" : "2"}px solid white`,
    position: "absolute",
    top: confirmation ? "8px" : "5px",
    right: "0px",
    transform: "translate(-50%, -50%)",
    zIndex: "10",
  })
);

export const StatusIcon: React.FC<StatusIconProps> = ({
  deskStatus,
  confirmation,
}) => {
  return (
    <BoxCenter
      sx={{
        backgroundColor:
          deskStatus === DeskStatus.DISABLED ? "shades.grey" : "green.regular",
        borderRadius: "50%",
        width: confirmation ? "56px" : "36px",
        height: confirmation ? "56px" : "36px",
        position: "relative",
        ...(confirmation
          ? {
              boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.1)",
            }
          : {}),
      }}
    >
      {deskStatus === DeskStatus.DEDICATED && (
        <Dot confirmation={confirmation} />
      )}
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "50%",
          width: confirmation ? "42px" : "27px",
          height: confirmation ? "42px" : "27px",
          position: "relative",
        }}
      >
        {deskStatus === DeskStatus.DISABLED ? (
          <>
            <Line width="9.33px" height="1.33px" rotate />
            <Line width="1.33px" height="9.33px" rotate />
          </>
        ) : (
          <>
            <Line width="9.33px" height="1.33px" />
            <Line width="1.33px" height="9.33px" />
          </>
        )}
      </Box>
    </BoxCenter>
  );
};
