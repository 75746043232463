import { useSearchParams } from "react-router-dom";
import { Location } from "../../../../assets/domain";
import { useLocationsSortedByFavoriteQuery } from "../../../../assets/hooks/useLocationsSortedByFavoriteQuery";
import { useOrganizationMembership } from "../../../../authentication/hooks";

export interface UseLocationsResult {
  locations: Location[];
  location: Location | null;
  locationId: string | null;
}

function getLocationFromSearchParams(
  searchParams: URLSearchParams,
  locations: Location[]
): [Location | null, string | null] {
  const locationId = searchParams.get("location");
  const location = locations.find((it) => it.id === locationId);
  const selectedLocation = location ?? locations[0] ?? null;
  return [selectedLocation, selectedLocation?.id ?? locationId];
}

export function useLocations(): UseLocationsResult {
  const organizationMembership = useOrganizationMembership();
  const [searchParams] = useSearchParams();
  const locationsQuery = useLocationsSortedByFavoriteQuery(
    organizationMembership.id
  );
  const locations = locationsQuery.data ?? [];
  const [location, locationId] = getLocationFromSearchParams(
    searchParams,
    locations
  );

  return {
    locations,
    location,
    locationId,
  };
}
