import { Box, Button, Typography } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { ChevronDown, Eye } from "react-feather";
import { useTranslation } from "react-i18next";
import { ColumnFilterMenu } from "..";
import { Icon, Info } from "../../../../../../global/styles";
import { useOrganizationMembership } from "../../../../../../modules/authentication/hooks";
import { formatISODate } from "../../../../../../modules/booking/api";
import { trackBackofficeCSVReports } from "../../../../../../tracking/backoffice";
import { useBackofficeLocations } from "../../../../../state";
import { useReservationsQuery } from "../../../hooks";
import { useReservationDate } from "../../../state";
import { ColumnItem } from "../AnalyticsTable";

interface ColumnFilterProps {
  selectedItems: ColumnItem[];
  menuContent: ColumnItem[];
  handleClickAll: () => void;
  handleClickItem: (item: ColumnItem) => void;
}

export const ColumnFilter: React.FC<ColumnFilterProps> = ({
  selectedItems,
  menuContent,
  handleClickAll,
  handleClickItem,
}) => {
  const { organization } = useOrganizationMembership();
  const { locationId } = useBackofficeLocations();
  const { data: reservations } = useReservationsQuery();
  const { dateRange } = useReservationDate();
  const { t } = useTranslation("analytics");
  const popupState = usePopupState({
    variant: "popover",
    popupId: "roomFilterMenu",
  });

  const csvRoute = (): string => {
    const locationPart = locationId ? `&location-id=${locationId}` : "";
    return `/api/reports/v2?organization-id=${
      organization.id
    }&start-date=${formatISODate(dateRange.startDate)}&end-date=${formatISODate(
      dateRange.endDate
    )}${locationPart}`;
  };

  const handleCSV = () => {
    trackBackofficeCSVReports(dateRange.isSingleDay);
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px",
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: theme.spacing(0.5),
        })}
      >
        <Icon>
          <Eye size={16} />
        </Icon>
        <Info>
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontWeight: "600",
              margin: "0px 5px",
              [theme.breakpoints.down("md")]: {
                margin: "0px",
              },
            })}
          >
            {t("data-preview.columns")}
          </Typography>
        </Info>
        <Button
          variant="iconLinkOutlined"
          sx={{ padding: "0px" }}
          {...bindTrigger(popupState)}
        >
          <ChevronDown size={16} />
        </Button>
        <ColumnFilterMenu
          popupState={popupState}
          selectedItems={selectedItems}
          menuContent={menuContent}
          handleClickAll={handleClickAll}
          handleClickItem={handleClickItem}
        />
      </Box>
      <Button
        variant="contained"
        disabled={!reservations || !reservations.length}
        href={csvRoute()}
        onClick={handleCSV}
      >
        {t("data-preview.csv-report")}
      </Button>
    </Box>
  );
};
