import { Box, Typography } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentUser } from "../../../../../authentication/hooks";
import { ItemActions } from "../../../../components";
import { DeskOverview, SpaceOverview } from "../../../../domain";
import { useApproveInvitation, useBookingCancel } from "../../../../hooks";
import { Caption } from "../Caption";
import { DeskItemButton } from "./DeskItemButton";
import { SvgPendingAvatar } from "./icons";

export interface DeskItemPendingProps {
  space: SpaceOverview;
  overview: DeskOverview;
}

interface DeskItemPendingComponentProps {
  overview: DeskOverview;
  onCancel: () => void;
  onConfirm: () => void;
  isMyInvitation?: boolean;
}

const DeskItemPendingActions: React.FC<DeskItemPendingComponentProps> = ({
  overview,
  onCancel,
  onConfirm,
  isMyInvitation,
}) => {
  if (overview.bookings.length !== 1) {
    return null;
  }

  return (
    <ItemActions
      booking={overview.bookings[0]}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isInvite={true}
      isMyInvitation={isMyInvitation}
    />
  );
};

const DeskItemPendingComponent: React.FC<DeskItemPendingComponentProps> =
  React.memo(({ overview, onConfirm, onCancel }) => {
    const { bookings } = overview;
    const { t } = useTranslation("booking");
    const currentUser = useCurrentUser();

    const booking = bookings[0];
    const bookedForSelf = booking.bookedFor.id === currentUser.id;

    return (
      <DeskItemButton overview={overview} data-state="pending">
        {booking && (
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {bookedForSelf ? (
                <SvgPendingAvatar color={"#ffa500"} />
              ) : (
                <SvgPendingAvatar color={"#da0099"} />
              )}
              <Box sx={{ lineHeight: 0 }}>
                <Caption>{t("item.pending-for")}</Caption>
                {bookedForSelf ? (
                  <Typography variant="body2" sx={{ fontWeight: "700" }}>
                    {t("item.pending-for-you.title")}
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    <strong>{booking.bookedFor.name.full}</strong>{" "}
                    {t("item.pending.title")}
                  </Typography>
                )}
              </Box>
            </Box>

            <DeskItemPendingActions
              overview={overview}
              onCancel={onCancel}
              onConfirm={onConfirm}
              isMyInvitation={bookedForSelf}
            />
          </Box>
        )}
      </DeskItemButton>
    );
  });

export const DeskItemPending: React.FC<DeskItemPendingProps> = ({
  space,
  overview,
}) => {
  const { initiate: approveInvitation } = useApproveInvitation();
  const { initiate: initiateCancel } = useBookingCancel();

  const handleConfirm = useMemoizedFn(() => {
    if (overview.bookings[0]) {
      approveInvitation(overview.bookings[0]);
    }
  });

  const handleCancel = useMemoizedFn(() => {
    if (overview.bookings[0]) {
      initiateCancel(overview.bookings[0]);
    }
  });

  return (
    <DeskItemPendingComponent
      overview={overview}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
};
