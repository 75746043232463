import { Box } from "@mui/material";
import React from "react";
import { MainLayoutPadding } from "../../../layout";
import { PageContainer, Sidebar } from "../../components";
import { BookingFilters } from "./BookingFilters";
import { BookingPageSkeleton } from "./BookingPageSkeleton";
import { FloorPlanList } from "./FloorPlan";
import { PresenceList } from "./Sidebar";
import { useLocations } from "./state";

export const BookingPageDesktopComponent: React.FC = React.memo(() => {
  const { location } = useLocations();

  if (!location) {
    return <BookingPageSkeleton />;
  }

  return (
    <PageContainer>
      <BookingFilters />
      <Box
        sx={{
          display: "flex",
          height: `calc(100vh - ${MainLayoutPadding.Top} - ${MainLayoutPadding.Bottom} - 104px)`,
        }}
      >
        <Sidebar>
          <PresenceList />
        </Sidebar>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            padding: 2,
            minHeight: "100%",
          }}
        >
          <FloorPlanList />
        </Box>
      </Box>
    </PageContainer>
  );
});
