import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import { fieldToTextField } from "formik-mui";
import React from "react";
import { AssetInventoryType } from "../../../../../../../modules/booking/domain";
import { InventoryTypePicker } from "./InventoryTypePicker";

export interface FormikInventoryTypePickerProps
  extends FieldProps<string>,
    Omit<TextFieldProps, "name" | "value" | "error" | "onChange"> {
  inventoryTypes: AssetInventoryType[];
}

export const FormikInventoryTypePicker: React.FC<
  FormikInventoryTypePickerProps
> = ({ inventoryTypes, ...props }) => (
  <InventoryTypePicker
    {...fieldToTextField(props)}
    inventoryTypes={inventoryTypes}
    value={props.field.value}
    onChange={(value: string) => {
      props.field.onChange({
        name: props.field.name,
        target: {
          name: props.field.name,
          value,
        },
      });
    }}
  />
);
