import { useSearchParams } from "react-router-dom";
import { useBackofficeFloors } from ".";
import { useOrganizationMembership } from "../../modules/authentication/hooks";
import { DeskWithStatus } from "../domain";
import { useDesksWithStatusQuery } from "../hooks/useDesksWithStatusQuery";
import { useBackofficeLocations } from "./useBackofficeLocations";

export interface UseDesksResult {
  desksWithStatus: DeskWithStatus[];
  desk: DeskWithStatus | null;
  deskId: string | null;
}

function getDesksFromSearchParams(
  searchParams: URLSearchParams,
  desksWithStatus: DeskWithStatus[]
): [DeskWithStatus | null, string | null] {
  const deskId = searchParams.get("desk");
  const deskWithStatus = desksWithStatus.find((it) => it.desk.id === deskId);
  if (deskWithStatus) {
    return [deskWithStatus, deskWithStatus.desk.id];
  }
  const preselectedDesk =
    desksWithStatus.length === 1 ? desksWithStatus[0] : null;
  return [preselectedDesk, preselectedDesk?.desk.id ?? deskId];
}

export function useBackofficeDesks(): UseDesksResult {
  const { organization } = useOrganizationMembership();
  const [searchParams] = useSearchParams();
  const { locationId } = useBackofficeLocations();
  const { floorId } = useBackofficeFloors();
  const desksQuery = useDesksWithStatusQuery(organization.id, {
    locationId: locationId ?? undefined,
    floorId: floorId ?? undefined,
  });
  const desksWithStatus = desksQuery.data ?? [];
  const [desk, deskId] = getDesksFromSearchParams(
    searchParams,
    desksWithStatus
  );
  return { desksWithStatus, desk, deskId };
}
