import { Box, Skeleton } from "@mui/material";
import React from "react";

const CaptionSkeleton: React.FC = () => (
  <Skeleton variant="rectangular" height={9} sx={{ mb: 1 }} />
);

const MaxWidthCaptionSkeleton: React.FC = () => (
  <Skeleton variant="rectangular" height={9} width={64} sx={{ mb: 1 }} />
);

const TextSkeleton: React.FC = () => (
  <Skeleton variant="rectangular" height={11} />
);

export const DeskItemSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 4,
      }}
    >
      <Box sx={{ flexShrink: 0, width: 29, mr: 1 }}>
        <CaptionSkeleton />
        <TextSkeleton />
      </Box>
      <Box sx={{ flexShrink: 0, width: 32, mr: 1 }}>
        <Skeleton variant="circular" width={32} height={32} />
      </Box>
      <Box sx={{ flexGrow: 1, mr: 1 }}>
        <MaxWidthCaptionSkeleton />
        <TextSkeleton />
      </Box>
      <Box sx={{ flexShrink: 0, width: 42 }}>
        <CaptionSkeleton />
        <TextSkeleton />
      </Box>
    </Box>
  );
};
