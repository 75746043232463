import { Box, Button, DialogContent, styled, Typography } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React from "react";
import { Check, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { UserInfo } from ".";
import { AmtDialog } from "../../../../../components";
import { trackRemoveAdmin } from "../../../../../tracking";
import { SimpleDialogTitle } from "../../../../components/Dialogs";
import { useDeleteAdminMutation } from "../../hooks";
import { useDeleteAdminForm } from "../../state";

const HideMobile = styled("span")(({ theme }) => ({
  [theme.breakpoints.down(375)]: {
    display: "none",
  },
}));

export const RemoveAdminDialog: React.FC = () => {
  const { t } = useTranslation("admin-management");
  const form = useDeleteAdminForm();
  const deleteAdminMutation = useDeleteAdminMutation();

  if (!form.admin) {
    throw new Error("Invalid usage of RemoveAdminDialog");
  }

  const handleSubmit = useMemoizedFn(() => {
    if (!form.admin)
      return Promise.reject("Invalid usage of RemoveAdminDialog");
    trackRemoveAdmin();
    return deleteAdminMutation.mutateAsync(form.admin.id);
  });

  return (
    <AmtDialog open={form.isOpen} onClose={form.closeDialog} closeButton>
      <DialogContent>
        <SimpleDialogTitle
          title={t("dialog.remove-admin-title")}
          sx={{ backgroundColor: "shades.greyUltimateLight" }}
        />
        <UserInfo admin={form.admin.user} sx={{ marginY: 2 }} />
        <Box
          sx={(theme) => ({
            textAlign: "center",
            marginY: 3,
            [theme.breakpoints.down("md")]: {
              marginY: 2,
            },
          })}
        >
          <Typography variant="h4" color="error.main">
            {t("dialog.delete-warning.title")}
          </Typography>
          <Typography variant="body2" color="shades.grey">
            {t("dialog.delete-warning.text")}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: 1,
            [theme.breakpoints.down("md")]: {
              gap: 2,
            },
          })}
        >
          <Button
            fullWidth
            color="cancel"
            variant="outlined"
            onClick={form.closeDialog}
            startIcon={<X size={16} />}
            data-testid="dialog--cancel"
          >
            <HideMobile>{t("dialog.action.no")}</HideMobile>
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            startIcon={<Check size={16} />}
            data-testid="dialog--submit"
          >
            <HideMobile>{t("dialog.action.yes")}</HideMobile>
          </Button>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
