import { Components, Theme } from "@mui/material";

export const MuiListSubheader: Components<Theme>["MuiListSubheader"] = {
  styleOverrides: {
    // @ts-ignore
    root: ({ theme }) => ({
      padding: 0,
      paddingBottom: theme.spacing(1),
      color: theme.palette.shades.grey,
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.shades.greyLight,
      ...theme.typography.overline,
    }),
  },
};
