import { Box, Button, styled, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { forwardRef } from "react";
import { Heart } from "react-feather";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AmtCard, AmtMasterBadge } from "../../../../../../components";
import { trackLocationSelected } from "../../../../../../tracking";
import { Location } from "../../../../../assets/domain";
import { LogoShortSvg } from "../../../../../layout/assets";
import { Capacity } from "../../../../domain";
import { TotalLocationBookingsTooltip } from "./TotalLocationBookingsTooltip";

const OccupancyNumber = styled(Typography)({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: "28px",
});

const OccupancyText: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Typography
      variant="caption"
      color="shades.grey"
      sx={{
        display: "block",
        paddingX: 0.5,
        paddingBottom: 0.5,
        borderBottom: "1px dashed",
        borderBottomColor: "shades.greyLight",
      }}
    >
      {children}
    </Typography>
  </Box>
);

const OccupancyNumbers = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  lineHeight: 0,
  gap: theme.spacing(0.5),
  margin: 0,
}));

const LocationImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  height: 122,
  backgroundColor: theme.palette.shades.greyUltimateLight,
  borderRadius: "8px",
}));

const LocationImage = styled("img")({
  objectFit: "cover",
  height: 122,
  width: "100%",
});

const StyledIconButton = styled(Box)(({ theme }) => ({
  width: 36,
  height: 36,
  borderRadius: "18px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  border: "1px solid",
  borderColor: theme.palette.shades.greyLight,
  color: "black",
}));

const StyledSelectedIconButton = styled(Box)(({ theme }) => ({
  width: 36,
  height: 36,
  borderRadius: "18px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  border: "1px solid",
  borderColor: theme.palette.shades.dark,
  color: "white",
  backgroundColor: theme.palette.shades.dark,
}));

const StyledFavoriteLocation = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  minWidth: 36,

  padding: theme.spacing(0, 0, 0, 0),
  borderRadius: "18px",
  backgroundColor: "white",

  "&:hover": {
    backgroundColor: theme.palette.shades.dark,
    color: "white",
    ".Amt--FavoriteLocation--Icon": {
      color: "white",
      borderColor: theme.palette.shades.dark,
    },
  },
}));

interface AddressProps {
  location: Location;
}

const Address: React.FC<AddressProps> = ({ location }) => {
  return (
    <Typography
      variant="caption"
      sx={{
        fontWeight: 600,
      }}
    >
      {location.address.addressLine1}
    </Typography>
  );
};

interface CityProps {
  location: Location;
}

const City: React.FC<CityProps> = ({ location }) => {
  return (
    <AmtMasterBadge
      sx={{
        display: "inline-block",
        backgroundColor: "orange.light",
        borderColor: "orange.light",
        color: "shades.dark",
        marginTop: 1,
        paddingY: 0,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {location.address.city}
      </Typography>
    </AmtMasterBadge>
  );
};

const NewLocation: React.FC = () => {
  const { t } = useTranslation("dashboard");

  return (
    <AmtMasterBadge
      sx={{
        display: "inline-block",
        backgroundColor: "cyclamen.regular",
        borderColor: "cyclamen.regular",
        color: "white",
        marginTop: 1,
        paddingY: 0,
        position: "absolute",
        left: -4,
        top: 10,
        zIndex: 10,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {t("location.new-location")}!
      </Typography>
    </AmtMasterBadge>
  );
};

interface LocationNameProps {
  location: Location;
}

const LocationName: React.FC<LocationNameProps> = ({ location }) => (
  <Typography
    variant="subtitle1"
    sx={{
      paddingTop: 0.5,
      paddingBottom: 0,
    }}
  >
    {location.name}
  </Typography>
);

export interface LocationCardProps {
  location: Location;
  capacity: Capacity;
  toggleFavorite: any;
  isFavorite: boolean;
  newLocation: boolean;
  todaysBookingLocation: boolean;
}

const CardStyles: SxProps<Theme> = {
  borderRadius: "10px",
  minWidth: 167,
  maxWidth: 222,
  textAlign: "center",
  padding: 0.5,
  flex: "1",
  fontSize: 0,
  overflow: "visible",
};

export const LocationCard: React.FC<LocationCardProps> = forwardRef(
  (
    {
      location,
      capacity,
      newLocation,
      toggleFavorite,
      isFavorite,
      todaysBookingLocation,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation("dashboard");
    const navigate = useNavigate();

    return (
      <AmtCard
        onClick={() => {
          trackLocationSelected(location, "dashboard");
          navigate({
            pathname: "/book",
            search: `?location=${location.id}`,
          });
        }}
        sx={CardStyles}
        pop
        data-testid="my-bookings--location"
        ref={ref}
        {...rest}
      >
        {newLocation && <NewLocation />}
        <LocationImageContainer>
          {todaysBookingLocation && (
            <AmtMasterBadge
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                backgroundColor: "green.regular",
                borderColor: "green.regular",
                borderRadius: "8px",
                color: "white",
                paddingY: 0.5,
                paddingX: 1,
                bottom: 0,
              }}
            >
              <LogoShortSvg />
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {t("you-are-here-today")}!
              </Typography>
            </AmtMasterBadge>
          )}
          {location.imageUrl && (
            <LocationImage src={location.imageUrl} alt="" role="presentation" />
          )}
        </LocationImageContainer>
        <StyledFavoriteLocation
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            toggleFavorite(event, location)
          }
        >
          {isFavorite ? (
            <StyledSelectedIconButton className="Amt--FavoriteLocation--Icon">
              <Heart size={16} />
            </StyledSelectedIconButton>
          ) : (
            <StyledIconButton className="Amt--FavoriteLocation--Icon">
              <Heart size={16} />
            </StyledIconButton>
          )}
        </StyledFavoriteLocation>
        <City location={location} />
        <LocationName location={location} />
        <Address location={location} />
        <OccupancyText>{t("location.occupancy")}</OccupancyText>
        <OccupancyNumbers data-testid="location-occupancy">
          <TotalLocationBookingsTooltip count={capacity.totalLocationBookings}>
            <OccupancyNumber color="cyclamen.regular">
              {capacity.occupied}
            </OccupancyNumber>
          </TotalLocationBookingsTooltip>
          <Typography variant="caption" align="left">
            <Trans t={t} i18nKey="location.occupancy-out-of" />
          </Typography>
          <OccupancyNumber color="green.regular">
            {capacity.total}
          </OccupancyNumber>
        </OccupancyNumbers>
      </AmtCard>
    );
  }
);
