import { User } from "../../../../modules/authentication/domain";

export class OrganizationGroups {
  constructor(public readonly groups: Group[]) {}

  get allMembers(): GroupMember[] {
    return this.groups.flatMap((it) => it.members);
  }
}

export class Group {
  constructor(
    public readonly name: string,
    public readonly members: GroupMember[]
  ) {}
}

export class GroupMember {
  constructor(
    public readonly id: string,
    public readonly user: User,
    public readonly deskIsDedicatedToMember: boolean,
    public readonly groupName: string
  ) {}
}
