import {
  Box,
  Button,
  ButtonProps,
  styled,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useFlag } from "@unleash/proxy-client-react";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { forwardRef, useEffect, useRef } from "react";
import { Minimize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useThrottledCallback } from "use-debounce";
import { Floor, Space } from "../../../assets/domain";
import { useCurrentUser } from "../../../authentication/hooks";
import {
  BookingDate,
  DeskBookings,
  DeskInventoryFilters,
  DeskOverview,
} from "../../../booking/domain";
import { ConferenceRoom } from "../../../conference/domain";
import { SpaceWithImages } from "../../../gallery/domain";
import {
  FloorCard,
  FloorNameBadge,
  InventoryFilterBadge,
} from "../assets/components";
import { ZoomEvent } from "../assets/FloorPlanD3Renderer";
import { FloorPlanTooltips, FloorPlanTooltipsHandle } from "../assets/Tooltips";
import { FloorPlanD3Renderer } from "./FloorPlanD3Renderer";

interface InteractiveFloorPlanProps {
  imageUrl?: string | null;
  interactive?: boolean;
  tooltips?: boolean;
  bookings: DeskBookings;
  conferenceRooms: ConferenceRoom[] | undefined;
  spacesWithImages: SpaceWithImages[] | undefined;
  inventoryFilters?: DeskInventoryFilters[] | undefined;
  floors?: Floor[];
  date?: BookingDate;
  selectedDesk: DeskOverview | null;
  selectedConferenceRoom: ConferenceRoom | null;
  selectedSpaceWithImages: SpaceWithImages | null;
  selectedInventoryFilters?: DeskInventoryFilters[] | undefined;
  selectedSpace?: Space | null;
  editDeskId?: string | null;
  floor: Floor;
  description: string;
  square?: boolean;
  isBackoffice?: boolean;
  onClick?: () => void;
  onSelectDesk?: (desk: DeskOverview | null) => void;
  onSelectConferenceRoom?: (conferenceRoom: ConferenceRoom | null) => void;
  onSelectSpaceWithImages?: (spaceWithImages: SpaceWithImages | null) => void;
  onChangeFloor?: (floor: Floor) => void;
  isDeskDisabled?: (desk: DeskOverview) => boolean;
  sx?: SxProps<Theme>;
}

const FullContentContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SvgContainer = styled(Box)({
  width: "100%",
  height: "100%",
  "& svg": {
    width: "100%",
    height: "100%",
  },
});

interface RendererRef {
  renderer: FloorPlanD3Renderer | null;
}

export const InteractiveFloorPlan: React.FC<InteractiveFloorPlanProps> = ({
  description,
  floor,
  interactive = false,
  tooltips = true,
  square = false,
  date,
  bookings,
  conferenceRooms,
  spacesWithImages,
  inventoryFilters,
  floors,
  selectedDesk,
  selectedConferenceRoom,
  selectedSpaceWithImages,
  selectedInventoryFilters,
  selectedSpace,
  editDeskId,
  isBackoffice,
  onClick,
  onSelectDesk,
  onSelectConferenceRoom,
  onSelectSpaceWithImages,
  onChangeFloor,
  isDeskDisabled,
  sx,
}) => {
  const imageUrl = floor.floorPlanImageUrl;
  const currentUser = useCurrentUser();
  const divRef = useRef<HTMLDivElement>(null);
  const fitToScreenRef = useRef<HTMLButtonElement>(null);
  const rendererRef = useRef<RendererRef>({
    renderer: null,
  });
  const tooltipsRef = useRef<FloorPlanTooltipsHandle>(null);
  const enabled = useFlag("user-inventory");

  const memoShowDeskTooltip = useMemoizedFn(
    (el: Element, desk: DeskOverview) => {
      tooltipsRef.current?.showDesk?.(el, desk);
    }
  );
  const memoShowFloorTooltip = useMemoizedFn((el: Element, floor: Floor) => {
    tooltipsRef.current?.showFloor(el, floor);
  });
  const memoHideTooltip = useMemoizedFn(() => {
    tooltipsRef.current?.hide();
  });

  function getRenderer(): FloorPlanD3Renderer | null {
    return rendererRef.current.renderer;
  }

  const onZoom = useThrottledCallback(({ zoomedToFit }: ZoomEvent) => {
    const style = fitToScreenRef.current?.style;
    if (!style) {
      return;
    }

    if (zoomedToFit) {
      style.opacity = "0";
    } else {
      style.opacity = "1";
    }
  }, 100);

  const memoOnZoom = useMemoizedFn(onZoom);
  const memoOnSelectDesk = useMemoizedFn((desk: DeskOverview | null) => {
    onSelectDesk?.(desk);
  });
  const memoOnSelectConferenceRoom = useMemoizedFn(
    (conferenceRoom: ConferenceRoom | null) => {
      onSelectConferenceRoom?.(conferenceRoom);
    }
  );
  const memoOnSelectSpaceWithImages = useMemoizedFn(
    (spaceWithImages: SpaceWithImages | null) => {
      onSelectSpaceWithImages?.(spaceWithImages);
    }
  );
  const memoOnChangeFloor = useMemoizedFn((floor: Floor) => {
    onChangeFloor?.(floor);
  });
  const memoIsDeskDisabled = useMemoizedFn((desk: DeskOverview): boolean => {
    return isDeskDisabled?.(desk) ?? false;
  });

  useEffect(
    () => {
      if (!imageUrl || !divRef.current) {
        return;
      }

      const renderer = new FloorPlanD3Renderer(
        divRef.current,
        imageUrl,
        currentUser,
        {
          interactive,
          ...(tooltips && {
            tooltips: {
              showDesk: memoShowDeskTooltip,
              showFloor: memoShowFloorTooltip,
              hide: memoHideTooltip,
            },
          }),
        }
      );
      renderer.isBackoffice = isBackoffice;
      renderer.onSelectDesk = memoOnSelectDesk;
      renderer.onSelectConferenceRoom = memoOnSelectConferenceRoom;
      renderer.onSelectSpaceWithImages = memoOnSelectSpaceWithImages;
      renderer.onChangeFloor = memoOnChangeFloor;
      renderer.onZoom = memoOnZoom;
      renderer.isDeskDisabled = memoIsDeskDisabled;
      rendererRef.current.renderer = renderer;
      if (bookings && date) {
        const desks = bookings.desks.filter((it) => it.floor.id === floor.id);
        renderer.setBookings(desks, date);
      }

      return function () {
        renderer.tearDown();
        if (rendererRef.current.renderer === renderer) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          rendererRef.current.renderer = null;
        }
      };
    },
    // Intentional - do not run this if deskBookings or currentUser change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageUrl]
  );

  useEffect(() => {
    const renderer = getRenderer();
    if (bookings && renderer && date) {
      renderer.setBookings(
        bookings.desks.filter((it) => it.floor.id === floor.id),
        date
      );
    }
    if (conferenceRooms && renderer && date) {
      renderer.setConferenceRooms(
        conferenceRooms.filter(
          (conferenceRoom) => conferenceRoom.floor.id === floor.id
        ),
        date
      );
    }
  }, [floor.id, bookings, date, conferenceRooms]);

  useEffect(() => {
    const renderer = getRenderer();
    if (spacesWithImages && renderer) {
      renderer.setSpacesWithImages(
        spacesWithImages.filter((space) => space.floorId === floor.id)
      );
    }
  }, [floor.id, spacesWithImages]);

  useEffect(() => {
    const renderer = getRenderer();
    if (selectedInventoryFilters && renderer) {
      renderer.setSelectedInventoryFilters(selectedInventoryFilters);
    }
  }, [selectedInventoryFilters]);

  useEffect(() => {
    const renderer = getRenderer();
    if (renderer && floors) {
      renderer.floors = floors;
    }
  }, [floors]);

  useEffect(() => {
    const renderer = getRenderer();
    if (!renderer) {
      return;
    }

    if (selectedDesk) {
      renderer.focusDesk(selectedDesk.desk.id);
    } else {
      renderer.unfocusDesk();
    }
  }, [selectedDesk]);

  useEffect(() => {
    const renderer = getRenderer();
    if (!renderer) {
      return;
    }

    if (selectedConferenceRoom) {
      renderer.doFocusConferenceRoom(selectedConferenceRoom.space.id);
    } else {
      renderer.unfocusConferenceRoom();
    }
  }, [selectedConferenceRoom]);

  useEffect(() => {
    const renderer = getRenderer();
    if (!renderer) {
      return;
    }

    if (selectedSpaceWithImages) {
      renderer.doFocusSpaceWithImages(selectedSpaceWithImages.id);
    } else {
      renderer.unfocusSpaceWithImages();
    }
  }, [selectedSpaceWithImages]);

  useEffect(() => {
    const renderer = getRenderer();
    if (!renderer) {
      return;
    }

    if (selectedSpace) {
      renderer.focusSpace(selectedSpace.id);
    } else {
      renderer.unfocusSpace();
    }
  }, [selectedSpace]);

  useEffect(() => {
    const renderer = getRenderer();
    if (!renderer) {
      return;
    }

    renderer.setEditingDeskId(editDeskId ?? null);
  }, [editDeskId]);

  return (
    <FloorCard
      onClick={onClick}
      interactive={interactive}
      square={square}
      floor={floor}
      sx={sx}
    >
      <ActualFloorPlan
        imageUrl={imageUrl}
        description={description}
        ref={divRef}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          position: "absolute",
          left: theme.spacing(1),
          top: theme.spacing(1),
        })}
      >
        <FloorNameBadge floor={floor} />
        {!!floors &&
          inventoryFilters &&
          inventoryFilters.length > 0 &&
          enabled && (
            <InventoryFilterBadge inventoryFilters={inventoryFilters} />
          )}
      </Box>
      {imageUrl && interactive && (
        <FitToScreenButton
          ref={fitToScreenRef}
          onClick={() => getRenderer()?.zoomToFit()}
        />
      )}
      <FloorPlanTooltips ref={tooltipsRef} />
    </FloorCard>
  );
};

const ActualFloorPlan = React.memo(
  React.forwardRef<
    HTMLDivElement,
    {
      imageUrl: string | null;
      description: string | null;
    }
  >(({ imageUrl, description }, ref) => {
    return (
      <FullContentContainer>
        {imageUrl && <SvgContainer ref={ref} />}
        {!imageUrl && (
          <Typography variant="h2" sx={{ padding: "20px" }}>
            {description}
          </Typography>
        )}
      </FullContentContainer>
    );
  })
);

interface FitToScreenButtonProps {
  onClick: ButtonProps["onClick"];
  shown?: boolean;
}

const FitToScreenButton = forwardRef<HTMLButtonElement, FitToScreenButtonProps>(
  ({ onClick }, ref) => {
    const { t } = useTranslation("floor-plan");

    return (
      <Tooltip title={t("fit-to-screen")} placement="left">
        <Button
          ref={ref}
          color="secondaryContained"
          variant="icon"
          onClick={onClick}
          sx={{
            position: "absolute",
            right: ({ spacing }) => spacing(1),
            top: ({ spacing }) => spacing(1),
            transition: "opacity 0.1s ease-in-out",
          }}
        >
          <Minimize2 size={16} />
        </Button>
      </Tooltip>
    );
  }
);
