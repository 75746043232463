import { Box } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useIsMobile } from "../../../../../../hooks";
import { UpcomingBooking } from "../../../../domain";
import { useIsFloorPlanVisible, useSelectedBooking } from "../../hooks";
import { BookingListItem } from "./BookingListItem";

export interface UpcomingBookingsListProps {
  bookings: UpcomingBooking[];
}

export const UpcomingBookingsList: React.FC<UpcomingBookingsListProps> = ({
  bookings,
}) => {
  const [selectedBooking, selectBooking] = useSelectedBooking();
  const [isFloorPlanVisible, openFloorPlan] = useIsFloorPlanVisible();
  const isMobile = useIsMobile();

  const unselectBooking = useMemoizedFn(() => {
    selectBooking(null);
  });

  return (
    <Box
      sx={{
        paddingX: 2,
        paddingY: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {bookings.map((it) => (
        <BookingListItem
          key={it.booking.id}
          booking={it}
          selected={selectedBooking?.booking.id === it.booking.id}
          onSelect={selectBooking}
          onViewFloorPlan={
            isMobile && !isFloorPlanVisible ? openFloorPlan : undefined
          }
          onUnselect={
            isMobile && isFloorPlanVisible ? unselectBooking : undefined
          }
        />
      ))}
    </Box>
  );
};
