import { Floor, Space } from "../../../../../assets/domain";

export class Spaces {
  public readonly flat: Space[];
  public readonly groupedByFloor: [Floor, Space[]][];

  constructor(
    public readonly floors: Floor[] = [],
    public readonly spaces: Map<string, Space[]> = new Map()
  ) {
    this.flat = this.floors.flatMap((it) => this.spaces.get(it.id) ?? []);
    this.groupedByFloor = this.floors.map((it) => [
      it,
      this.spaces.get(it.id) ?? [],
    ]);
  }

  get isEmpty(): boolean {
    return this.flat.length === 0;
  }

  static of(list: [Floor, Space][]) {
    const floors = Spaces.getUniqueFloors(list);
    const spaces = new Map<string, Space[]>();
    list.forEach((it) => {
      const floor = it[0];
      const space = it[1];
      const spaceList = spaces.get(floor.id) ?? [];
      spaceList.push(space);
      spaces.set(floor.id, spaceList);
    });
    return new Spaces(floors, spaces);
  }

  private static getUniqueFloors(list: [Floor, Space][]): Floor[] {
    const set = new Set<string>();
    const floors: Floor[] = [];
    list.forEach((item) => {
      const floor = item[0];
      if (!set.has(floor.id)) {
        set.add(floor.id);
        floors.push(floor);
      }
    });
    return floors;
  }
}
