import { Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtDefaultBadge, AmtDefaultBadgeProps } from "../../../../components";
import { useBookingNavigation } from "../../../../hooks";
import { usePermissions } from "../../../permissions/hooks";
import { BookedNotification, isDeskBookingNotification } from "../../domain";
import { BookingDetails } from "./BookingDetails";
import { NotificationItem } from "./NotificationItem";

export interface BookedNotificationItemProps {
  onClose: () => void;
  notification: BookedNotification;
}

export const BookedNotificationItem: React.FC<BookedNotificationItemProps> = ({
  onClose,
  notification,
}) => {
  const { t } = useTranslation("notifications");
  const { bookings } = usePermissions();
  const navigateToBooking = useBookingNavigation();
  const snackbar = useSnackbar();

  const isDeskBooking = isDeskBookingNotification(notification.context);
  const handleClick = useMemoizedFn(() => {
    if (!bookings.canViewBookingsOnDate(notification.context.bookingDate)) {
      snackbar.enqueueSnackbar(t("feedback.expired"), {
        variant: "warning",
      });
      return;
    }
    navigateToBooking({
      date: notification.context.bookingDate,
      locationId: notification.context.location.id,
      ...(isDeskBooking && {
        deskId: notification.context.desk.id,
        floorId: notification.context.floor.id,
      }),
      action: "view",
    });
  });

  const keyPrefix = isDeskBooking ? "desk" : "location";

  const color: AmtDefaultBadgeProps["color"] = isDeskBooking
    ? "orange"
    : "cyclamen";

  return (
    <NotificationItem
      onClick={handleClick}
      onClose={onClose}
      notification={notification}
      badge={
        <AmtDefaultBadge color={color}>
          {t(`${keyPrefix}-booked.badge`)}
        </AmtDefaultBadge>
      }
      content={
        <>
          <Typography variant="body2" sx={{ mb: 1, mt: 1 }}>
            {t(`${keyPrefix}-booked.text`)}
          </Typography>
          <BookingDetails context={notification.context} />
        </>
      }
    />
  );
};
