import { addDays, isBefore } from "date-fns";
import React, { useEffect, useState } from "react";
import { trackLoadMoreConference } from "../../../../../tracking";
import { CalendarEvent } from "../../../domain";
import { ConferenceDateRange } from "../../../domain/ConferenceDateRange";
import { useCalendarEventsQuery } from "../../../hooks";
import { Content } from "./Content";
import { Header } from "./Header";
import "./styles.css";

export interface ConferenceCalendarProps {
  dateRange: ConferenceDateRange;
  setDateRange: (newRange: ConferenceDateRange) => void;
  openConfirmDialog: () => void;
}

export enum WeekChangeType {
  PREV,
  NEXT,
}

export const ConferenceCalendar: React.FC<ConferenceCalendarProps> = ({
  dateRange,
  setDateRange,
  openConfirmDialog,
}) => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const { data: calendarEvents, isLoading } = useCalendarEventsQuery();
  const [allEvents, setAllEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    if (calendarEvents) {
      setAllEvents(calendarEvents.events.flat());
    }
  }, [calendarEvents]);

  const handleClick = async (changeType: WeekChangeType) => {
    const amountOfdateRange = changeType === WeekChangeType.PREV ? -7 : 7;
    const newStart: Date =
      changeType === WeekChangeType.PREV &&
      isBefore(addDays(dateRange.startDate, amountOfdateRange), new Date())
        ? new Date()
        : addDays(dateRange.startDate, amountOfdateRange);
    setDateRange(ConferenceDateRange.of(newStart, addDays(newStart, 6)));
    setSelectedDay(newStart);
    trackLoadMoreConference(changeType);
  };

  return (
    <div className="calendarContainer">
      {!isLoading ? (
        <>
          <Header
            dateRange={dateRange}
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            handleClick={handleClick}
            allEvents={allEvents}
          />
          <Content
            dateRange={dateRange}
            selectedDay={selectedDay}
            allEvents={allEvents}
            openConfirmDialog={openConfirmDialog}
          />
        </>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
};
