import murmurhash from "murmurhash";
import { useCurrentUser } from "../../authentication/hooks";

export function useFeatureFlag<VariationType>(
  variations: VariationType[]
): VariationType {
  try {
    const currentUser = useCurrentUser();
    const hash = murmurhash.v3(currentUser.id);
    const bucket = getBucketFrom(hash, variations.length);
    return variations[bucket] ?? variations[0];
  } catch (err) {
    return variations[0];
  }
}

function getBucketFrom(hash: number, buckets: number): number {
  const value = hash % 100;
  return Math.floor(value / (100 / buckets));
}
