import { Box, ListItemText, MenuItem, styled, TextField } from "@mui/material";
import React from "react";
import { Layers } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtInputAdornment } from "../../../../../components";
import { trackFloorChange } from "../../../../../tracking";
import { Floor } from "../../../../assets/domain";

export interface FloorSelectorProps {
  floor: Floor | null;
  floors: Floor[];
  onChangeFloor: (newFloor: Floor | null) => void;
}

const FloorSelectorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  gap: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    flexGrow: 1,
    alignItems: "center",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.down("md")]: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
    minWidth: "auto",
    "& .MuiInputLabel-root": {
      flexGrow: 1,
    },
    "& .MuiOutlinedInput-root": {
      flexGrow: 1,
    },
  },
}));

export const FloorSelector: React.FC<FloorSelectorProps> = React.memo(
  ({ floors, floor, onChangeFloor }) => {
    const { t } = useTranslation("booking");

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
      const newFloor = floors.find((it) => it.id === event.target.value);

      if (newFloor) {
        trackFloorChange(newFloor, "bookings-filter");
      }
      onChangeFloor(newFloor ?? null);
    }

    return (
      <FloorSelectorContainer>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <AmtInputAdornment placement="start">
            <Layers size={16} />
          </AmtInputAdornment>
          <StyledTextField
            select
            className="MuiInput-leftAdornment"
            value={floor?.id ?? "all"}
            disabled={floors.length <= 1}
            onChange={onChange}
            data-testid="book--filters--floor"
          >
            {floors.map((floor) => (
              <MenuItem key={floor.id} value={floor.id}>
                <ListItemText primary={floor.description} />
              </MenuItem>
            ))}
            <MenuItem value="all">
              <ListItemText primary={t("floors.items.all")} />
            </MenuItem>
          </StyledTextField>
        </Box>
        {/*<Tooltip title={t("floors.items.all")}>*/}
        {/*  <Box>*/}
        {/*    <Button*/}
        {/*      variant="icon"*/}
        {/*      color="secondary"*/}
        {/*      disabled={floors.length <= 1}*/}
        {/*      onClick={() => onChangeFloor(null)}*/}
        {/*      data-testid="book--filters--all-floors"*/}
        {/*    >*/}
        {/*      <Undo fontSize="small" />*/}
        {/*    </Button>*/}
        {/*  </Box>*/}
        {/*</Tooltip>*/}
      </FloorSelectorContainer>
    );
  }
);
