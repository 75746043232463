import { Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtDefaultBadge } from "../../../../components";
import { useBookingNavigation } from "../../../../hooks";
import { BookingInfoComparison } from "../../../booking/components";
import { BookingDate } from "../../../booking/domain";
import { usePermissions } from "../../../permissions/hooks";
import { isDeskBookingNotification, UpdatedNotification } from "../../domain";
import { NotificationItem } from "./NotificationItem";

export interface UpdatedNotificationItemProps {
  onClose: () => void;
  notification: UpdatedNotification;
}

export const UpdatedNotificationItem: React.FC<
  UpdatedNotificationItemProps
> = ({ onClose, notification }) => {
  const { t } = useTranslation("notifications");
  const { bookings } = usePermissions();
  const navigateToBooking = useBookingNavigation();
  const snackbar = useSnackbar();

  const isDeskBooking = isDeskBookingNotification(notification.context.after);
  const handleClick = useMemoizedFn(() => {
    if (
      !bookings.canViewBookingsOnDate(notification.context.after.bookingDate)
    ) {
      snackbar.enqueueSnackbar(t("feedback.expired"), {
        variant: "warning",
      });
      return;
    }
    navigateToBooking({
      date: notification.context.after.bookingDate,
      locationId: notification.context.after.location.id,
      ...(isDeskBooking && {
        deskId: notification.context.after.desk.id,
        floorId: notification.context.after.floor.id,
      }),
      action: "view",
    });
  });

  const keyPrefix = isDeskBooking ? "desk" : "location";

  return (
    <NotificationItem
      onClick={handleClick}
      onClose={onClose}
      notification={notification}
      badge={
        <AmtDefaultBadge color="orangelight">
          {t(`${keyPrefix}-updated.badge`)}
        </AmtDefaultBadge>
      }
      content={
        <>
          <Typography variant="body2" sx={{ mb: 1, mt: 1 }}>
            {t(`${keyPrefix}-updated.text`)}
          </Typography>
          <BookingInfoComparison
            before={{
              date: BookingDate.of(notification.context.before.bookingDate),
              location: notification.context.before.location,
              bookedBy: notification.context.before.bookedBy,
              ...(isDeskBookingNotification(notification.context.before) && {
                floor: notification.context.before.floor,
                space: notification.context.before.space,
                desk: notification.context.before.desk,
              }),
            }}
            after={{
              date: BookingDate.of(notification.context.after.bookingDate),
              location: notification.context.after.location,
              bookedBy: notification.context.after.bookedBy,
              ...(isDeskBookingNotification(notification.context.after) && {
                floor: notification.context.after.floor,
                space: notification.context.after.space,
                desk: notification.context.after.desk,
              }),
            }}
          />
        </>
      }
    />
  );
};
