import React from "react";
import { BackofficePageContainer } from "../../../components";
import { AssetManagement } from "../AssetManagement";

export const AssetManagementPage: React.FC = () => {
  return (
    <BackofficePageContainer>
      <AssetManagement />
    </BackofficePageContainer>
  );
};
