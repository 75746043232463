import { Box, Button, Paper, styled, Typography } from "@mui/material";
import {
  SelfServiceLoginFlow,
  SuccessfulSelfServiceLoginWithoutBrowser,
} from "@ory/kratos-client";
import React, { useLayoutEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Configuration } from "../../../../config";
import { LogoSvg } from "../../../layout/assets";
import { FullPageError } from "../../../layout/FullPageError";
import {
  api,
  getRedirectBrowserTo,
  isFlowInstance,
  isSessionAlreadyAvailableError,
} from "../../api";
import { UiMessages } from "../../components";
import { FullPageLoader } from "../../layout";
import { OidcForm, PasswordForm, SectionDivider } from "./components";
import MatoriSnagaSrc from "./MatoriSnaga.png";

function useLoginFlow(options?: {
  flowId?: string;
  aal?: string;
}): UseQueryResult<SelfServiceLoginFlow | null> {
  const navigate = useNavigate();

  function initiateLoginFlow(): Promise<SelfServiceLoginFlow | null> {
    return api
      .initializeSelfServiceLoginFlowForBrowsers(undefined, options?.aal)
      .then((res) => res.data)
      .catch((err) => {
        if (isSessionAlreadyAvailableError(err?.response.data)) {
          return Promise.resolve(null);
        }
        return Promise.reject(err);
      });
  }

  function getSelfServiceLoginFlow(flowId: string) {
    return api
      .getSelfServiceLoginFlow(flowId)
      .then((response) => response.data);
  }

  function getOrInitiateLoginFlow(): Promise<SelfServiceLoginFlow | null> {
    if (options?.flowId) {
      return getSelfServiceLoginFlow(options.flowId).catch(initiateLoginFlow);
    } else {
      return initiateLoginFlow();
    }
  }

  return useQuery("login", getOrInitiateLoginFlow, {
    retry: false,
    onSuccess(flow: SelfServiceLoginFlow | null) {
      if (flow) {
        navigate(`/login?flow=${flow.id}`, { replace: true });
      } else {
        navigate("/");
      }
    },
  });
}

function useLogin(): UseMutationResult<
  SuccessfulSelfServiceLoginWithoutBrowser,
  unknown,
  { flow: SelfServiceLoginFlow; values: any }
> {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    ["login"],
    ({ flow, values }) =>
      api
        .submitSelfServiceLoginFlow(flow.id, undefined, values)
        .then((res) => res.data),
    {
      onSuccess() {
        navigate("/");
      },
      onError(err: any) {
        const response = err.response?.data;
        const redirectUrl = getRedirectBrowserTo(err.response?.data);
        if (redirectUrl) {
          document.location = redirectUrl;
        } else if (isFlowInstance<SelfServiceLoginFlow>(response)) {
          queryClient.setQueryData("login", response);
        }
      },
    }
  );
}

const StyledImg = styled("img")(({ theme }) => ({
  position: "absolute",
  left: "calc(50% - 30%/2)",
  bottom: "-70px",
  width: "30%",
}));

const AccentedText = styled("span")(({ theme }) => ({
  color: theme.palette.green.regular,
}));

const FullPageContainer = styled(Box)({
  width: "100vw",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const LoginForm: React.FC<{ flow: SelfServiceLoginFlow }> = ({ flow }) => {
  const { t } = useTranslation("anonymous");
  const { mutateAsync: submit } = useLogin();

  async function handleSubmit(values: any): Promise<void> {
    await submit({
      flow,
      values,
    });
  }

  const oidcEnabled = Configuration.login.includes("oidc");
  const passwordEnabled = Configuration.login.includes("password");

  return (
    <FullPageContainer>
      <Paper
        sx={(theme) => ({
          width: 351,
          minHeight: 356,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          padding: 0.5,
          boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.15);",
          borderRadius: "16px",
          backdropFilter: "blur(4px)",

          [theme.breakpoints.down("md")]: {
            width: "100%",
            height: "100vh",
            borderRadius: 0,
            gap: "80px",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            height: "144px",
            backgroundColor: "orange.light",
            overflow: "hidden",
            borderRadius: "16px",
            position: "relative",

            [theme.breakpoints.down("md")]: {
              flex: "1 1 auto",
              maxHeight: "322px",
            },
          })}
        >
          <StyledImg src={MatoriSnagaSrc} alt="" role="presentation" />
        </Box>

        <Box
          sx={(theme) => ({
            paddingX: 3,
            paddingY: 2,
            [theme.breakpoints.down("md")]: {
              flex: "1 1 auto",
            },
          })}
        >
          <Typography variant="h5" align="center">
            <Trans
              t={t}
              i18nKey="login.welcome"
              components={{
                Accent: <AccentedText />,
              }}
            />
          </Typography>
          <Box sx={{ width: "fit-content", margin: "auto" }}>
            <LogoSvg />
          </Box>

          <Typography variant="h6" align="center">
            {t("login.tagline")}
          </Typography>

          {flow.ui.messages && <UiMessages messages={flow.ui.messages} />}

          <SectionDivider>{t("login.join-us")}</SectionDivider>

          {oidcEnabled && <OidcForm handleSubmit={handleSubmit} ui={flow.ui} />}

          {passwordEnabled && (
            <>
              {oidcEnabled && (
                <SectionDivider sx={{ marginTop: 2 }}>
                  {t("login.admin")}
                </SectionDivider>
              )}

              <PasswordForm handleSubmit={handleSubmit} ui={flow.ui} />
            </>
          )}
        </Box>
      </Paper>
    </FullPageContainer>
  );
};

export const LoginPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get("flow");
  const aal = searchParams.get("aal");
  const login = useLoginFlow({
    ...(flowId && { flowId }),
    ...(aal && { aal }),
  });

  function reloadPage() {
    document.location = "/login";
  }

  useLayoutEffect(() => {
    const bgClassName = "green-bg";
    document.body.classList.add(bgClassName);
    return function () {
      document.body.classList.remove(bgClassName);
    };
  });

  if (login.isError) {
    return (
      <FullPageError
        message="An unexpected error has occurred."
        actions={
          <Button variant="contained" onClick={reloadPage} sx={{ mt: 2 }}>
            Try again
          </Button>
        }
      />
    );
  }

  if (login.isLoading || !login.data) {
    return <FullPageLoader />;
  }

  return <LoginForm flow={login.data} />;
};
