import { useQuery, UseQueryResult } from "react-query";
import { OrganizationMembership } from "../../modules/authentication/domain";
import { useOrganizationMembership } from "../../modules/authentication/hooks";
import { getAdminUsers } from "../api";

export function useAdminUsersQuery(): UseQueryResult<OrganizationMembership[]> {
  const { organization } = useOrganizationMembership();

  return useQuery(["admin-users", organization.id], () =>
    getAdminUsers(organization.id)
  );
}
