import {
  addBusinessDays,
  addDays,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
} from "date-fns";

export class AnalyticsDateRange {
  private _days: Date[] | null = null;

  protected constructor(
    public readonly startDate: Date,
    public readonly endDate: Date
  ) {
    if (isBefore(endDate, startDate)) {
      this.endDate = this.startDate;
    }
  }

  get workingDays(): Date[] {
    if (this._days !== null) {
      return this._days;
    }
    this._days = this.calculateWorkingDays();
    return this._days;
  }

  get isSingleDay(): boolean {
    return isSameDay(this.startDate, this.endDate);
  }

  get isDateRange(): boolean {
    return !this.isSingleDay;
  }

  get isSameMonth(): boolean {
    return this.startDate.getMonth() === this.endDate.getMonth();
  }

  get dateCellWidth(): string {
    return `${this.workingDays.length * 66}px`;
  }

  get firstMonthWidth(): string {
    if (this.isSameMonth) return "100%";
    const firstMonthDays = this.workingDays.filter((day) =>
      isSameMonth(day, this.startDate)
    );
    return `${firstMonthDays.length * 66}px`;
  }

  public static of(startDate: Date, endDate?: Date, isAdmin?: boolean) {
    const date = new AnalyticsDateRange(startDate, endDate ?? startDate);
    if (!isAdmin && date.workingDays.length > 10) {
      return new AnalyticsDateRange(startDate, addBusinessDays(startDate, 9));
    }
    return date;
  }

  private calculateWorkingDays(): Date[] {
    let date = this.startDate;
    const result: Date[] = [];

    while (isBefore(date, this.endDate) || isSameDay(date, this.endDate)) {
      if (!isWeekend(date)) {
        result.push(date);
      }
      date = addDays(date, 1);
    }
    return result;
  }
}
