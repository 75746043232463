import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSearchParams } from "react-router-dom";
import { ConferenceRoom } from "../../domain";
import { useConferenceRooms } from "../../hooks";
import { useConferenceForm } from "../useConferenceForm";

export interface UseSelectionResult {
  selectedConferenceRoom: ConferenceRoom | null;
  onSelectConferenceRoom(conferenceRoom: ConferenceRoom | null): void;
}

export function useConferenceSelection(): UseSelectionResult {
  const conferenceForm = useConferenceForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: conferenceRooms } = useConferenceRooms();

  const conferenceRoomId = searchParams.get("conference");

  const selectedConferenceRoom =
    conferenceRooms?.find(
      (conferenceRoom) => conferenceRoom.id === conferenceRoomId
    ) ?? null;

  const onSelectConferenceRoom = useMemoizedFn(
    (conferenceRoom: ConferenceRoom | null) => {
      const updated = new URLSearchParams(searchParams);
      updated.delete("desk");
      if (conferenceRoom === null) {
        updated.delete("conference");
      } else {
        updated.set("location", conferenceRoom.location.id);
        updated.set("floor", conferenceRoom.floor.id);
        if (updated.get("room")) {
          updated.set("room", conferenceRoom.space.id);
        }
        updated.set("conference", conferenceRoom.id);

        conferenceForm.openDialog();
      }
      setSearchParams(updated);
    }
  );

  return {
    selectedConferenceRoom,
    onSelectConferenceRoom,
  };
}
