import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { UpcomingBooking } from "../../../domain";
import { useSelectedBooking } from "./useSelectedBooking";

const floorPlanView = atom<boolean>(false);

export type UseFloorPlanViewResult = [
  boolean,
  (booking: UpcomingBooking) => void
];

export function useIsFloorPlanVisible(): UseFloorPlanViewResult {
  const [isFloorPlanView, setIsFloorPlanView] = useAtom(floorPlanView);
  const [selectedBooking, selectBooking] = useSelectedBooking();

  const openFloorPlan = useMemoizedFn((booking: UpcomingBooking) => {
    selectBooking(booking);
    setIsFloorPlanView(true);
  });

  return [selectedBooking ? isFloorPlanView : false, openFloorPlan];
}
