import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export interface SearchNavigation {
  onRemoveUser(userId: string): void;
  onRemoveTeam(teamId: string): void;
  onRemoveLocation(locationId: string): void;
}

export function useSearchNavigation(): SearchNavigation {
  const [searchParams, setSearchParams] = useSearchParams();

  const onRemoveUser = useCallback(
    (userId: string) => {
      const userIds = searchParams.getAll("userIds") ?? [];
      const updatedUserIds = userIds.filter((id) => id !== userId);
      const updatedParams = new URLSearchParams(searchParams);
      if (updatedUserIds.length > 0) {
        updatedParams.set("userIds", updatedUserIds[0]);
        for (let i = 1; i < updatedUserIds.length; i++) {
          updatedParams.append("userIds", updatedUserIds[i]);
        }
      } else updatedParams.delete("userIds");
      setSearchParams(updatedParams);
    },
    [searchParams, setSearchParams]
  );

  const onRemoveTeam = useCallback(
    (teamId: string) => {
      const teamIds = searchParams.getAll("teamIds") ?? [];
      const updatedTeamIds = teamIds.filter((id) => id !== teamId);
      const updatedParams = new URLSearchParams(searchParams);
      if (updatedTeamIds.length > 0) {
        updatedParams.set("teamIds", updatedTeamIds[0]);
        for (let i = 1; i < updatedTeamIds.length; i++) {
          updatedParams.append("teamIds", updatedTeamIds[i]);
        }
      } else updatedParams.delete("teamIds");
      setSearchParams(updatedParams);
    },
    [searchParams, setSearchParams]
  );

  const onRemoveLocation = useCallback(
    (locationId: string) => {
      const locationIds = searchParams.getAll("locationIds") ?? [];
      const updatedLocationIds = locationIds.filter((id) => id !== locationId);
      const updatedParams = new URLSearchParams(searchParams);
      if (updatedLocationIds.length > 0) {
        updatedParams.set("locationIds", updatedLocationIds[0]);
        for (let i = 1; i < updatedLocationIds.length; i++) {
          updatedParams.append("locationIds", updatedLocationIds[i]);
        }
      } else updatedParams.delete("locationIds");
      setSearchParams(updatedParams);
    },
    [searchParams, setSearchParams]
  );

  return {
    onRemoveUser,
    onRemoveTeam,
    onRemoveLocation,
  };
}
