import { Box, Button, styled, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBookingNavigation } from "../../../../../hooks";
import { Booking } from "../../../domain";
import { SelectedBookingLocationHeader } from "../SelectedBookingLocationHeader";
import EmptyIconSrc from "./EmptyIcon.svg";

const StyledPaper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  background: "white",
  boxShadow: "0px 4px 4px rgba(20, 17, 16, 0.05)",
  borderRadius: "10px",
  border: "1px solid",
  borderColor: theme.palette.shades.greyLight,
  paddingTop: theme.spacing(19),
  paddingBottom: theme.spacing(5),
  paddingX: theme.spacing(3),
  justifyContent: "center",
  display: "flex",
}));

const StyledPaperContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxWidth: 450,
  alignItems: "center",
  textAlign: "center",
}));

export interface LocationBookingEmptyStateProps {
  booking: Booking;
}

export const LocationBookingEmptyState: React.FC<
  LocationBookingEmptyStateProps
> = ({ booking }) => {
  const { t } = useTranslation("dashboard");
  const navigate = useBookingNavigation();
  const handleNavigate = useMemoizedFn(() => {
    navigate({
      date: booking.bookingDate,
      locationId: booking.location.id,
      bookingId: booking.id,
      action: "view",
    });
  });

  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        padding: 2,
        height: "100%",

        display: "flex",
        flexDirection: "column",

        [theme.breakpoints.up("md")]: {
          paddingTop: 1,
        },
      })}
    >
      <SelectedBookingLocationHeader />
      <StyledPaper>
        <StyledPaperContent>
          <img src={EmptyIconSrc} alt="" role="presentation" />
          <Typography variant="h6">
            {t("location-booking-preview.title")}
          </Typography>
          <Typography variant="body1">
            {t("location-booking-preview.description")}
          </Typography>
          <Button onClick={handleNavigate} color="secondary" variant="outlined">
            {t("location-booking-preview.view-bookings")}
          </Button>
        </StyledPaperContent>
      </StyledPaper>
    </Box>
  );
};
