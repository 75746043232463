import { Box, Components } from "@mui/material";
import { ChevronDown } from "react-feather";

export const MuiSelect: Components["MuiSelect"] = {
  defaultProps: {
    MenuProps: {
      anchorOrigin: {
        horizontal: "center",
        vertical: 50,
      },
    },
    IconComponent: (props) => (
      <Box
        sx={{
          pr: 1.5,
          userSelect: "none",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          height: "100%",
          position: "absolute",
          right: 0,
        }}
      >
        <ChevronDown size={16} />
      </Box>
    ),
  },
  styleOverrides: {
    // @ts-ignore
    select: {
      ".MuiListItemText-root": {
        margin: 0,
      },
      ".MuiTypography-root": {
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
};
