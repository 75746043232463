import { useCreation } from "ahooks";
import { useOrganizationMembership } from "../../../../modules/authentication/hooks";
import { useDedicatedGroups } from "../../../hooks";
import { GroupMember } from "../domain";

export const useDedicatedOptions = (value: GroupMember[]): GroupMember[] => {
  const { organization } = useOrganizationMembership();
  const { data: organizationGroups } = useDedicatedGroups(organization.id);

  return useCreation(() => {
    if (!organizationGroups) return [];
    return organizationGroups.allMembers;
  }, [organizationGroups?.groups, value]);
};
