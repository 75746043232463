import { Box } from "@mui/material";
import React from "react";

export interface LocationBoxProps {
  forCurrentUser?: boolean;
}

export const LocationBox: React.FC<LocationBoxProps> = ({ forCurrentUser }) => {
  return (
    <Box
      className="Amt--LocationBookingItem--Box"
      sx={{
        width: 42,
        height: 42,
        padding: "3px",
        borderRadius: "8px",
        backgroundColor: "cyclamen.light",
      }}
    >
      {forCurrentUser && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            backgroundColor: "orange.light",
          }}
        />
      )}
    </Box>
  );
};
