import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { OrganizationMembership } from "../../../../modules/authentication/domain";

type DeleteAdminFormState =
  | {
      isOpen: false;
      admin?: OrganizationMembership;
    }
  | {
      isOpen: true;
      admin: OrganizationMembership;
    };

export type UseDeleteAdminFormResult = DeleteAdminFormState & {
  openDialog(admin: OrganizationMembership): void;
  closeDialog(): void;
};

const formStateAtom = atom<DeleteAdminFormState>({ isOpen: false });

export function useDeleteAdminForm(): UseDeleteAdminFormResult {
  const [formState, setFormState] = useAtom(formStateAtom);

  const openDialog = useMemoizedFn((admin: OrganizationMembership) => {
    setFormState({
      isOpen: true,
      admin,
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isOpen: false,
    });
  });

  return {
    ...formState,
    openDialog,
    closeDialog,
  };
}
