import { Box, DialogContent } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtError } from "../../../../../../api";
import { AmtAlert, AmtDialog } from "../../../../../../components";
import { useOrganizationMembership } from "../../../../../../modules/authentication/hooks";
import { trackUpdateDeskStatus } from "../../../../../../tracking";
import { useDedicatedGroups } from "../../../../../hooks";
import {
  useBackofficeFloors,
  useBackofficeLocations,
} from "../../../../../state";
import { DeskStatus, GroupMember } from "../../../domain";
import { useChangeDeskStatusMutation } from "../../../hooks";
import { useAssetForm } from "../../../state";
import { DialogActions, DialogInfo, DialogTitle } from "./components";
import {
  DedicatedWarning,
  DialogInputSection,
} from "./components/DialogInputSection.tsx";
import { FormikCheckbox } from "./components/DialogInputSection.tsx/ApplyToRoomCheckbox";
import { FormikDedicatedAutocomplete } from "./components/DialogInputSection.tsx/DedicatedAutocomplete";
import { FormikStatusPicker } from "./components/DialogInputSection.tsx/StatusPicker";

export interface DeskManagementFormValues {
  status: DeskStatus;
  dedicatedValues: GroupMember[];
  applyToRoom: boolean;
}

export const DeskManagementDialog: React.FC = () => {
  const form = useAssetForm();
  const { isOpen, closeDialog } = form;
  const { location } = useBackofficeLocations();
  const changeDeskStatus = useChangeDeskStatusMutation();
  const { floor } = useBackofficeFloors();
  const { t } = useTranslation("asset-management");

  if (!form.desk) {
    throw new Error("Invalid usage of DeskManagementDialog");
  }

  const doSubmit = useMemoizedFn((values: DeskManagementFormValues) => {
    if (!form.desk) {
      throw new Error("Invalid usage of DeskManagementDialog");
    }
    return changeDeskStatus.mutateAsync(values);
  });

  const handleSubmit = useMemoizedFn(
    (
      values: DeskManagementFormValues,
      formik: FormikHelpers<DeskManagementFormValues>
    ) => {
      trackUpdateDeskStatus(values.status);
      return doSubmit(values).catch((err: AmtError) => {
        formik.setFieldError("bookFor", t("dialog.feedback.update-desk-error"));
      });
    }
  );

  const validate = useMemoizedFn(
    async (
      values: DeskManagementFormValues
    ): Promise<FormikErrors<DeskManagementFormValues>> => {
      const errors: FormikErrors<DeskManagementFormValues> = {};

      if (
        values.status === DeskStatus.DEDICATED &&
        values.dedicatedValues.length === 0
      ) {
        errors.dedicatedValues = "";
      }
      return errors;
    }
  );

  const { organization } = useOrganizationMembership();
  const { data: organizationGroups } = useDedicatedGroups(organization.id);

  const initialValue = organizationGroups?.allMembers.filter(
    (member) => member.deskIsDedicatedToMember
  );

  const initialStatus = form.desk ? form.desk.deskStatus : DeskStatus.FREE;

  return (
    <AmtDialog open={isOpen} onClose={closeDialog} closeButton>
      <Formik
        enableReinitialize
        initialValues={{
          status: initialStatus,
          dedicatedValues: initialValue ?? [],
          applyToRoom: false,
        }}
        validate={validate}
        validateOnMount
        // @ts-ignore
        initialTouched={{ status: true, dedicatedValues: true }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, isValidating, values }) => {
          return (
            <DialogContent>
              <DialogTitle />
              <Form style={{ margin: 0 }}>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginBottom: "20px",
                    },
                  })}
                >
                  <DialogInfo
                    sx={(theme) => ({
                      marginY: 3,
                      [theme.breakpoints.down("md")]: {
                        marginY: 1,
                      },
                    })}
                    desk={form.desk ? form.desk.desk : null}
                    location={location ? location.location : null}
                    floor={floor}
                    space={form.desk ? form.desk.desk.space : null}
                  />
                </Box>
                <DialogInputSection label={t("dialog.desk-status")}>
                  <Field name="status" component={FormikStatusPicker} />
                </DialogInputSection>
                {values.status === DeskStatus.DEDICATED && (
                  <>
                    <DedicatedWarning />
                    <DialogInputSection
                      label={t("dialog.dedicated-for")}
                      sx={{ marginTop: 1 }}
                    >
                      <Field
                        name="dedicatedValues"
                        component={FormikDedicatedAutocomplete}
                        multiple
                        placeholder={
                          values.dedicatedValues.length === 0
                            ? t("dialog.form.add-people")
                            : undefined
                        }
                      />
                    </DialogInputSection>
                  </>
                )}
                <Field
                  name="applyToRoom"
                  component={FormikCheckbox}
                  type="checkbox"
                />
                {values.applyToRoom && (
                  <AmtAlert
                    text={t("dialog.feedback.apply-to-all-warning")}
                    title={""}
                    variant="error"
                  />
                )}
                <DialogActions
                  onCancel={closeDialog}
                  isSubmitting={isSubmitting}
                  isInvalid={
                    values.status === DeskStatus.DEDICATED &&
                    values.dedicatedValues.length === 0
                  }
                />
              </Form>
            </DialogContent>
          );
        }}
      </Formik>
    </AmtDialog>
  );
};
