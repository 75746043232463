import { Box, Typography } from "@mui/material";
import React from "react";
import { ContentColumn } from ".";
import { useIsMobile } from "../../../../../hooks";
import { CalendarEvent } from "../../../domain";
import { ConferenceDateRange } from "../../../domain/ConferenceDateRange";
import "./styles.css";

export interface ContentProps {
  dateRange: ConferenceDateRange;
  selectedDay: Date;
  allEvents: CalendarEvent[];
  openConfirmDialog: () => void;
}

export interface EventInfo {
  event: CalendarEvent | null;
  duration: number;
  movePercentage: number;
  eventColor: string;
  allDay: boolean;
  eventStart: Date;
  eventEnd: Date;
}

export const Content: React.FC<ContentProps> = ({
  dateRange,
  selectedDay,
  allEvents,
  openConfirmDialog,
}) => {
  const isMobile = useIsMobile();

  const hours: number[] = [];
  for (let i = 9; i < 24; i++) {
    hours.push(i);
  }

  const pad = (n: number) => {
    return n < 10 ? "0" + n : n;
  };

  return (
    <div className="contentContainer" onClick={openConfirmDialog}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {hours &&
          hours.map((hour) => {
            return (
              <Box
                sx={{
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
                key={hour}
              >
                <Typography variant="overline" id={`calendar-hour-${hour}`}>
                  {pad(hour)}:00
                </Typography>
              </Box>
            );
          })}
      </Box>
      {!isMobile ? (
        dateRange.workingDays.map((day, index) => (
          <ContentColumn
            key={index}
            day={day}
            hours={hours}
            allEvents={allEvents}
          />
        ))
      ) : (
        <ContentColumn day={selectedDay} hours={hours} allEvents={allEvents} />
      )}

      <div className="empty"></div>
    </div>
  );
};
