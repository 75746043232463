import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../authentication/hooks";
import { formatISODate } from "../../booking/api";
import { getCalendarEvents } from "../api";
import { CalendarEvents } from "../domain";
import { useConferenceSelection } from "../state";
import { useConferenceDateRange } from "../state/useConferenceDateRange";
import { useConferenceForm } from "../state/useConferenceForm";

export function useCalendarEventsQuery(): UseQueryResult<CalendarEvents> {
  const { organization } = useOrganizationMembership();
  const { selectedConferenceRoom } = useConferenceSelection();
  if (!selectedConferenceRoom) {
    throw new Error("No conference room selected");
  }
  const { dateRange } = useConferenceDateRange();
  const form = useConferenceForm();
  return useQuery(
    [
      "calendar-events",
      selectedConferenceRoom.id,
      formatISODate(dateRange.startDate),
    ],
    () =>
      getCalendarEvents(
        organization.id,
        selectedConferenceRoom.id,
        dateRange.startDate
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 60000,
      enabled: form.isOpen,
    }
  );
}
