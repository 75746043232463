import { Box } from "@mui/material";
import React from "react";
import { useBackofficeLocations } from "../../state";
import { LocationPreview, LocationsList } from "./components";

export const AssetManagement: React.FC = () => {
  const { location } = useBackofficeLocations();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "hidden",
      }}
    >
      {location ? <LocationPreview /> : <LocationsList />}
    </Box>
  );
};
