import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { AssetInventoryType } from "../../../../../../../modules/booking/domain";

export interface InventoryTypePickerProps {
  inventoryTypes: AssetInventoryType[];
  value: string;
  onChange: (type: string) => void;
}

export const InventoryTypePicker: React.FC<InventoryTypePickerProps> =
  React.memo(({ inventoryTypes, value, onChange }) => {
    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onChange(event.target.value);
    };

    const typeName = (id: string) => {
      const type = inventoryTypes.find((type) => type.id === id);
      return type ? type.name : "";
    };

    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={(theme) => ({
            display: "flex",
            flex: "1 1 auto",
            [theme.breakpoints.down("md")]: {
              marginBottom: 1,
            },
          })}
        >
          <TextField
            fullWidth
            select
            value={value}
            onChange={(event) => handleChange(event)}
            SelectProps={{
              renderValue: () => <ListItemText primary={typeName(value)} />,
              displayEmpty: true,
            }}
            data-testid="book--filters--location"
          >
            {inventoryTypes.map((type) => {
              return (
                <MenuItem key={type.id} value={type.id}>
                  <ListItemText primary={type.name} />
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      </Box>
    );
  });
