import {
  BookingDate,
  DeskAvailability,
  DeskOverview,
  LocationBookingOverview,
} from "../../../../domain";

export abstract class AvailabilityFilter {
  protected constructor(private value: string) {}

  public abstract isApplicableTo(date: BookingDate): boolean;

  public abstract filterDesk(desk: DeskOverview): boolean;
  public abstract filterLocationBooking(
    locationBooking: LocationBookingOverview
  ): boolean;

  public toString(): string {
    return this.value;
  }
}

class AnyFilter extends AvailabilityFilter {
  private constructor() {
    super("ANY");
  }

  isApplicableTo(date: BookingDate): boolean {
    return true;
  }

  filterDesk(desk: DeskOverview): boolean {
    return true;
  }

  filterLocationBooking(locationBooking: LocationBookingOverview): boolean {
    return true;
  }

  static INSTANCE = new AnyFilter();
}

class OnlyAvailableFilter extends AvailabilityFilter {
  private constructor() {
    super("ONLY_AVAILABLE");
  }

  isApplicableTo(date: BookingDate): boolean {
    return true;
  }

  filterDesk(desk: DeskOverview): boolean {
    return desk.availability === DeskAvailability.AVAILABLE;
  }

  filterLocationBooking(locationBooking: LocationBookingOverview): boolean {
    return false;
  }

  static INSTANCE = new OnlyAvailableFilter();
}

class OnlyBookedFilter extends AvailabilityFilter {
  private constructor() {
    super("ONLY_BOOKED");
  }

  isApplicableTo(date: BookingDate): boolean {
    return date.isSingleDay;
  }

  filterDesk(desk: DeskOverview): boolean {
    return desk.availability !== DeskAvailability.AVAILABLE;
  }

  filterLocationBooking(locationBooking: LocationBookingOverview): boolean {
    return true;
  }

  static INSTANCE = new OnlyBookedFilter();
}

class PartiallyAvailableFilter extends AvailabilityFilter {
  private constructor() {
    super("PARTIALLY_AVAILABLE");
  }

  isApplicableTo(date: BookingDate): boolean {
    return date.isDateRange;
  }

  filterDesk(desk: DeskOverview): boolean {
    return desk.availability === DeskAvailability.PARTIALLY_AVAILABLE;
  }

  filterLocationBooking(locationBooking: LocationBookingOverview): boolean {
    return true;
  }

  static INSTANCE = new PartiallyAvailableFilter();
}

class NotAvailableFilter extends AvailabilityFilter {
  private constructor() {
    super("NOT_AVAILABLE");
  }

  isApplicableTo(date: BookingDate): boolean {
    return date.isDateRange;
  }

  filterDesk(desk: DeskOverview): boolean {
    return desk.availability === DeskAvailability.NOT_AVAILABLE;
  }

  filterLocationBooking(locationBooking: LocationBookingOverview): boolean {
    return true;
  }

  static INSTANCE = new NotAvailableFilter();
}

class OnlyLocationBookings extends AvailabilityFilter {
  private constructor() {
    super("ONLY_LOCATION_BOOKINGS");
  }

  isApplicableTo(date: BookingDate): boolean {
    return true;
  }

  filterDesk(desk: DeskOverview): boolean {
    return false;
  }

  filterLocationBooking(locationBooking: LocationBookingOverview): boolean {
    return true;
  }

  static INSTANCE = new OnlyLocationBookings();
}

export class AvailabilityFilters {
  private constructor() {}

  static ANY: AvailabilityFilter = AnyFilter.INSTANCE;
  static ONLY_AVAILABLE: AvailabilityFilter = OnlyAvailableFilter.INSTANCE;
  static ONLY_BOOKED: AvailabilityFilter = OnlyBookedFilter.INSTANCE;
  static PARTIALLY_AVAILABLE = PartiallyAvailableFilter.INSTANCE;
  static NOT_AVAILABLE = NotAvailableFilter.INSTANCE;
  static ONLY_LOCATION_BOOKINGS = OnlyLocationBookings.INSTANCE;
  static ALL_VALUES = [
    this.ANY,
    this.ONLY_AVAILABLE,
    this.ONLY_BOOKED,
    this.PARTIALLY_AVAILABLE,
    this.NOT_AVAILABLE,
    this.ONLY_LOCATION_BOOKINGS,
  ];

  static valueOf(value: string): AvailabilityFilter {
    const found = this.ALL_VALUES.find((it) => it.toString() === value);
    if (found) {
      return found;
    }
    return AvailabilityFilters.ANY;
  }
}
