import qs from "query-string";
import { getCsrfToken } from "./csrf";
import { AmtError } from "./errors";

export async function getJson<Response>(
  uri: string,
  queryParams?: Record<string, any>
): Promise<Response> {
  if (queryParams) {
    const q = qs.stringify(queryParams);
    if (q.length) {
      uri += `?${q}`;
    }
  }
  const response = await fetch(uri, {
    credentials: "same-origin",
    redirect: "manual",
    headers: {
      accept: "application/json",
    },
  });
  handleResponseError(response);
  return response.json();
}

export async function request<Response = any, Request extends {} = {}>(
  method: string,
  uri: string,
  body?: Request
): Promise<Response> {
  const response = await fetch(uri, {
    method: method.toUpperCase(),
    headers: {
      "content-type": "application/json",
      accept: "application/json",
      "X-XSRF-TOKEN": getCsrfToken(),
    },
    body: body ? JSON.stringify(body) : null,
    credentials: "same-origin",
    redirect: "manual",
  });

  return handleResponseError(response).then(() => {
    return response.status !== 204 ? response.json() : null;
  });
}

function handleResponseError(response: Response): Promise<Response> {
  if (is3xxRedirect(response.status)) {
    // 3xx means it's most likely a redirect to the login page
    // either one should reload the window and OAuth will do the rest
    document.location.reload();
    return new Promise(() => {});
  }
  if (isUnauthorized(response.status) && !isCurrentUserRequest(response)) {
    // redirect to login page if 401 unauthorized and is not a request to getting the current user
    // @see {ProtectedRoute} for session error handling
    document.location = "/login";
    return new Promise(() => {});
  }
  if (is4xxClientError(response.status) || is5xxServerError(response.status)) {
    return AmtError.ofResponse(response);
  }
  return Promise.resolve(response);
}

function isCurrentUserRequest(response: Response): boolean {
  return /\/api\/current-user/g.test(response.url);
}

function is3xxRedirect(status: number): boolean {
  return status >= 300 && status < 400;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isUnauthorized(status: number): boolean {
  return status === 401;
}

function is4xxClientError(status: number): boolean {
  return status >= 400 && status < 500;
}

function is5xxServerError(status: number): boolean {
  return status >= 500 && status < 600;
}
