import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Field, Form, Formik, FormikErrors } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useOrganizationMembership } from "../../../../../authentication/hooks";
import { configureSageHr, SageHrConfigurationOptions } from "../../api";

export interface FormValues {
  apiUrl: string;
  apiKeyConfigured: boolean;
  apiKey: string;
}

export interface SageHrConfigurationDialogProps {
  initialValues?: FormValues;
  open: boolean;
  onClose: () => void;
}

const newConfigurationInitialValues: FormValues = {
  apiUrl: "",
  apiKeyConfigured: false,
  apiKey: "",
};

function validate(values: FormValues): FormikErrors<FormValues> {
  const errors: FormikErrors<FormValues> = {};
  if (values.apiUrl.trim().length === 0) {
    errors.apiUrl = "This is a required field";
  }
  if (!values.apiKeyConfigured && values.apiKey.trim().length === 0) {
    errors.apiKey = "This is a required field";
  }
  return errors;
}

export const SageHrConfigurationDialog: React.FC<
  SageHrConfigurationDialogProps
> = ({ initialValues, open, onClose }) => {
  const qc = useQueryClient();
  const { organization } = useOrganizationMembership();
  const { mutateAsync } = useMutation(
    ["configure-sage-hr", organization.id],
    (values: SageHrConfigurationOptions) =>
      configureSageHr(organization.id, values),
    {
      onSuccess: () => {
        qc.invalidateQueries("sage-hr-integration");
      },
    }
  );

  function handleSubmit(values: FormValues) {
    const options: SageHrConfigurationOptions = {
      ...(values.apiKey.length > 0 && { apiKey: values.apiKey }),
      apiUrl: values.apiUrl,
      enabled: true,
    };

    return mutateAsync(options).then(() => {
      onClose();
    });
  }

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <Formik
        initialValues={initialValues ?? newConfigurationInitialValues}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnMount
      >
        {({ isValid, isSubmitting, values }) => (
          <Form>
            <DialogTitle>Configure SageHR</DialogTitle>
            <Divider />
            <DialogContent>
              <Field
                component={TextField}
                fullWidth
                margin="normal"
                label="API Url"
                name="apiUrl"
                autocomplete="off"
              />
              <Field
                component={TextField}
                fullWidth
                margin="normal"
                label="API Token"
                name="apiKey"
                type="password"
                autocomplete="off"
                helperText={
                  values.apiKeyConfigured &&
                  "You already have an API key configured. Enter a new one to replace."
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Configure
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
