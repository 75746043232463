import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { formatISO } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { Desk, Floor, Location, Space } from "../../../../../assets/domain";
import { BookingDate, DeskOverview } from "../../../../domain";

export interface BookingNavigation {
  onChangeDate(date: BookingDate): void;
  onChangeLocation(location: Location): void;
  onChangeFloor(floor: Floor | null): void;
  onChangeSpace(space: Space | null): void;
  onChangeDesk(desk: DeskOverview | null): void;

  onNavigate(params: NavigationParams): void;

  clearDeskAndAction(): void;
}

function applyBookingDate(searchParams: URLSearchParams, newDate: BookingDate) {
  searchParams.set(
    "date",
    formatISO(newDate.startDate, { representation: "date" })
  );

  if (newDate.isDateRange) {
    searchParams.set(
      "date-end",
      formatISO(newDate.endDate, { representation: "date" })
    );
  } else {
    searchParams.delete("date-end");
  }
}

export function useBookingNavigation(): BookingNavigation {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeDate = useMemoizedFn((newDate: BookingDate) => {
    const updated = new URLSearchParams(searchParams);
    applyBookingDate(updated, newDate);
    updated.delete("booking");
    setSearchParams(updated);
  });

  const onChangeLocation = useMemoizedFn((newLocation: Location) => {
    const updated = new URLSearchParams(searchParams);
    updated.set("location", newLocation.id);
    updated.delete("floor");
    updated.delete("room");
    updated.delete("desk");
    updated.delete("booking");
    setSearchParams(updated);
  });

  const onChangeFloor = useMemoizedFn((newFloor: Floor | null) => {
    const updated = new URLSearchParams(searchParams);
    if (newFloor) {
      updated.set("location", newFloor.locationId);
      updated.set("floor", newFloor.id);
    } else {
      updated.delete("floor");
    }
    updated.delete("room");
    updated.delete("desk");
    updated.delete("booking");
    setSearchParams(updated);
  });

  const onChangeSpace = useMemoizedFn((newSpace: Space | null) => {
    const updated = new URLSearchParams(searchParams);
    if (newSpace) {
      updated.set("location", newSpace.locationId);
      updated.set("floor", newSpace.floorId);
      updated.set("room", newSpace.id);
    } else {
      updated.delete("room");
    }
    setSearchParams(updated);
  });

  const onNavigate = useMemoizedFn((params: NavigationParams) => {
    const updated = new URLSearchParams(searchParams);
    if (typeof params.date !== "undefined") {
      if (params.date !== null) {
        applyBookingDate(updated, params.date);
      } else {
        updated.delete("date");
        updated.delete("date-end");
      }
    }
    if (typeof params.location !== "undefined") {
      if (params.location !== null) {
        updated.set("location", params.location.id);
      } else {
        updated.delete("location");
      }
    }
    if (typeof params.floor !== "undefined") {
      if (params.floor !== null) {
        updated.set("floor", params.floor.id);
      } else {
        updated.delete("floor");
      }
    }
    if (typeof params.space !== "undefined") {
      if (params.space !== null) {
        updated.set("room", params.space.id);
      } else {
        updated.delete("room");
      }
    }
    if (typeof params.desk !== "undefined") {
      if (params.desk !== null) {
        updated.set("desk", params.desk.id);
      } else {
        updated.delete("desk");
      }
    }
    setSearchParams(updated);
  });

  const onChangeDesk = useMemoizedFn((desk: DeskOverview | null) => {
    const updated = new URLSearchParams(searchParams);
    if (desk) {
      updated.set("location", desk.location.id);
      updated.set("floor", desk.floor.id);
      if (updated.get("room")) {
        updated.set("room", desk.space.id);
      }
    }
    setSearchParams(updated);
  });

  const clearDeskAndAction = useMemoizedFn(() => {
    const updated = new URLSearchParams(searchParams);
    updated.delete("desk");
    updated.delete("booking");
    setSearchParams(updated, { replace: true });
  });

  return {
    onChangeDate,
    onChangeLocation,
    onChangeFloor,
    onChangeSpace,
    onChangeDesk,
    onNavigate,
    clearDeskAndAction,
  };
}

export class NavigationParams {
  public date?: BookingDate | null = undefined;
  public location?: Location | null = undefined;
  public floor?: Floor | null = undefined;
  public space?: Space | null = undefined;
  public desk?: Desk | null = undefined;

  static fromEmpty(): NavigationParams {
    return new NavigationParams()
      .removeDate()
      .removeFloor()
      .removeLocation()
      .removeSpace();
  }

  withDate(date: BookingDate): this {
    this.date = date;
    return this;
  }

  removeDate(): this {
    this.date = null;
    return this;
  }

  ignoreDate(): this {
    this.date = undefined;
    return this;
  }

  withLocation(location: Location): this {
    this.location = location;
    return this;
  }

  removeLocation(): this {
    this.location = null;
    return this;
  }

  ignoreLocation(): this {
    this.location = undefined;
    return this;
  }

  withFloor(floor: Floor): this {
    this.floor = floor;
    return this;
  }

  removeFloor(): this {
    this.floor = null;
    return this;
  }

  ignoreFloor(): this {
    this.floor = undefined;
    return this;
  }

  withSpace(space: Space): this {
    this.space = space;
    return this;
  }

  removeSpace(): this {
    this.space = null;
    return this;
  }

  ignoreSpace(): this {
    this.space = undefined;
    return this;
  }

  withDesk(desk: Desk): this {
    this.desk = desk;
    return this;
  }

  removeDesk(): this {
    this.desk = null;
    return this;
  }

  ignoreDesk(): this {
    this.desk = undefined;
    return this;
  }
}
