import { Divider, ListItemText, Menu, MenuItem } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import { CheckCircle, Circle } from "react-feather";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../styles";

interface FullNameMenuProps {
  popupState: PopupState;
}

export const FullNameMenu: React.FC<FullNameMenuProps> = ({ popupState }) => {
  const { t } = useTranslation("common");

  return (
    <Menu
      disableAutoFocusItem
      {...bindMenu(popupState)}
      PaperProps={{
        style: {
          width: 247,
        },
      }}
    >
      <MenuItem onClick={() => {}}>
        <Icon>
          <CheckCircle size={16} />
        </Icon>
        <ListItemText primary={t("components.table.user-menu.all")} />
      </MenuItem>
      <Divider />
      <MenuItem>
        <Icon>
          <Circle size={16} />
        </Icon>
        <ListItemText
          primary={`${t("components.table.user-menu.often")}`}
          secondary={`${t("components.table.sort.desc")}`}
        />
      </MenuItem>
      <MenuItem>
        <Icon>
          <Circle size={16} />
        </Icon>
        <ListItemText
          primary={`${t("components.table.user-menu.rarely")}`}
          secondary={`${t("components.table.sort.asc")}`}
        />
      </MenuItem>
    </Menu>
  );
};
