import { Box, Components, Theme } from "@mui/material";
import React from "react";
import { ChevronDown } from "react-feather";

const PopupIcon: React.FC = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        color: theme.palette.shades.grey,
      })}
    >
      <ChevronDown size={16} />
    </Box>
  );
};

export const MuiAutocomplete: Components<Theme>["MuiAutocomplete"] = {
  defaultProps: {
    popupIcon: <PopupIcon />,
  },
  styleOverrides: {
    paper: ({ theme }) => ({
      border: `1px solid ${theme.palette.shades.greyLight}`,
      borderRadius: "8px !important",
      background: "white",
      boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.1) !important",
      padding: theme.spacing(0, 1),
    }),
    listbox: ({ theme }) => ({
      padding: theme.spacing(0, 1),
    }),
  },
};
