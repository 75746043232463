import { Components, Theme } from "@mui/material";
import { darkenPrimary } from "../palette";

export const MuiButton: Components<Theme>["MuiButton"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      display: "inline-flex",
      gap: "10px",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(1, 2),
      borderRadius: "8px",
      letterSpacing: "normal",
      color: theme.palette.shades.grey,
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: "20px",
      textTransform: "none",
      "&:hover": {
        color: theme.palette.shades.darkLight,
        background: "transparent",
      },
      "&:focus, &:active": {
        color: theme.palette.green.regular,
      },
      "&.MuiButton-textPrimary .MuiButton-startIcon": {
        color: theme.palette.green.regular,
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        opacity: 0.55,
      },
    }),
    contained: {
      boxShadow: "0px 1px 2px rgba(20, 17, 16, 0.2)",
      "&:hover, &:focus": {
        boxShadow: "0px 1px 2px rgba(20, 17, 16, 0.2)",
      },
    },
    containedPrimary: ({ theme }) => ({
      color: "white",
      "&.Mui-disabled": {
        color: "white",
        backgroundColor: theme.palette.green.regular,
      },
      "&:hover": {
        backgroundColor: darkenPrimary(0.12),
        color: "white",
      },
      "&:focus": {
        color: "white",
      },
    }),
    // @ts-ignore
    containedCyclamen: ({ theme }) => ({
      color: "white",
      backgroundColor: theme.palette.cyclamen.regular,
      "&.Mui-disabled": {
        color: "white",
        backgroundColor: theme.palette.cyclamen.regular,
        opacity: 0.55,
      },
      "&:hover": {
        backgroundColor: theme.palette.cyclamen.hover,
        color: "white",
      },
      "&:focus, &:focus-visible": {
        color: "white",
      },
    }),
    startIcon: {
      margin: 0,
    },
    endIcon: {
      margin: 0,
    },
    outlinedPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
      borderColor: theme.palette.shades.greyLight,
      backgroundColor: "white",
      "&:hover, &:focus": {
        color: theme.palette.primary.main,
        borderColor: theme.palette.shades.grey,

        ".MuiIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    }),
    outlinedSecondary: ({ theme }) => ({
      color: theme.palette.shades.dark,
      borderColor: theme.palette.shades.greyMedium,
      backgroundColor: "transparent",
      "&:hover": {
        color: theme.palette.shades.darkLight,
        backgroundColor: "transparent",
        borderColor: theme.palette.shades.grey,

        ".MuiIcon-root": {
          color: theme.palette.shades.darkLight,
        },
      },
      "&:focus, &:focus-visible": {
        backgroundColor: theme.palette.shades.darkExtra,
        borderColor: theme.palette.shades.darkExtra,
        color: "white",
      },
      "&:active": {
        color: theme.palette.shades.darkLight,
      },
    }),
    // @ts-ignore
    outlinedCancel: ({ theme }) => ({
      color: theme.palette.shades.grey,
      borderColor: theme.palette.shades.greyLight,
      backgroundColor: "white",
      "&:hover": {
        color: theme.palette.shades.darkLight,
        borderColor: theme.palette.shades.grey,

        ".MuiIcon-root": {
          color: theme.palette.shades.grey,
        },
      },
      "&:focus, &:focus-visible": {
        color: theme.palette.errors.regular,
        borderColor: theme.palette.shades.grey,
      },
      "&:active": {
        color: theme.palette.errors.regular,
      },
    }),
    icon: {
      borderRadius: "18px",
      width: "36px",
      height: "36px",
      minWidth: "auto",
      padding: "10px",
      border: "1px solid",
    },
    // @ts-ignore
    iconPrimary: ({ theme }) => ({
      background: theme.palette.green.regular,
      color: "white",
      border: "1px solid white",
      "&:hover": {
        backgroundColor: theme.palette.green.hover,
        color: "white",
      },
      "&:focus, &:focus-visible": {
        boxShadow: "none",
        backgroundColor: theme.palette.green.regular,
        color: "white",
      },
    }),
    // @ts-ignore
    iconSecondary: ({ theme }) => ({
      color: theme.palette.shades.dark,
      borderColor: theme.palette.shades.greyMedium,
      backgroundColor: "transparent",
      "&:hover": {
        color: theme.palette.shades.darkLight,
        backgroundColor: "white",
        borderColor: theme.palette.shades.grey,
      },
      "&:focus, &:focus-visible": {
        backgroundColor: theme.palette.shades.darkExtra,
        borderColor: theme.palette.shades.darkExtra,
        color: "white",
      },
      "&:active": {
        color: "white",
      },
    }),
    // @ts-ignore
    iconDark: ({ theme }) => ({
      color: "white",
      borderColor: "white",
      backgroundColor: "transparent",
      "&:hover": {
        color: theme.palette.shades.dark,
        backgroundColor: "white",
        borderColor: "rgba(255, 255, 255, 0.65)",
      },
      "&:focus, &:focus-visible": {
        backgroundColor: theme.palette.shades.darkExtra,
        borderColor: theme.palette.shades.darkExtra,
        color: "white",
      },
      "&:active": {
        color: "white",
      },
    }),
    // @ts-ignore
    iconSecondaryContained: ({ theme }) => ({
      color: theme.palette.shades.dark,
      borderColor: theme.palette.shades.greyMedium,
      backgroundColor: "white",
      "&:hover": {
        color: theme.palette.shades.darkLight,
        backgroundColor: "white",
        borderColor: theme.palette.shades.grey,
      },
      "&:focus, &:focus-visible": {
        backgroundColor: theme.palette.shades.darkExtra,
        borderColor: theme.palette.shades.darkExtra,
        color: "white",
      },
      "&:active": {
        color: "white",
      },
    }),
    // @ts-ignore
    iconLinkOutlinedPrimary: ({ theme }) => ({
      borderRadius: "18px",
      width: "36px",
      height: "36px",
      minWidth: "auto",
      padding: "10px",
      background: "white",
      border: "1px solid white",
      color: theme.palette.shades.grey,
      "&:hover": {
        backgroundColor: "white",
        borderColor: theme.palette.shades.grey,
        color: theme.palette.shades.grey,
      },
      "&:focus, &:focus-visible": {
        backgroundColor: theme.palette.shades.greyUltimateLight,
        borderColor: theme.palette.shades.greyMedium,
        color: theme.palette.shades.darkLight,
      },
    }),
  },
};
