import { Box } from "@mui/material";
import useCreation from "ahooks/es/useCreation";
import React from "react";
import { useOrganizationMembership } from "../../../../authentication/hooks";
import { useConferenceRooms } from "../../../../conference/hooks";
import { useConferenceSelection } from "../../../../conference/state";
import { InteractiveFloorPlan } from "../../../../floor-plan/components";
import { useSpaceImages } from "../../../../gallery/hooks";
import { useGallerySelection } from "../../../../gallery/state";
import {
  BookingDate,
  DeskBooking,
  DeskBookings,
  DeskOverview,
} from "../../../domain";
import { useBookingsOverview } from "../../../hooks";
import { useMyBookingsNavigation, useSelectedBooking } from "../hooks";
import { SelectedBookingLocationHeader } from "../SelectedBookingLocationHeader";

function findDeskOverview(
  bookings: DeskBookings,
  bookingId: string
): DeskOverview | null {
  return (
    bookings.spaces
      .flatMap((space) => space.desks)
      .find((it) => it.bookings.find((booking) => booking.id === bookingId)) ??
    null
  );
}

export const FloorPlan: React.FC = () => {
  const { organization } = useOrganizationMembership();
  const organizationMembership = useOrganizationMembership();
  const [selectedBooking] = useSelectedBooking();
  const { selectDesk } = useMyBookingsNavigation();
  const { selectedConferenceRoom, onSelectConferenceRoom } =
    useConferenceSelection();
  const { selectedSpaceWithImages, onSelectSpaceWithImages } =
    useGallerySelection();

  if (!selectedBooking) {
    throw new Error("Upcoming booking must be selected");
  }

  const { booking } = selectedBooking;
  const bookingDate = useCreation(
    () => BookingDate.of(booking.bookingDate),
    [booking.bookingDate]
  );
  const { data: bookings } = useBookingsOverview(
    bookingDate,
    organization.id,
    organizationMembership.id,
    booking.location.id
  );
  const { data: conferenceRooms } = useConferenceRooms();
  const { data: spacesWithImages } = useSpaceImages();

  const selectedDesk: DeskOverview | null = bookings
    ? findDeskOverview(bookings.deskBookings, selectedBooking.booking.id)
    : null;

  function handleSelectDesk(desk: DeskOverview | null) {
    if (!desk) {
      return;
    }

    selectDesk(desk);
  }

  return (
    <Box
      sx={(theme) => ({
        flexGrow: 1,
        padding: 2,
        height: "100%",
        [theme.breakpoints.up("md")]: {
          paddingTop: 1,
        },
      })}
    >
      <SelectedBookingLocationHeader />
      {bookings && booking instanceof DeskBooking && (
        <InteractiveFloorPlan
          sx={{ height: "100%" }}
          floor={booking.floor}
          description={booking.location.address.addressLine1}
          bookings={bookings.deskBookings}
          conferenceRooms={conferenceRooms}
          spacesWithImages={spacesWithImages}
          date={bookingDate}
          selectedDesk={selectedDesk}
          selectedConferenceRoom={selectedConferenceRoom}
          selectedSpaceWithImages={selectedSpaceWithImages}
          onSelectDesk={handleSelectDesk}
          onSelectConferenceRoom={onSelectConferenceRoom}
          onSelectSpaceWithImages={onSelectSpaceWithImages}
          interactive
        />
      )}
    </Box>
  );
};
