import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { AlertTriangle } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  selectIsFullCapacity,
  selectTotalBooked,
  useBookingDate,
  useBookingState,
} from "../../state";
import { Caption } from "../Caption";

export interface BookedBoxProps {
  booked: number;
  fullCapacity: boolean;
}

const BookedBoxComponent: React.FC<BookedBoxProps> = React.memo(
  ({ booked, fullCapacity }) => {
    const { t } = useTranslation("booking");
    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          border: "1px solid",
          borderColor: "shades.greyExtraLight",
          paddingY: 0.25,
          paddingX: 1,
          textAlign: "right",
          borderRadius: "4px",
          lineHeight: 0,
        }}
        data-testid="book--filters--booked-count"
        aria-valuenow={booked}
      >
        <Caption>{t("booked-count.label")}</Caption>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip title={t("booked-count.full-capacity")}>
            <Box
              color="orange.regular"
              sx={{ visibility: fullCapacity ? "visible" : "hidden" }}
            >
              <AlertTriangle size={16} />
            </Box>
          </Tooltip>
          <Typography
            variant="subtitle2"
            sx={(theme) => ({ color: theme.palette.cyclamen.regular })}
          >
            {booked}
          </Typography>
        </Box>
      </Box>
    );
  }
);

export const BookedBox: React.FC = () => {
  const { date } = useBookingDate();
  const state = useBookingState();
  const booked = selectTotalBooked(state);
  const fullCapacity = selectIsFullCapacity(state);
  if (date.isDateRange) {
    return null;
  }
  return <BookedBoxComponent booked={booked} fullCapacity={fullCapacity} />;
};
