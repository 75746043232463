import { useQuery, UseQueryResult } from "react-query";
import { getLocationsWithCapacityQuery } from "../api";
import { LocationWithCapacity } from "../domain";

export function useLocationsWithCapacityQuery(
  organizationId: string
): UseQueryResult<LocationWithCapacity[]> {
  return useQuery(
    ["locations-with-capacity", organizationId],
    () => getLocationsWithCapacityQuery(organizationId),
    {
      refetchOnMount: "always",
    }
  );
}
