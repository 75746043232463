import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError, AmtKnownError, ErrorCodes } from "../../../api";
import { trackFailedBooking, trackGroupBooking } from "../../../tracking";
import { useCurrentUser } from "../../authentication/hooks";
import { bookDeskForGroup } from "../api";
import { GroupDeskBookings } from "../domain";
import { GroupBookFormMutationValues } from "../pages/BookingPage/Dialog";
import {
  DeskBookingRequest,
  useBookingEvents,
  useBookingForm,
} from "../pages/BookingPage/state";

interface GroupBookingResult {
  groupDeskBookings: GroupDeskBookings;
  isInvitation: boolean;
}

function isBookingAssetConflictError(res: AmtError): boolean {
  return (
    res instanceof AmtKnownError &&
    res.contains(ErrorCodes.BOOKING_ASSET_CONFLICT)
  );
}

export function useCreateGroupBookingMutation(): UseMutationResult<
  GroupBookingResult,
  AmtError,
  GroupBookFormMutationValues
> {
  const { t } = useTranslation("booking");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const currentUser = useCurrentUser();
  const form = useBookingForm();
  const events = useBookingEvents();

  return useMutation(
    ["create-group-booking"],
    async (formValues: GroupBookFormMutationValues) => {
      if (!form.target) {
        return Promise.reject("Request must be present in state");
      }
      const groupDeskBookings = await bookDeskForGroup(
        form.target.date,
        formValues.bookFor.map((it) => it.id),
        {
          locationId: form.target.locationId,
          isInvitation: formValues.isInvitation,
          ...(form.target instanceof DeskBookingRequest && {
            spaceId: form.target.spaceId,
          }),
        }
      );
      return {
        groupDeskBookings,
        isInvitation: formValues.isInvitation,
      };
    },
    {
      onSuccess: (response: GroupBookingResult) => {
        if (!form.target) {
          return;
        }

        trackGroupBooking({
          forPeriod: response.groupDeskBookings.date.isDateRange,
          isInvitation: response.isInvitation,
        });

        events.groupBooking$.emit({
          request: form.target,
          booking: response.groupDeskBookings.desks.map((it) => ({
            bookedFor: it.user,
            desk: it.desk,
          })),
          isInvitation: response.isInvitation,
        });
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        trackFailedBooking(currentUser, err);

        if (isBookingAssetConflictError(err)) {
          snackbar.enqueueSnackbar(t("form.feedback.asset-conflict-error"), {
            variant: "error",
          });
          form.closeDialog();
        }
      },
      onSettled: () => {
        qc.invalidateQueries(["bookings"]);
      },
    }
  );
}
