import clsx from "clsx";
import * as d3 from "d3";
import { DeskWithSpace, DeskWithStatus } from "../../../../backoffice/domain";
import { DeskStatus } from "../../../../backoffice/modules/asset-management/domain";
import { trackBackofficeFloorChange } from "../../../../tracking";
import { Floor } from "../../../assets/domain";
import { CurrentUser } from "../../../authentication/domain";
import { addDefinitions, getBBox, Zoom } from "../assets/FloorPlanD3Renderer";
import { PinMouseoverOutlineSvg } from "../assets/FloorPlanD3Renderer/assets";
import { PinBackoffice } from "../assets/FloorPlanD3Renderer/assets/PinBackoffice";
import { FloorPlanStyles } from "../assets/FloorPlanD3Renderer/styles";
import "../assets/FloorPlanD3Renderer/styles.css";
import {
  GSelection,
  OnChangeFloorCallback,
  OnSelectDeskCallback,
  OnZoomCallback,
  SVGSelection,
} from "../assets/FloorPlanD3Renderer/types";
import { FloorPlanTooltipsHandle } from "../assets/Tooltips";

const DATA_PIN_DESK_ID = "data-pin-desk-id";
const DATA_FLOOR_ID = "data-floor-id";

function deskSelector(deskId: string): string {
  return `[data-desk-id="${deskId}"]`;
}

function deskPinSelector(deskId: string): string {
  return `[data-pin-desk-id="${deskId}"]`;
}

export interface FloorPlanRendererOptions {
  interactive?: boolean;
  tooltips?: FloorPlanTooltipsHandle;
}

export enum PinType {
  DESK,
  CONFERENCE,
}

export class FloorPlanD3Renderer {
  private destroyed: boolean = false;
  private $svg: SVGSelection | null = null;
  private $image: GSelection | null = null;
  private zoom: Zoom | null = null;
  private _desksWithStatus: DeskWithStatus[] = [];
  private selectedDeskId: string | null = null;
  private selectedSpaceId: string | null = null;
  public floors: Floor[] = [];
  public onChangeFloor?: OnChangeFloorCallback;
  public onSelectDesk?: OnSelectDeskCallback;
  public onZoom?: OnZoomCallback;
  private readonly maxScale: number = 2;
  private readonly tooltips?: FloorPlanTooltipsHandle;
  private readonly styles: FloorPlanStyles;
  private readonly options: FloorPlanRendererOptions = {
    interactive: false,
  };

  constructor(
    div: HTMLDivElement,
    imageUrl: string,
    private currentUser: CurrentUser,
    options: FloorPlanRendererOptions = {}
  ) {
    this.options = { ...this.options, ...options };

    const $div = d3.select(div)!!;
    this.styles = new FloorPlanStyles(this.options.tooltips ?? null);
    this.tooltips = this.options.tooltips;

    d3.xml(imageUrl).then((data) => {
      if (this.destroyed) {
        return;
      }

      div.append(data.documentElement);
      this.$svg = $div
        .selectChild<SVGElement>("svg")!!
        .attr("viewBox", null)
        .attr("width", null)
        .attr("height", null)
        .on("click", () => {
          this.onSelectDesk?.(null, null);
        });

      this.$image = this.$svg!!.selectChild("g");

      this.addDefinitions();
      this.drawPins();
      this.drawFloorNavigationButtons();
      this.setupZoom();

      const preselectedDeskIsOnThisFloor = this._desksWithStatus.find(
        (it) => it.desk.id === this.selectedDeskId
      );

      if (
        this.options.interactive &&
        this.selectedDeskId &&
        preselectedDeskIsOnThisFloor
      ) {
        this.doFocusDesk(this.selectedDeskId, false);
      } else {
        this.zoomToFit();
      }
    });
  }

  tearDown() {
    this.destroyed = true;
    this.$svg?.remove();
  }

  private addDefinitions() {
    if (!this.$svg) {
      return;
    }

    addDefinitions(this.$svg);
  }

  private setupZoom() {
    const container = this.$svg?.node();
    if (container) {
      this.zoom = new Zoom(container, {
        interactive: this.options.interactive ?? false,
        maxScale: this.maxScale,
      });
      this.zoom.onZoom = (event) => {
        this.onZoom?.(event);
      };
    }
  }

  zoomToFit() {
    this.zoom?.zoomToFit();
  }

  private zoomToEl(el: SVGGraphicsElement, animate: boolean) {
    this.zoom?.zoomToEl(el, animate);
  }

  setDesks(desksWithStatus: DeskWithStatus[]) {
    this._desksWithStatus = desksWithStatus;
    this.drawPins();
  }

  get desksWithStatus(): DeskWithStatus[] {
    return this._desksWithStatus;
  }

  focusDesk(deskId: string, animate: boolean = true) {
    if (this.selectedDeskId === deskId) {
      return;
    }

    this.doFocusDesk(deskId, animate);
  }

  private doFocusDesk(deskId: string, animate: boolean = true) {
    this.selectedDeskId = deskId;
    this.styles.selectedElementId = deskId;

    if (!this.$image) {
      return;
    }

    this.drawPins();
    this.$image.select(deskPinSelector(deskId)).call((selection) => {
      const node = selection.node();
      if (node) {
        this.zoomToEl(node as SVGGraphicsElement, animate);
      }
    });
  }

  unfocusDesk() {
    this.selectedDeskId = null;
    this.styles.selectedElementId = null;
    this.drawPins();
  }

  private handleSelectDesk(d: DeskWithStatus, event: MouseEvent) {
    if (this.options.interactive) {
      this.onSelectDesk?.(d, event);
    }
  }

  private handleChangeFloor(floorId: string) {
    const floor = this.floors.find((it) => it.id === floorId);
    if (!floor || !this.onChangeFloor) {
      return;
    }

    trackBackofficeFloorChange(true, "Floorplan stairs");
    this.onChangeFloor(floor);
  }

  private drawPins() {
    if (!this.$image || !this._desksWithStatus) {
      return;
    }

    const $self = this;

    this.$image
      .selectAll(`[${DATA_PIN_DESK_ID}]`)
      .data<DeskWithStatus>(
        this._desksWithStatus,
        (d) => (d as DeskWithStatus).desk.id
      )
      .join(
        // @ts-ignore
        function (enter) {
          return enter
            .append("g")
            .attr("class", "amt-floor-plan--pin")
            .attr(DATA_PIN_DESK_ID, (d) => d.desk.id)
            .append("g")
            .each(function fillOutline() {
              this.innerHTML = PinMouseoverOutlineSvg;
            })
            .attr("transform", function (d) {
              return $self.getPinTransform(this, d.desk);
            })
            .on("dblclick", function (e) {
              e.stopPropagation();
            })
            .on("click", function (e, d) {
              e.stopPropagation();
              $self.handleSelectDesk(d, e);
            })
            .on("mousedown", function (e) {
              e.stopPropagation();
            })
            .on("mouseenter", function (_, d) {
              $self.styles.applyPinHover(this, d);
            })
            .on("mouseleave", function (_, d) {
              $self.styles.removePinHover(this);
            })
            .call((el) => {
              el.append("g").each(function drawPin(d) {
                if (this.innerHTML) {
                  return;
                }

                const selector = deskSelector(d.desk.id);
                const node = $self.$image?.select(selector).node();
                if (!node) {
                  return;
                }

                this.innerHTML = PinBackoffice;
              });
            });
        },
        function (update) {
          return update.select("g");
        },
        function (exit) {
          exit.remove();
        }
      )
      .attr("class", (d) => {
        return clsx("amt-floor-plan--pin-inner", {
          "amt-floor-plan--pin-desk-available":
            d.deskStatus === DeskStatus.FREE ||
            d.deskStatus === DeskStatus.DEDICATED,
          "amt-floor-plan--pin-desk-not-available":
            d.deskStatus === DeskStatus.DISABLED,
          "amt-floor-plan--pin-selected": d.desk.id === $self.selectedDeskId,
          "amt-floor-plan--pin-dedicated":
            d.deskStatus === DeskStatus.DEDICATED,
        });
      });
  }

  private drawFloorNavigationButtons() {
    const $self = this;
    if (!$self.$image) {
      return;
    }

    function styleReactive() {
      $self
        .$image!.selectAll<SVGGraphicsElement, unknown>(`[${DATA_FLOOR_ID}]`)
        .attr("class", "amt-floor-plan--stairs")
        .on("mousedown", function (e) {
          e.stopPropagation();
          const $this = d3.select(this);
          const floorId = $this.attr(DATA_FLOOR_ID);
          if (floorId?.length) {
            $self.handleChangeFloor(floorId);
          }
        })
        .on("mouseenter", function handleHover() {
          const floorId = d3.select(this).attr(DATA_FLOOR_ID);
          const floor = $self.floors.find((it) => it.id === floorId);
          if (floor) {
            $self.tooltips?.showFloor(this, floor);
          }
        })
        .on("mouseleave", function handleMouseLeave() {
          $self.tooltips?.hide();
        });
    }

    function styleDisabled() {
      $self
        .$image!.selectAll(`[${DATA_FLOOR_ID}]`)
        .attr("class", "amt-floor-plan--stairs-disabled");
    }

    if ($self.onChangeFloor) {
      styleReactive();
    } else {
      styleDisabled();
    }
  }

  /**
   * Position the pin above desk number.
   */
  private getPinTransform = (el: SVGGraphicsElement, d: DeskWithSpace) => {
    const marginY = 4;

    const $text = this.$image!.select<SVGGraphicsElement>(deskSelector(d.id));

    const textNode = $text.node();
    if (!textNode) {
      return null;
    }

    const pinBBox = getBBox(el, false, this.$image?.node());
    const textBBox = getBBox(textNode, false, this.$image?.node());

    const pinHalfWidth = pinBBox.width / 2;
    const pinHalfHeight = pinBBox.height / 2;
    const cx = textBBox.x + textBBox.width / 2;
    const cy = textBBox.y - pinBBox.height / 2 - marginY;

    return `translate(${cx}, ${cy}) translate(-${pinHalfWidth}, -${pinHalfHeight})`;
  };
}
