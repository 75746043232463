import { isToday } from "date-fns";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError, AmtKnownError, ErrorCodes } from "../../../api";
import {
  trackFailedBooking,
  trackSuccessfulBooking,
  trackSuccessfulBookingEdit,
} from "../../../tracking";
import { User } from "../../authentication/domain";
import { useCurrentUser } from "../../authentication/hooks";
import { bookDeskForRange, editBooking } from "../api";
import { DeskBooking } from "../domain";
import { SingleBookFormMutationValues } from "../pages/BookingPage/Dialog";
import {
  DeskBookingRequest,
  useBookingEvents,
  useBookingForm,
} from "../pages/BookingPage/state";

function isBookingAssetConflictError(res: AmtError): boolean {
  return (
    res instanceof AmtKnownError &&
    res.contains(ErrorCodes.BOOKING_ASSET_CONFLICT)
  );
}

interface SingleUserBookingResult {
  bookedFor: User;
  analytics: {
    forSelf: boolean;
    forToday: boolean;
    forPeriod: boolean;
  };
  isInvitation: boolean;
}

export function useCreateBookingMutation(): UseMutationResult<
  SingleUserBookingResult,
  AmtError,
  SingleBookFormMutationValues
> {
  const { t } = useTranslation("booking");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const currentUser = useCurrentUser();
  const form = useBookingForm();
  const events = useBookingEvents();

  return useMutation(
    ["create-booking"],
    async (formValues: SingleBookFormMutationValues) => {
      if (!form.target) {
        return Promise.reject("Expected desk to be selected");
      }

      const oldBooking = form.source?.booking;
      const newLocationId = form.target.location.id;

      const newDeskId =
        form.target instanceof DeskBookingRequest
          ? form.target.desk.id
          : undefined;
      if (oldBooking) {
        await editBooking(
          oldBooking.id,
          form.target.date,
          formValues.bookFor.user.id,
          {
            locationId: newLocationId,
            isInvitation: formValues.isInvitation,
            deskId: newDeskId,
          }
        );
      } else {
        await bookDeskForRange(form.target.date, formValues.bookFor.user.id, {
          locationId: newLocationId,
          isInvitation: formValues.isInvitation,
          deskId: newDeskId,
        });
      }
      return {
        analytics: {
          forSelf: currentUser.isUser(formValues.bookFor.user),
          forToday: isToday(form.target.date.startDate),
          forPeriod: form.target.date.isDateRange,
          ...(oldBooking && {
            changedName: oldBooking.bookedFor.isUser(formValues.bookFor.user),
            originType: oldBooking instanceof DeskBooking ? "desk" : "location",
            destinationType: newDeskId ? "desk" : "location",
          }),
        },
        bookedFor: formValues.bookFor.user,
        isInvitation: formValues.isInvitation,
      };
    },
    {
      onSuccess: (result: SingleUserBookingResult) => {
        if (!form.target) {
          return;
        }

        if (form.isEditing) {
          trackSuccessfulBookingEdit(result.analytics);
        } else {
          trackSuccessfulBooking(result.analytics);
        }

        events.singleBooking$.emit({
          edited: form.isEditing,
          request: form.target,
          bookedFor: result.bookedFor,
          isInvitation: result.isInvitation,
        });
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        trackFailedBooking(currentUser, err);

        if (isBookingAssetConflictError(err)) {
          snackbar.enqueueSnackbar(t("form.feedback.asset-conflict-error"), {
            variant: "error",
          });
          form.closeDialog();
        }
      },
      onSettled: () => {
        qc.invalidateQueries(["bookings"]);
      },
    }
  );
}
