import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../modules/authentication/hooks";
import { AssetInventory } from "../../modules/booking/domain";
import { getInventories } from "../api";

export function useInventoriesQuery(): UseQueryResult<AssetInventory[]> {
  const { organization } = useOrganizationMembership();

  return useQuery(
    ["inventories", organization.id],
    () => getInventories(organization.id),
    {
      refetchOnMount: "always",
    }
  );
}
