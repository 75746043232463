import { Location } from "../../../assets/domain";

export class Capacity {
  constructor(
    public readonly available: number,
    public readonly occupied: number,
    public readonly total: number,
    public readonly totalLocationBookings: number
  ) {}
}

export class LocationCapacity {
  constructor(
    public readonly location: Location,
    public readonly capacity: Capacity,
    public readonly newLocation: boolean
  ) {}
}
