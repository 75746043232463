import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Layers } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtInputAdornment } from "../../components";
import { Floor } from "../../modules/assets/domain";
import { trackBackofficeFloorChange } from "../../tracking";

export interface FloorPickerProps {
  floor: Floor | null;
  floors: Floor[];
  onChangeFloor: (newFloor: Floor | null) => void;
}

export const FloorPicker: React.FC<FloorPickerProps> = React.memo(
  ({ floor, floors, onChangeFloor }) => {
    const { t } = useTranslation("backoffice");

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const newFloor = floors.find((it) => it.id === event.target.value);
      trackBackofficeFloorChange(!!newFloor, "Floor dropdown picker");
      onChangeFloor(newFloor ? newFloor : null);
    };

    const pickerValue = () => {
      if (floor) return floor.id;
      if (floors.length === 1) return floors[0].id;
      return "";
    };

    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          width: "260px",
          marginLeft: 2,
          [theme.breakpoints.down("md")]: {
            marginBottom: 1,
            marginLeft: 0,
            width: "100%",
          },
        })}
      >
        <AmtInputAdornment placement="start">
          <Layers size={16} />
        </AmtInputAdornment>
        <TextField
          fullWidth
          select
          className="MuiInput-leftAdornment"
          value={pickerValue()}
          onChange={(event) => handleChange(event)}
          SelectProps={{
            renderValue: () => (
              <ListItemText
                primary={
                  floor ? `${floor.description}` : t("picker.all-floors")
                }
              />
            ),
            displayEmpty: true,
          }}
          data-testid="book--filters--floor"
          disabled={floors.length <= 1}
        >
          {floors.map((floor) => (
            <MenuItem key={floor.id} value={floor.id}>
              <ListItemText primary={floor.description} />
            </MenuItem>
          ))}
          {floors.length > 1 && (
            <MenuItem value={""}>
              <ListItemText primary={t("picker.all-floors")} />
            </MenuItem>
          )}
        </TextField>
      </Box>
    );
  }
);
