import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { useState } from "react";
import { Desk, Floor, Location, Space } from "../../../../assets/domain";
import { User } from "../../../../authentication/domain";
import { useSubscription } from "../../../../events/hooks";
import { BookingStatus } from "../../../api";
import { BookingDate, DeskBooking } from "../../../domain";
import { useInviteEvents } from "../../../hooks";
import { DeskBookingRequest, useBookingEvents, useBookingForm } from "../state";
import { BookingConfirmationDialog } from "./BookingConfirmationDialog";
import { GroupBookingConfirmationDialog } from "./GroupBookingConfirmationDialog";

interface ConfirmationDialogState {
  shown: boolean;
  context?: {
    edited: boolean;
    date: BookingDate;
    location: Location;
    isInvitation: boolean;
    isApproveInvite?: boolean;
    floor?: Floor;
    space?: Space;
    singleBooking?: {
      desk?: Desk;
      bookedFor: User;
    };
    groupBooking?: {
      desk: Desk | null;
      bookedFor: User;
    }[];
  };
}

export const BookingConfirmationDialogs: React.FC = () => {
  const events = useBookingEvents();
  const inviteEvents = useInviteEvents();
  const { exitEditMode } = useBookingForm();

  const [state, setState] = useState<ConfirmationDialogState>({
    shown: false,
  });

  useSubscription(inviteEvents, (event) => {
    setState({
      shown: true,
      context: {
        edited: false,
        date: BookingDate.of(event.bookingDate),
        location: event.location,
        isInvitation: event.status === BookingStatus.PENDING,
        isApproveInvite: true,
        ...(event instanceof DeskBooking
          ? {
              floor: event.floor,
              space: event.space,
            }
          : {
              floor: undefined,
              space: undefined,
            }),
        singleBooking: {
          desk: event instanceof DeskBooking ? event.desk : undefined,
          bookedFor: event.bookedFor,
        },
      },
    });
  });

  useSubscription(events.singleBooking$, (event) => {
    setState({
      shown: true,
      context: {
        edited: event.edited,
        date: event.request.date,
        location: event.request.location,
        isInvitation: event.isInvitation,
        ...(event.request instanceof DeskBookingRequest
          ? {
              floor: event.request.floor,
              space: event.request.space.space,
            }
          : {
              floor: undefined,
              space: undefined,
            }),
        singleBooking: {
          desk:
            event.request instanceof DeskBookingRequest
              ? event.request.desk
              : undefined,
          bookedFor: event.bookedFor,
        },
      },
    });
  });

  useSubscription(events.groupBooking$, (event) => {
    setState({
      shown: true,
      context: {
        edited: false,
        date: event.request.date,
        location: event.request.location,
        isInvitation: event.isInvitation,
        ...(event.request instanceof DeskBookingRequest
          ? {
              floor: event.request.floor,
              space: event.request.space.space,
            }
          : {
              floor: undefined,
              space: undefined,
            }),
        groupBooking: event.booking,
      },
    });
  });

  const closeDialog = useMemoizedFn(() => {
    exitEditMode();
    setState({ ...state, shown: false });
  });

  return (
    <>
      {!!state.context && !!state.context.singleBooking && (
        <BookingConfirmationDialog
          {...state.context}
          {...state.context.singleBooking}
          open={state.shown}
          onClose={closeDialog}
        />
      )}
      {!!state.context && !!state.context.groupBooking && (
        <GroupBookingConfirmationDialog
          {...state.context}
          bookings={state.context.groupBooking}
          open={state.shown}
          onClose={closeDialog}
        />
      )}
    </>
  );
};
