import {
  UiNode,
  UiNodeAnchorAttributes,
  UiNodeImageAttributes,
  UiNodeInputAttributes,
  UiNodeTextAttributes,
} from "@ory/kratos-client";
import { TFunction } from "i18next";

export function getTranslatedNodeLabel(node: UiNode, t: TFunction) {
  const label = getNodeLabel(node);
  return t(label, node.meta);
}

export function getNodeLabel(node: UiNode) {
  const attributes = node.attributes;
  if (isUiNodeAnchorAttributes(attributes)) {
    return attributes.title.text;
  }
  if (isUiNodeImageAttributes(attributes)) {
    return node.meta.label?.text ?? "";
  }
  if (isUiNodeInputAttributes(attributes)) {
    if (attributes.label?.text) {
      return attributes.label.text;
    }
  }
  return node.meta.label?.text ?? "";
}

export function isUiNodeAnchorAttributes(
  attrs: any
): attrs is UiNodeAnchorAttributes {
  return attrs.node_type === "a";
}

export function isUiNodeImageAttributes(
  attrs: any
): attrs is UiNodeImageAttributes {
  return attrs.node_type === "img";
}

export function isUiNodeInputAttributes(
  attrs: any
): attrs is UiNodeInputAttributes {
  return attrs.node_type === "input";
}

export function isUiNodeTextAttributes(
  attrs: any
): attrs is UiNodeTextAttributes {
  return attrs.node_type === "text";
}

export function isUiNodeScriptAttributes(
  attrs: any
): attrs is UiNodeInputAttributes {
  return attrs.node_type === "script";
}
