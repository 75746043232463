import { parseISO } from "date-fns";
import { getJson } from "../../../api";
import {
  FloorDto,
  LocationDto,
  mapFloor,
  mapLocation,
  mapSpace,
  SpaceDto,
} from "../../assets/api";
import { mapUser, UserDto } from "../../authentication/api";
import { formatISODate } from "../../booking/api";
import {
  CalendarEvent,
  CalendarEvents,
  ConferenceRoom,
  TimeSlot,
} from "../domain";

export interface TimeSlotDto {
  from: string;
  to: string;
}

export interface ConferenceRoomDto {
  id: string;
  capacity: number;
  name: string;
  space: SpaceDto;
  floor: FloorDto;
  location: LocationDto;
}

export interface CalendarEventDto {
  eventId: string;
  conferenceRoom: ConferenceRoomDto;
  creator: UserDto;
  timeSlot: TimeSlotDto;
}

export function mapConferenceRoom(dto: ConferenceRoomDto): ConferenceRoom {
  return new ConferenceRoom(
    dto.id,
    dto.capacity,
    dto.name,
    mapSpace(dto.space),
    mapFloor(dto.floor),
    mapLocation(dto.location)
  );
}

export function mapTimeSlot(dto: TimeSlotDto): TimeSlot {
  return new TimeSlot(parseISO(dto.from), parseISO(dto.to));
}

export function mapCalendarEvent(dto: CalendarEventDto): CalendarEvent {
  return new CalendarEvent(
    dto.eventId,
    mapConferenceRoom(dto.conferenceRoom),
    mapUser(dto.creator),
    mapTimeSlot(dto.timeSlot)
  );
}

interface CalendarEventsResponse {
  conferenceRoom: ConferenceRoomDto;
  events: CalendarEventDto[];
}

export async function getCalendarEvents(
  organizationId: string,
  conferenceRoomId: string,
  startDate: Date | undefined
): Promise<CalendarEvents> {
  const response = await getJson<CalendarEventsResponse>(
    "/api/calendar-events",
    {
      "organization.id": organizationId,
      "conference.room.id": conferenceRoomId,
      "start.date": startDate ? formatISODate(startDate) : null,
    }
  );
  return new CalendarEvents(
    mapConferenceRoom(response.conferenceRoom),
    response.events.map(mapCalendarEvent)
  );
}
