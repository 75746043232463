import { Box, BoxProps, styled } from "@mui/material";
import React, { forwardRef } from "react";

export interface AmtCardProps extends BoxProps {
  pop?: boolean;
  HoverOverlayComponent?: React.ComponentType<{ className?: string }>;
}

export const AmtCardHoverOverlay = styled(Box)({
  content: '""',
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#14111026",
  zIndex: 1,
  pointerEvents: "none",
  borderRadius: "10px",
});

export const AmtCard: React.FC<AmtCardProps> = forwardRef(
  (
    {
      sx,
      pop = false,
      HoverOverlayComponent = AmtCardHoverOverlay,
      children,
      ...props
    },
    ref
  ) => (
    <Box
      sx={[
        {
          position: "relative",
          backgroundColor: "white",
          borderRadius: "10px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "shades.greyExtraLight",
          boxShadow: "0px 4px 4px rgba(20, 17, 16, 0.05)",
          overflow: "hidden",
          transition: "0.1s easy-in-out",
          transitionProperty: "transform, box-shadow, border-color",

          "&:not(:hover) .AmtHoverOverlay": {
            display: "none",
          },

          ...(!pop && {
            ".AmtHoverOverlay": {
              display: "none",
            },
          }),
          ...(pop && {
            "&:hover": {
              cursor: "pointer",
              borderColor: "green.regular",
              boxShadow: "0px 8px 8px rgba(20, 17, 16, 0.05)",
              transform: "translateY(-3px)",
            },
          }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      ref={ref}
      {...props}
    >
      {children}
      {React.createElement(HoverOverlayComponent, {
        className: "AmtHoverOverlay",
      })}
    </Box>
  )
);
