import { Desk } from "../../assets/domain";

export class DeskInventoryFilters {
  constructor(
    public name: string,
    public quantity: number,
    public deskIds: string[]
  ) {}
}

export class AssetInventory {
  constructor(
    public id: string,
    public description: string,
    public type: AssetInventoryType,
    public quantity: number // public desks: DeskInventory[]
  ) {}
}

export class DeskInventory {
  constructor(
    public deskId: string,
    public assetInventoryId: string,
    public quantity: number,
    public desk: Desk
  ) {}
}

export class AssetInventoryType {
  constructor(public id: string, public name: string) {}
}

export class DeskAssetInventory {
  constructor(
    public assetInventory: AssetInventory,
    public quantityOnDesk: number
  ) {}
}
