import { parseISO } from "date-fns";
import { getJson, request } from "../../../api";
import {
  DeskDto,
  FloorDto,
  LocationDto,
  mapDesk,
  mapFloor,
  mapLocation,
  mapSpace,
  SpaceDto,
} from "../../assets/api";
import { Desk, Floor, Location, Space } from "../../assets/domain";
import { mapUser, UserDto } from "../../authentication/api";
import {
  Booking,
  BookingDate,
  Bookings,
  DeskAvailability,
  DeskBooking,
  DeskBookings,
  DeskOverview,
  GroupDeskBooking,
  GroupDeskBookings,
  LocationBookingOverview,
  LocationBookings,
  LocationBookingsOverview,
  SpaceOverview,
} from "../domain";
import { BookingDto, BookingStatus, formatISODate } from "./dto";

export interface DeskOverviewResponse {
  desk: DeskDto;
  bookings: BookingDto[];
  availability: string;
  isDedicated: boolean;
  isDisabled: boolean;
  dedicatedFor: string[];
  deskInventories: string[];
}

export interface SpaceOverviewResponse {
  space: SpaceDto;
  floor: FloorDto;
  location: LocationDto;
  desks: DeskOverviewResponse[];
}

export interface BookingDateDto {
  startDate: string;
  endDate: string;
}

export interface DeskBookingsResponse {
  date: BookingDateDto;
  spaces: SpaceOverviewResponse[];
}

export interface LocationBookingDto {
  bookedFor: UserDto;
  bookings: BookingDto[];
}

export interface LocationBookingsOverviewResponse {
  location: LocationDto;
  userBookings: LocationBookingDto[];
}

export interface LocationBookingsResponse {
  bookingDate: BookingDateDto;
  locations: LocationBookingsOverviewResponse[];
}

export interface BookingsResponse {
  deskBookings: DeskBookingsResponse;
  locationBookings: LocationBookingsResponse;
}

export interface OccupancyResponse {
  chartLines: ChartLine[];
}

export interface ChartLine {
  points: ChartPoint[];
  locationId: string;
  locationName: string;
  capacity: number;
}

export interface ChartPoint {
  date: Date;
  occupied: number;
}

export interface GroupDeskBookingResponse {
  membership: {
    user: UserDto;
  };
  location: LocationDto;
  desk: DeskDto | null;
}

export interface GroupDeskBookingsResponse {
  date: BookingDateDto;
  desks: GroupDeskBookingResponse[];
}

function mapDeskOverview(
  date: BookingDate,
  response: DeskOverviewResponse,
  location: Location,
  floor: Floor,
  space: Space
): DeskOverview {
  const desk = mapDesk(response.desk);
  const bookings = response.bookings.map((booking) =>
    mapDeskBooking(booking, location, floor, space, desk)
  );
  const availability = DeskAvailability.valueOf(response.availability);
  const isDedicated = response.isDedicated;
  const isDisabled = response.isDisabled;
  const dedicatedFor = response.dedicatedFor;
  const deskInventories = response.deskInventories;
  return new DeskOverview(
    desk,
    location,
    floor,
    space,
    bookings,
    availability,
    isDedicated,
    isDisabled,
    dedicatedFor,
    date,
    deskInventories
  );
}

function mapSpaceOverview(
  date: BookingDate,
  response: SpaceOverviewResponse
): SpaceOverview {
  const space = mapSpace(response.space);
  const floor = mapFloor(response.floor);
  const location = mapLocation(response.location);
  const desks = response.desks.map((desk) =>
    mapDeskOverview(date, desk, location, floor, space)
  );
  return new SpaceOverview(space, floor, location, desks);
}

function mapDeskBookings(response: DeskBookingsResponse): DeskBookings {
  const bookingDate = mapBookingDate(response.date);
  const spaces = response.spaces.map((it) => mapSpaceOverview(bookingDate, it));
  return new DeskBookings(spaces);
}

function mapLocationBookings(
  response: LocationBookingsResponse
): LocationBookings {
  const bookingDate = mapBookingDate(response.bookingDate);
  const locations = response.locations.map((overview) => {
    const location = mapLocation(overview.location);
    const userBookings = overview.userBookings.map((userBooking) => {
      const bookedFor = mapUser(userBooking.bookedFor);
      const bookings = userBooking.bookings.map((booking) =>
        mapBooking(booking, location)
      );
      return new LocationBookingOverview(
        location,
        bookingDate,
        bookedFor,
        bookings
      );
    });
    return new LocationBookingsOverview(location, userBookings);
  });
  return new LocationBookings(bookingDate, locations);
}

export function mapBooking(booking: BookingDto, location: Location): Booking {
  return new Booking(
    booking.id,
    parseISO(booking.bookingDate),
    mapUser(booking.bookedFor),
    mapUser(booking.bookedBy),
    parseISO(booking.bookedAt),
    location,
    mapBookingStatus(booking.status)
  );
}

export function mapDeskBooking(
  booking: BookingDto,
  location: Location,
  floor: Floor,
  space: Space,
  desk: Desk
): DeskBooking {
  return new DeskBooking(
    booking.id,
    parseISO(booking.bookingDate),
    mapUser(booking.bookedFor),
    mapUser(booking.bookedBy),
    parseISO(booking.bookedAt),
    location,
    mapBookingStatus(booking.status),
    floor,
    space,
    desk
  );
}

export function mapBookingStatus(status: string): BookingStatus {
  switch (status) {
    case "PENDING":
      return BookingStatus.PENDING;
    case "COMPLETED":
    default:
      return BookingStatus.COMPLETED;
  }
}

export function mapBookingDate(date: BookingDateDto): BookingDate {
  const startDate = parseISO(date.startDate);
  const endDate = parseISO(date.endDate);
  return BookingDate.of(startDate, endDate);
}

export async function getBookings(
  date: BookingDate,
  organizationId: string,
  organizationMembershipId: string,
  locationId: string
): Promise<Bookings> {
  const startDate = formatISODate(date.startDate);
  const endDate = formatISODate(date.endDate);
  const response = await getJson<BookingsResponse>("/api/bookings", {
    "start-date": startDate,
    "end-date": endDate,
    "organization.id": organizationId,
    "organizationMembership.id": organizationMembershipId,
    "filter.location.id": locationId,
  });

  const deskBookings = mapDeskBookings(response.deskBookings);
  const locationBookings = mapLocationBookings(response.locationBookings);
  return new Bookings(deskBookings, locationBookings);
}

export async function getChartData(
  organizationId: string,
  from: Date,
  to: Date,
  locationId: string | undefined
): Promise<OccupancyResponse> {
  return await getJson<OccupancyResponse>(
    "/api/filter-bookings/occupancy-percentage",
    {
      "organization-id": organizationId,
      from: formatISODate(from),
      to: formatISODate(to),
      "location.id": locationId,
    }
  );
}

export async function bookDeskForRange(
  date: BookingDate,
  bookForId: string,
  bookRequest: {
    locationId: string;
    deskId?: string;
    isInvitation?: boolean;
  }
): Promise<void> {
  const isoStartDate = formatISODate(date.startDate);
  const isoEndDate = formatISODate(date.endDate);
  await request<BookingDto[]>("POST", "/api/bookings", {
    startDate: isoStartDate,
    endDate: isoEndDate,
    bookForId,
    ...bookRequest,
  });
}

export async function bookDeskForGroup(
  date: BookingDate,
  membershipIds: string[],
  bookRequest: {
    locationId: string;
    spaceId?: string;
    isInvitation?: boolean;
  }
): Promise<GroupDeskBookings> {
  const isoStartDate = formatISODate(date.startDate);
  const isoEndDate = formatISODate(date.endDate);
  const response = await request<GroupDeskBookingsResponse>(
    "POST",
    "/api/group-bookings",
    {
      startDate: isoStartDate,
      endDate: isoEndDate,
      membershipIds,
      ...bookRequest,
    }
  );

  return new GroupDeskBookings(
    mapBookingDate(response.date),
    response.desks.map(
      (it) =>
        new GroupDeskBooking(
          mapUser(it.membership.user),
          it.desk ? mapDesk(it.desk) : null
        )
    )
  );
}

export async function editBooking(
  bookingId: string,
  date: BookingDate,
  bookForId: string,
  bookRequest: {
    locationId: string;
    deskId?: string;
    isInvitation?: boolean;
  }
): Promise<void> {
  const isoStartDate = formatISODate(date.startDate);
  const isoEndDate = formatISODate(date.endDate);
  await request("PATCH", "/api/bookings", {
    bookingId,
    bookingDate: {
      startDate: isoStartDate,
      endDate: isoEndDate,
    },
    bookForId,
    ...bookRequest,
  });
}

export async function cancelBooking(bookingId: string): Promise<void> {
  await request("DELETE", `/api/bookings/${bookingId}`);
}

export async function declineInvitation(bookingId: string): Promise<void> {
  await request("DELETE", `/api/booking-invitations/${bookingId}`);
}

export async function approveInvitation(bookingId: string): Promise<void> {
  await request("PATCH", `/api/booking-invitations/${bookingId}`);
}
