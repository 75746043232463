import { Avatar, Box, Button, styled, Tooltip } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React, { forwardRef, useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { BoxCenter } from "../../../../../../global/components";
import { useCurrentUser } from "../../../../../authentication/hooks";
import { LocationBookingOverview } from "../../../../domain";
import { BookingTooltip } from "../../FloatingLocationBookings/BookingTooltip";
import { useSelection } from "../../state";

interface FloatingMobileAvatarsProps {
  sortedLocationBookings: LocationBookingOverview[];
}

interface StyledAvatarProps {
  index: number;
  sliderIndex: number;
  isCurrentUser?: boolean;
  src?: string;
  onClick?: () => void;
}

const StyledAvatarContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  cursor: "pointer",
  borderRadius: "50%",
  border: "1px solid white",
  padding: "4px",
}));

const StyledAvatarBorder = styled(Avatar)({
  border: "1px solid white",
  width: 30,
  height: 30,
});

const StyledAvatar = forwardRef(
  (
    { isCurrentUser, index, sliderIndex, src, ...props }: StyledAvatarProps,
    ref
  ) => (
    <StyledAvatarContainer
      ref={ref}
      {...props}
      sx={{
        transition: "0.5s all",
        right: (index - sliderIndex) * 32 - 8,
        backgroundColor: "cyclamen.regular",
        ...(isCurrentUser && {
          backgroundColor: "orange.regular",
        }),
      }}
    >
      <StyledAvatarBorder src={src} />
    </StyledAvatarContainer>
  )
);

const BookingAvatar: React.FC<{
  index: number;
  booking: LocationBookingOverview;
  sliderIndex: number;
  onClick?: (locationBooking: LocationBookingOverview) => void;
}> = ({ index, booking, sliderIndex, onClick }) => {
  const currentUser = useCurrentUser();
  const handleSelectLocationBooking = useMemoizedFn(() => {
    onClick?.(booking);
  });

  return (
    <Tooltip title={<BookingTooltip booking={booking} />} placement="left">
      <StyledAvatar
        src={booking.bookedFor.avatarImageUrlSrc}
        isCurrentUser={currentUser.isUser(booking.bookedFor)}
        onClick={handleSelectLocationBooking}
        index={index}
        sliderIndex={sliderIndex}
      />
    </Tooltip>
  );
};

export const FloatingMobileAvatars: React.FC<FloatingMobileAvatarsProps> = ({
  sortedLocationBookings,
}) => {
  const { onSelectLocationBooking } = useSelection();
  const [sliderIndex, setSliderIndex] = useState<number>(0);
  const avatarContainerRef = useRef<HTMLInputElement>(null);

  const checkOverflowLeft = (): boolean => {
    if (avatarContainerRef.current) {
      const avatarsAbleToFit = avatarContainerRef.current.offsetWidth / 32;
      if (sortedLocationBookings.length - sliderIndex < avatarsAbleToFit) {
        return true;
      }
    }
    return false;
  };

  return (
    <BoxCenter
      sx={{
        marginBottom: 1,
        width: "100%",
        height: "40px",
      }}
    >
      <Button
        onClick={() => setSliderIndex((prevState) => prevState + 3)}
        variant="icon"
        color="secondaryContained"
        sx={{ position: "absolute", zIndex: "10", left: 8 }}
        disabled={checkOverflowLeft()}
      >
        <ChevronLeft />
      </Button>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          width: "calc(100% - 68px)",
          left: 6,
          height: "100%",
          overflowX: "hidden",
        }}
        ref={avatarContainerRef}
      >
        {sortedLocationBookings.map((booking, index) => (
          <BookingAvatar
            key={booking.bookedFor.id}
            booking={booking}
            sliderIndex={sliderIndex}
            onClick={onSelectLocationBooking}
            index={sortedLocationBookings.length - index - 1}
          />
        ))}
      </Box>
      <Button
        onClick={() => setSliderIndex((prevState) => prevState - 3)}
        variant="icon"
        color="secondaryContained"
        sx={{
          position: "absolute",
          zIndex: "10",
          right: 8,
          "&:disabled": { opacity: "1" },
        }}
        disabled={sliderIndex === 0}
      >
        <ChevronRight />
      </Button>
    </BoxCenter>
  );
};
