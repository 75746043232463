import { Box, Button } from "@mui/material";
import React from "react";
import { Plus, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { trackRedirectToGoogleCalendar } from "../../../../../tracking";

export interface ConfirmDialogActionsProps {
  onCancel: () => void;
}

export const ConfirmDialogActions: React.FC<ConfirmDialogActionsProps> = ({
  onCancel,
}) => {
  const { t } = useTranslation("booking");

  const navigateGoogle = () => {
    trackRedirectToGoogleCalendar();
    window.open("https://calendar.google.com", "_blank", "noopener,noreferrer");
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        gap: theme.spacing(1),
        marginTop: theme.spacing(3),
        width: "100%",
        [theme.breakpoints.down("md")]: {
          marginTop: theme.spacing(1),
        },
      })}
    >
      <Button
        fullWidth
        color="cancel"
        variant="outlined"
        onClick={onCancel}
        startIcon={<X size={16} />}
      >
        {t("form.action.cancel")}
      </Button>
      <Button
        fullWidth
        variant="outlined"
        sx={{
          color: "violet.regular",
          "&:hover": { color: "violet.hover", borderColor: "violet.hover" },
          "&:focus": { color: "violet.hover", borderColor: "violet.hover" },
        }}
        startIcon={<Plus size={16} />}
        onClick={navigateGoogle}
      >
        {t("form.action.book-conference")}
      </Button>
    </Box>
  );
};
