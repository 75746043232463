import { Box } from "@mui/material";
import React from "react";
import { useMyBookingsQuery } from "../hooks";
import { UpcomingBookingsEmpty } from "../UpcomingBookings/UpcomingBookingsEmpty";

export const UpcomingBookingsContent: React.FC = () => {
  const { data: bookings } = useMyBookingsQuery();
  const bookingsAvailable = bookings && bookings.length > 0;

  return (
    <Box sx={{ position: "relative", flex: "1 1 auto" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <UpcomingBookingsEmpty
          svgHeight={100}
          svgWidth={100}
          big
          {...(bookingsAvailable && { description: "content.description" })}
          hideButton={bookingsAvailable}
        />
      </Box>
    </Box>
  );
};
