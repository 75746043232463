import React, { createContext } from "react";
import { BookingDispatch, BookingState } from "./types";

export const BookingStateContext = createContext<BookingState | undefined>(
  undefined
);
export const BookingDispatchContext = createContext<
  BookingDispatch | undefined
>(undefined);

export function useBookingContext(): [BookingState, BookingDispatch] {
  const stateCtx = React.useContext(BookingStateContext);
  const dispatchCtx = React.useContext(BookingDispatchContext);
  if (!stateCtx || !dispatchCtx) {
    throw new Error(
      "useBookingContext can only be used inside BookingContextProvider."
    );
  }
  return [stateCtx, dispatchCtx];
}

export function useBookingState(): BookingState {
  const ctx = React.useContext(BookingStateContext);
  if (!ctx) {
    throw new Error(
      "useBookingState can only be used inside BookingContextProvider."
    );
  }
  return ctx;
}

export function useBookingDispatch(): BookingDispatch {
  const ctx = React.useContext(BookingDispatchContext);
  if (!ctx) {
    throw new Error(
      "useBookingDispatch can only be used inside BookingContextProvider."
    );
  }
  return ctx;
}
