export const PinSvgAvatarSize = 32;

export const PinSvg = `
  <g class="hover-shadow">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z"
    />
  </g>
  <path
    class="pin-path"
    stroke-width="4"
    stroke-linecap="round"
    stroke="transparent" 
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="transparent"
    d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z"
  />
  <g class="icon--dedicated">
    <rect x="32" y="3" width="8" height="8" rx="4" fill="#FFA500"/>
    <rect x="32" y="3" width="8" height="8" rx="4" stroke="white" stroke-width="2"/>
  </g>
  <g class="hover-shadow hover-rotate">
    <rect x="4.5" y="4.5" width="31" height="31" rx="15.5" fill="white"/>
    <g class="icon--available">
        <path d="M20 15.3334V24.6667" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 15.3334V24.6667" stroke="white" stroke-opacity="0.38" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.3334 20H24.6667" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.3334 20H24.6667" stroke="white" stroke-opacity="0.38" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g class="icon--partially-available">
        <path d="M7.11505 3.22515L1.99076 11.7797C1.88511 11.9627 1.82921 12.1701 1.82862 12.3814C1.82803 12.5927 1.88277 12.8004 1.98739 12.984C2.09202 13.1676 2.24288 13.3205 2.42497 13.4277C2.60706 13.5348 2.81404 13.5924 3.0253 13.5947H13.2739C13.4851 13.5924 13.6921 13.5348 13.8742 13.4277C14.0563 13.3205 14.2072 13.1676 14.3118 12.984C14.4164 12.8004 14.4711 12.5927 14.4706 12.3814C14.47 12.1701 14.4141 11.9627 14.3084 11.7797L9.18412 3.22515C9.07627 3.04735 8.92441 2.90034 8.7432 2.79832C8.56199 2.6963 8.35754 2.6427 8.14959 2.6427C7.94163 2.6427 7.73718 2.6963 7.55597 2.79832C7.37476 2.90034 7.2229 3.04735 7.11505 3.22515V3.22515Z" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.14941 6.33484V8.75481" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.14941 11.1747H8.15546" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g class="icon--not-available">
        <path d="M11.7794 4.51984L4.51953 11.7797" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7794 4.51984L4.51953 11.7797" stroke="white" stroke-opacity="0.38" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.51953 4.51984L11.7794 11.7797" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.51953 4.51984L11.7794 11.7797" stroke="white" stroke-opacity="0.38" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <g class="icon--invite">
        <path d="M8 13.3333H14" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8 13.3333H14" stroke="white" stroke-opacity="0.04" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 2.33333C11.2652 2.06811 11.6249 1.91911 12 1.91911C12.1857 1.91911 12.3696 1.95569 12.5412 2.02676C12.7128 2.09783 12.8687 2.202 13 2.33333C13.1313 2.46465 13.2355 2.62055 13.3066 2.79213C13.3776 2.96371 13.4142 3.14761 13.4142 3.33333C13.4142 3.51904 13.3776 3.70294 13.3066 3.87452C13.2355 4.0461 13.1313 4.202 13 4.33333L4.66667 12.6667L2 13.3333L2.66667 10.6667L11 2.33333Z" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 2.33333C11.2652 2.06811 11.6249 1.91911 12 1.91911C12.1857 1.91911 12.3696 1.95569 12.5412 2.02676C12.7128 2.09783 12.8687 2.202 13 2.33333C13.1313 2.46465 13.2355 2.62055 13.3066 2.79213C13.3776 2.96371 13.4142 3.14761 13.4142 3.33333C13.4142 3.51904 13.3776 3.70294 13.3066 3.87452C13.2355 4.0461 13.1313 4.202 13 4.33333L4.66667 12.6667L2 13.3333L2.66667 10.6667L11 2.33333Z" stroke="white" stroke-opacity="0.04" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </g>    
    <!-- insert avatar here -->
    <g class="avatar-placeholder" transform="translate(4, 4)"></g>
    <rect x="4.5" y="4.5" width="31" height="31" rx="15.5" stroke="white"/>
  </g>
`;
