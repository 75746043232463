import { Box, Button, DialogContent, styled, Typography } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React from "react";
import { Check, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { InventoryInfo } from ".";
import { AmtDialog } from "../../../../../../components";
import { trackDeleteInventory } from "../../../../../../tracking";
import { SimpleDialogTitle } from "../../../../../components/Dialogs";
import { useDeleteInventoryMutation } from "../../../hooks/useDeleteInventoryMutation";
import { useDeleteInventoryForm } from "../../../state";

const HideMobile = styled("span")(({ theme }) => ({
  [theme.breakpoints.down(375)]: {
    display: "none",
  },
}));

export const DeleteInventoryDialog: React.FC = () => {
  const { t } = useTranslation("inventory-management");
  const form = useDeleteInventoryForm();
  const deleteInventoryMutation = useDeleteInventoryMutation();

  if (!form.inventory) {
    throw new Error("Invalid usage of DeleteInventoryDialog");
  }

  const handleSubmit = useMemoizedFn(() => {
    if (!form.inventory)
      return Promise.reject("Invalid usage of DeleteInventoryDialog");
    trackDeleteInventory();
    return deleteInventoryMutation.mutateAsync(form.inventory.id);
  });

  return (
    <AmtDialog open={form.isOpen} onClose={form.closeDialog} closeButton>
      <DialogContent>
        <SimpleDialogTitle
          title={t("dialog.delete-inventory-title")}
          sx={{ backgroundColor: "shades.greyUltimateLight" }}
        />
        <InventoryInfo inventory={form.inventory} sx={{ marginY: 2 }} />
        <Box
          sx={(theme) => ({
            textAlign: "center",
            marginY: 3,
            [theme.breakpoints.down("md")]: {
              marginY: 2,
            },
          })}
        >
          <Typography variant="h4" color="error.main">
            {t("dialog.delete-warning.title")}
          </Typography>
          <Typography variant="body2" color="shades.grey">
            {t("dialog.delete-warning.text")}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: 1,
            [theme.breakpoints.down("md")]: {
              gap: 2,
            },
          })}
        >
          <Button
            fullWidth
            color="cancel"
            variant="outlined"
            onClick={form.closeDialog}
            startIcon={<X size={16} />}
            data-testid="dialog--cancel"
          >
            <HideMobile>{t("dialog.action.no")}</HideMobile>
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            startIcon={<Check size={16} />}
            data-testid="dialog--submit"
          >
            <HideMobile>{t("dialog.action.yes")}</HideMobile>
          </Button>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
