import { Box } from "@mui/material";
import React from "react";
import {
  useBackofficeInventories,
  useBackofficeInventoryTypes,
} from "../../state";
import { CreateInventoryDialog } from "./components/Dialogs/CreateInventoryDialog/CreateInventoryDialog";
import { CreateInventoryTypeDialog } from "./components/Dialogs/CreateInventoryTypeDialog";
import { ConnectedDeleteInventoryDialog } from "./components/Dialogs/DeleteInventoryDialog";
import { Header } from "./components/Header";
import { InventoryTable } from "./components/InventoryTable";

export const InventoryManagement: React.FC = () => {
  const { inventory, inventories } = useBackofficeInventories();
  const { inventoryType } = useBackofficeInventoryTypes();

  const filteredInventories = inventory
    ? [inventory]
    : inventoryType
    ? inventories.filter((inventory) => inventory.type.id === inventoryType?.id)
    : inventories;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "hidden",
      }}
    >
      <Header />
      <InventoryTable inventories={filteredInventories} />
      <CreateInventoryTypeDialog />
      <CreateInventoryDialog />
      <ConnectedDeleteInventoryDialog />
    </Box>
  );
};
