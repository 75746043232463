const SvgDeskHappy = ({ width = 70, height = 70, ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 70 70"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.669 57.5a3.501 3.501 0 0 0 3.464 3h9.734a3.5 3.5 0 0 0 3.464-3H26.67Z"
      fill="#00CC99"
    />
    <path
      d="M26.669 57.5a3.501 3.501 0 0 0 3.464 3h9.734a3.5 3.5 0 0 0 3.464-3H26.67Z"
      fill="#fff"
      fillOpacity={0.68}
    />
    <path
      d="M26.669 57.5a3.501 3.501 0 0 0 3.464 3h9.734a3.5 3.5 0 0 0 3.464-3H26.67Z"
      stroke="#00CC99"
    />
    <rect
      x={-0.5}
      y={0.5}
      width={69}
      height={49}
      rx={3.5}
      transform="matrix(-1 0 0 1 69 8)"
      fill="#fff"
      stroke="#00CC99"
    />
    <path
      d="m30.985 28-1.91-.675a7 7 0 0 0-4.666 0L22.5 28M28.743 29.243l-1-.354a3 3 0 0 0-2 0l-1 .354M44.5 29.243l-1-.354a3 3 0 0 0-2 0l-1 .354M46.985 28l-1.91-.675a7 7 0 0 0-4.666 0L38.5 28M26.5 43l.728.255A22.02 22.02 0 0 0 42.5 43v0"
      stroke="#00CC99"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgDeskHappy;
