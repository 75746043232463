import { BoxProps, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import clsx from "clsx";
import React from "react";
import { trackSelectDesk } from "../../../../../../tracking";
import { useCurrentUser } from "../../../../../authentication/hooks";
import { DeskOverview } from "../../../../domain";
import { canBook, useBookingForm, useSelection } from "../../state";
import { BookingItemButton } from "../BookingItem";
import { DeskItemIdentifier } from "./components";

export interface DeskItemButtonProps extends Omit<BoxProps, "onClick"> {
  overview: DeskOverview;
  disabled?: boolean;
  selected?: boolean;
  onClick?: (overview: DeskOverview) => void;
  sx?: SxProps<Theme>;
}

const DeskItemSx: SxProps<Theme> = (theme) => ({
  '&:not([aria-pressed="true"]) .Amt--DeskItem--NotAvailableDetails': {
    display: "none",
  },
  "&:not(:hover).Amt--DeskItemButton--hasBookingForCurrentUser": {
    backgroundColor: theme.palette.orange.light,
    ".AmtDeskIdentifier": {
      backgroundColor: theme.palette.orange.regular,
      color: "white",
    },
    ".AmtDeskIdentifier--Caption": {
      color: "white",
    },
  },
  '&[aria-pressed="true"]:not(:hover).Amt--DeskItemButton--hasBookingForOtherUser':
    {
      backgroundColor: theme.palette.cyclamen.light,
      ".AmtDeskIdentifier": {
        backgroundColor: theme.palette.cyclamen.regular,
        color: "white",
      },
      ".AmtDeskIdentifier--Caption": {
        color: "white",
      },
    },
});

export const DeskItemButtonComponent: React.FC<
  React.PropsWithChildren<DeskItemButtonProps>
> = React.memo(
  ({
    overview,
    className,
    selected = false,
    disabled = false,
    onClick,
    sx = [],
    children,
    ...props
  }: React.PropsWithChildren<DeskItemButtonProps>) => {
    const { id } = useCurrentUser();
    const hasBookingForCurrentUser = !!overview.bookings.find(
      (it) => it.bookedFor.id === id
    );
    const hasBookingForOtherUser =
      overview.date.isSingleDay &&
      overview.bookings.length > 0 &&
      !hasBookingForCurrentUser;

    const handleOnClick = useMemoizedFn(() => {
      if (!selected) {
        trackSelectDesk(overview, "bookings-list");
      }
      onClick?.(overview);
    });

    return (
      <BookingItemButton
        onClick={handleOnClick}
        disabled={disabled}
        selected={selected}
        className={clsx({
          "Amt--DeskItemButton--hasBookingForCurrentUser":
            hasBookingForCurrentUser,
          "Amt--DeskItemButton--hasBookingForOtherUser": hasBookingForOtherUser,
        })}
        sx={[DeskItemSx, ...(Array.isArray(sx) ? sx : [sx])]}
        {...props}
      >
        <DeskItemIdentifier
          identifier={overview.desk.identifier}
          isDedicated={overview.isDedicated}
        />

        {children}
      </BookingItemButton>
    );
  }
);

export const DeskItemButton: React.FC<DeskItemButtonProps> = (props) => {
  const form = useBookingForm();
  const { selectedDesk, onSelectDesk } = useSelection();
  return (
    <DeskItemButtonComponent
      disabled={form.isEditing && !canBook(props.overview, form)}
      selected={selectedDesk?.desk.id === props.overview.desk.id}
      onClick={onSelectDesk}
      {...props}
    />
  );
};
