import { Box } from "@mui/material";
import React from "react";
import { MapPin } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AmtCenteredLoader, MobilePageHeader } from "../../../../../components";
import { FloorPlan } from "../FloorPlan";
import {
  useIsFloorPlanVisible,
  useMyBookingsQuery,
  useSelectedBooking,
} from "../hooks";
import { UpcomingBookingsEmpty } from "./UpcomingBookingsEmpty";
import { UpcomingBookingsList } from "./UpcomingBookingsList";

const UpcomingBookingsHeader: React.FC<{ onBack?: () => void }> = ({
  onBack,
}) => {
  const { t } = useTranslation("dashboard");
  return (
    <MobilePageHeader
      title={
        <>
          <MapPin size={16} />
          {t("upcoming-bookings")}
        </>
      }
      onBack={onBack}
    />
  );
};

const UpcomingBookingsMobileContent: React.FC = () => {
  const { data: bookings, isLoading } = useMyBookingsQuery();

  return (
    <>
      {(!bookings || isLoading) && <AmtCenteredLoader />}
      {bookings && bookings.length === 0 && <UpcomingBookingsEmpty />}
      {bookings && bookings.length > 0 && (
        <UpcomingBookingsList bookings={bookings} />
      )}
    </>
  );
};

const FloorPlanView: React.FC = () => {
  const [selectedBooking, onSelectBooking] = useSelectedBooking();

  function onBack() {
    onSelectBooking(null);
  }

  if (!selectedBooking) {
    throw new Error("Invalid usage of FloorPlanView");
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <UpcomingBookingsHeader onBack={onBack} />
      <Box
        sx={{
          flex: "0 1 auto",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <UpcomingBookingsList bookings={[selectedBooking]} />
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          overflow: "auto",
        }}
      >
        <FloorPlan />
      </Box>
    </Box>
  );
};

const ListView: React.FC = () => {
  const navigate = useNavigate();

  function onBack() {
    navigate("/");
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <UpcomingBookingsHeader onBack={onBack} />
      <Box
        sx={{
          flex: "1 1 auto",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <UpcomingBookingsMobileContent />
      </Box>
    </Box>
  );
};

export const UpcomingBookingsMobile: React.FC = () => {
  const [isFloorPlanVisible] = useIsFloorPlanVisible();

  if (isFloorPlanVisible) {
    return <FloorPlanView />;
  } else {
    return <ListView />;
  }
};
