import { Location } from "../../../assets/domain";
import { User } from "../../../authentication/domain";
import { Booking } from "./Booking";
import { BookingDate } from "./BookingDate";

export class LocationBookingOverview {
  constructor(
    public readonly location: Location,
    public readonly bookingDate: BookingDate,
    public readonly bookedFor: User,
    public readonly bookings: Booking[]
  ) {}
}

export class LocationBookingsOverview {
  constructor(
    public readonly location: Location,
    public readonly userBookings: LocationBookingOverview[]
  ) {}
}

export class LocationBookings {
  constructor(
    public readonly bookingDate: BookingDate,
    public readonly locations: LocationBookingsOverview[]
  ) {}

  get bookings(): LocationBookingOverview[] {
    return this.locations.flatMap((it) => it.userBookings);
  }

  findByBookingId(bookingId: string): LocationBookingOverview | null {
    return (
      this.bookings.find((locationBooking) =>
        locationBooking.bookings.find((booking) => booking.id === bookingId)
      ) ?? null
    );
  }
}
