import React from "react";
import { RemoveAdminDialog } from ".";
import { useDeleteAdminForm } from "../../state";

export const ConnectedRemoveAdminDialog: React.FC = () => {
  const form = useDeleteAdminForm();

  if (!form.admin) return null;

  return <RemoveAdminDialog />;
};
