import { AvatarProps, Box, Typography, TypographyProps } from "@mui/material";
import React from "react";
import { BookingItemAvatar } from "../BookingItem";
import { Caption } from "../Caption";

export interface ConferenceRoomItemContentProps {
  primary: string;
  primaryTypographyProps?: Partial<TypographyProps>;
  secondary: string;
  AvatarComponent?: React.ElementType<AvatarProps>;
  AvatarProps?: Partial<AvatarProps>;
}

export const ConferenceRoomItemContent: React.FC<
  ConferenceRoomItemContentProps
> = ({
  primary,
  primaryTypographyProps,
  secondary,
  AvatarComponent = BookingItemAvatar,
  AvatarProps,
}) => {
  return (
    <Box
      sx={[{ display: "flex", alignItems: "center", gap: 1 }]}
      className="Item-visibleNoHover"
    >
      <AvatarComponent {...AvatarProps} />
      <Box sx={{ lineHeight: 0, display: "flex", flexDirection: "column" }}>
        <Caption>{secondary}</Caption>
        <Typography variant="body2" {...primaryTypographyProps}>
          {primary}
        </Typography>
      </Box>
    </Box>
  );
};
