import * as d3 from "d3";
import { D3ZoomEvent, zoomIdentity } from "d3";
import { getBBox } from "./boundingBox";
import { OnZoomCallback } from "./types";

export interface ZoomOptions {
  interactive: boolean;
  maxScale: number;
}

export class Zoom {
  private readonly $svg: d3.Selection<SVGElement, unknown, null, unknown>;
  private readonly $image: d3.Selection<SVGGElement, unknown, null, unknown>;
  private readonly zoom: d3.ZoomBehavior<any, any> = d3.zoom();
  private fitScale: number = 1;

  public onZoom: OnZoomCallback | null = null;

  constructor(
    private readonly svg: SVGElement,
    private readonly options: ZoomOptions
  ) {
    this.$svg = d3.select(svg);
    this.$image = this.$svg.select("g");

    this.zoom.interpolate(d3.interpolate);
    this.fitScale = this.calculateFitScale();
    this.zoom.scaleExtent([this.fitScale, this.options.maxScale]);
    this.hook();

    if (this.options.interactive) {
      this.$svg.call(this.zoom);
    }
  }

  private hook() {
    this.zoom.on("zoom", (e: D3ZoomEvent<any, any>) => {
      this.$image?.attr("transform", e.transform.toString());
      this.onZoom?.({
        transform: e.transform,
        fitScale: this.fitScale,
        zoomedToFit: this.fitScale === e.transform.k,
      });
    });
  }

  zoomToFit() {
    const image = this.$image.node();
    const parent = image?.parentElement;
    if (!this.$svg || !this.$image || !image || !parent) {
      return;
    }

    const bounds = image.getBBox();
    const width = bounds.width,
      height = bounds.height;
    const midX = bounds.x + width / 2,
      midY = bounds.y + height / 2;

    const fullWidth = parent.clientWidth,
      fullHeight = parent.clientHeight;

    this.fitScale = this.calculateFitScale();

    this.zoom.transform(
      this.$svg,
      zoomIdentity
        .translate(fullWidth / 2, fullHeight / 2)
        .scale(this.fitScale)
        .translate(-midX, -midY)
    );
  }

  zoomToEl(el: SVGGraphicsElement, animate: boolean) {
    this.zoomToBBox(getBBox(el, false, this.$image.node()), animate);
  }

  zoomToElements(elements: SVGGraphicsElement[], animate: boolean) {
    const boundingBox = {
      left: Number.MAX_VALUE,
      top: Number.MAX_VALUE,
      right: Number.MIN_VALUE,
      bottom: Number.MIN_VALUE,
    };

    elements.forEach((el) => {
      const { x, y, width, height } = getBBox(el, false, this.$image.node());
      const bottom = y + height;
      const right = x + width;

      if (x < boundingBox.left) {
        boundingBox.left = x;
      }
      if (y < boundingBox.top) {
        boundingBox.top = y;
      }
      if (right > boundingBox.right) {
        boundingBox.right = right;
      }
      if (bottom > boundingBox.bottom) {
        boundingBox.bottom = bottom;
      }
    });

    const rect = new DOMRect(
      boundingBox.left,
      boundingBox.top,
      boundingBox.right - boundingBox.left,
      boundingBox.bottom - boundingBox.top
    );
    this.zoomToBBox(rect, animate);
  }

  zoomToBBox(boundingBox: DOMRect, animate: boolean) {
    const { x, y, width, height } = boundingBox;
    const left = x;
    const top = y;
    const right = x + width;
    const bottom = y + height;

    const fullWidth = this.svg.clientWidth;
    const fullHeight = this.svg.clientHeight;
    const centerPoint = {
      x: fullWidth / 2,
      y: fullHeight / 2,
    };

    const $svg = animate ? this.$svg.transition().duration(500) : this.$svg;

    const idealScale = 0.9 / Math.max(width / fullWidth, height / fullHeight);
    const limitedScale = Math.min(
      this.options.maxScale,
      Math.max(1, idealScale)
    );

    $svg.call(
      this.zoom.transform,
      zoomIdentity
        .translate(centerPoint.x, centerPoint.y)
        .scale(limitedScale)
        .translate(-(left + right) / 2, -(top + bottom) / 2)
    );
  }

  private calculateFitScale(): number {
    const image = this.$image.node();
    const parent = image?.parentElement;
    if (!image || !parent) {
      return 1;
    }

    const bounds = image.getBBox();
    const width = bounds.width,
      height = bounds.height;

    const fullWidth = parent.clientWidth,
      fullHeight = parent.clientHeight;

    if (width <= 0 || height <= 0) {
      return 1;
    }

    return 0.9 / Math.max(width / fullWidth, height / fullHeight);
  }
}
