import { Box, styled, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { useLayoutEffect, useRef } from "react";

export interface BookingItemButtonProps {
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode | undefined;
  onClick?: () => void;
}

const StyledButton = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  borderRadius: "6px",
  display: "flex",
  marginBottom: "10px",
  backgroundColor: "white",
  '&[aria-disabled="true"]': {
    opacity: 0.55,
    pointerEvents: "none",
  },
  '&:hover, &[aria-pressed="true"]': {
    backgroundColor: theme.palette.shades.greyUltimateLight,
    cursor: "pointer",
    ".AmtDeskIdentifier": {
      backgroundColor: theme.palette.shades.greyExtraLight,
    },
  },
}));

export const BookingItemButton: React.FC<BookingItemButtonProps> = ({
  disabled = false,
  selected = false,
  sx,
  className,
  onClick,
  children,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!selected) {
      return;
    }
    // Hack: certain mobile phones are slow so this doesn't behave as expected.
    setTimeout(() => {
      if (ref.current && ref.current.scrollIntoView) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 0);
  }, [selected]);

  return (
    <StyledButton
      ref={ref}
      onClick={onClick}
      role="button"
      className={className}
      sx={sx}
      aria-pressed={selected}
      aria-disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};
