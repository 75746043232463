import { Box } from "@mui/material";
import React from "react";
import { Header } from "./Header";

export const MainLayoutPadding = {
  Top: "56px",
  Right: "0px",
  Bottom: "0px",
  Left: "0px",
};

export const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { Top, Right, Bottom, Left } = MainLayoutPadding;
  return (
    <>
      <Header />
      <Box
        sx={{
          padding: `${Top} ${Right} ${Bottom} ${Left}`,
        }}
      >
        {children}
      </Box>
    </>
  );
};
