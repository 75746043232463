import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { Booking } from "../domain";

type CancelBookingState =
  | {
      open: false;
      booking?: Booking;
    }
  | {
      open: true;
      booking: Booking;
    };

interface CancelBookingDispatch {
  initiate(booking: Booking): void;
  closeDialog(): void;
}

type UseBookingCancelResult = CancelBookingState & CancelBookingDispatch;

const stateAtom = atom<CancelBookingState>({
  open: false,
});

export function useBookingCancel(): UseBookingCancelResult {
  const [state, setState] = useAtom(stateAtom);

  const initiate = useMemoizedFn((booking: Booking) => {
    setState({
      open: true,
      booking,
    });
  });
  const closeDialog = useMemoizedFn(() => {
    setState((prev) => ({ ...prev, open: false }));
  });

  return { ...state, initiate, closeDialog };
}
