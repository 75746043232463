import { Box, Button, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtDialog, BookingInfo } from "../../../../components";
import { MuiSlideUpAnimation } from "../../../../theme/components/MuiSlideUpAnimation";
import { useConferenceSelection } from "../../state";
import { useConferenceDateRange } from "../../state/useConferenceDateRange";
import { useConferenceForm } from "../../state/useConferenceForm";
import { DialogTitle } from "./components";
import { ConferenceCalendar } from "./components/ConferenceCalendar";
import { ConfirmDialog } from "./components/ConfirmDialog";

export const ConferenceFormDialog: React.FC = () => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { t } = useTranslation("booking");
  const form = useConferenceForm();
  const { isOpen, closeDialog } = form;
  const { dateRange, setDateRange } = useConferenceDateRange();

  const { selectedConferenceRoom } = useConferenceSelection();

  const closeConfirmDialog = () => {
    setConfirmOpen(false);
  };

  const openConfirmDialog = () => {
    setConfirmOpen(true);
  };

  useEffect(() => {
    // Wait for the elements to mount
    setTimeout(() => {
      const hour = new Date().getHours();
      const calendarRow = document.getElementById(`calendar-hour-${hour}`);
      if (calendarRow) calendarRow.scrollIntoView({ behavior: "smooth" });
    }, 1200);
  }, [form.isOpen]);

  return (
    <>
      <ConfirmDialog isOpen={confirmOpen} closeDialog={closeConfirmDialog} />
      <AmtDialog
        open={isOpen}
        onClose={closeDialog}
        closeButton
        fullScreen
        TransitionComponent={MuiSlideUpAnimation}
      >
        <DialogContent
          sx={(theme) => ({
            width: "70%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          })}
        >
          <DialogTitle />

          {dateRange && (
            <>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginBottom: "20px",
                  },
                })}
              >
                <BookingInfo
                  sx={(theme) => ({
                    marginY: 3,
                    [theme.breakpoints.down("md")]: {
                      marginY: 1,
                    },
                  })}
                  floor={selectedConferenceRoom?.floor}
                  space={selectedConferenceRoom?.space}
                  date={dateRange}
                />
                <Button
                  sx={{
                    color: "violet.regular",
                    "&:hover": {
                      color: "violet.hover",
                    },
                    "&:focus": {
                      color: "violet.hover",
                    },
                    padding: "0px",
                  }}
                  onClick={openConfirmDialog}
                >
                  <Plus size={16} />
                  {t("form.conference-booking.book")}
                </Button>
              </Box>
              <ConferenceCalendar
                setDateRange={setDateRange}
                dateRange={dateRange}
                openConfirmDialog={openConfirmDialog}
              />
            </>
          )}
        </DialogContent>
      </AmtDialog>
    </>
  );
};
