import { Box, Button } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  LocationBookingRequest,
  useBookingDate,
  useBookingForm,
  useLocations,
} from "../../state";
import { BookingItemButton, BookingItemContent } from "../BookingItem";
import { SvgAvailableAvatar } from "../BookingItem/icons";
import { LocationBox } from "./LocationBox";

const PlusIcon: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "cyclamen.regular",
      }}
    >
      <Plus size={16} />
    </Box>
  );
};

export const LocationBookingAvailableItem: React.FC = () => {
  const { t } = useTranslation("booking");
  const { location } = useLocations();
  const { date } = useBookingDate();
  const { openDialog } = useBookingForm();
  const handleOnClick = useMemoizedFn(() => {
    if (location) {
      openDialog(new LocationBookingRequest(date, location));
    }
  });

  return (
    <BookingItemButton
      onClick={handleOnClick}
      data-testid="book--list-item"
      sx={{
        ".Item-visibleHover": {
          display: "none",
        },
        "&:hover": {
          backgroundColor: "shades.greyUltimateLight",
          cursor: "pointer",
          ".Item-visibleNoHover": {
            display: "none",
          },
          ".Item-visibleHover": {
            display: "flex",
            alignItems: "center",
          },
        },
      }}
    >
      <LocationBox />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <BookingItemContent
          primary={t("item.location-available.title")}
          primaryTypographyProps={{ color: "cyclamen.regular" }}
          secondary={t("item.booked-for")}
          AvatarComponent={AvailableItemAvatar}
        />
        <Button
          className="Item-visibleHover"
          fullWidth
          startIcon={<PlusIcon />}
        >
          {t("item.action.drop-in")}
        </Button>
      </Box>
    </BookingItemButton>
  );
};

const AvailableItemAvatar = () => {
  return (
    <Box
      sx={{ color: "cyclamen.regular", display: "flex", alignItems: "center" }}
    >
      <SvgAvailableAvatar />
    </Box>
  );
};
