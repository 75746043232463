import { Box, BoxProps } from "@mui/material";
import React from "react";

export const SearchPageContainer: React.FC<BoxProps> = React.memo(
  ({ sx, ...props }) => {
    return (
      <Box
        {...props}
        sx={[
          (theme) => ({
            height: "calc(100vh - 54px)",
            display: "flex",
            flexDirection: "column",
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    );
  }
);
