import { UiNode, UiText } from "@ory/kratos-client";
import { setIn } from "formik";
import { isUiNodeInputAttributes } from "./ui";

export interface InitialFormState {
  initialValues: Record<string, string>;
  initialErrors: Record<string, string>;
  initialTouched: Record<string, boolean>;
}

export function getInitialFormState(nodes: UiNode[]): InitialFormState {
  const state: InitialFormState = {
    initialValues: {},
    initialErrors: {},
    initialTouched: {},
  };
  nodes.forEach(({ messages, attributes }) => {
    if (isUiNodeInputAttributes(attributes)) {
      // Set initial value
      state.initialValues = setIn(
        state.initialValues,
        attributes.name,
        attributes.value ?? ""
      );

      // If there are error messages initialize the error and mark field as touched.
      const errorMessages = getErrorMessages(messages);
      if (errorMessages.length > 0) {
        state.initialErrors = setIn(
          state.initialErrors,
          attributes.name,
          errorMessages.map((message) => message.text).join("\n")
        );
        state.initialTouched = setIn(
          state.initialTouched,
          attributes.name,
          true
        );
      }
    }
  });
  return state;
}

function getErrorMessages(messages: UiText[]): UiText[] {
  return messages.filter((message) => message.type === "error");
}
