import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../../api";
import { useOrganizationMembership } from "../../../../modules/authentication/hooks";
import { AssetInventoryType } from "../../../../modules/booking/domain";
import { createInventoryType } from "../../../api";
import { CreateInventoryTypeFormValues } from "../components/Dialogs/CreateInventoryTypeDialog";
import { useInventoryTypeForm } from "../state";

export function useCreateInventoryTypeMutation(): UseMutationResult<
  AssetInventoryType,
  AmtError,
  CreateInventoryTypeFormValues
> {
  const { t } = useTranslation("inventory-management");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const form = useInventoryTypeForm();
  const { organization } = useOrganizationMembership();

  return useMutation(
    ["create-inventory-type"],
    async (formValues: CreateInventoryTypeFormValues) => {
      return createInventoryType(organization.id, formValues.typeName);
    },
    {
      onSuccess: (response: AssetInventoryType) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.create-type-success"), {
          variant: "success",
        });
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.create-type-error"), {
          variant: "error",
        });
        form.closeDialog();
      },
      onSettled: () => {
        qc.invalidateQueries(["inventory-types"]);
      },
    }
  );
}
