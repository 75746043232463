import { createTheme } from "@mui/material";
import "./body.css";
import { components } from "./components";
import { palette } from "./palette";
import { typography } from "./typography";

export const theme = createTheme({
  typography,
  palette,
  components,
  breakpoints: {
    values: {
      xxs: 0,
      xs: 480,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
