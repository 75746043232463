import { Logout } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { MainLayout, MainLayoutSkeleton } from "../../layout";
import { FullPageError } from "../../layout/FullPageError";
import { useLogout, useSessionLoader } from "../hooks";

const ForbiddenError: React.FC = () => {
  return (
    <FullPageError
      message="It doesn't seem you have the correct permissions to access this page."
      actions={
        <Button
          component={RouterLink}
          to="/"
          variant="contained"
          sx={{ mt: 2 }}
        >
          Go back home
        </Button>
      }
    />
  );
};

const ServerError: React.FC = () => {
  function reloadPage() {
    document.location.reload();
  }

  return (
    <FullPageError
      message="Uh-oh. This shouldn't happen. Please try again or contact your system administrator."
      actions={
        <Button variant="contained" onClick={reloadPage} sx={{ mt: 2 }}>
          Reload page
        </Button>
      }
    />
  );
};

const NoOrganizationError: React.FC = () => {
  const performLogout = useLogout();
  return (
    <FullPageError
      message="You do not have an organization assigned."
      actions={
        <Button
          variant="contained"
          startIcon={<Logout />}
          onClick={performLogout}
          sx={{ mt: 2 }}
        >
          Logout
        </Button>
      }
    />
  );
};

export const ProtectedRoute: React.FC = () => {
  const { data, isLoading, isError, error } = useSessionLoader();

  if (isError) {
    const err = error as any;
    if (err?.status === 403 || err.response?.status === 403) {
      return <ForbiddenError />;
    }
    if (err?.status >= 500 || err.response?.status >= 500) {
      return <ServerError />;
    }
    return <Navigate to="/login" />;
  }

  if (isLoading || !data) {
    return <MainLayoutSkeleton />;
  }

  if (data.organizations.length === 0) {
    return <NoOrganizationError />;
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};
