import { useQuery, UseQueryResult } from "react-query";
import { FavoriteLocationDto } from "../../assets/api";
import {
  getBookableGroups,
  getBookings,
  getFavoriteLocations,
  getLocationCapacity,
} from "../api";
import {
  BookingDate,
  Bookings,
  LocationCapacity,
  OrganizationGroups,
} from "../domain";
import { useSelection } from "../pages/BookingPage/state";

export function useBookingsOverview(
  date: BookingDate,
  organizationId: string,
  organizationMembershipId: string,
  locationId: string
): UseQueryResult<Bookings> {
  return useQuery(
    ["bookings", date, organizationId, organizationMembershipId, locationId],
    () =>
      getBookings(date, organizationId, organizationMembershipId, locationId),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useLocationCapacity(
  organizationId: string,
  from: Date
): UseQueryResult<LocationCapacity[]> {
  return useQuery(
    ["location-capacity", organizationId, from],
    () => getLocationCapacity(organizationId, from),
    {
      refetchOnWindowFocus: "always",
    }
  );
}

export function useBookingGroups(
  organizationId: string,
  date: BookingDate,
  ignoreBookingId: string | null
): UseQueryResult<OrganizationGroups> {
  const { selectedDesk } = useSelection();
  return useQuery(
    [
      "booking-groups",
      organizationId,
      date.startDate,
      date.endDate,
      ignoreBookingId,
      selectedDesk?.desk.id,
    ],
    () =>
      getBookableGroups(
        organizationId,
        date,
        ignoreBookingId,
        selectedDesk?.desk.id
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useFavoriteLocation(
  organizationMembershipId: string
): UseQueryResult<FavoriteLocationDto[]> {
  return useQuery(
    ["favorite-locations", organizationMembershipId],
    () => getFavoriteLocations(organizationMembershipId),
    {
      refetchOnMount: "always",
    }
  );
}
