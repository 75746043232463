import { Box, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useTranslation } from "react-i18next";
import { ItemActions } from "../../../../components";
import { DeskOverview, SpaceOverview } from "../../../../domain";
import { useBookingCancel } from "../../../../hooks";
import {
  EditDeskSnapshot,
  RedirectTo,
  useBookingDate,
  useBookingForm,
} from "../../state";
import { Caption } from "../Caption";
import { DeskItemAvatar } from "./DeskItemAvatar";
import { DeskItemButton } from "./DeskItemButton";

export interface DeskItemBookedProps {
  space: SpaceOverview;
  overview: DeskOverview;
}

interface DeskItemBookedComponentProps {
  overview: DeskOverview;
  isEditMode?: boolean;
  onCancel: () => void;
  onEdit: () => void;
}

const DeskItemBookedActions: React.FC<DeskItemBookedComponentProps> = ({
  overview,
  onEdit,
  onCancel,
}) => {
  if (overview.bookings.length !== 1) {
    return null;
  }

  return (
    <ItemActions
      booking={overview.bookings[0]}
      onConfirm={onEdit}
      onCancel={onCancel}
    />
  );
};

const DeskItemBookedComponent: React.FC<DeskItemBookedComponentProps> =
  React.memo(({ overview, isEditMode, onEdit, onCancel }) => {
    const { bookings } = overview;
    const { t } = useTranslation("booking");

    const booking = bookings[0];

    return (
      <DeskItemButton overview={overview} data-state="booked">
        {booking && (
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <DeskItemAvatar src={booking.bookedFor.avatarImageUrlSrc} />
              <Box sx={{ lineHeight: 0 }}>
                <Caption>{t("item.booked-for")}</Caption>
                <Typography variant="body2">
                  {booking.bookedFor.name.full}
                </Typography>
              </Box>
            </Box>
            {!isEditMode && (
              <DeskItemBookedActions
                overview={overview}
                onCancel={onCancel}
                onEdit={onEdit}
              />
            )}
          </Box>
        )}
      </DeskItemButton>
    );
  });

export const DeskItemBooked: React.FC<DeskItemBookedProps> = ({
  space,
  overview,
}) => {
  const { date } = useBookingDate();
  const { enterEditMode, isEditing } = useBookingForm();
  const { initiate: initiateCancel } = useBookingCancel();

  const handleEdit = useMemoizedFn(() => {
    enterEditMode(
      new EditDeskSnapshot(
        date,
        space.location,
        overview.bookings[0],
        overview.floor,
        overview.space,
        overview.desk,
        RedirectTo.desk(overview)
      )
    );
  });

  const handleCancel = useMemoizedFn(() => {
    if (overview.bookings[0]) {
      initiateCancel(overview.bookings[0]);
    }
  });

  return (
    <DeskItemBookedComponent
      onEdit={handleEdit}
      onCancel={handleCancel}
      overview={overview}
      isEditMode={isEditing}
    />
  );
};
