import { Button, TableCell, Typography } from "@mui/material";
import React from "react";
import { Trash2 } from "react-feather";

interface ContentCellProps {
  content: string;
  deleteItem?: () => void;
}

export const ContentCell: React.FC<ContentCellProps> = ({
  content,
  deleteItem,
}) => {
  return (
    <TableCell
      sx={{
        padding: "10px 0px",
        minHeight: "52px",
        position: "relative",
      }}
    >
      <Typography variant="body2" sx={{ paddingLeft: "8px" }}>
        {content}
      </Typography>
      {deleteItem && (
        <Button
          onClick={deleteItem}
          sx={{
            position: "absolute",
            right: "8px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "errors.regular",
          }}
        >
          <Trash2 size={16} />
        </Button>
      )}
    </TableCell>
  );
};
