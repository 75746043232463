import { Box } from "@mui/material";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useBookingForm } from "../state";
import "./BookingFilters.css";
import { DesktopBookingFilters } from "./DesktopBookingFilters";

export const BookingFilters: React.FC = () => {
  const { isEditing } = useBookingForm();

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box
      sx={(theme) => ({
        overflow: "hidden",
        position: "relative",
        height: 104,
        [theme.breakpoints.down("md")]: {
          height: 150,
        },
      })}
    >
      <CSSTransition
        nodeRef={containerRef}
        in={isEditing}
        timeout={300}
        className="slide-header"
        classNames="slide-header"
      >
        <Box
          ref={containerRef}
          sx={(theme) => ({
            height: 104,
            [theme.breakpoints.down("md")]: {
              height: 150,
            },
          })}
        >
          <DesktopBookingFilters isEditing={false} sx={{ width: "100vw" }} />
          <DesktopBookingFilters isEditing={true} sx={{ width: "100vw" }} />
        </Box>
      </CSSTransition>
    </Box>
  );
};
