import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import { fieldToTextField } from "formik-mui";
import React from "react";
import { PasswordField } from "./PasswordField";

export interface FormikPasswordFieldProps
  extends FieldProps,
    Omit<TextFieldProps, "name" | "value" | "error"> {}

export const FormikPasswordField: React.FC<FormikPasswordFieldProps> = ({
  children,
  ...props
}) => {
  return <PasswordField {...fieldToTextField(props)}>{children}</PasswordField>;
};
