export class Page<Type> {
  constructor(
    public readonly content: Type[],
    public readonly totalPages: number,
    public readonly totalElements: number
  ) {}

  map<Result>(mapFn: (el: Type, idx: number) => Result): Page<Result> {
    return new Page<Result>(
      this.content.map(mapFn),
      this.totalPages,
      this.totalElements
    );
  }

  static empty<T>(): Page<T> {
    return new Page<T>([], 0, 0);
  }
}

export interface PageDto<Type> {
  content: Type[];
  totalPages: number;
  totalElements: number;
}

export function createPage<DtoType, Type>(
  page: PageDto<DtoType>,
  mapFn: (el: DtoType, idx: number) => Type
): Page<Type> {
  return new Page(page.content.map(mapFn), page.totalPages, page.totalElements);
}
