import { Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtDefaultBadge, AmtDefaultBadgeProps } from "../../../../components";
import { useBookingNavigation } from "../../../../hooks";
import { usePermissions } from "../../../permissions/hooks";
import { InvitationSentNotification } from "../../domain";
import { BookingDetails } from "./BookingDetails";
import { NotificationItem } from "./NotificationItem";

export interface InvitationSentNotificationItemProps {
  onClose: () => void;
  notification: InvitationSentNotification;
}

export const InvitationSentNotificationItem: React.FC<
  InvitationSentNotificationItemProps
> = ({ onClose, notification }) => {
  const { t } = useTranslation("notifications");
  const { bookings } = usePermissions();
  const navigateToBooking = useBookingNavigation();
  const snackbar = useSnackbar();

  const handleClick = useMemoizedFn(() => {
    if (!bookings.canViewBookingsOnDate(notification.context.bookingDate)) {
      snackbar.enqueueSnackbar(t("feedback.expired"), {
        variant: "warning",
      });
      return;
    }
    navigateToBooking({
      date: notification.context.bookingDate,
      locationId: notification.context.location.id,
      deskId: notification.context.desk?.id,
      floorId: notification.context.floor?.id,
      action: "view",
    });
  });

  const color: AmtDefaultBadgeProps["color"] = "cyclamen";

  return (
    <NotificationItem
      onClick={handleClick}
      onClose={onClose}
      notification={notification}
      badge={
        <AmtDefaultBadge color={color}>
          {t(`invitation-sent.badge`)}
        </AmtDefaultBadge>
      }
      content={
        <>
          <Typography variant="body2" sx={{ mb: 1, mt: 1 }}>
            {t(`invitation-sent.text`)}
          </Typography>
          <BookingDetails context={notification.context} />
        </>
      }
    />
  );
};
