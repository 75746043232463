import { Box, Paper } from "@mui/material";
import { Heart, Home, User, Users } from "react-feather";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { AmtAlert } from "../../../../components";
import { useIsMobile } from "../../../../hooks";
import { SearchTab, useSearchState } from "../../state";
import { SearchTabButton } from "./SearchTabButton";

export const SearchMenu = ({ children, filteredOptions }: any) => {
  const { t } = useTranslation("header");
  const { activeTab } = useSearchState();
  const isMobile = useIsMobile();

  const optionCount = filteredOptions[activeTab].length;

  let allEmpty = true;

  for (let tab in filteredOptions.length) {
    if (filteredOptions[tab].length) allEmpty = false;
  }

  return (
    <Paper
      sx={{
        width: isMobile ? "110%" : "434px",
        padding: "0px",
        border: "1px solid",
        borderColor: "shades.greyLight",
        borderRadius: "8px",
        transform: `translateY(6px)${
          !isMobile && ` translateX(-${434 - 264}px)`
        } `,
        whiteSpace: "nowrap",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: "shades.greyLight",
          display: "flex",
          justifyContent: "center",
          padding: "0px",
          paddingTop: "8px",
        }}
      >
        <ScrollContainer
          vertical={false}
          style={{
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          <SearchTabButton
            content={t("search.following")}
            tabType={SearchTab.FOLLOWING}
            icon={<Heart size={14} />}
            selected={activeTab === SearchTab.FOLLOWING}
            filteredOptions={filteredOptions}
          />
          <SearchTabButton
            content={t("search.person")}
            tabType={SearchTab.USER}
            icon={<User size={14} />}
            selected={activeTab === SearchTab.USER}
            filteredOptions={filteredOptions}
          />
          <SearchTabButton
            content={t("search.team")}
            tabType={SearchTab.TEAM}
            icon={<Users size={14} />}
            selected={activeTab === SearchTab.TEAM}
            filteredOptions={filteredOptions}
          />
          <SearchTabButton
            content={t("search.location")}
            tabType={SearchTab.LOCATION}
            icon={<Home size={14} />}
            selected={activeTab === SearchTab.LOCATION}
            filteredOptions={filteredOptions}
          />
        </ScrollContainer>
      </Box>
      {optionCount <= 0 && (
        <Box
          sx={{
            padding: "8px 16px",
          }}
        >
          <AmtAlert
            title={t("search.alert.title")}
            text={
              allEmpty
                ? t(`search.alert.none-found`)
                : t(`search.alert.${activeTab}`)
            }
            variant="neutral"
          />
        </Box>
      )}
      {children}
    </Paper>
  );
};
