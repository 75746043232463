import { Chip } from "@mui/material";
import React from "react";
import { Check } from "react-feather";
import { Space } from "../../../../../../assets/domain";

export interface SelectableChipProps {
  label: string;
  selected?: boolean;
  onClick?: () => void;
}

export const SelectableChip: React.FC<SelectableChipProps> = ({
  label,
  selected,
  onClick,
}) => (
  <Chip
    variant="outlined"
    className={selected ? "Mui-selected" : undefined}
    icon={selected ? <Check size={16} /> : undefined}
    label={label}
    onClick={onClick}
    sx={{ mr: 0.5 }}
  />
);

export interface SelectableRoomChipProps {
  space: Space;
  selected?: boolean;
  onSelect?: (space: Space) => void;
}

export const SelectableRoomChip: React.FC<SelectableRoomChipProps> = React.memo(
  ({ space, selected, onSelect }) => (
    <SelectableChip
      selected={selected}
      label={space.description}
      onClick={() => onSelect?.(space)}
    />
  )
);
