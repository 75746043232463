import { List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { useGoogleCalendarIntegrationSettings } from "../../hooks";

export const GoogleCalendarSettingsPage: React.FC = () => {
  const settings = useGoogleCalendarIntegrationSettings();
  return (
    <>
      {settings.data && (
        <List>
          <ListItem>
            <ListItemText
              primary={
                <>
                  Integracija sa Google Kalendarom je trenutno{" "}
                  <strong>
                    {settings.data.enabled ? "uključena" : "isključena"}
                  </strong>
                </>
              }
              secondary="Konfiguriši integraciju soba za sastanke"
            />
          </ListItem>
          {settings.data.connectedUser && (
            <ListItem>
              <ListItemText
                primary={
                  <>
                    Konfiguraciju je izvršio/la{" "}
                    <strong>{settings.data.connectedUser.name.full}</strong>
                  </>
                }
                secondary="Povezivanje preko API-ja koristi OAuth2 token navedene osobe"
              />
            </ListItem>
          )}
        </List>
      )}
    </>
  );
};
