import { Button } from "@mui/material";
import React from "react";
import { ChevronDown, ChevronUp } from "react-feather";

export interface ExpandButtonProps {
  onClick?: () => void;
  expanded?: boolean;
}

export const ExpandButton: React.FC<ExpandButtonProps> = ({
  onClick,
  expanded,
}) => (
  <Button
    variant="icon"
    color="secondaryContained"
    onClick={onClick}
    sx={{
      position: "relative",
      left: 2,
    }}
  >
    {expanded ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
  </Button>
);
