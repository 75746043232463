import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../../../modules/authentication/hooks";
import { useBackofficeLocations } from "../../../state";
import {
  getFilteredReservations,
  Reservation,
} from "../api/getFilteredReservations";
import { useReservationDate } from "../state";

export function useReservationsQuery(): UseQueryResult<Reservation[]> {
  const { organization } = useOrganizationMembership();
  const { dateRange } = useReservationDate();
  const { locationId } = useBackofficeLocations();

  return useQuery(
    ["filtered-reservations", organization.id, dateRange, locationId],
    () =>
      getFilteredReservations(
        organization.id,
        locationId,
        dateRange.startDate,
        dateRange.endDate
      )
  );
}
