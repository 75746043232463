import { Typography } from "@mui/material";
import React from "react";
import { Floor } from "../../../../../assets/domain";

export interface FloorTooltipProps {
  floor: Floor;
}

export const FloorTooltip: React.FC<FloorTooltipProps> = ({ floor }) => {
  return <Typography variant="inherit">{floor.description}</Typography>;
};
