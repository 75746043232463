import { Box } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { AmtAlert, AmtCenteredLoader } from "../../../../../components";
import { BookingStatus } from "../../../api";
import { useMyBookingsQuery } from "../hooks";
import {
  UpcomingBookingsTab,
  useUpcomingBookingsTab,
} from "../state/upcomingBookingsTab";
import { UpcomingBookingsList } from "./UpcomingBookingsList";

export const UpcomingBookings: React.FC = () => {
  const { data: bookings, isLoading } = useMyBookingsQuery();
  const { upcomingBookingsTab, changeUpcomingBookingsTab } =
    useUpcomingBookingsTab();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("dashboard");

  useEffect(() => {
    let tab = searchParams.get("tab");
    if (
      tab !== null &&
      tab === UpcomingBookingsTab.INVITATIONS.name.toLowerCase()
    ) {
      changeUpcomingBookingsTab(UpcomingBookingsTab.INVITATIONS);
    } else if (
      bookings?.filter(
        (booking) => booking.booking.status !== BookingStatus.PENDING
      ).length === 0 &&
      bookings.length > 0
    ) {
      changeUpcomingBookingsTab(UpcomingBookingsTab.INVITATIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings, searchParams]);

  const previewBookings = useMemoizedFn(() => {
    if (!bookings) return [];
    switch (upcomingBookingsTab) {
      case UpcomingBookingsTab.INVITATIONS:
        return bookings.filter(
          (booking) => booking.booking.status === BookingStatus.PENDING
        );
      case UpcomingBookingsTab.BOOKINGS:
      default:
        return bookings.filter(
          (booking) => booking.booking.status !== BookingStatus.PENDING
        );
    }
  });

  return (
    <Box
      sx={{
        flex: "1 1 auto",
        overflow: "auto",
      }}
    >
      {(!bookings || isLoading) && <AmtCenteredLoader />}
      {bookings && previewBookings().length === 0 && (
        <Box sx={{ margin: 1 }}>
          <AmtAlert
            title={t("no-bookings-alert.title")}
            text={t("no-bookings-alert.text")}
            variant="neutral"
          />
        </Box>
      )}
      {bookings && previewBookings().length > 0 && (
        <UpcomingBookingsList bookings={previewBookings()} />
      )}
    </Box>
  );
};
