import { Divider, ListItemText, Menu, MenuItem, Switch } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ColumnItem } from "..";
import { CellType } from "../../../../../../global/components/Tables";
import { trackFilterColumnsAnalytics } from "../../../../../../tracking";

interface ColumnFilterMenuProps {
  popupState: PopupState;
  selectedItems: ColumnItem[];
  menuContent: ColumnItem[];
  handleClickAll: () => void;
  handleClickItem: (item: ColumnItem) => void;
}

export const ColumnFilterMenu: React.FC<ColumnFilterMenuProps> = ({
  popupState,
  selectedItems,
  menuContent,
  handleClickAll,
  handleClickItem,
}) => {
  const { t } = useTranslation("analytics");

  const checkItem = (item: { id: number; cellType: CellType }) => {
    return !!selectedItems.find((element) => element.id === item.id);
  };

  return (
    <Menu
      disableAutoFocusItem
      {...bindMenu(popupState)}
      PaperProps={{
        style: {
          width: 247,
        },
      }}
    >
      <MenuItem onClick={handleClickAll} sx={{ padding: 1 }}>
        <Switch
          size="small"
          checked={selectedItems.length === menuContent.length}
        />
        <ListItemText primary={t("data-preview.column-names.all")} />
      </MenuItem>
      <Divider />
      {menuContent.map((item) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              handleClickItem(item);
              trackFilterColumnsAnalytics(item.cellType);
            }}
            sx={{ padding: 1 }}
          >
            <Switch size="small" checked={checkItem(item)} />
            <ListItemText
              primary={t(
                `data-preview.column-names.${item.cellType.toString()}`
              )}
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
