import { Box, styled } from "@mui/material";
import React from "react";
import { SpaceImage } from "../../../domain";

interface GalleryContentProps {
  spaceImages: SpaceImage[];
}

const GalleryImageContainer = styled("div")(({ theme }) => ({
  overflow: "hidden",
  height: 354,
  backgroundColor: theme.palette.shades.greyUltimateLight,
  borderRadius: "8px",
}));

const GalleryImage = styled("img")({
  objectFit: "cover",
  height: 354,
  width: "100%",
});

export const GalleryContent: React.FC<
  React.PropsWithChildren<GalleryContentProps>
> = ({ spaceImages }) => {
  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        maxHeight: "75%",
        overflowY: "scroll",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
        },
      })}
    >
      {spaceImages.map((spaceImage) => {
        return (
          <GalleryImageContainer key={spaceImage.id}>
            <GalleryImage
              src={spaceImage.spaceImageUrl}
              alt=""
              role="presentation"
            />
          </GalleryImageContainer>
        );
      })}
    </Box>
  );
};
