import { Box, Fade, styled, Tooltip } from "@mui/material";
import { useToggle } from "ahooks";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { forwardRef } from "react";
import { Avatar } from "../../../../../components";
import { useCurrentUser } from "../../../../authentication/hooks";
import { LocationBookingOverview } from "../../../domain";
import {
  selectSortedLocationBookings,
  useBookingState,
  useSelection,
} from "../state";
import { BookingTooltip } from "./BookingTooltip";
import { ExpandButton } from "./ExpandButton";

const FloatingContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: 22,
  bottom: 78,
  display: "flex",
  flexDirection: "column",

  [theme.breakpoints.down("md")]: {
    bottom: 62,
    right: 9,
  },
}));

interface StyledAvatarProps {
  index: number;
  isCurrentUser?: boolean;
  src?: string;
  onClick?: () => void;
}

interface StyledAvatarContainerProps {
  isCurrentUser?: boolean;
}

const StyledAvatarContainer = styled(Box)<StyledAvatarContainerProps>(
  ({ theme, isCurrentUser }) => ({
    position: "absolute",
    cursor: "pointer",
    borderRadius: "50%",
    border: "1px solid white",
    padding: "4px",
    backgroundColor: theme.palette.cyclamen.regular,
    ...(isCurrentUser && {
      backgroundColor: theme.palette.orange.regular,
    }),
  })
);

const StyledAvatarBorder = styled(Avatar)({
  border: "1px solid white",
  width: 30,
  height: 30,
});

const StyledAvatar = forwardRef(
  ({ isCurrentUser, index, src, ...props }: StyledAvatarProps, ref) => (
    <StyledAvatarContainer
      ref={ref}
      {...props}
      isCurrentUser={isCurrentUser}
      sx={{
        bottom: index * 32,
      }}
    >
      <StyledAvatarBorder src={src} />
    </StyledAvatarContainer>
  )
);

const BookingAvatar: React.FC<{
  index: number;
  booking: LocationBookingOverview;
  onClick?: (locationBooking: LocationBookingOverview) => void;
}> = ({ index, booking, onClick }) => {
  const currentUser = useCurrentUser();
  const handleSelectLocationBooking = useMemoizedFn(() => {
    onClick?.(booking);
  });

  return (
    <Tooltip title={<BookingTooltip booking={booking} />} placement="left">
      <StyledAvatar
        src={booking.bookedFor.avatarImageUrlSrc}
        isCurrentUser={currentUser.isUser(booking.bookedFor)}
        onClick={handleSelectLocationBooking}
        index={index}
      />
    </Tooltip>
  );
};

export const FloatingLocationBookings: React.FC = () => {
  const state = useBookingState();
  const [expanded, { toggle: toggleExpanded }] = useToggle(true);
  const sortedLocationBookings = selectSortedLocationBookings(state);
  const { onSelectLocationBooking } = useSelection();

  if (sortedLocationBookings.length === 0) {
    return null;
  }

  return (
    <FloatingContainer>
      <Fade in={expanded}>
        <Box
          sx={(theme) => ({
            position: "relative",
            overflow: "hidden",
            width: 40,
            bottom: -8,
            height: `min(calc(100vh - 78px - 256px), ${
              sortedLocationBookings.length * 32 + 8
            }px)`,
            [theme.breakpoints.down("md")]: {
              height: `min(calc(100vh - 78px - 292px), ${
                sortedLocationBookings.length * 32 + 8
              }px)`,
            },
          })}
        >
          {sortedLocationBookings.map((booking, index) => (
            <BookingAvatar
              key={booking.bookedFor.id}
              booking={booking}
              onClick={onSelectLocationBooking}
              index={sortedLocationBookings.length - index - 1}
            />
          ))}
        </Box>
      </Fade>
      <ExpandButton onClick={toggleExpanded} expanded={expanded} />
    </FloatingContainer>
  );
};
