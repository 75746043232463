import { getJson } from "../../../api";
import { DeskInventoryFilters } from "../domain";

export interface AssetInventoryTypeDto {
  id: string;
  name: string;
}

interface DeskInventoryFiltersDto {
  name: string;
  quantity: number;
  deskIds: string[];
}

function mapDeskInventoryFilters(
  response: DeskInventoryFiltersDto
): DeskInventoryFilters {
  return new DeskInventoryFilters(
    response.name,
    response.quantity,
    response.deskIds
  );
}

export async function getDeskInventoryFiltersByFloor(
  floorId: string
): Promise<DeskInventoryFilters[]> {
  const response = await getJson<DeskInventoryFiltersDto[]>(
    `/api/desk-inventories/floors/${floorId}`
  );
  return response.map(mapDeskInventoryFilters);
}
