export class SpaceCapacity {
  constructor(
    public readonly total: number,
    public readonly reserved: number
  ) {}

  calculateProvisional(bookForSize: number): number {
    return Math.min(this.reserved + bookForSize, this.total);
  }

  reachesCapacity(bookForSize: number): boolean {
    return this.calculateProvisional(bookForSize) >= this.total;
  }
}
