import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { DeskStatus } from "../../../../../../domain";
import { StatusIcon } from "../../../../../assets";

export interface StatusPickerProps {
  value: DeskStatus;
  onChange: (deskStatus: DeskStatus) => void;
}

export const StatusPicker: React.FC<StatusPickerProps> = React.memo(
  ({ value, onChange }) => {
    const { t } = useTranslation("asset-management");

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const newValue = event.target.value as DeskStatus;
      onChange(newValue);
    };

    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <StatusIcon deskStatus={value} />
        <Box
          sx={(theme) => ({
            display: "flex",
            flex: "1 1 auto",
            [theme.breakpoints.down("md")]: {
              marginBottom: 1,
            },
          })}
        >
          <TextField
            fullWidth
            select
            value={value}
            onChange={(event) => handleChange(event)}
            SelectProps={{
              renderValue: () => (
                <ListItemText primary={t(`dialog.status.${value}`)} />
              ),
              displayEmpty: true,
            }}
            data-testid="book--filters--location"
          >
            {Object.values(DeskStatus).map((deskStatus) => {
              return (
                <MenuItem key={deskStatus} value={deskStatus}>
                  <ListItemText primary={t(`dialog.status.${deskStatus}`)} />
                </MenuItem>
              );
            })}
          </TextField>
        </Box>
      </Box>
    );
  }
);
