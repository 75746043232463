import { useSearchParams } from "react-router-dom";
import { OrganizationMembership } from "../../modules/authentication/domain";
import { useAdminUsersQuery } from "../hooks";

export interface UseAdminUsersResult {
  adminUsers: OrganizationMembership[];
  adminUser: OrganizationMembership | null;
  userId: string | null;
}

function getAdminFromSearchParams(
  searchParams: URLSearchParams,
  adminUsers: OrganizationMembership[]
): [OrganizationMembership | null, string | null] {
  const userId = searchParams.get("adminUser");
  if (!userId) return [null, null];
  const adminUser = adminUsers.find((it) => it.id === userId);
  const selectedUser = adminUser ? adminUser : null;
  return [selectedUser, selectedUser?.id ?? userId];
}

export function useBackofficeAdmins(): UseAdminUsersResult {
  const [searchParams] = useSearchParams();
  const adminsQuery = useAdminUsersQuery();
  const adminUsers = adminsQuery.data ?? [];
  const [adminUser, userId] = getAdminFromSearchParams(
    searchParams,
    adminUsers
  );

  return {
    adminUsers,
    adminUser,
    userId,
  };
}
