import Fuse from "fuse.js";
import { stripDiacritics } from "../../../../../assets/helperFunctions";
import { DeskOverview, LocationBookingOverview } from "../../../../domain";

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
// Give up on IE11 support for this feature

const fuseDeskOverviewSearchOptions: Fuse.IFuseOptions<DeskOverview> = {
  isCaseSensitive: false,
  findAllMatches: true,
  minMatchCharLength: 2,
  shouldSort: false,
  threshold: 0.2,
  keys: [
    {
      name: "First name",
      getFn(overview: DeskOverview): string {
        const value = overview.bookings[0]?.bookedFor.name.first ?? "";
        return stripDiacritics(value);
      },
    },
    {
      name: "Last name",
      getFn(overview: DeskOverview): string {
        const value = overview.bookings[0]?.bookedFor.name.last ?? "";
        return stripDiacritics(value);
      },
    },
    {
      name: "Full name",
      getFn(overview: DeskOverview): string {
        const value = overview.bookings[0]?.bookedFor.name.full ?? "";
        return stripDiacritics(value);
      },
    },
    {
      name: "Reversed full name",
      getFn(overview: DeskOverview): string {
        const name = overview.bookings[0]?.bookedFor.name;
        const value = `${name?.last} ${name?.first}`;
        return stripDiacritics(value);
      },
    },
  ],
};

const fuseLocationOverviewSearchOptions: Fuse.IFuseOptions<LocationBookingOverview> =
  {
    isCaseSensitive: false,
    findAllMatches: true,
    minMatchCharLength: 2,
    shouldSort: false,
    threshold: 0.2,
    keys: [
      {
        name: "First name",
        getFn(overview: LocationBookingOverview): string {
          const value = overview.bookedFor.name.first ?? "";
          return stripDiacritics(value);
        },
      },
      {
        name: "Last name",
        getFn(overview: LocationBookingOverview): string {
          const value = overview.bookedFor.name.last ?? "";
          return stripDiacritics(value);
        },
      },
      {
        name: "Full name",
        getFn(overview: LocationBookingOverview): string {
          const value = overview.bookedFor.name.full ?? "";
          return stripDiacritics(value);
        },
      },
      {
        name: "Reversed full name",
        getFn(overview: LocationBookingOverview): string {
          const name = overview.bookedFor.name;
          const value = `${name?.last} ${name?.first}`;
          return stripDiacritics(value);
        },
      },
    ],
  };

export function filterDesksBySearch(
  desks: DeskOverview[],
  search: string
): DeskOverview[] {
  if (search.length < 2) {
    return desks;
  }
  const fuse = new Fuse(desks, fuseDeskOverviewSearchOptions);
  const result = fuse.search(search);
  return result.map((it) => it.item);
}

export function filterLocationsBySearch(
  bookings: LocationBookingOverview[],
  search: string
): LocationBookingOverview[] {
  if (search.length < 2) {
    return bookings;
  }
  const fuse = new Fuse(bookings, fuseLocationOverviewSearchOptions);
  const result = fuse.search(search);
  return result.map((it) => it.item);
}
