import * as React from "react";

export const SvgPartiallyAvailableAvatar: React.FC = (props: any) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} fill="#fff" />
    <path
      d="M14.86 10.573 9.213 20a1.333 1.333 0 0 0 1.14 2h11.294a1.332 1.332 0 0 0 1.14-2l-5.647-9.427a1.333 1.333 0 0 0-2.28 0v0ZM16 14v2.667M16 19.333h.007"
      stroke="#FD9F00"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke="#FD9F00" />
  </svg>
);
