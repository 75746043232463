import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { DeskOverview } from "../../../../domain";
import { useBookingForm } from "../../state";
import { Caption } from "../Caption";
import { DeskItemButton } from "./DeskItemButton";
import { SvgAvailableAvatar } from "./icons";

export interface DeskItemAvailableProps {
  overview: DeskOverview;
}

const PlusIcon: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: "primary.main",
      }}
    >
      <Plus size={16} />
    </Box>
  );
};

const DeskItemAvailableContent: React.FC<{ isDedicated: boolean }> = React.memo(
  ({ isDedicated }) => {
    const { isEditing } = useBookingForm();
    const { t } = useTranslation("booking");
    return (
      <>
        <Box
          className="Item-visibleNoHover"
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SvgAvailableAvatar />
            <Box sx={{ lineHeight: 0 }}>
              <Caption>{t("item.booked-for")}</Caption>
              <Typography variant="body2" color="primary">
                {isDedicated
                  ? t("item.dedicated.title")
                  : isEditing
                  ? t("item.available-edit.title")
                  : t("item.desk-available.title")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button
          className="Item-visibleHover"
          disableRipple
          fullWidth
          startIcon={<PlusIcon />}
        >
          {isEditing ? t("item.action.book-edit") : t("item.action.book")}
        </Button>
      </>
    );
  }
);

const deskItemAvailableStyles: SxProps<Theme> = {
  ".Item-visibleHover": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "shades.greyUltimateLight",
    cursor: "pointer",
    ".Item-visibleNoHover": {
      display: "none",
    },
    ".Item-visibleHover": {
      display: "flex",
      alignItems: "center",
    },
  },
};

export const DeskItemAvailable: React.FC<DeskItemAvailableProps> = React.memo(
  ({ overview }) => (
    <DeskItemButton
      overview={overview}
      sx={deskItemAvailableStyles}
      data-state="available"
    >
      {/*
        Performance improvement: content inside is always the same
        */}
      <DeskItemAvailableContent isDedicated={overview.isDedicated} />
    </DeskItemButton>
  )
);
