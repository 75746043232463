import { Components, Theme } from "@mui/material";

export const MuiSkeleton: Components<Theme>["MuiSkeleton"] = {
  defaultProps: {
    animation: "wave",
  },
  styleOverrides: {
    rectangular: ({ theme }) => ({
      borderRadius: "2px",
      backgroundColor: theme.palette.shades.greyExtraLight,
    }),
    circular: ({ theme }) => ({
      backgroundColor: theme.palette.shades.greyExtraLight,
    }),
  },
};
