import useMemoizedFn from "ahooks/es/useMemoizedFn";
import {
  BookingNavigationParams,
  useBookingNavigation,
} from "../../../../../hooks";
import { DeskBooking, DeskOverview, UpcomingBooking } from "../../../domain";

export interface MyBookingsActions {
  selectDesk: (overview: DeskOverview) => void;
  editBooking: (overview: UpcomingBooking) => void;
}

export function useMyBookingsNavigation(): MyBookingsActions {
  const navigateToBooking = useBookingNavigation();

  const selectDesk = useMemoizedFn(
    (
      booking: DeskOverview,
      action: BookingNavigationParams["action"] = "view"
    ) => {
      navigateToBooking({
        date: booking.date.startDate,
        locationId: booking.location.id,
        floorId: booking.floor.id,
        deskId: booking.desk.id,
        action,
      });
    }
  );

  const editBooking = useMemoizedFn(({ booking }: UpcomingBooking) => {
    navigateToBooking({
      date: booking.bookingDate,
      locationId: booking.location.id,
      bookingId: booking.id,
      ...(booking instanceof DeskBooking && {
        floorId: booking.floor.id,
        deskId: booking.desk.id,
      }),
      action: "edit",
    });
  });

  return {
    selectDesk,
    editBooking,
  };
}
