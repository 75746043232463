import useCreation from "ahooks/es/useCreation";
import { addDays, isValid, isWeekend, parseISO } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { useBookingPermissions } from "../../../../permissions/hooks";
import { BookingDate } from "../../../domain";

export interface UseBookingDateResult {
  date: BookingDate;
}

const preselectedDate = getFirstWorkingDay(new Date());

function useParseDate(dateString: string | null): Date {
  return useParseDateOrDefault(dateString, preselectedDate);
}

function useParseDateOrDefault(
  dateString: string | null,
  defaultDate: Date
): Date {
  const permissions = useBookingPermissions();
  return useCreation(() => {
    if (!dateString) {
      return defaultDate;
    }

    const date = parseISO(dateString);
    if (isValid(date) && permissions.canViewBookingsOnDate(date)) {
      return date;
    }
    return defaultDate;
  }, [dateString, defaultDate]);
}

function getFirstWorkingDay(after: Date): Date {
  while (isWeekend(after)) {
    after = addDays(after, 1);
  }
  return after;
}

export function useBookingDate(): UseBookingDateResult {
  const [searchParams] = useSearchParams();
  const startDate = useParseDate(searchParams.get("date"));
  const endDate = useParseDateOrDefault(
    searchParams.get("date-end"),
    startDate
  );
  const date = useCreation(() => {
    return BookingDate.of(startDate, endDate);
  }, [startDate, endDate]);
  return { date };
}
