import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import { fieldToTextField } from "formik-mui";
import React from "react";
import { GroupMember } from "../../domain";
import { BookForAutocomplete } from "./BookForAutocomplete";

export interface FormikBookForAutocompleteProps
  extends FieldProps<GroupMember[]>,
    Omit<TextFieldProps, "name" | "value" | "error" | "onChange"> {
  multiple?: boolean;
  capacityReached?: boolean;
}

export const FormikBookForAutocomplete: React.FC<
  FormikBookForAutocompleteProps
> = ({ multiple, capacityReached, ...props }) => (
  <BookForAutocomplete
    {...fieldToTextField(props)}
    multiple={multiple ?? false}
    capacityReached={capacityReached ?? false}
    value={props.field.value}
    onChange={(value: GroupMember[]) => {
      props.field.onChange({
        name: props.field.name,
        target: {
          name: props.field.name,
          value,
        },
      });
    }}
  />
);
