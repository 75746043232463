import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledDialogTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
  borderRadius: "8px",
  backgroundColor: theme.palette.green.regular,
  color: "white",
}));

export const DialogTitle: React.FC = () => {
  const { t } = useTranslation("asset-management");

  return (
    <StyledDialogTitle>
      <Typography variant="subtitle1">{t("dialog.title")}</Typography>
    </StyledDialogTitle>
  );
};
