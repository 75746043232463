import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../modules/authentication/hooks";
import { DeskAssetInventory } from "../../modules/booking/domain";
import { getDeskInventories } from "../api/getDeskInventories";

export function useDeskInventoriesQuery(
  deskId: string
): UseQueryResult<DeskAssetInventory[]> {
  const { organization } = useOrganizationMembership();

  return useQuery(
    ["desk-inventories", organization.id, deskId],
    () => getDeskInventories(organization.id, deskId),
    {
      refetchOnMount: "always",
    }
  );
}
