export interface AmtErrorItemDto {
  code: number;
  message: string;
}

export interface AmtErrorDto {
  error_id: string;
  errors: AmtErrorItemDto[];
}

function parseAmtErrorDto(data: any): AmtErrorDto | null {
  if ("error_id" in data && "errors" in data) {
    return {
      error_id: data.error_id,
      errors: data.errors as AmtErrorItemDto[],
    };
  }
  return null;
}

export class AmtErrorItem {
  constructor(public readonly code: number, public readonly message: string) {}
}

export class AmtError {
  constructor(public readonly response: Response) {}

  static ofResponse(response: Response): Promise<Response> {
    return response.json().then((data) => {
      const result = parseAmtErrorDto(data);
      if (result) {
        return Promise.reject(AmtKnownError.of(result, response));
      }
      return Promise.reject(new AmtError(response));
    });
  }
}

export class AmtKnownError extends AmtError {
  constructor(
    public readonly errorId: string,
    public readonly errors: AmtErrorItem[],
    response: Response
  ) {
    super(response);
  }

  contains(code: number): boolean {
    return !!this.errors.find((it) => it.code === code);
  }

  get(code: number): AmtErrorItem | null {
    return this.errors.find((it) => it.code === code) ?? null;
  }

  static of(dto: AmtErrorDto, response: Response): AmtKnownError {
    return new AmtKnownError(
      dto.error_id,
      dto.errors.map((err) => new AmtErrorItem(err.code, err.message)),
      response
    );
  }
}

export const ErrorCodes = {
  BOOKING_DATE_PAST: 1001,
  BOOKING_FORBIDDEN: 1002,
  BOOKING_ASSET_CONFLICT: 1003,
  BOOKING_USER_CONFLICT: 1004,
};
