import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { FC, useState } from "react";
import { Eye, EyeOff } from "react-feather";

export const PasswordField: FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" sx={{ paddingRight: 1 }}>
            <IconButton
              aria-label="Toggle password visibility"
              onClick={toggleShowPassword}
              edge="end"
            >
              {showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
