import { Components, Theme } from "@mui/material";

export const MuiDialog: Components<Theme>["MuiDialog"] = {
  styleOverrides: {
    paper: {
      margin: 16,
      width: "calc(100% - 32px)",
    },
  },
};
