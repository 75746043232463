import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../../api";
import { useOrganizationMembership } from "../../../../modules/authentication/hooks";
import {
  createDeskStatus,
  deleteDeskStatus,
  deleteSpaceStatus,
  updateDeskStatus,
} from "../../../api";
import { AssetRule } from "../../../domain/assetRule";
import { DeskManagementFormValues } from "../components/Dialogs/DeskManagementDialog";
import { DeskStatus } from "../domain";
import { useAssetEvents, useAssetForm } from "../state";

export function useChangeDeskStatusMutation(): UseMutationResult<
  AssetRule,
  AmtError,
  DeskManagementFormValues
> {
  const { t } = useTranslation("asset-management");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const form = useAssetForm();
  const { organization } = useOrganizationMembership();
  const events = useAssetEvents();

  return useMutation(
    ["change-desk-status"],
    async (formValues: DeskManagementFormValues) => {
      if (!form.desk) {
        return Promise.reject("Request must be present in state");
      }
      const spaces = formValues.applyToRoom ? [form.desk.desk.space.id] : null;
      const desks = formValues.applyToRoom ? null : [form.desk.desk.id];
      const memberships =
        formValues.status === DeskStatus.DISABLED
          ? null
          : formValues.dedicatedValues.map((value) => value.id);

      if (formValues.status === DeskStatus.FREE) {
        return formValues.applyToRoom
          ? deleteSpaceStatus(form.desk.desk.space.id)
          : deleteDeskStatus(form.desk.assetRuleId);
      } else if (form.desk.deskStatus === DeskStatus.FREE) {
        return createDeskStatus(
          organization.id,
          spaces,
          desks,
          memberships,
          null
        );
      } else {
        return updateDeskStatus(
          form.desk.assetRuleId,
          spaces,
          desks,
          memberships,
          null
        );
      }
    },
    {
      onSuccess: (response: AssetRule) => {
        events.deskStatusChange$.emit({
          desk: response.desk,
          space: response.space,
          changedBy: response.changedBy,
          dedicatedFor: response.dedicatedFor,
          assetStatus: response.assetStatus,
        });
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.update-desk-error"), {
          variant: "error",
        });
        form.closeDialog();
      },
      onSettled: () => {
        qc.invalidateQueries(["desks-with-status"]);
        qc.invalidateQueries(["dedicated-groups"]);
        qc.invalidateQueries(["bookings"]);
      },
    }
  );
}
