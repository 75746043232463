import React from "react";
import { useBookingState } from "../../state";
import { PresenceListDesks } from "./PresenceListDesks";
import { PresenceListHeader } from "./PresenceListHeader";
import { PresenceListRoomFilter } from "./PresenceListRoomFilter";
import { PresenceListSkeleton } from "./PresenceListSkeleton";

export const PresenceList: React.FC = () => {
  const { bookings } = useBookingState();

  if (!bookings.data || bookings.isLoading) {
    return <PresenceListSkeleton />;
  }

  return (
    <>
      <PresenceListHeader />
      <PresenceListRoomFilter />
      <PresenceListDesks />
    </>
  );
};
