import { Box } from "@mui/material";
import React from "react";
import { AmtCenteredLoader } from "../../../../components";
import { MainLayoutPadding } from "../../../layout";

export const BookingPageSkeleton: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      height: `calc(100vh - ${MainLayoutPadding.Top} - ${MainLayoutPadding.Bottom})`,
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <AmtCenteredLoader />
  </Box>
);
