import { DialogContent } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AmtAlert, AmtDialog } from "../../../../../../components";
import { trackUpdateInventory } from "../../../../../../tracking";
import {
  DialogActions,
  SimpleDialogTitle,
} from "../../../../../components/Dialogs";
import { useDeskInventoriesQuery } from "../../../../../hooks";
import { useUpdateInventoryMutation } from "../../../hooks";
import { useUpdateInventoryForm } from "../../../state";
import { InventoryTable } from "./InventoryTable";

export interface DeskInventoryChange {
  assetInventoryId: string;
  quantity: number | undefined;
}

export interface UpdateInventoryFormValues {
  deskId: string;
  deskInventories: DeskInventoryChange[];
}

export const UpdateInventoryDialog: React.FC = () => {
  const form = useUpdateInventoryForm();
  const { isOpen, closeDialog } = form;
  const updateInventoryMutation = useUpdateInventoryMutation();
  const { t } = useTranslation("asset-management");
  const [modifiedQuantities, setModifiedQuantities] = useState<
    Map<string, number>
  >(new Map());

  if (!form.desk) {
    throw new Error("Invalid usage of UpdateInventoryDialog");
  }

  const { data: deskInventories } = useDeskInventoriesQuery(form.desk.id);

  const handleSubmit = useMemoizedFn(() => {
    if (!deskInventories) return Promise.reject("Inventories not available");
    if (!form.desk)
      return Promise.reject("Invalid usage of UpdateInventoryDialog");

    trackUpdateInventory();

    const changedInventories: DeskInventoryChange[] = deskInventories
      .map((inventory) => {
        const modifiedQuantity = modifiedQuantities.get(
          inventory.assetInventory.id
        );
        return {
          assetInventoryId: inventory.assetInventory.id,
          quantity:
            modifiedQuantity !== undefined
              ? modifiedQuantity
              : inventory.quantityOnDesk,
        };
      })
      .filter((inventory) => inventory.quantity > 0);

    setModifiedQuantities(new Map());

    return updateInventoryMutation.mutateAsync({
      deskId: form.desk.id,
      deskInventories: changedInventories,
    });
  });

  return (
    <AmtDialog open={isOpen} onClose={closeDialog} closeButton>
      <Formik enableReinitialize initialValues={{}} onSubmit={handleSubmit}>
        {({ isSubmitting }) => {
          return (
            <DialogContent>
              <SimpleDialogTitle title={t("dialog.update-inventory-title")} />
              <Form style={{ margin: 0 }}>
                {deskInventories && deskInventories.length ? (
                  <InventoryTable
                    inventories={deskInventories}
                    modifiedQuantities={modifiedQuantities}
                    setModifiedQuantities={setModifiedQuantities}
                  />
                ) : (
                  <AmtAlert
                    title={t("dialog.feedback.update-inventory-alert-title")}
                    text={t("dialog.feedback.update-inventory-alert-text")}
                  />
                )}
                <DialogActions
                  onCancel={() => {
                    setModifiedQuantities(new Map());
                    closeDialog();
                  }}
                  isInvalid={!deskInventories || deskInventories.length === 0}
                  isSubmitting={isSubmitting}
                />
              </Form>
            </DialogContent>
          );
        }}
      </Formik>
    </AmtDialog>
  );
};
