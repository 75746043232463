import { Box } from "@mui/material";
import React from "react";
import { LocationCard } from ".";
import { Location } from "../../../../modules/assets/domain";
import { trackBackofficeLocationChange } from "../../../../tracking";
import { LocationPicker } from "../../../components";
import {
  useBackofficeLocations,
  useBackofficeNavigation,
} from "../../../state";

export const LocationsList: React.FC = () => {
  const { location, locations } = useBackofficeLocations();
  const { onChangeLocation } = useBackofficeNavigation();

  const handleChangeLocation = (newLocation: Location | null): void => {
    onChangeLocation(newLocation);
    trackBackofficeLocationChange(true, "Location dashboard");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "hidden",
      }}
    >
      <LocationPicker
        location={location ? location.location : null}
        locations={locations}
        onChange={onChangeLocation}
        allAvailable
      />
      <Box
        sx={{
          marginTop: 2,
          paddingTop: 1,
          overflow: "auto",
        }}
      >
        {locations.map((location) => (
          <LocationCard
            key={location.location.id}
            locationWithCapacity={location}
            onChangeLocation={handleChangeLocation}
            sx={{
              maxWidth: "580px",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};
