import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { DeskNameCaption } from "./DeskNameCaption";

export interface DeskItemIdentifierProps {
  identifier: string;
  isDedicated: boolean;
}

const Dot = styled(Box)(({ theme }) => ({
  width: "9px",
  height: "9px",
  backgroundColor: theme.palette.orange.regular,
  borderRadius: "50%",
  border: `2px solid white`,
  position: "absolute",
  top: "4px",
  right: "-5px",
  transform: "translate(-50%, -50%)",
  zIndex: "10",
}));

export const DeskItemIdentifier: React.FC<DeskItemIdentifierProps> = ({
  identifier,
  isDedicated,
}) => {
  const { t } = useTranslation("booking");
  return (
    <Box
      className="AmtDeskIdentifier"
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "flex-end",
        width: 42,
        height: 42,
        lineHeight: 0,
        fontSize: 0,
        flexGrow: 0,
        flexShrink: 0,
        paddingX: 1,
        paddingY: 0.5,
        borderRadius: "8px",
        backgroundColor: "shades.greyUltimateLight",
      }}
    >
      {isDedicated && <Dot />}
      <DeskNameCaption>{t("item.desk")}</DeskNameCaption>
      <Typography className="AmtDeskIdentifier--Identifier" variant="body2">
        {identifier}
      </Typography>
    </Box>
  );
};
