import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Home } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtInputAdornment } from "../../components";
import { Location } from "../../modules/assets/domain";
import { trackBackofficeLocationChange } from "../../tracking/backoffice";
import { LocationWithCapacity } from "../domain";

export interface LocationPickerProps {
  location: Location | null;
  locations: LocationWithCapacity[];
  onChange: (newLocation: Location | null) => void;
  allAvailable?: boolean;
}

export const LocationPicker: React.FC<LocationPickerProps> = React.memo(
  ({ location, locations, onChange, allAvailable }) => {
    const { t } = useTranslation("backoffice");

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const newLocation = locations.find(
        (it) => it.location.id === event.target.value
      );
      trackBackofficeLocationChange(!!newLocation, "Location dropdown picker");
      onChange(newLocation ? newLocation.location : null);
    };

    const pickerValue = (): string => {
      if (allAvailable) return location ? location.id : "";
      else return location ? location.id : locations[0].location.id;
    };

    if (!allAvailable && !location) {
      throw new Error("Location must be loaded");
    }

    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          width: "260px",
          [theme.breakpoints.down("md")]: {
            marginBottom: 1,
            width: "100%",
          },
        })}
      >
        <AmtInputAdornment placement="start">
          <Home size={16} />
        </AmtInputAdornment>
        <TextField
          fullWidth
          select
          className="MuiInput-leftAdornment"
          value={pickerValue()}
          onChange={(event) => handleChange(event)}
          SelectProps={{
            renderValue: () => (
              <ListItemText
                primary={
                  location
                    ? `${location.address.cityShort} | ${location.name}`
                    : t("picker.all-locations")
                }
              />
            ),
            displayEmpty: true,
          }}
          data-testid="book--filters--location"
        >
          {allAvailable && (
            <MenuItem value={""}>
              <ListItemText primary={t("picker.all-locations")} />
            </MenuItem>
          )}
          {locations.map((location) => (
            <MenuItem key={location.location.id} value={location.location.id}>
              <ListItemText
                primary={location.location.name}
                secondary={`${location.location.address.addressLine1}, ${location.location.address.city}`}
              />
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  }
);
