import { useSearchParams } from "react-router-dom";
import { Space } from "../../../../assets/domain";
import { selectAllSpaces, useBookingContext } from "./useBookingContext";

export interface UseSpacesResult {
  spaces: Space[];
  space: Space | null;
  spaceId: string | null;
}

function getSpaceFromSearchParams(
  searchParams: URLSearchParams,
  spaces: Space[]
): [Space | null, string | null] {
  const spaceId = searchParams.get("room");
  const space = spaces.find((it) => it.id === spaceId);
  return [space ?? null, space?.id ?? spaceId];
}

export function useSpaces(): UseSpacesResult {
  const [searchParams] = useSearchParams();
  const [state] = useBookingContext();
  const spaces = selectAllSpaces(state);
  const [space, spaceId] = getSpaceFromSearchParams(searchParams, spaces);

  return { spaces, space, spaceId };
}
