export const AmtLoaderSvg = (props: any) => (
  <svg
    width={100}
    height={100}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <style>
      {
        "@keyframes pin{0%{transform:scaleX(1) scaleY(1) translateX(30px) translateY(48px);height:23px}to{transform:scaleX(1) scaleY(1.4) translateX(30px) translateY(15px);height:50px}}@keyframes shadow{0%{transform:translateX(0) translateY(0);rx:4px;ry:1px;opacity:1}to{transform:translateX(1px) translateY(0);rx:20px;ry:1px;opacity:.2}}.shadow{animation:.8s linear 0s infinite alternate forwards running shadow;transform:translateX(0) translateY(0);transform-box:fill-box;transofrm-origin:0 0;transition:all .5s ease-in-out}"
      }
    </style>
    <g clipPath="url(#clip_0_475)" data-name="Loading" className="Loading">
      <path
        d="M34.853 25.435c3.2-2.63 5.147-6.116 5.147-9.939C40 7.299 31.046.654 20 .654S0 7.299 0 15.496c0 3.823 1.948 7.308 5.147 9.94L17.47 36.65c1.506 1.48 3.552 1.48 5.058 0l12.324-11.216Z"
        fill="#00CC99"
        data-name="pin"
        style={{
          animation: ".8s linear 0s infinite alternate forwards running pin",
          transform: "scaleX(1) scaleY(1) translateX(30px) translateY(48px)",
          height: 23,
        }}
      />
      <g data-name="shadow" className="shadow">
        <ellipse
          cx={48}
          cy={88}
          rx={10}
          ry={1}
          fill="#AAA9A9"
          data-name="shadow"
          className="shadow"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip_0_475">
        <path d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
);
