import { Box, Skeleton } from "@mui/material";
import React from "react";
import { DeskItemSkeleton } from "../DeskItem";

export const PresenceListSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={56}
        sx={{ mb: 2 }}
      />
      <Skeleton animation="wave" variant="rectangular" height={64} />
      <Box sx={{ padding: 4 }}>
        {Array(8)
          .fill(1)
          .map((_, index) => (
            <DeskItemSkeleton key={index} />
          ))}
      </Box>
    </Box>
  );
};
