import { darken, lighten, PaletteOptions } from "@mui/material";

export const comboDark = "#141110";
export const comboGreen = "#00CC99";

export function lightenDark(coefficient: number) {
  return lighten(comboDark, coefficient);
}

export function lightenPrimary(coefficient: number) {
  return lighten(comboGreen, coefficient);
}

export function darkenPrimary(coefficient: number) {
  return darken(comboGreen, coefficient);
}

export const palette: PaletteOptions = {
  background: {
    default: "#f8f8f8",
    paper: "white",
  },
  primary: {
    main: "#00CC99",
    light: "#00CC99",
    contrastText: "white",
  },
  error: {
    main: "#C80000",
  },
  errors: {
    regular: "#CC0000",
    medium: lighten("#CC0000", 0.46),
    light: lighten("#CC0000", 0.91),
  },
  cancel: {
    main: lighten("#141110", 0.38),
  },
  text: {
    primary: lightenDark(0.04),
    secondary: lightenDark(0.4),
  },
  green: {
    regular: "#00CC99",
    medium: lighten("#00CC99", 0.68),
    light: lighten("#00CC99", 0.87),
    vivid: "#00FF99",
    hover: "#02b689",
  },
  red: {
    regular: "#CC0000",
    medium: lighten("#CC0000", 0.46),
    light: lighten("#CC0000", 0.91),
  },
  cyclamen: {
    regular: "#DA0099",
    medium: lighten("#DA0099", 0.6),
    light: lighten("#DA0099", 0.89),
    hover: "#bc0384",
  },
  orange: {
    regular: "#FFA500",
    medium: lighten("#FFA500", 0.6),
    light: lighten("#FFA500", 0.8),
    hover: "#e89602",
  },
  violet: {
    regular: "#5C1AEA",
    medium: lighten("#5C1AEA", 0.6),
    light: lighten("#5C1AEA", 0.89),
    hover: "#4a18b3",
  },
  shades: {
    darkExtra: darken("#141110", 0.3),
    dark: darken("#141110", 0.04),
    darkLight: lighten("#141110", 0.18),
    grey: lighten("#141110", 0.38),
    greyMedium: lighten("#141110", 0.64),
    greyLight: lighten("#141110", 0.8),
    greyExtraLight: lighten("#141110", 0.89),
    greyUltimateLight: lighten("#141110", 0.96),
  },
};
