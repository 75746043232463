import { Box, Typography } from "@mui/material";
import { isSameDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AmtDefaultBadge,
  AmtDefaultBadgeProps,
  AmtMasterBadge,
  BookingInfo,
} from "../../../../../../components";
import { LogoShortSvg } from "../../../../../layout/assets";
import {
  BookedNotification,
  isDeskBookingNotification,
  Notification,
  UpdatedNotification,
} from "../../../../../notifications/domain";
import { BookingStatus } from "../../../../api";
import { BookingInfoComparison } from "../../../../components";
import { BookingDate, DeskBooking, UpcomingBooking } from "../../../../domain";

export interface BookingListItemDetailsProps {
  upcomingBooking: UpcomingBooking;
  expanded?: boolean;
}

interface BookingListItemBadgeProps {
  notification: Notification;
}

const BookingListItemBadge: React.FC<BookingListItemBadgeProps> = ({
  notification,
}) => {
  const { t } = useTranslation("notifications");

  if (notification instanceof BookedNotification) {
    const isDeskBooking = isDeskBookingNotification(notification.context);
    const keyPrefix = isDeskBooking ? "desk" : "location";
    const color: AmtDefaultBadgeProps["color"] = isDeskBooking
      ? "orange"
      : "cyclamen";
    return (
      <AmtDefaultBadge sx={{ marginBottom: "2px" }} color={color}>
        {t(`${keyPrefix}-booked.badge`)}
      </AmtDefaultBadge>
    );
  }
  if (notification instanceof UpdatedNotification) {
    const isDeskBooking = isDeskBookingNotification(notification.context.after);
    const keyPrefix = isDeskBooking ? "desk" : "location";
    const color: AmtDefaultBadgeProps["color"] = isDeskBooking
      ? "orangelight"
      : "cyclamenlight";
    return (
      <AmtDefaultBadge sx={{ marginBottom: "2px" }} color={color}>
        {t(`${keyPrefix}-updated.badge`)}
      </AmtDefaultBadge>
    );
  }
  return null;
};

export const BookingListItemDetails: React.FC<BookingListItemDetailsProps> =
  React.memo(({ upcomingBooking, expanded }) => {
    const [notification, setNotification] = useState<Notification | null>(null);
    const { t } = useTranslation("dashboard");

    useEffect(() => {
      if (upcomingBooking.notification) {
        setNotification(upcomingBooking.notification);
      }
    }, [upcomingBooking.notification]);

    return (
      <Box>
        {isSameDay(upcomingBooking.booking.bookingDate, new Date()) &&
          !expanded && (
            <AmtMasterBadge
              sx={{
                display: "flex",
                width: "fit-content",
                backgroundColor: "green.regular",
                borderColor: "green.regular",
                color: "white",
                paddingY: 0,
                paddingX: 1,
              }}
            >
              <LogoShortSvg />
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {t("you-are-here-today")}!
              </Typography>
            </AmtMasterBadge>
          )}
        {upcomingBooking.booking.status === BookingStatus.PENDING &&
          !expanded && (
            <AmtMasterBadge
              sx={{
                display: "inline-block",
                backgroundColor: "cyclamen.regular",
                borderColor: "cyclamen.regular",
                color: "white",
                paddingY: 0,
                paddingX: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {t("invitation")}!
              </Typography>
            </AmtMasterBadge>
          )}
        {upcomingBooking.notification !== null &&
          upcomingBooking.booking.status !== BookingStatus.PENDING && (
            <BookingListItemBadge notification={upcomingBooking.notification} />
          )}
        {notification instanceof UpdatedNotification && expanded ? (
          <BookingInfoComparison
            before={{
              date: BookingDate.of(notification.context.before.bookingDate),
              location: notification.context.before.location,
              bookedBy: notification.context.before.bookedBy,
              ...(isDeskBookingNotification(notification.context.before) && {
                floor: notification.context.before.floor,
                space: notification.context.before.space,
                desk: notification.context.before.desk,
              }),
            }}
            after={{
              date: BookingDate.of(notification.context.after.bookingDate),
              location: notification.context.after.location,
              bookedBy: notification.context.after.bookedBy,
              ...(isDeskBookingNotification(notification.context.after) && {
                floor: notification.context.after.floor,
                space: notification.context.after.space,
                desk: notification.context.after.desk,
              }),
            }}
          />
        ) : (
          <BookingInfo
            date={BookingDate.of(upcomingBooking.booking.bookingDate)}
            location={upcomingBooking.booking.location}
            {...(expanded &&
              upcomingBooking.booking instanceof DeskBooking && {
                floor: upcomingBooking.booking.floor,
                space: upcomingBooking.booking.space,
                desk: upcomingBooking.booking.desk,
              })}
            {...(expanded && {
              bookedBy: upcomingBooking.booking.bookedBy,
            })}
          />
        )}
      </Box>
    );
  });
