import { request } from "../../api";
import { DeskDto, mapDesk, mapSpace, SpaceDto } from "../../modules/assets/api";
import { mapUser, UserDto } from "../../modules/authentication/api";
import { formatISODate } from "../../modules/booking/api";
import { AssetRule } from "../domain/assetRule";
import { mapDeskStatus } from "./getDesksWithStatus";

interface ChangeDeskStatusResponse {
  assetRuleId: string;
  assetStatus: string;
  desk: DeskDto;
  space: SpaceDto;
  dedicatedFor: UserDto[];
  changedBy: UserDto;
}

function mapChangeDeskStatusResponse(
  response: ChangeDeskStatusResponse
): AssetRule {
  return new AssetRule(
    response.assetRuleId,
    mapDeskStatus(response.assetStatus),
    response.desk ? mapDesk(response.desk) : null,
    mapSpace(response.space),
    response.dedicatedFor.map((user) => mapUser(user)),
    mapUser(response.changedBy)
  );
}

export async function createDeskStatus(
  organizationId: string,
  spaces: string[] | null,
  desks: string[] | null,
  memberships: string[] | null,
  teams: string[] | null
): Promise<AssetRule> {
  const response = await request<ChangeDeskStatusResponse>(
    "POST",
    `/api/manage-asset-rules`,
    {
      organizationId,
      startDate: formatISODate(new Date()),
      ...(spaces ? { spaces } : {}),
      ...(desks ? { desks } : {}),
      ...(memberships ? { memberships } : {}),
      ...(teams ? { teams } : {}),
    }
  );

  return mapChangeDeskStatusResponse(response);
}

export async function updateDeskStatus(
  dedicatedAssetRuleId: string,
  spaces: string[] | null,
  desks: string[] | null,
  memberships: string[] | null,
  teams: string[] | null
): Promise<AssetRule> {
  const response = await request<ChangeDeskStatusResponse>(
    "PATCH",
    `/api/manage-asset-rules/${dedicatedAssetRuleId}`,
    {
      ...(spaces ? { spaces } : {}),
      ...(desks ? { desks } : {}),
      ...(memberships ? { memberships } : {}),
      ...(teams ? { teams } : {}),
    }
  );

  return mapChangeDeskStatusResponse(response);
}

export async function deleteDeskStatus(
  dedicatedAssetRuleId: string
): Promise<AssetRule> {
  const response = await request<ChangeDeskStatusResponse>(
    "DELETE",
    `/api/manage-asset-rules/${dedicatedAssetRuleId}`
  );

  return mapChangeDeskStatusResponse(response);
}

export async function deleteSpaceStatus(spaceId: string): Promise<AssetRule> {
  const response = await request<ChangeDeskStatusResponse>(
    "DELETE",
    `/api/manage-asset-rules/spaces/${spaceId}`
  );

  return mapChangeDeskStatusResponse(response);
}
