import { Button, Typography } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { ChevronDown, ChevronUp, Filter } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtMasterBadge } from "../../../../../components";
import { DeskInventoryFilters } from "../../../../booking/domain";
import { InventoryFilterMenu } from "./InventoryFilterMenu";

interface InventoryFilterBadgeProps {
  inventoryFilters: DeskInventoryFilters[];
}

export const InventoryFilterBadge: React.FC<InventoryFilterBadgeProps> = ({
  inventoryFilters,
}) => {
  const { t } = useTranslation("floor-plan");

  const popupState = usePopupState({
    variant: "popover",
    popupId: "roomFilterMenu",
  });

  return (
    <>
      <Button {...bindTrigger(popupState)} sx={{ padding: 0 }}>
        <AmtMasterBadge>
          <Filter size={16} />
          <Typography sx={{ ml: 1 }} variant="body2">
            <strong>{t("equipment")}</strong>
          </Typography>
          {popupState.isOpen ? (
            <ChevronUp size={16} />
          ) : (
            <ChevronDown size={16} />
          )}
        </AmtMasterBadge>
      </Button>
      <InventoryFilterMenu
        popupState={popupState}
        buttonState={null}
        inventoryFilters={inventoryFilters}
      />
    </>
  );
};
