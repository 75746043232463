import { useMemoizedFn } from "ahooks";
import { addDays } from "date-fns";
import { atom, useAtom } from "jotai";
import { ConferenceDateRange } from "../../domain/ConferenceDateRange";

export type UseConferenceDateRangeResult = {
  dateRange: ConferenceDateRange;
  setDateRange(newRange: ConferenceDateRange): void;
};

const dateRangeAtom = atom<ConferenceDateRange>(
  ConferenceDateRange.of(new Date(), addDays(new Date(), 6))
);

export function useConferenceDateRange(): UseConferenceDateRangeResult {
  const [dateRangeState, setDateRangeState] = useAtom(dateRangeAtom);

  const setDateRange = useMemoizedFn((newRange: ConferenceDateRange) => {
    setDateRangeState(newRange);
  });

  return { dateRange: dateRangeState, setDateRange };
}
