import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtMasterBadge } from "../../../../../components";
import { useCurrentUser } from "../../../../authentication/hooks";
import { BookingStatus } from "../../../api";
import { Sidebar } from "../../../components";
import { useMyBookingsQuery } from "../hooks";
import {
  UpcomingBookingsTab,
  useUpcomingBookingsTab,
} from "../state/upcomingBookingsTab";
import { UpcomingBookings } from "./UpcomingBookings";

interface HeaderTabButtonProps {
  selected: boolean;
  tab: UpcomingBookingsTab;
  onClick: () => void;
}

const Welcome: React.FC = () => {
  const { t } = useTranslation("dashboard");
  const currentUser = useCurrentUser();
  return (
    <Box
      padding={2}
      paddingTop={3}
      sx={(theme) => ({
        backgroundColor: "white",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      })}
    >
      <Typography variant="subtitle1">{t("welcome")}</Typography>
      <Typography variant="body1" color="shades.dark">
        {currentUser.name.full}
      </Typography>
    </Box>
  );
};

const HeaderTabButton: React.FC<HeaderTabButtonProps> = ({
  selected,
  tab,
  onClick,
}) => {
  const { t } = useTranslation("dashboard");
  const { data: bookings } = useMyBookingsQuery();
  const { upcomingBookingsTab } = useUpcomingBookingsTab();

  const invitationBookingsLength = bookings?.filter(
    (booking) => booking.booking.status === BookingStatus.PENDING
  ).length;

  const showInvitationsBadge = (): boolean => {
    return (
      tab.equals(UpcomingBookingsTab.INVITATIONS) &&
      !!bookings &&
      !upcomingBookingsTab.equals(UpcomingBookingsTab.INVITATIONS) &&
      !!invitationBookingsLength &&
      invitationBookingsLength > 0
    );
  };

  const disableButton = (): boolean => {
    return (
      (tab.equals(UpcomingBookingsTab.BOOKINGS) &&
        bookings?.filter(
          (booking) => booking.booking.status !== BookingStatus.PENDING
        ).length === 0 &&
        bookings.length > 0) ||
      (tab.equals(UpcomingBookingsTab.INVITATIONS) &&
        invitationBookingsLength === 0)
    );
  };

  return (
    <Button
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 8px",
        padding: "8px 2px",
        borderBottom: "2px solid",
        color: selected ? "black" : "shades.grey",
        borderRadius: 0,
        borderColor: selected ? "green.regular" : "transparent",
        transform: "translateY(1px)",
        gap: "0",
      }}
      onClick={onClick}
      disabled={disableButton()}
    >
      {React.createElement(tab.icon, { size: 16 })}
      <Typography sx={{ marginLeft: 1 }}>
        {t(`tab.${tab.name.toLowerCase()}`)}
      </Typography>
      {showInvitationsBadge() && bookings && (
        <AmtMasterBadge
          sx={{
            display: "inline-block",
            backgroundColor: "cyclamen.regular",
            borderColor: "cyclamen.regular",
            color: "white",
            marginLeft: 0.5,
            paddingY: 0,
            paddingX: 0.5,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {invitationBookingsLength}
          </Typography>
        </AmtMasterBadge>
      )}
    </Button>
  );
};

const SidebarHeader: React.FC = () => {
  const { upcomingBookingsTab, changeUpcomingBookingsTab } =
    useUpcomingBookingsTab();

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: 2,
        backgroundColor: "shades.greyUltimateLight",
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderColor: "shades.greyExtraLight",
      }}
    >
      {UpcomingBookingsTab.values.map((tab) => (
        <HeaderTabButton
          key={tab.name}
          selected={upcomingBookingsTab === tab}
          tab={tab}
          onClick={() => changeUpcomingBookingsTab(tab)}
        />
      ))}
    </Box>
  );
};

export const UpcomingBookingsSidebar: React.FC = React.memo(() => (
  <Sidebar>
    <Welcome />
    <SidebarHeader />
    <UpcomingBookings />
  </Sidebar>
));
