import * as d3 from "d3";
import { DeskOverview } from "../../../../booking/domain";
import { FloorPlanTooltipsHandle } from "../../assets/Tooltips";

export interface FloorplanElement {}

export class FloorPlanStyles {
  constructor(private tooltip: FloorPlanTooltipsHandle | null) {}

  public selectedElementId: string | null = null;

  applyPinHover(container: SVGGraphicsElement, d: FloorplanElement) {
    const $container = d3.select<SVGGElement, FloorplanElement>(container);
    // Hover: Rotate avatar/plus icon
    $container
      .selectAll(".hover-rotate")
      .transition()
      .duration(350)
      .attrTween("transform", function rotate360() {
        const i = d3.interpolate(0, 360);
        return function (t) {
          return `rotate(${i(t)})`;
        };
      });

    if (this.tooltip && d instanceof DeskOverview) {
      this.tooltip.showDesk?.(container, d);
    }
  }

  removePinHover(container: SVGGElement) {
    const $container = d3.select<SVGGElement, FloorplanElement>(container);

    // Mouse leave: reset avatar/icon rotation
    $container.selectAll(".hover-rotate").attr("transform", null);

    // Remove tooltip
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }
}
