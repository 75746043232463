import { atom, useAtom } from "jotai";
import { Edit3, IconProps, MapPin } from "react-feather";
import { useSearchParams } from "react-router-dom";

export class UpcomingBookingsTab {
  private constructor(public name: string, public icon: React.FC<IconProps>) {}

  static BOOKINGS = new UpcomingBookingsTab("BOOKINGS", MapPin);
  static INVITATIONS = new UpcomingBookingsTab("INVITATIONS", Edit3);

  static readonly values: UpcomingBookingsTab[] = [
    UpcomingBookingsTab.BOOKINGS,
    UpcomingBookingsTab.INVITATIONS,
  ];

  static valueOf(name: string): UpcomingBookingsTab {
    const resolved = UpcomingBookingsTab.values.find(
      (value) => value.name === name
    );
    if (resolved) {
      return resolved;
    }
    console.error(
      `Invalid DeskAvailability ${name}; Defaulting to NOT_BOOKINGS`
    );
    return UpcomingBookingsTab.BOOKINGS;
  }

  equals(other: UpcomingBookingsTab): boolean {
    return this.name === other.name;
  }
}

export interface UpcomingBookingsTabResponse {
  upcomingBookingsTab: UpcomingBookingsTab;
  changeUpcomingBookingsTab: (tab: UpcomingBookingsTab) => void;
}

const upcomingBookingsTabAtom = atom<UpcomingBookingsTab>(
  UpcomingBookingsTab.BOOKINGS
);

export function useUpcomingBookingsTab(): UpcomingBookingsTabResponse {
  const [upcomingBookingsTab, setUpcomingBookingsTab] = useAtom(
    upcomingBookingsTabAtom
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const changeUpcomingBookingsTab = (tab: UpcomingBookingsTab) => {
    setUpcomingBookingsTab(tab);
    const updated = new URLSearchParams(searchParams);
    if (tab === UpcomingBookingsTab.INVITATIONS) {
      updated.set("tab", UpcomingBookingsTab.INVITATIONS.name.toLowerCase());
    } else if (tab === UpcomingBookingsTab.BOOKINGS) {
      updated.set("tab", UpcomingBookingsTab.BOOKINGS.name.toLowerCase());
    }
    setSearchParams(updated);
  };

  return { upcomingBookingsTab, changeUpcomingBookingsTab };
}
