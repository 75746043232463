import { useUpdateInventoryForm } from "../../../state";
import { UpdateInventoryDialog } from "./UpdateInventoryDialog";

export const ConnectedUpdateInventoryDialog: React.FC = () => {
  const form = useUpdateInventoryForm();

  if (!form.desk) {
    return null;
  }

  return <UpdateInventoryDialog />;
};
