import { Email, Name, User } from "../../authentication/domain";

export class UserWithTeam extends User {
  constructor(
    public id: string,
    public membershipId: string,
    public name: Name,
    public email: Email,
    public avatarImageUrl: string | null,
    public teams: Team[],
    public doIFollow: boolean
  ) {
    super(id, name, email, avatarImageUrl);
  }
}

export const newUserWithTeam = (user: User): UserWithTeam => {
  return new UserWithTeam(
    user.id,
    "",
    user.name,
    user.email,
    user.avatarImageUrl,
    [],
    false
  );
};

export class Team {
  constructor(public id: string, public name: string) {}
}
