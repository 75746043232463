import { Box, Button, styled, Typography } from "@mui/material";
import React from "react";
import { Filter, Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDateFns, useIsMobile } from "../../../../../../hooks";
import { BookingDate } from "../../../../domain";
import { ActiveFilter, useBookingDate, useBookingFilters } from "../../state";
import { SearchFilter, StatusFilter } from "../PresenceListFilters";
import { BookedBox } from "./BookedBox";

interface TitleProps {
  date: BookingDate;
  state: ActiveFilter;
}

const Title: React.FC<TitleProps> = ({ date, state }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("booking");
  const { formatRange } = useDateFns();
  return (
    <Box
      sx={{
        ...(state !== ActiveFilter.INACTIVE && { display: "none" }),
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mr: 4,
          display: "flex",
          gap: 1,
          flexShrink: 0,
        }}
      >
        {t("presence-list-header")}
      </Typography>
      {!isMobile && (
        <Typography variant="body2">{formatRange(date)}</Typography>
      )}
    </Box>
  );
};

const HeaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  alignItems: "center",
  flexGrow: 0,
  flexShrink: 0,
  height: "64px",
  borderBottom: "1px solid",
  borderColor: theme.palette.shades.greyExtraLight,
  backgroundColor: theme.palette.shades.greyUltimateLight,
}));

interface PresenceListHeaderComponentProps {
  date: BookingDate;
  activeFilter: ActiveFilter;
  resetFilter: () => void;
  openSearchFilter: () => void;
  openAvailabilityFilter: () => void;
}

const PresenceListHeaderComponent: React.FC<PresenceListHeaderComponentProps> =
  React.memo(
    ({
      date,
      activeFilter,
      resetFilter,
      openSearchFilter,
      openAvailabilityFilter,
    }) => (
      <HeaderContainer>
        <Title state={activeFilter} date={date} />
        {activeFilter === ActiveFilter.INACTIVE && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                gap: 1,
                display: "flex",
              }}
            >
              <Button
                variant="icon"
                color="secondary"
                onClick={openAvailabilityFilter}
                data-testid="book--filters--availability"
              >
                <Filter size={16} />
              </Button>
              {date.isSingleDay && (
                <Button
                  variant="icon"
                  color="secondary"
                  onClick={openSearchFilter}
                  data-testid="book--filters--search"
                >
                  <Search size={16} />
                </Button>
              )}
            </Box>
            <BookedBox />
          </Box>
        )}
        {activeFilter === ActiveFilter.AVAILABILITY && (
          <StatusFilter onClose={resetFilter} />
        )}
        {activeFilter === ActiveFilter.SEARCH && (
          <SearchFilter onClose={resetFilter} />
        )}
      </HeaderContainer>
    )
  );

export const PresenceListHeader: React.FC = () => {
  const { date } = useBookingDate();
  const [{ state }, { resetFilter, openAvailabilityFilter, openSearchFilter }] =
    useBookingFilters();

  return (
    <PresenceListHeaderComponent
      date={date}
      activeFilter={state}
      resetFilter={resetFilter}
      openAvailabilityFilter={openAvailabilityFilter}
      openSearchFilter={openSearchFilter}
    />
  );
};
