import { Box, Typography } from "@mui/material";
import React from "react";
import { AlertTriangle } from "react-feather";

export interface AmtToastProps {
  text: React.ReactNode;
  variant?: "warning";
}

export const AmtToast: React.FC<AmtToastProps> = ({ text, variant }) => {
  return (
    <Box
      sx={{
        color: "white",
        display: "flex",
        alignItems: "center",
        gap: 2,
        paddingX: 2,
        paddingY: 1,
        borderRadius: "8px",
        backgroundColor: "orange.regular",
      }}
    >
      <Box
        sx={{
          flex: "0 1 auto",

          display: "flex",
          alignItems: "center",
        }}
      >
        <AlertTriangle size={40} />
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption">Upozorenje!</Typography>
        <Typography variant="subtitle1">{text}</Typography>
      </Box>
    </Box>
  );
};
