import { ListItemText, Menu, MenuItem } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import { BarChart2, Monitor } from "react-feather";
import { useTranslation } from "react-i18next";
import { DeskWithSpace, DeskWithStatus } from "../../../../backoffice/domain";
import { Icon } from "../../../../global/styles";

interface ContextMenuProps {
  popupState: PopupState;
  onSelectStatusManagement: (desk: DeskWithStatus | null) => void;
  onSelectInventoryManagement: (desk: DeskWithSpace) => void;
  desk: DeskWithStatus | null;
}

export const ContextMenu: React.FC<ContextMenuProps> = ({
  popupState,
  onSelectInventoryManagement,
  onSelectStatusManagement,
  desk,
}) => {
  const { t } = useTranslation("asset-management");

  return (
    <Menu
      disableAutoFocusItem
      {...bindMenu(popupState)}
      PaperProps={{
        style: {
          width: 247,
        },
      }}
    >
      <MenuItem
        onClick={() => {
          if (desk) onSelectStatusManagement(desk);
          popupState.close();
        }}
      >
        <Icon>
          <BarChart2 size={16} />
        </Icon>
        <ListItemText primary={`${t("floor-plan.status-management")}`} />
      </MenuItem>
      <MenuItem
        onClick={() => {
          if (desk) onSelectInventoryManagement(desk.desk);
          popupState.close();
        }}
      >
        <Icon>
          <Monitor size={16} />
        </Icon>
        <ListItemText primary={`${t("floor-plan.inventory-management")}`} />
      </MenuItem>
    </Menu>
  );
};
