import { Components, Theme } from "@mui/material";
import { fontWeight } from "../typography";

export const MuiTextField: Components<Theme>["MuiTextField"] = {
  defaultProps: {
    size: "small",
  },
  styleOverrides: {
    // @ts-ignore
    root: ({ theme, ownerState }) => ({
      ".MuiInputLabel-root": {
        position: "relative",
        transform: "none",
        marginBottom: theme.spacing(0.5),
        ...theme.typography.caption,
      },
      ".MuiOutlinedInput-root": {
        background: "white",
        borderRadius: "8px",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.shades.grey,
        },
        "&.Mui-disabled": {
          cursor: "not-allowed",
          opacity: 0.55,
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.shades.greyLight,
          },
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: "#17FFBA",
        },
      },
      ".MuiOutlinedInput-input": {
        padding: theme.spacing(1, 0, 1, 1.5),
        color: theme.palette.shades.dark,
        fontWeight: fontWeight.medium,
        ".MuiTypography-body1": {
          fontWeight: fontWeight.medium,
        },
      },
      "&.MuiInput-leftAdornment": {
        ".MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
      "&.MuiInput-rightAdornment": {
        ".MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
      "&.MuiInput-search": {
        ".MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
      "&.MuiInput-search:hover": {
        ".MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.green.vivid,
        },
      },
      "& .MuiOutlinedInput-root": {
        padding: "0",
      },
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.shades.greyLight,
        borderRadius: "8px",
        top: 0,
      },
      legend: {
        display: "none",
      },
    }),
  },
};
