import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface DialogTitleProps {
  isDeskBooking: boolean;
  isEditing: boolean;
  isGroup: boolean;
}

const StyledDialogTitle = styled(Box)<DialogTitleProps>(
  ({ theme, isEditing, isDeskBooking }) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 2),
    borderRadius: "8px",
    color: theme.palette.shades.dark,
    backgroundColor: theme.palette.cyclamen.light,
    ...(isDeskBooking && {
      backgroundColor: theme.palette.green.regular,
      color: "white",
    }),
    ...(isEditing && {
      backgroundColor: theme.palette.shades.grey,
      color: "white",
    }),
  })
);

export const DialogTitle: React.FC<
  React.PropsWithChildren<DialogTitleProps>
> = ({ isDeskBooking, isEditing, isGroup, ...props }) => {
  const { t } = useTranslation("booking");
  const asset = isDeskBooking ? "desk" : "location";
  const type = isEditing ? "edit" : isGroup ? "group" : "single";
  const translation = `form.${asset}-booking.title.${type}`;

  return (
    <StyledDialogTitle
      isDeskBooking={isDeskBooking}
      isEditing={isEditing}
      isGroup={isGroup}
      {...props}
    >
      <Typography variant="subtitle1">{t(translation)}</Typography>
    </StyledDialogTitle>
  );
};
