export type SubscriberFn<Event> = (event: Event) => void;
export type UnsubscribeFn = () => void;

export class EventEmitter<Event> {
  private subscribers: SubscriberFn<Event>[] = [];

  subscribe(fn: SubscriberFn<Event>): UnsubscribeFn {
    this.subscribers.push(fn);
    return () => {
      this.subscribers = this.subscribers.filter((it) => it !== fn);
    };
  }

  emit(event: Event) {
    this.subscribers.forEach((subscriber) => {
      subscriber(event);
    });
  }
}
