import { Box, Popover, styled, TextField, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { isWeekend } from "date-fns";
import { bindTrigger } from "material-ui-popup-state";
import { bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import React, { useState } from "react";
import { DateRange, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  ArrowRight as ArrowRightIcon,
  Calendar as CalendarIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtInputAdornment } from "../../../components";
import { useDateFns, useIsMobile } from "../../../hooks";
import { useBackofficePermissions } from "../../../modules/permissions/hooks/useBackofficePermissions";
import { CustomDateRange } from "../../domain";
import { useDateNavigation, useDateRange } from "../../state";
import "./DatePicker.css";

export interface DatePickerProps {
  trackDateChange: (isSingleDay: boolean) => void;
}

export const DatePicker: React.FC<DatePickerProps> = React.memo(
  ({ trackDateChange }) => {
    const { dateRange } = useDateRange();
    const { onChangeDate } = useDateNavigation();
    const { t } = useTranslation("common");
    const { format, locale } = useDateFns();
    const popupState = usePopupState({
      popupId: "date-preview-picker",
      variant: "popover",
    });
    const [focusedRange, setFocusedRange] = useState<
      [number, 0 | 1] | undefined
    >();
    const isMobile = useIsMobile();
    const { isAdmin } = useBackofficePermissions();

    const handleChange = useMemoizedFn((e: RangeKeyDict) => {
      const startDate = e.selection.startDate ?? new Date();
      const endDate = e.selection.endDate ?? startDate;
      const analyticsDate = CustomDateRange.of(startDate, endDate, isAdmin);
      onChangeDate(analyticsDate);
      setFocusedRange(undefined);
      trackDateChange(dateRange.isSingleDay);
    });

    const isDateDisabled = (date: Date): boolean => {
      if (isWeekend(date)) {
        return true;
      }
      return false;
    };

    const focusStartDate = useMemoizedFn(() => {
      setFocusedRange([0, 0]);
    });
    const focusEndDate = useMemoizedFn(() => {
      setFocusedRange([0, 1]);
    });

    return (
      <Box sx={{ display: "flex", width: isMobile ? "100%" : "auto" }}>
        <AmtInputAdornment placement="start">
          <CalendarIcon size={16} />
        </AmtInputAdornment>
        <TextField
          fullWidth
          sx={{ flexGrow: 1 }}
          inputProps={{ readOnly: true }}
          className="MuiInput-leftAdornment MuiInput-rightAdornment"
          data-testid="book--filters--booking-date-start"
          aria-describedby="book--filters-booking-date-start"
          value={format(dateRange.startDate, "dd.MM.yyyy.")}
          {...bindTrigger(popupState)}
          onFocus={focusStartDate}
        />
        <AmtInputAdornment>
          <ArrowRightIcon size={16} />
        </AmtInputAdornment>
        <TextField
          fullWidth
          sx={{ flexGrow: 1 }}
          className="MuiInput-leftAdornment"
          inputProps={{ readOnly: true }}
          data-testid="book--filters--booking-date-end"
          aria-describedby="book--filters-booking-date-end"
          value={format(dateRange.endDate, "dd.MM.yyyy.")}
          {...bindTrigger(popupState)}
          onFocus={focusEndDate}
        />
        <StyledPopover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Notice>
            <Typography variant="body2">
              {t("components.datepicker.notice")}
            </Typography>
          </Notice>
          <DateRangeContainer>
            <DateRange
              disabledDay={isDateDisabled}
              displayMode="dateRange"
              onChange={handleChange}
              showPreview={true}
              showDateDisplay={false}
              rangeColors={["#00CC99"]}
              focusedRange={focusedRange}
              locale={locale}
              ranges={[
                {
                  startDate: dateRange.startDate,
                  endDate: dateRange.endDate,
                  key: "selection",
                },
              ]}
            />
          </DateRangeContainer>
        </StyledPopover>
      </Box>
    );
  }
);

const Notice = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 1.5),
  backgroundColor: theme.palette.cyclamen.regular,
  color: "white",
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
}));

const DateRangeContainer = styled(Box)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.shades.greyLight,
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  overflow: "hidden",
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  ".MuiPaper-root": {
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.1)",
  },
}));
