export const LogoShortSvg: React.FC<{ width?: string; height?: string }> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22_2003)">
        <path
          d="M8.12187 15.8054L4.87455 11.4931L7.25821 8.32542L12.8964 15.8054H8.12187Z"
          fill="#FFA500"
          stroke="white"
          stroke-width="0.389201"
        />
        <path
          d="M13.2868 0H8.02481L2 8.00198L4.63099 11.4931L7.25809 8.00198L13.2868 0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_22_2003">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LogoSvg: React.FC = () => {
  return (
    <svg
      width="111"
      height="42"
      viewBox="0 0 111 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_123)">
        <path
          d="M43.6219 19.8799V41.2931H40.6075L40.3119 38.4019C38.8926 40.4667 36.5279 42 33.1593 42C27.4846 42 23.3465 38.5189 23.3465 32.5025C23.3465 26.4862 27.1891 23.2415 33.9875 23.2415H40.1361V19.9384C40.1361 15.3969 38.0084 12.9761 33.9875 12.9761C30.4405 12.9761 28.0172 14.9824 27.7802 18.4025H24.2919C24.5288 13.0931 28.3714 9.61192 34.1047 9.61192C39.838 9.61192 43.6219 13.5635 43.6219 19.8773V19.8799ZM40.1335 32.5635V26.4862H33.8677C29.3168 26.4862 26.8935 28.6094 26.8935 32.5635C26.8935 36.5176 29.6709 38.5799 33.6919 38.5799C37.7128 38.5799 40.1361 36.1032 40.1361 32.5635H40.1335Z"
          fill="black"
        />
        <path
          d="M69.6842 21.4717V41.2931H66.1958V22.0032C66.1958 15.7503 64.5421 12.9176 60.4625 12.9176C56.1485 12.9176 53.3099 16.5157 53.3099 22.5321V41.2906H49.8215V10.3239H52.7187L53.3099 14.1585C54.905 11.3868 57.5653 9.617 61.3493 9.617C66.3742 9.617 69.6842 12.9786 69.6842 21.4742V21.4717Z"
          fill="black"
        />
        <path
          d="M88.7059 38.2824V41.5855H86.9324C81.2577 41.5855 78.1235 38.0459 78.1235 32.088V13.5686H73.3942V10.3239H78.1235V3.18617H81.6119V10.3239H87.995V13.5686H81.6119V32.0906C81.6119 36.1617 83.2657 38.2849 86.9324 38.2849H88.7059V38.2824Z"
          fill="black"
        />
        <path
          d="M110.5 38.0484V41.2931H91.8809V38.4604L105.832 13.566H92.5306V10.3214H110.029V13.0931L96.0776 38.0459H110.5V38.0484Z"
          fill="black"
        />
        <path d="M4.53368 0H0.5V41.2931H4.53368V0Z" fill="#FFA500" />
        <path
          d="M17.7763 0H22.3272L7.13022 20.6453L22.9999 41.2931H18.4464L2.90033 20.8996L17.7763 0Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_123">
          <rect
            width="110"
            height="42"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LogoHeaderSvg: React.FC = () => {
  return (
    <svg
      width="27"
      height="36"
      viewBox="0 0 27 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_1869)">
        <path
          d="M6.26653 35.5L0 33.8594V2.05944L6.26653 0.5V35.5Z"
          fill="white"
        />
        <path
          d="M9.67113 2.14056H6.89062V33.8594H9.67113V2.14056Z"
          fill="#F04FB6"
        />
        <path
          d="M27 2.14056H19.2953L8.10815 18L19.2953 33.8594H27L16.5094 18.9861L11.9614 23.4625L27 2.14056Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_1869">
          <rect
            width="27"
            height="35"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
