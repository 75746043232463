import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Box, Button, TableCell, Typography } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { MoreVertical } from "react-feather";
import { useTranslation } from "react-i18next";
import { CellType } from "..";
import { ReservationFilters } from "../../../../backoffice/modules/analytics/components/AnalyticsTable";
import { useDateFns } from "../../../../hooks";
import { useBackofficePermissions } from "../../../../modules/permissions/hooks/useBackofficePermissions";
import { useDateRange } from "../../../state";
import { FullNameMenu, ReservationsMenu } from "../Dropdowns";

interface DataHeaderCellProps {
  cellType: CellType;
  reservationFilters: ReservationFilters;
  setReservationFilters: React.Dispatch<
    React.SetStateAction<ReservationFilters>
  >;
  shadow?: { boxShadow: string; borderRight: string };
  sortByFavoritePerson?: any;
}

export const DataHeaderCell: React.FC<DataHeaderCellProps> = ({
  cellType,
  reservationFilters,
  setReservationFilters,
  shadow,
  sortByFavoritePerson,
}) => {
  const { t } = useTranslation("analytics");
  const popupState = usePopupState({
    variant: "popover",
    popupId: "roomFilterMenu",
  });
  const { dateRange } = useDateRange();
  const { formatMonth, formatDayName } = useDateFns();
  const { isAdmin } = useBackofficePermissions();

  const onFilter = (cellType: CellType, value: string | null) => {
    setReservationFilters((prev) => {
      return { ...prev, [`${cellType.toString()}Filter`]: value };
    });
  };

  const cellWidth = () => {
    if (
      cellType === CellType.FULL_NAME ||
      cellType === CellType.EMAIL ||
      cellType === CellType.TEAM
    ) {
      return { width: "259px" };
    }
  };

  const totalColumn = () => {
    if (cellType === CellType.TOTAL)
      return {
        right: "0",
        zIndex: 32,
        minWidth: "100px",
        borderLeft: "1px solid",
        borderLeftColor: "shades.greyExtraLight",
      };
  };

  if (cellType === CellType.TEAM && isAdmin) return null;
  if (cellType === CellType.EMAIL && !isAdmin) return null;

  return (
    <TableCell
      sx={{
        backgroundColor: "#FBFBFB",
        minHeight: "93px",
        borderBottom: "1px solid",
        borderTop: "1px solid",
        borderRight: "1px solid",
        borderColor: "shades.greyExtraLight",
        padding: "0px",
        position: "sticky",
        verticalAlign: "top",
        top: 0,
        zIndex: 10,
        ...shadow,
        "&:first-of-type": {
          zIndex: 30,
          left: 0,
          backgroundColor: "#FBFBFB",
        },
        "&:last-of-type": {
          borderRight: "none",
        },
        "&:nth-of-type(2)": {
          zIndex: 25,
        },
        ...cellWidth(),
        ...totalColumn(),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "0px 4px",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "600" }}>
          {t(`data-preview.column-names.${cellType.toString()}`)}
        </Typography>
        {cellType === CellType.RESERVATIONS && (
          <Button
            sx={{ padding: "0px", minWidth: "1px" }}
            {...bindTrigger(popupState)}
          >
            <MoreVertical size={16} />
          </Button>
        )}
        {cellType === CellType.FAVORITE && sortByFavoritePerson && (
          <Button
            sx={{ padding: "0px", minWidth: "1px" }}
            onClick={() => sortByFavoritePerson()}
          >
            <SwapVertIcon style={{ fontSize: "18px" }} />
          </Button>
        )}
        {cellType === CellType.FULL_NAME && (
          <FullNameMenu popupState={popupState} />
        )}
        {cellType === CellType.RESERVATIONS && (
          <ReservationsMenu
            popupState={popupState}
            onFilter={onFilter}
            buttonState={reservationFilters.reservationsFilter}
          />
        )}
      </Box>
      {cellType === CellType.RESERVATIONS && (
        <>
          <Box
            sx={{
              backgroundColor: "shades.greyUltimateLight",
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: "shades.greyExtraLight",
              display: "flex",
            }}
          >
            <Typography
              variant="body3"
              sx={{
                width: dateRange.firstMonthWidth,
                padding: "0px 8px",
              }}
            >
              {formatMonth(dateRange.startDate)}
            </Typography>
            {!dateRange.isSameMonth && (
              <Typography
                variant="body3"
                sx={{
                  borderLeft: "1px solid ",
                  borderColor: "shades.greyExtraLight",
                  padding: "0px 8px",
                }}
              >
                {formatMonth(dateRange.endDate)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
            }}
          >
            <Box
              sx={{
                borderRadius: "8px",
                padding: "4px 16px",
                display: "flex",
                justifyContent: "space-between",
                width: dateRange.dateCellWidth,
              }}
            >
              {dateRange.workingDays.map((day: Date, index) => {
                return (
                  <Box
                    sx={{
                      borderRadius: "8px",
                      width: "38px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "4px",
                      "&:hover": {
                        backgroundColor: "cyclamen.light",
                      },
                    }}
                    key={index}
                  >
                    <Typography variant="overline" sx={{ color: "#6d6b6b" }}>
                      {formatDayName(day)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#000" }}>
                      <strong>{("0" + day.getDate()).slice(-2)}</strong>
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </>
      )}
    </TableCell>
  );
};
