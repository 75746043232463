import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSearchParams } from "react-router-dom";

export class MobileDisplayedPage {
  protected constructor(private readonly name: string) {}

  public toString() {
    return this.name;
  }

  static MAPS = new MobileDisplayedPage("MAPS");
  static LIST = new MobileDisplayedPage("LIST");
}

export function useMobileDisplayedPage(): [
  MobileDisplayedPage,
  (newPage: MobileDisplayedPage) => void
] {
  const [searchParams, setSearchParams] = useSearchParams();
  const onChange = useMemoizedFn((newPage: MobileDisplayedPage) => {
    const updated = new URLSearchParams(searchParams);
    updated.set("mode", newPage.toString());
    setSearchParams(updated);
  });

  const mode =
    searchParams.get("mode") === "LIST"
      ? MobileDisplayedPage.LIST
      : MobileDisplayedPage.MAPS;

  return [mode, onChange];
}
