import { request } from "../../../api";
import { mapOrganizationMembership } from "../../../backoffice/api";
import { OrganizationMembersDto } from "../../authentication/api";
import { OrganizationMembership } from "../../authentication/domain";

export async function followMembership(
  membershipId: string,
  followById: string
): Promise<OrganizationMembership | null> {
  const response = await request<OrganizationMembersDto[]>(
    "POST",
    "/api/follow-memberships",
    {
      membershipId,
      followById,
    }
  );
  return response[0] ? mapOrganizationMembership(response[0]) : null;
}
