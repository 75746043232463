import { useQuery, UseQueryResult } from "react-query";
import { getSageHrIntegration } from "../api";
import { SageHrIntegration } from "../domain";

export function useSageHrIntegration(
  organizationId: string
): UseQueryResult<SageHrIntegration> {
  return useQuery(
    ["sage-hr-integration", organizationId],
    () => getSageHrIntegration(organizationId),
    {
      staleTime: 60_000,
      refetchOnWindowFocus: false,
      refetchInterval: 10_000,
    }
  );
}
