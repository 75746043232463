import { Box, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { useEffect, useMemo, useState } from "react";
import { Flipped, Flipper } from "react-flip-toolkit";
import { Trans, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { AmtCard } from "../../../../../components";
import { trackToggleFavoriteLocation } from "../../../../../tracking";
import { Location } from "../../../../assets/domain";
import { useToggleFavoriteLocation } from "../../../../assets/hooks";
import {
  useCurrentUser,
  useOrganizationMembership,
} from "../../../../authentication/hooks";
import { LogoShortSvg } from "../../../../layout/assets";
import { LocationCapacity } from "../../../domain";
import { useLocationCapacityQuery, useMyBookingsQuery } from "../hooks";
import { useFavoriteLocationQuery } from "../hooks/useFavoriteLocation";
import { useTodaysBooking } from "../hooks/useTodaysBooking";
import { LocationCard } from "./LocationCard";

const LocationList: React.FC = React.memo(() => {
  const { data: locationsData, isLoading } = useLocationCapacityQuery();
  const { data: favoriteLocationsData } = useFavoriteLocationQuery();
  const toggleFavorite = useToggleFavoriteLocation();
  const todaysBooking = useTodaysBooking();
  const { t } = useTranslation("dashboard");
  const queryClient = useQueryClient();
  const organizationMembership = useOrganizationMembership();
  const navigate = useNavigate();
  const [sortedLocations, setSortedLocations] = useState<LocationCapacity[]>(
    []
  );

  const locations = useMemo(() => {
    return locationsData ?? [];
  }, [locationsData]);

  const favoriteLocations = useMemo(() => {
    return favoriteLocationsData ?? [];
  }, [favoriteLocationsData]);

  useEffect(() => {
    let array = locations;
    if (sortedLocations.length > 0) {
      array = sortedLocations;
    }
    const newLocations = array.slice();
    newLocations.sort((a, b) => {
      return (
        favoriteLocations.findIndex((fl) => fl.locationId === b.location.id) -
        favoriteLocations.findIndex((fl) => fl.locationId === a.location.id)
      );
    });
    setSortedLocations(newLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteLocations, locations]);

  const updateFavoriteLocations = useMemoizedFn(
    (event: React.MouseEvent<HTMLElement>, location: Location) => {
      event?.stopPropagation();
      toggleFavorite.mutateAsync(location.id).then((response) => {
        queryClient.setQueryData(
          ["favorite-locations", organizationMembership.id],
          response
        );
      });
      trackToggleFavoriteLocation();
    }
  );

  if (isLoading) {
    return null;
  }

  function checkIfLocationIsFavorite(location: Location): boolean {
    return (
      favoriteLocations.filter((it) => it.locationId === location.id).length > 0
    );
  }

  return (
    <Flipper
      flipKey={sortedLocations.map((it) => it.location.id).join("")}
      spring={{
        stiffness: 150,
        damping: 50,
      }}
      staggerConfig={{
        default: {
          reverse: false,
          speed: 1,
        },
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          paddingBottom: 2,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={(theme) => ({
            justifyContent: "center",
            display: "flex",
            gap: 2,
            [theme.breakpoints.down("lg")]: {
              display: "grid",
              gridGap: theme.spacing(2),
              gridTemplateColumns: "1fr 1fr",
              gridAutoRows: "1fr",
              justifyItems: "center",
            },
            [theme.breakpoints.down("sm")]: {
              gridGap: theme.spacing(1),
            },
            [theme.breakpoints.down(375)]: {
              gridTemplateColumns: "1fr",
              justifyItems: "center",
            },
          })}
        >
          <AmtCard
            sx={{
              borderRadius: "10px",
              minWidth: 167,
              maxWidth: 222,
              width: "100%",
              padding: 0.5,
              flex: "1",
              overflow: "visible",
            }}
            onClick={() => {
              navigate("/?my-bookings");
            }}
            pop
          >
            <Box
              sx={{
                backgroundColor: "green.regular",
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LogoShortSvg width="29" height="41" />
              <Typography variant="subtitle1" color="white">
                {t("upcoming-bookings")}
              </Typography>
            </Box>
          </AmtCard>
          {sortedLocations.map(({ location, capacity, newLocation }) => (
            <Flipped key={location.id} flipId={location.id} stagger={false}>
              <LocationCard
                key={location.id}
                location={location}
                capacity={capacity}
                newLocation={newLocation}
                isFavorite={checkIfLocationIsFavorite(location)}
                toggleFavorite={updateFavoriteLocations}
                todaysBookingLocation={
                  !!(todaysBooking && todaysBooking.location.equals(location))
                }
              />
            </Flipped>
          ))}
        </Box>
      </Box>
    </Flipper>
  );
});

export const DashboardLocations: React.FC = () => {
  const { t } = useTranslation("dashboard");
  const currentUser = useCurrentUser();
  const { data: bookings, isLoading } = useMyBookingsQuery();

  if (isLoading || !bookings) {
    return null;
  }

  const translation = bookings.length > 0 ? "has-upcoming" : "no-upcoming";

  return (
    <Box
      sx={(theme) => ({
        flex: "1 1 auto",
        overflow: "auto",
        [theme.breakpoints.down("md")]: {
          paddingBottom: 12,
        },
      })}
    >
      <Box
        sx={{
          paddingX: 2,
          margin: "0 auto",
        }}
      >
        <Box
          textAlign="center"
          sx={(theme) => ({
            paddingY: 7,
            [theme.breakpoints.down("md")]: {
              paddingY: 4,
            },
          })}
        >
          <Typography
            variant="h5"
            sx={(theme) => ({
              display: "none",
              color: "green.regular",
              mb: 1,
              [theme.breakpoints.down("md")]: {
                display: "block",
              },
            })}
          >
            {t("welcome-mobile", { name: currentUser.name.first })}
            <br />
          </Typography>
          <Typography
            variant="h4"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                // @ts-ignore
                ...theme.typography.h6,
                mb: 1,
              },
            })}
          >
            <Trans t={t} i18nKey={`heading.${translation}.title`} />
          </Typography>
          <Typography
            variant="h6"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            })}
          >
            <Trans t={t} i18nKey={`heading.${translation}.subtitle`} />
          </Typography>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              [theme.breakpoints.down("md")]: {
                // @ts-ignore
                ...theme.typography.body1,
                fontWeight: theme.typography.fontWeightBold,
              },
            })}
          >
            <Trans t={t} i18nKey={`heading.${translation}.description`} />
          </Typography>
        </Box>

        <LocationList />
      </Box>
    </Box>
  );
};
