import { Box, styled } from "@mui/material";
import React from "react";
import { Avatar } from "../../../../../../../../../components";
import { GroupMember } from "../../../../../../domain";

export interface SelectedMemberAvatarProps {
  dedicatedFor: GroupMember[];
}

export const SelectedMemberAvatar: React.FC<SelectedMemberAvatarProps> = ({
  dedicatedFor,
}) => {
  if (dedicatedFor.length > 1) {
    return (
      <DedicatedFormAvatarGroup>
        <DedicatedFormAvatar src={dedicatedFor[1].user.avatarImageUrlSrc} />
        <DedicatedFormAvatar src={dedicatedFor[0].user.avatarImageUrlSrc} />
      </DedicatedFormAvatarGroup>
    );
  }
  if (dedicatedFor.length === 1) {
    return <DedicatedFormAvatar src={dedicatedFor[0].user.avatarImageUrlSrc} />;
  }
  return <DedicatedFormAvatar />;
};

export const DedicatedFormAvatar = styled(Avatar)({
  flexShrink: 0,
  width: 36,
  height: 36,
  border: "1px solid white",
});

const DedicatedFormAvatarGroup = styled(Box)({
  position: "relative",
  flexShrink: 0,
  width: 36,
  height: 36,
  ".MuiAvatar-root": {
    position: "absolute",
    width: 24,
    height: 24,
  },
  ".MuiAvatar-root:last-of-type": {
    left: 0,
    bottom: 0,
  },
  ".MuiAvatar-root:first-of-type": {
    right: 0,
    top: 0,
  },
});
