import { Components, Theme } from "@mui/material";

export const MuiMenu: Components<Theme>["MuiMenu"] = {
  styleOverrides: {
    // @ts-ignore
    paper: ({ theme }) => ({
      border: `1px solid ${theme.palette.shades.greyLight}`,
      borderRadius: "8px !important",
      background: "white",
      boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.1) !important",
      padding: theme.spacing(0, 1),
    }),
  },
};
