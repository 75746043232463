import { Grid } from "@mui/material";
import React from "react";
import { AmtCenteredLoader } from "../../../../../components";
import { trackFloorChange } from "../../../../../tracking";
import { Floor, Location, Space } from "../../../../assets/domain";
import { ConferenceRoom } from "../../../../conference/domain";
import { useConferenceRooms } from "../../../../conference/hooks";
import { useConferenceSelection } from "../../../../conference/state";
import { InteractiveFloorPlan } from "../../../../floor-plan/components";
import { useInventoryFiltersQuery } from "../../../../floor-plan/hooks/useInventoryFiltersQuery";
import { SpaceWithImages } from "../../../../gallery/domain";
import { useSpaceImages } from "../../../../gallery/hooks";
import { useGallerySelection } from "../../../../gallery/state";
import {
  BookingDate,
  DeskBookings,
  DeskInventoryFilters,
  DeskOverview,
} from "../../../domain";
import {
  EditDeskSnapshot,
  useBookingContext,
  useBookingDate,
  useBookingForm,
  useBookingNavigation,
  useFloors,
  useLocations,
  useSelectedInventoryFilters,
  useSelection,
  useSpaces,
} from "../state";

export interface FloorPlanListProps {
  date: BookingDate;
  editingDeskId: string | null;
  deskBookings: DeskBookings;
  conferenceRooms: ConferenceRoom[] | undefined;
  spacesWithImages: SpaceWithImages[] | undefined;
  inventoryFilters: DeskInventoryFilters[] | undefined;
  selectedDesk: DeskOverview | null;
  selectedConferenceRoom: ConferenceRoom | null;
  selectedSpaceWithImages: SpaceWithImages | null;
  selectedInventoryFilters: DeskInventoryFilters[] | undefined;
  selectedSpace: Space | null;
  location: Location;
  floor: Floor | null;
  floors: Floor[];
  onChangeFloor: (newFloor: Floor | null) => void;
  onSelectDesk: (desk: DeskOverview | null) => void;
  onSelectConferenceRoom: (conferenceRoom: ConferenceRoom | null) => void;
  onSelectSpaceWithImages: (spaceWithImages: SpaceWithImages | null) => void;
  isDeskDisabled: (desk: DeskOverview) => boolean;
}

const FloorPlanListComponent: React.FC<FloorPlanListProps> = React.memo(
  ({
    date,
    editingDeskId,
    deskBookings,
    conferenceRooms,
    spacesWithImages,
    inventoryFilters,
    selectedDesk,
    selectedConferenceRoom,
    selectedSpaceWithImages,
    selectedInventoryFilters,
    selectedSpace,
    location,
    floor,
    floors,
    onChangeFloor,
    onSelectDesk,
    onSelectConferenceRoom,
    onSelectSpaceWithImages,
    isDeskDisabled,
  }) => {
    const selectedFloor = floors.length === 1 ? floors[0] : floor;

    function handleChangeFloorByClickingFloorPlan(floor: Floor) {
      onChangeFloor(floor);
      trackFloorChange(floor, "floor-plan");
    }

    return (
      <>
        <Grid
          container
          spacing={2}
          sx={(theme) => ({
            minHeight: "100%",
            [theme.breakpoints.down("md")]: {
              ...(selectedFloor === null && {
                paddingBottom: 7,
              }),
            },
          })}
        >
          {!selectedFloor &&
            floors.map((it) => (
              <Grid item xxs={12} xs={12} sm={6} key={it.id}>
                <InteractiveFloorPlan
                  square
                  interactive={false}
                  bookings={deskBookings}
                  conferenceRooms={conferenceRooms}
                  spacesWithImages={spacesWithImages}
                  date={date}
                  editDeskId={editingDeskId}
                  selectedDesk={selectedDesk}
                  selectedConferenceRoom={selectedConferenceRoom}
                  selectedSpaceWithImages={selectedSpaceWithImages}
                  floor={it}
                  imageUrl={it.floorPlanImageUrl}
                  description={`${it.description} (${location?.address.addressLine1})`}
                  onClick={() => handleChangeFloorByClickingFloorPlan(it)}
                  isDeskDisabled={isDeskDisabled}
                  onSelectDesk={onSelectDesk}
                  onSelectConferenceRoom={onSelectConferenceRoom}
                  onSelectSpaceWithImages={onSelectSpaceWithImages}
                />
              </Grid>
            ))}
          {selectedFloor && (
            <Grid item xxs={12} xs={12} key={selectedFloor.id}>
              <InteractiveFloorPlan
                interactive
                bookings={deskBookings}
                conferenceRooms={conferenceRooms}
                spacesWithImages={spacesWithImages}
                inventoryFilters={inventoryFilters}
                date={date}
                editDeskId={editingDeskId}
                selectedDesk={selectedDesk}
                selectedConferenceRoom={selectedConferenceRoom}
                selectedSpaceWithImages={selectedSpaceWithImages}
                selectedInventoryFilters={selectedInventoryFilters}
                selectedSpace={selectedSpace}
                floor={selectedFloor}
                floors={floors}
                imageUrl={selectedFloor.floorPlanImageUrl}
                description={`${selectedFloor.description} (${location?.address.addressLine1})`}
                onSelectDesk={onSelectDesk}
                onSelectConferenceRoom={onSelectConferenceRoom}
                onSelectSpaceWithImages={onSelectSpaceWithImages}
                onChangeFloor={onChangeFloor}
                isDeskDisabled={isDeskDisabled}
                sx={{
                  height: "100%",
                }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);

export const FloorPlanList: React.FC = () => {
  const { location } = useLocations();
  const { floor, floors } = useFloors();
  const { date } = useBookingDate();
  const { space } = useSpaces();
  const { selectedDesk, onSelectDesk } = useSelection();
  const { selectedConferenceRoom, onSelectConferenceRoom } =
    useConferenceSelection();
  const { selectedSpaceWithImages, onSelectSpaceWithImages } =
    useGallerySelection();
  const { selectedInventoryFilters } = useSelectedInventoryFilters();
  const { onChangeFloor } = useBookingNavigation();
  const { data: conferenceRooms } = useConferenceRooms();
  const { data: spacesWithImages } = useSpaceImages();
  const { data: inventoryFilters } = useInventoryFiltersQuery();

  const [
    {
      bookings: { data: bookings },
      isDeskDisabled,
    },
  ] = useBookingContext();
  const form = useBookingForm();

  if (!location) {
    throw new Error("Location must be loaded");
  }
  if (!bookings || floors.length === 0) {
    return <AmtCenteredLoader />;
  }
  const editingDeskId =
    form.isEditing && form.source instanceof EditDeskSnapshot
      ? form.source.desk.id
      : null;
  return (
    <FloorPlanListComponent
      date={date}
      editingDeskId={editingDeskId}
      deskBookings={bookings.deskBookings}
      conferenceRooms={conferenceRooms}
      spacesWithImages={spacesWithImages}
      inventoryFilters={inventoryFilters}
      selectedDesk={selectedDesk}
      selectedConferenceRoom={selectedConferenceRoom}
      selectedSpaceWithImages={selectedSpaceWithImages}
      selectedInventoryFilters={selectedInventoryFilters}
      selectedSpace={space}
      location={location}
      floor={floor}
      floors={floors}
      onChangeFloor={onChangeFloor}
      onSelectDesk={onSelectDesk}
      onSelectConferenceRoom={onSelectConferenceRoom}
      onSelectSpaceWithImages={onSelectSpaceWithImages}
      isDeskDisabled={isDeskDisabled}
    />
  );
};
