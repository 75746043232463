import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../authentication/hooks";
import { getConferenceRooms } from "../api";
import { ConferenceRoom } from "../domain";

export function useConferenceRooms(): UseQueryResult<ConferenceRoom[]> {
  const { organization } = useOrganizationMembership();
  return useQuery(["conference-rooms", organization.id], () =>
    getConferenceRooms(organization.id)
  );
}
