import * as React from "react";

interface SvgPendingAvatarProps {
  color: string;
}

export const SvgPendingAvatar: React.FC<SvgPendingAvatarProps> = (
  props: SvgPendingAvatarProps
) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} fill="#fff" />
    <path
      d="M17 22.3333H23"
      stroke={props.color}
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 11.3334C20.2652 11.0682 20.6249 10.9192 21 10.9192C21.1857 10.9192 21.3696 10.9558 21.5412 11.0268C21.7128 11.0979 21.8687 11.2021 22 11.3334C22.1313 11.4647 22.2355 11.6206 22.3066 11.7922C22.3776 11.9638 22.4142 12.1477 22.4142 12.3334C22.4142 12.5191 22.3776 12.703 22.3066 12.8746C22.2355 13.0462 22.1313 13.2021 22 13.3334L13.6667 21.6667L11 22.3334L11.6667 19.6667L20 11.3334Z"
      stroke={props.color}
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={0.5}
      y={0.5}
      width={31}
      height={31}
      rx={15.5}
      stroke={props.color}
    />
  </svg>
);
