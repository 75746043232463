import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Navigate } from "react-router";
import { AmtCenteredLoader } from "../../../../../../components";
import { useDateFns } from "../../../../../../hooks";
import { useOrganizationMembership } from "../../../../../authentication/hooks";
import { usePermissions } from "../../../../../permissions/hooks";
import { configureSageHr } from "../../api";
import { useSageHrIntegration } from "../../hooks";
import { SageHrConfigurationDialog } from "./SageHrConfigurationDialog";

const InternalSageHrSettingsPage: React.FC = () => {
  const qc = useQueryClient();
  const { format, formatDistanceToNow } = useDateFns();
  const { organization } = useOrganizationMembership();
  const { data, isLoading } = useSageHrIntegration(organization.id);
  const [dialogOpen, setDialogOpen] = useState(false);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "sageConfigurationMenu",
  });

  const { mutate: disableIntegration } = useMutation(
    ["disable-sage-hr", organization.id],
    () =>
      configureSageHr(organization.id, {
        enabled: false,
      }),
    {
      onSuccess: () => {
        qc.invalidateQueries("sage-hr-integration");
      },
    }
  );

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  if (!data || isLoading) {
    return <AmtCenteredLoader />;
  }

  return (
    <Box pt={4}>
      <SageHrConfigurationDialog
        initialValues={{
          apiUrl: data.apiUrl ?? "",
          apiKeyConfigured: data.apiKeyConfigured,
          apiKey: "",
        }}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
      <List>
        {!data.enabled && (
          <ListItemButton onClick={handleDialogOpen}>
            <ListItemText
              primary={
                <>
                  SageHR integration is <strong>disabled</strong>
                </>
              }
              secondary="Configure integration to periodically synchronize employee details"
            />
          </ListItemButton>
        )}
        {data.enabled && (
          <>
            <ListItemButton {...bindTrigger(popupState)}>
              <ListItemText
                primary={
                  <>
                    SageHR integration is <strong>enabled</strong>
                  </>
                }
                secondary="Periodically synchronizes SageHR employee details"
              />
            </ListItemButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  handleDialogOpen();
                  popupState.close();
                }}
              >
                <ListItemText primary="Configure" />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  disableIntegration();
                  popupState.close();
                }}
              >
                <ListItemText primary="Disable" />
              </MenuItem>
            </Menu>
          </>
        )}
        {data.lastSyncedAt && (
          <ListItem>
            <ListItemText
              primary="Synchronization status"
              secondary={
                <>
                  Last synchronized{" "}
                  <span
                    title={format(data.lastSyncedAt, "dd. MMM yyyy. HH:mm")}
                  >
                    {formatDistanceToNow(data.lastSyncedAt, {
                      addSuffix: true,
                    })}
                  </span>
                </>
              }
            />
          </ListItem>
        )}
        {data.lastSyncedAt === null && (
          <ListItem>
            <ListItemText
              primary="Synchronization status"
              secondary={<>Never synchronized</>}
            />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export const SageHrSettingsPage: React.FC = () => {
  const permissions = usePermissions();

  if (!permissions.sageHr.canConfigure()) {
    return <Navigate to="/settings/account" />;
  }

  return <InternalSageHrSettingsPage />;
};
