import {
  Box,
  Button,
  DialogContent,
  styled,
  Theme,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { Check, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtDialog } from "../../../../components";
import { BookingStatus } from "../../api";
import { Booking, BookingDate, DeskBooking } from "../../domain";
import { BookingInfo } from "../../pages/BookingPage/Dialog/BookingInfo";
import { BookedForUserHeader } from "../BookedForUserHeader";

export interface BookingCancellationDialogProps {
  open: boolean;
  onClose: () => void;
  booking: Booking;
  disabled?: boolean;
  onConfirm?: () => void;
}

const MobileHeading: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h6,
  },
});

const HideMobile = styled("span")(({ theme }) => ({
  [theme.breakpoints.down(375)]: {
    display: "none",
  },
}));

export const BookingCancellationDialog: React.FC<
  BookingCancellationDialogProps
> = ({ open, onClose, booking, disabled, onConfirm }) => {
  const { t } = useTranslation("booking-dialog");

  const isDeskBooking = booking instanceof DeskBooking;

  return (
    <AmtDialog open={open} onClose={onClose} closeButton>
      <DialogContent>
        <BookedForUserHeader user={booking.bookedFor} />
        <BookingInfo
          date={BookingDate.of(booking.bookingDate)}
          location={booking.location}
          {...(isDeskBooking && {
            floor: booking.floor,
            space: booking.space,
            desks: [booking.desk],
          })}
          bookedBy={booking.bookedBy}
        />
        <Box
          sx={(theme) => ({
            textAlign: "center",
            marginY: 3,
            [theme.breakpoints.down("md")]: {
              marginY: 2,
            },
          })}
        >
          <Typography variant="h4" color="error.main" sx={MobileHeading}>
            {booking.status === BookingStatus.PENDING
              ? t("cancellation.title.invite")
              : isDeskBooking
              ? t("cancellation.title.desk")
              : t("cancellation.title.location")}
          </Typography>
          <Typography variant="body2" color="shades.grey">
            {t("cancellation.confirmation")}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: 1,
            [theme.breakpoints.down("md")]: {
              gap: 2,
            },
          })}
        >
          <Button
            fullWidth
            color="cancel"
            variant="outlined"
            disabled={disabled}
            onClick={onClose}
            startIcon={<X size={16} />}
            data-testid="dialog--cancel"
          >
            <HideMobile>{t("cancellation.action.no")}</HideMobile>
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            disabled={disabled}
            onClick={onConfirm}
            startIcon={<Check size={16} />}
            data-testid="dialog--submit"
          >
            <HideMobile>{t("cancellation.action.yes")}</HideMobile>
          </Button>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
