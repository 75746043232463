import { Box, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import {
  Bell,
  Calendar as CalendarIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  User as UserIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { Icon, Info, Label, Row } from "../../../../../global/styles";
import { useDateFns } from "../../../../../hooks";
import { Desk, Floor, Location, Space } from "../../../../assets/domain";
import { User } from "../../../../authentication/domain";
import { BookingDate } from "../../../domain";
import { DeskBookingRequest, useBookingForm } from "../state";

const BellIcon = (
  <Box
    sx={{
      color: "orange.regular",
      display: "flex",
      alignItems: "center",
      flexBasis: "24px",
      width: "24px",
    }}
  >
    <Bell size={16} />
  </Box>
);

export interface BookingInfoProps {
  sx?: SxProps<Theme>;
  date: BookingDate;
  location: Location;
  floor?: Floor;
  space?: Space;
  desks?: Desk[];
  bookedBy?: User;
  showDedicatedWarning?: boolean;
  dedicatedDeskSelected?: boolean;
}

export const BookingInfo: React.FC<BookingInfoProps> = ({
  sx,
  date,
  location,
  floor,
  space,
  desks,
  bookedBy,
  showDedicatedWarning = true,
  dedicatedDeskSelected,
}) => {
  const { formatRange } = useDateFns();
  const { t } = useTranslation("booking-details");
  const form = useBookingForm();

  const hasDedicatedDesks = (): boolean => {
    if (!form.target || !(form.target instanceof DeskBookingRequest)) {
      return false;
    } else {
      const desk = form.target.space.desks.find((desk) => desk.isDedicated);
      return !!desk;
    }
  };

  const deskInventory = (): string[][] => {
    if (
      !form.target ||
      !(form.target instanceof DeskBookingRequest) ||
      !desks
    ) {
      return [];
    } else {
      const desksInOverview = form.target.space.desks.filter((overview) => {
        return desks.some((desk) => {
          return overview.desk.id === desk.id;
        });
      });
      const inventories = desksInOverview.map((it) => it.deskInventories);
      return inventories;
    }
  };

  return (
    <Box sx={sx}>
      <Row>
        <Icon>
          <CalendarIcon size={16} />
        </Icon>
        <Info data-testid="dialog--booking-date">
          <strong>{formatRange(date)}</strong>
        </Info>
      </Row>
      <Row>
        <span>
          <Icon>
            <HomeIcon size={16} />
          </Icon>
        </span>
        <Info data-testid="dialog--location">
          <span>
            <strong>{location.name}</strong>
            <br />
            {location.address.addressLine1}, {location.address.city}
          </span>
        </Info>
      </Row>
      {floor && (
        <Row>
          <Icon>
            <LayersIcon size={16} />
          </Icon>
          <Info data-testid="dialog--floor">{floor.description}</Info>
        </Row>
      )}
      {space && (
        <Row>
          <Icon />
          <Info data-testid="dialog--space">
            <Label>{t("label.space")}:</Label>
            {space.description}
          </Info>
        </Row>
      )}
      {desks && (
        <Row>
          <Icon />
          <Info data-testid="dialog--desk">
            <Label>{t("label.desk")}:</Label>
            {desks.length > 0 && (
              <>
                <strong>{desks.map((it) => it.identifier).join(", ")}</strong>
                {deskInventory().length > 0 && (
                  <>
                    <Label>{t("label.inventory")}:</Label>
                    {deskInventory().map((inventoryGroup, groupIndex) => (
                      <div key={groupIndex}>
                        {inventoryGroup.map((inventory, index) => (
                          <div key={index}>
                            <strong>{inventory}</strong>
                          </div>
                        ))}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
            {desks.length === 0 && (
              <>
                <Typography variant="inherit" color="errors.regular">
                  {t("feedback.desk-available")}
                </Typography>
              </>
            )}
          </Info>
        </Row>
      )}

      {bookedBy && (
        <Row>
          <Icon>
            <UserIcon size={16} />
          </Icon>
          <Info data-testid="dialog--booked-by">
            <Label>{t("label.booked-by")}:</Label>
            {bookedBy.name.full}
          </Info>
        </Row>
      )}
      {hasDedicatedDesks() && showDedicatedWarning && (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginY: 0.5,
              backgroundColor: "orange.light",
              padding: 1,
              borderRadius: 1,
            }}
          >
            <Typography variant="body2">{BellIcon}</Typography>
            <Typography variant="body2">
              {t(dedicatedDeskSelected ? "dedicated" : "dedicated-in-room")}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
