import { useCreation } from "ahooks";
import { useOrganizationMembership } from "../../authentication/hooks";
import { GroupMember } from "../domain";
import { useBookingForm, useSelection } from "../pages/BookingPage/state";
import { useBookingGroups } from "./queries";

export const useAutocompleteOptions = (value: GroupMember[]): GroupMember[] => {
  const { organization } = useOrganizationMembership();
  const form = useBookingForm();
  if (!form.target) {
    throw new Error("Invalid usage of BookForAutocomplete");
  }
  const { selectedDesk, selectedSpace } = useSelection();
  const { data: organizationGroups } = useBookingGroups(
    organization.id,
    form.target.date,
    form.isEditing ? form.source.bookingId : null
  );

  return useCreation(() => {
    if (!organizationGroups) return [];
    if (value.length === 0 && selectedDesk?.isDedicated) {
      return organizationGroups.allMembers.filter((member) =>
        selectedDesk.dedicatedFor.includes(member.user.id)
      );
    }
    if (selectedSpace) {
      const dedicatedDesks = selectedSpace
        ? selectedSpace.desks.filter((desk) => desk.isDedicated)
        : [];
      const dedicatedMembersSelected = value.filter((member) => {
        const memberSelected = dedicatedDesks.find((desk) =>
          desk.dedicatedFor.includes(member.user.id)
        );
        return !!memberSelected;
      });
      const dedicatedDesksBooked = Math.min(
        dedicatedMembersSelected.length,
        dedicatedDesks.length
      );
      if (
        selectedSpace.desks.length - value.length <=
        dedicatedDesks.length - dedicatedDesksBooked
      ) {
        return organizationGroups.allMembers.filter(
          (member) => member.team !== null
        );
      }
    }
    return organizationGroups.allMembers;
  }, [organizationGroups?.groups, value]);
};
