import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { AlertCircle, AlertTriangle, MessageSquare } from "react-feather";

export interface AmtAlertProps {
  text: React.ReactNode;
  title?: string;
  variant?: "warning" | "neutral" | "error";
  sx?: SxProps<Theme>;
}

export const AmtAlert: React.FC<AmtAlertProps> = ({
  title,
  text,
  variant = "warning",
  sx,
}) => {
  const getStyle = (variant: "warning" | "neutral" | "error") => {
    switch (variant) {
      case "warning":
        return {
          backgroundColor: "orange.light",
          textColor: "orange.regular",
          icon: <AlertTriangle size={16} />,
        };
      case "neutral":
        return {
          backgroundColor: "shades.greyUltimateLight",
          textColor: "shades.grey",
          icon: <MessageSquare size={16} />,
        };
      case "error":
        return {
          backgroundColor: "red.light",
          textColor: "red.regular",
          icon: <AlertCircle size={16} />,
        };
      default:
        return {
          backgroundColor: "orange.regular",
          textColor: "orange.regular",
          icon: <AlertTriangle size={16} />,
        };
    }
  };
  const { backgroundColor, textColor, icon } = getStyle(variant);

  return (
    <Box
      sx={{
        color: title ? textColor : "shades.dark",
        display: "flex",
        alignItems: "flex-start",
        gap: title ? 2 : 1,
        paddingX: title ? 2 : 1,
        paddingY: title ? 1 : 0.5,
        borderRadius: "8px",
        backgroundColor: backgroundColor,
        ...sx,
      }}
    >
      {icon}
      <Box
        sx={{
          flex: "1 1 auto",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {title && <Typography variant="caption">{title}</Typography>}
        <Typography
          variant="body2"
          sx={{ color: "shades.dark", whiteSpace: "pre-line" }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
