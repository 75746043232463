import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BookingDate } from "../modules/booking/domain";
import { DeskOccupancyList, DeskOccupancyListProps } from "./DeskOccupancyList";

type ContainerVariant = "none" | "contained";

const Container = styled(Box)<{ variant: ContainerVariant }>(
  ({ theme, variant }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 2,
    ...(variant === "contained" && {
      border: "1px solid",
      borderColor: theme.palette.shades.greyExtraLight,
      borderRadius: 8,
      background: "white",
      padding: theme.spacing(1),
    }),
  })
);

const Text = styled(Typography)({
  margin: 0,
  padding: 0,
});

function canShowDetails(date: BookingDate): boolean {
  return date.workingDays.length <= 5;
}

interface ContainedDeskOccupancyListProps extends DeskOccupancyListProps {
  variant?: ContainerVariant;
  dedicated?: boolean;
}

export const ContainedDeskOccupancyList: React.FC<
  ContainedDeskOccupancyListProps
> = ({ variant = "none", sx, ...props }) => {
  const { t } = useTranslation("booking");

  if (props.dedicated) {
    return (
      <Container variant={variant} sx={sx}>
        <Text {...props.TypographyProps}>{t("occupancy-list.dedicated")}</Text>
      </Container>
    );
  }

  if (!canShowDetails(props.date)) {
    return (
      <Container variant={variant} sx={sx}>
        <Text {...props.TypographyProps}>
          {t("occupancy-list.occupancy-limit")}
        </Text>
      </Container>
    );
  }
  return (
    <Container variant={variant} sx={sx}>
      <Text
        {...props.TypographyProps}
        sx={{
          borderBottom: "1px solid",
          borderBottomColor: "shades.darkLight",
          ...(variant === "contained" && {
            borderBottomColor: "shades.greyExtraLight",
          }),
        }}
      >
        {t("occupancy-list.occupied")}:
      </Text>
      <DeskOccupancyList {...props} />
    </Container>
  );
};
