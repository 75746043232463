import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../../../authentication/hooks";
import { getUpcomingBookings } from "../../../api";
import { UpcomingBooking } from "../../../domain";
import { useDashboardDate } from "./useDashboardDate";

export function useMyBookingsQuery(): UseQueryResult<UpcomingBooking[]> {
  const membership = useOrganizationMembership();
  const [from] = useDashboardDate();
  return useQuery(
    ["upcoming-bookings", membership.id, from],
    () => getUpcomingBookings(membership.id, from),
    {
      refetchOnWindowFocus: "always",
    }
  );
}
