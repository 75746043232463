import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { Desk, Space } from "../../../../modules/assets/domain";
import { User } from "../../../../modules/authentication/domain";
import { EventEmitter } from "../../../../modules/events/domain";
import { DeskWithStatus } from "../../../domain";
import { DeskStatus } from "../domain";

type AssetFormState =
  | {
      isOpen: false;
      desk?: DeskWithStatus;
    }
  | {
      isOpen: true;
      desk: DeskWithStatus;
    };

export type UseAssetFormResult = AssetFormState & {
  openDialog(desk: DeskWithStatus): void;
  closeDialog(): void;
};

export interface DeskStatusEvent {
  desk: Desk | null;
  space: Space;
  changedBy: User;
  dedicatedFor: User[];
  assetStatus: DeskStatus;
}

interface FormEvent {
  deskStatusChange$: EventEmitter<DeskStatusEvent>;
}

const eventsAtom = atom<FormEvent>({
  deskStatusChange$: new EventEmitter<DeskStatusEvent>(),
});

const formStateAtom = atom<AssetFormState>({
  isOpen: false,
});

export function useAssetForm(): UseAssetFormResult {
  const [formState, setFormState] = useAtom(formStateAtom);

  const openDialog = useMemoizedFn((desk: DeskWithStatus) => {
    setFormState({
      isOpen: true,
      desk,
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isOpen: false,
    });
  });

  return {
    ...formState,
    openDialog,
    closeDialog,
  };
}

export function useAssetEvents(): FormEvent {
  const [events] = useAtom(eventsAtom);
  return events;
}
