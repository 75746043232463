import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import { fieldToTextField } from "formik-mui";
import React from "react";
import { AdminAutocomplete } from ".";
import { GroupMember } from "../../../../asset-management/domain";

export interface FormikAdminAutocompleteProps
  extends FieldProps<GroupMember>,
    Omit<TextFieldProps, "name" | "value" | "error" | "onChange"> {}

export const FormikAdminAutocomplete: React.FC<
  FormikAdminAutocompleteProps
> = ({ ...props }) => (
  <AdminAutocomplete
    {...fieldToTextField(props)}
    value={props.field.value}
    onChange={(value: GroupMember | undefined) => {
      props.field.onChange({
        name: props.field.name,
        target: {
          name: props.field.name,
          value,
        },
      });
    }}
  />
);
