import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIsOverflow } from "../../../../../backoffice/modules/analytics/hooks";
import { AmtAlert } from "../../../../../components";
import {
  CellType,
  GroupedReservations,
} from "../../../../../global/components/Tables";
import {
  DataContentCell,
  DataHeaderCell,
} from "../../../../../global/components/Tables/TableCells";
import { useBackofficePermissions } from "../../../../permissions/hooks/useBackofficePermissions";
import { useReservationsQuery } from "../../../hooks";

export class ColumnItem {
  constructor(public readonly id: number, public readonly cellType: CellType) {}
}

export class ReservationFilters {
  constructor(
    public readonly nameFilter: string | null,
    public readonly reservationsFilter: string | null
  ) {}
}

export const SearchTable: React.FC = () => {
  const { isAdmin } = useBackofficePermissions();
  const { t } = useTranslation("analytics");
  const { data: bookings } = useReservationsQuery();
  const menuContent = isAdmin
    ? [
        new ColumnItem(4, CellType.FAVORITE),
        new ColumnItem(0, CellType.EMAIL),
        new ColumnItem(3, CellType.RESERVATIONS),
      ]
    : [
        new ColumnItem(4, CellType.FAVORITE),
        new ColumnItem(1, CellType.TEAM),
        new ColumnItem(3, CellType.RESERVATIONS),
      ];
  const [filteredReservations, setFilteredReservations] = useState<
    GroupedReservations[]
  >([]);
  const [reservationFilters, setReservationFilters] =
    useState<ReservationFilters>(new ReservationFilters(null, null));
  const [sortType, setSortType] = useState<String>("asc");

  const filterReservations = useMemoizedFn((): GroupedReservations[] => {
    if (bookings) {
      let allReservations = [...bookings];
      if (reservationFilters.reservationsFilter !== null) {
        allReservations = allReservations.map(({ reservations, ...el }) => ({
          ...el,
          reservations: reservations.filter(
            ({ desk }) =>
              (!!desk && reservationFilters.reservationsFilter) ||
              (!desk && !reservationFilters.reservationsFilter)
          ),
        }));
      }
      return allReservations;
    }
    return [];
  });

  useEffect(() => {
    setFilteredReservations(filterReservations());
  }, [filterReservations, reservationFilters, bookings]);

  const sortByFavoritePerson = () => {
    let sortedReservations: GroupedReservations[] | undefined = [];
    if (sortType === "asc") {
      sortedReservations = [...filteredReservations].sort(
        (a, b) => Number(b.bookedFor.doIFollow) - Number(a.bookedFor.doIFollow)
      );
      setFilteredReservations(sortedReservations);
      setSortType("desc");
    } else if (sortType === "desc") {
      sortedReservations = [...filteredReservations].sort(
        (a, b) => Number(a.bookedFor.doIFollow) - Number(b.bookedFor.doIFollow)
      );
      setFilteredReservations(sortedReservations);
      setSortType("default");
    } else {
      if (bookings) {
        setFilteredReservations(bookings);
      }
      setSortType("asc");
    }
  };

  const ref = React.useRef<HTMLInputElement>(null);
  const isOverflow = useIsOverflow(ref);

  const cellShadow = () => {
    if (isOverflow)
      return {
        boxShadow: "3px 0px 3px rgba(20, 17, 16, 0.1)",
        borderRight: "1px solid",
        borderColor: "shades.greyExtraLight",
      };
  };

  return (
    <Box
      sx={{
        marginTop: "36px",
        border: "1px solid",
        borderTop: isAdmin ? "1px solid" : "none",
        borderRadius: "6px",
        borderColor: "shades.greyExtraLight",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          maxHeight: "100vh",
          overflow: "auto",
          "&:focus": {
            boxShadow: "0 0 0.5em rgba(0, 0, 0, 0.5)",
            outline: 0,
          },
        }}
        ref={ref}
      >
        <Table
          sx={{
            whiteSpace: "nowrap",
            margin: "0",
            borderCollapse: "separate",
            borderSpacing: "0",
            width: "100%",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "&:hover": {
                  color: "red",
                },
              }}
            >
              <DataHeaderCell
                cellType={CellType.FULL_NAME}
                reservationFilters={reservationFilters}
                setReservationFilters={setReservationFilters}
                shadow={cellShadow()}
                sortByFavoritePerson={sortByFavoritePerson}
              />
              {menuContent.map((item) => (
                <DataHeaderCell
                  key={item.id}
                  cellType={item.cellType}
                  reservationFilters={reservationFilters}
                  setReservationFilters={setReservationFilters}
                  sortByFavoritePerson={sortByFavoritePerson}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              textAlign: "center",
              position: "relative",
            }}
          >
            {filteredReservations && filteredReservations.length > 0 ? (
              filteredReservations.map((groupedReservations, index) => {
                return (
                  <TableRow
                    key={groupedReservations.bookedFor.id}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "shades.greyExtraLight",
                      backgroundColor: "white",
                      "&:nth-of-type(even)": {
                        backgroundColor: "#FBFBFB",
                      },
                      "&:last-child": {
                        border: "none",
                        " > td": {
                          borderBottom: "none",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "green.light",
                      },
                    }}
                  >
                    <DataContentCell
                      cellType={CellType.FULL_NAME}
                      groupedReservations={groupedReservations}
                      shadow={cellShadow()}
                    />
                    {menuContent.map((item) => (
                      <DataContentCell
                        key={item.id}
                        cellType={item.cellType}
                        groupedReservations={groupedReservations}
                      />
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow sx={{ padding: "4px 8px" }}>
                <TableCell colSpan={3}>
                  <AmtAlert
                    title={t("data-preview.alert-title")}
                    text={t("data-preview.not-available")}
                    variant="neutral"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
