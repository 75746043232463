import { Desk, Floor, Location, Space } from "../../../assets/domain";
import { FloorplanElement } from "../../../floor-plan/components/assets/FloorPlanD3Renderer/styles";
import { Booking } from "./Booking";
import { BookingDate } from "./BookingDate";
import { DeskAvailability } from "./DeskAvailability";

export class DeskOverview implements FloorplanElement {
  constructor(
    public readonly desk: Desk,
    public readonly location: Location,
    public readonly floor: Floor,
    public readonly space: Space,
    public readonly bookings: Booking[],
    public readonly availability: DeskAvailability,
    public readonly isDedicated: boolean,
    public readonly isDisabled: boolean,
    public readonly dedicatedFor: string[],
    public readonly date: BookingDate,
    public readonly deskInventories: string[]
  ) {}
}

export class SpaceOverview {
  constructor(
    public readonly space: Space,
    public readonly floor: Floor,
    public readonly location: Location,
    public readonly desks: DeskOverview[]
  ) {}
}

export class DeskBookings {
  constructor(public readonly spaces: SpaceOverview[]) {}

  get desks(): DeskOverview[] {
    return this.spaces.flatMap((space) => space.desks);
  }

  findDeskById(deskId: string): [SpaceOverview, DeskOverview] | null {
    const spaceOverview = this.spaces.find(({ desks }) => {
      return !!desks.find(({ desk }) => desk.id === deskId);
    });
    if (!spaceOverview) {
      return null;
    }
    const deskOverview = spaceOverview.desks.find(
      ({ desk }) => desk.id === deskId
    );
    return deskOverview ? [spaceOverview, deskOverview] : null;
  }
}
