import { DialogContent } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtError } from "../../../../../../api";
import { AmtDialog } from "../../../../../../components";
import { trackCreateInventoryType } from "../../../../../../tracking";
import {
  DialogActions,
  DialogInputSection,
  SimpleDialogTitle,
} from "../../../../../components/Dialogs";
import { FormikTextInput } from "../../../../../components/Formik/TextInput";
import { useCreateInventoryTypeMutation } from "../../../hooks";
import { useInventoryTypeForm } from "../../../state";

export interface CreateInventoryTypeFormValues {
  typeName: string;
}

export const CreateInventoryTypeDialog: React.FC = () => {
  const form = useInventoryTypeForm();
  const { isOpen, closeDialog } = form;
  const createInventoryType = useCreateInventoryTypeMutation();
  const { t } = useTranslation("inventory-management");

  const doSubmit = useMemoizedFn((values: CreateInventoryTypeFormValues) => {
    return createInventoryType.mutateAsync(values);
  });

  const handleSubmit = useMemoizedFn(
    (
      values: CreateInventoryTypeFormValues,
      formik: FormikHelpers<CreateInventoryTypeFormValues>
    ) => {
      trackCreateInventoryType();
      return doSubmit(values).catch((err: AmtError) => {
        formik.setFieldError("bookFor", t("dialog.feedback.create-type-error"));
      });
    }
  );

  const validate = useMemoizedFn(
    async (
      values: CreateInventoryTypeFormValues
    ): Promise<FormikErrors<CreateInventoryTypeFormValues>> => {
      const errors: FormikErrors<CreateInventoryTypeFormValues> = {};

      if (values.typeName === "") {
        errors.typeName = "";
      }
      return errors;
    }
  );

  return (
    <AmtDialog open={isOpen} onClose={closeDialog} closeButton>
      <Formik
        enableReinitialize
        initialValues={{
          typeName: "",
        }}
        validate={validate}
        validateOnMount
        // @ts-ignore
        initialTouched={{ status: true, dedicatedValues: true }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => {
          return (
            <DialogContent>
              <SimpleDialogTitle
                title={t("dialog.create-inventory-type-title")}
              />
              <Form style={{ margin: 0 }}>
                <DialogInputSection label={t("dialog.inventory-type-name")}>
                  <Field
                    name="typeName"
                    component={FormikTextInput}
                    placeholder={t("dialog.inventory-type-name-placeholder")}
                  />
                </DialogInputSection>
                <DialogActions
                  onCancel={closeDialog}
                  isSubmitting={isSubmitting}
                  isInvalid={values.typeName === ""}
                />
              </Form>
            </DialogContent>
          );
        }}
      </Formik>
    </AmtDialog>
  );
};
