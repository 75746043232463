import { Box, styled } from "@mui/material";
import React from "react";

const StyledInputAdornment = styled(Box)<AmtInputAdornmentProps>(
  ({ theme, placement }) => ({
    display: "inline-flex",
    alignItems: "center",
    padding: theme.spacing(1),
    border: "1px solid",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: theme.palette.shades.greyLight,
    backgroundColor: theme.palette.shades.greyUltimateLight,
    color: theme.palette.shades.grey,
    ...(placement === "start" && {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
      borderLeftWidth: 1,
    }),
    ...(placement === "end" && {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRightWidth: 1,
    }),
  })
);

export interface AmtInputAdornmentProps {
  placement?: "start" | "end";
}

export const AmtInputAdornment: React.FC<
  React.PropsWithChildren<AmtInputAdornmentProps>
> = ({ children, placement }) => {
  return (
    <StyledInputAdornment placement={placement}>
      {children}
    </StyledInputAdornment>
  );
};
