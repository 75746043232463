import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useOrganizationMembership } from "../../../../../authentication/hooks";
import { useSubscription } from "../../../../../events/hooks";
import { getBookings } from "../../../../api";
import { BookingDate, Bookings, DeskOverview } from "../../../../domain";
import { useBookingDate } from "../useBookingDate";
import {
  EditDeskSnapshot,
  useBookingEvents,
  useBookingForm,
} from "../useBookingForm";
import { useLocations } from "../useLocations";
import { BookingDispatchContext, BookingStateContext } from "./context";
import { NavigationParams, useBookingNavigation } from "./navigation";
import { canBook } from "./selectors";

function useBookingsQuery(
  date: BookingDate,
  organizationId: string,
  organizationMembershipId: string,
  locationId: string | null
): UseQueryResult<Bookings> {
  return useQuery(
    ["bookings", date, organizationId, locationId],
    () => {
      if (locationId) {
        return getBookings(
          date,
          organizationId,
          organizationMembershipId,
          locationId
        );
      } else {
        return Promise.reject("Location must be loaded");
      }
    },
    {
      enabled: !!locationId,
      refetchOnWindowFocus: false,
      staleTime: 60_000,
    }
  );
}

export const BookingContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { organization } = useOrganizationMembership();
  const organizationMembership = useOrganizationMembership();
  const navigation = useBookingNavigation();
  const form = useBookingForm();
  const events = useBookingEvents();
  const [searchParams, setSearchParams] = useSearchParams();

  const { date } = useBookingDate();
  const { location, locationId } = useLocations();

  // Load desks
  const bookings = useBookingsQuery(
    date,
    organization.id,
    organizationMembership.id,
    locationId
  );

  const onResetFilters = useMemoizedFn(() => {
    if (form.isEditing) {
      if (form.source instanceof EditDeskSnapshot) {
        navigation.onNavigate(
          NavigationParams.fromEmpty()
            .withLocation(form.source.location)
            .withFloor(form.source.floor)
            .withDesk(form.source.desk)
            .withDate(form.source.date)
        );
      } else {
        navigation.onNavigate(
          NavigationParams.fromEmpty()
            .withLocation(form.source.location)
            .withDate(form.source.date)
        );
      }
      return;
    }

    const params = NavigationParams.fromEmpty();
    if (location) {
      params.withLocation(location);
    }
    navigation.onNavigate(params);
  });

  const unselectDesk = useMemoizedFn(() => {
    const updated = new URLSearchParams(searchParams);
    updated.delete("booking");
    updated.delete("desk");
    setSearchParams(updated);
  });

  useSubscription(events.singleBooking$, unselectDesk);
  useSubscription(events.groupBooking$, unselectDesk);
  // form.events.singleBooking$.useSubscription(unselectDesk);
  // form.events.groupBooking$.useSubscription(unselectDesk);

  const isDeskDisabled = useMemoizedFn((desk: DeskOverview): boolean => {
    return form.isEditing && !canBook(desk, form);
  });

  return (
    <BookingStateContext.Provider
      value={{
        bookings,
        isDeskDisabled,
      }}
    >
      <BookingDispatchContext.Provider
        value={{
          ...navigation,
          onResetFilters,
        }}
      >
        {children}
      </BookingDispatchContext.Provider>
    </BookingStateContext.Provider>
  );
};
