import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { UpcomingBooking } from "../../../domain";
import { useBookingCancel } from "../../../hooks";

export type UseCancelBookingResult = (overview: UpcomingBooking) => void;

export function useCancelBooking(): UseCancelBookingResult {
  const { initiate: cancelBooking } = useBookingCancel();
  return useMemoizedFn((upcomingBooking: UpcomingBooking) => {
    cancelBooking(upcomingBooking.booking);
  });
}
