import { Box, LinearProgress, styled, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { useFormikContext } from "formik";
import React from "react";
import { DeskBookingRequest, useBookingForm } from "../../../state";
import { BookFormValues } from "../types";

export interface CapacityWidgetProps {
  total: number;
  reserved: number;
  provisional: number;
  sx?: SxProps<Theme>;
  className?: string;
}

export const CapacityWidgetComponent: React.FC<CapacityWidgetProps> = ({
  total,
  reserved,
  provisional,
  sx,
  className,
}) => {
  const valuePercent = (reserved / total) * 100;
  const valueBufferPercent = (provisional / total) * 100;

  return (
    <Widget className={className} sx={sx}>
      <Typography variant="caption" color="shades.grey">
        Kapacitet sobe:
      </Typography>
      <ProgressBarContainer>
        <Typography variant="body3">{total}</Typography>
        <LinearProgress
          variant="buffer"
          sx={{ flexGrow: 1 }}
          value={valuePercent}
          valueBuffer={valueBufferPercent}
        />
        <Typography variant="body3">{provisional}</Typography>
      </ProgressBarContainer>
    </Widget>
  );
};

export const CapacityWidget: React.FC = () => {
  const { target } = useBookingForm();
  const { isEditing } = useBookingForm();
  const { values } = useFormikContext<BookFormValues>();
  const capacity =
    target instanceof DeskBookingRequest ? target.capacity : null;

  if (isEditing || !capacity) {
    return null;
  }

  return (
    <CapacityWidgetComponent
      total={capacity.total}
      reserved={capacity.reserved}
      provisional={capacity.reserved + values.bookFor.length}
    />
  );
};

const Widget = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: 8,
  height: "42px",
  border: "1px solid",
  borderColor: theme.palette.shades.greyUltimateLight,
  padding: theme.spacing(0.5, 1),
  backgroundColor: "white",

  position: "absolute",
  right: theme.spacing(2),
  top: "-10px",
  width: "110px",

  [theme.breakpoints.down("md")]: {
    right: theme.spacing(1),
  },
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(0.5),
  alignItems: "center",
}));
