import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import {
  useApproveInvitation,
  useApproveInvitationMutation,
  useBookingCancelMutation,
} from "../../hooks";
import { ApproveInvitationDialog } from "./ApproveInvitationDialog";

export const ConnectedApproveInvitationDialog: React.FC = () => {
  const { open, booking, closeDialog } = useApproveInvitation();
  const approveInvitationMutation = useApproveInvitationMutation();
  const rejectInvitationMutation = useBookingCancelMutation();

  const onConfirm = useMemoizedFn(() => {
    if (booking) {
      approveInvitationMutation.mutate(booking, {
        onSuccess: closeDialog,
      });
    }
  });

  const onCancel = useMemoizedFn(() => {
    if (booking) {
      rejectInvitationMutation.mutate(booking, {
        onSuccess: closeDialog,
      });
    }
  });

  return (
    <>
      {booking && (
        <ApproveInvitationDialog
          open={open}
          onClose={closeDialog}
          booking={booking}
          disabled={approveInvitationMutation.isLoading}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </>
  );
};
