import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";

type GalleryFormState = {
  isOpen: boolean;
};

export type UseGalleryFormResult = GalleryFormState & {
  isOpen: boolean;
  openDialog(): void;
  closeDialog(): void;
};

const formStateAtom = atom<GalleryFormState>({
  isOpen: false,
});

export function useGalleryForm(): UseGalleryFormResult {
  const [formState, setFormState] = useAtom(formStateAtom);

  const openDialog = useMemoizedFn(() => {
    setFormState({
      isOpen: true,
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      isOpen: false,
    });
  });

  return {
    ...formState,
    openDialog,
    closeDialog,
  };
}
