import { Floor, Location, Space } from "../../assets/domain";
import { FloorplanElement } from "../../floor-plan/components/assets/FloorPlanD3Renderer/styles";

export class ConferenceRoom implements FloorplanElement {
  constructor(
    public readonly id: string,
    public readonly capacity: number,
    public readonly name: string,
    public readonly space: Space,
    public readonly floor: Floor,
    public readonly location: Location
  ) {}
}
