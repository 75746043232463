import {
  AutocompleteRenderInputParams,
  Button,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../../hooks";

interface SearchInputProps {
  params: AutocompleteRenderInputParams;
  isSearchOpen: boolean;
  setSearchOpen: (isOpen: boolean) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  params,
  isSearchOpen,
  setSearchOpen,
}) => {
  const { t } = useTranslation("analytics");
  const isMobile = useIsMobile();

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flex: "1 1 auto",
        borderColor: "shades.greyLight",
        boxShadow: "none",
        height: "36px",
        backgroundColor: "transparent",
        "&:hover .buttonHover": {
          borderColor: "green.vivid",
        },
      }}
    >
      {(isSearchOpen || !isMobile) && (
        <TextField
          sx={{
            transform: "translateX(16px)",
            transition: "0.2sec all",

            height: "100%",
          }}
          className="MuiInput-search"
          placeholder={t("search.placeholder")}
          {...params}
        />
      )}
      <Button
        variant="icon"
        color={!isSearchOpen && isMobile ? "primary" : "secondary"}
        onClick={() => {
          if (isMobile) setSearchOpen(true);
        }}
        sx={{
          opacity: 1,
          transition: "0.2sec all",
          ...((isSearchOpen || !isMobile) && {
            background: "white",
            borderColor: "inherit",
          }),
        }}
        className="buttonHover"
      >
        <Search size={16} />
      </Button>
    </Paper>
  );
};
