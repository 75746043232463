import { Box, BoxProps, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useCurrentUser } from "../../../../../authentication/hooks";
import { ItemActions } from "../../../../components";
import {
  Booking,
  BookingDate,
  LocationBookingOverview,
} from "../../../../domain";
import { useBookingCancel } from "../../../../hooks";
import {
  EditLocationSnapshot,
  RedirectTo,
  useBookingForm,
  useSelection,
} from "../../state";
import { BookingItemButton, BookingItemContent } from "../BookingItem";
import { LocationBox } from "./LocationBox";

export interface DeskItemButtonProps extends Omit<BoxProps, "onClick"> {
  overview: LocationBookingOverview;
  disabled?: boolean;
  visible?: boolean;
  selected?: boolean;
  onClick?: (overview: LocationBookingOverview) => void;
  sx?: SxProps<Theme>;
}

export const LocationBookingItemComponent: React.FC<DeskItemButtonProps> =
  React.memo(
    ({
      overview,
      selected = false,
      disabled = false,
      onClick,
      sx = [],
    }: React.PropsWithChildren<DeskItemButtonProps>) => {
      const currentUser = useCurrentUser();
      const forCurrentUser = overview.bookedFor.isUser(currentUser);

      const handleOnClick = useMemoizedFn(() => {
        onClick?.(overview);
      });

      const actionableBooking =
        overview.bookings.length === 1 ? overview.bookings[0] : null;

      return (
        <BookingItemButton
          onClick={handleOnClick}
          sx={[
            {
              "&:hover": {
                backgroundColor: "cyclamen.light",
                ".Amt--LocationBookingItem--Box, .Amt--LocationBookingItem--Box > *":
                  {
                    backgroundColor: "cyclamen.regular",
                  },
              },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          data-testid="book--list-item"
          disabled={disabled}
          selected={selected}
        >
          <LocationBox forCurrentUser={forCurrentUser} />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "space-between",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <BookingItemContent
              avatarImageUrl={overview.bookedFor.avatarImageUrlSrc}
              primary={overview.bookedFor.name.full}
              secondary="Ovde je"
            />
            {actionableBooking && (
              <ConnectedItemActions booking={actionableBooking} />
            )}
          </Box>
        </BookingItemButton>
      );
    }
  );

const ConnectedItemActions: React.FC<{ booking: Booking }> = ({ booking }) => {
  const { enterEditMode, isEditing } = useBookingForm();
  const { initiate: initiateCancel } = useBookingCancel();
  const handleEdit = useMemoizedFn(() => {
    enterEditMode(
      new EditLocationSnapshot(
        BookingDate.of(booking.bookingDate),
        booking.location,
        booking,
        RedirectTo.book()
      )
    );
  });
  const handleCancel = useMemoizedFn(() => {
    initiateCancel(booking);
  });
  if (isEditing) {
    return null;
  }
  return (
    <ItemActions
      booking={booking}
      onConfirm={handleEdit}
      onCancel={handleCancel}
    />
  );
};

export interface LocationBookingItemProps {
  booking: LocationBookingOverview;
}

export const LocationBookingItem: React.FC<LocationBookingItemProps> = ({
  booking,
}) => {
  const { isEditing, source } = useBookingForm();
  const disabled =
    isEditing &&
    booking.bookings.filter((it) => it.id === source?.bookingId).length === 0;
  const { selectedLocationBooking, onSelectLocationBooking } = useSelection();

  return (
    <LocationBookingItemComponent
      onClick={onSelectLocationBooking}
      overview={booking}
      selected={
        !!selectedLocationBooking &&
        selectedLocationBooking.bookedFor.isUser(booking.bookedFor)
      }
      disabled={disabled}
    />
  );
};
