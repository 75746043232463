import { Box } from "@mui/material";
import React from "react";
import { DatePicker } from "../../../../../global/components/DatePickers";
import { trackSearchDatePicker } from "../../../../../tracking";

export const SearchHeader: React.FC = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      })}
    >
      <DatePicker trackDateChange={trackSearchDatePicker} />
    </Box>
  );
};
