import { styled, SxProps, Theme } from "@mui/material";
import React from "react";
import { AmtCard } from "../../../../components";
import { Location } from "../../../../modules/assets/domain";
import { LocationWithCapacity } from "../../../domain";
import { LocationInfo } from "./LocationInfo";

interface LocationCardProps {
  locationWithCapacity: LocationWithCapacity;
  onChangeLocation?: (newLocation: Location | null) => void;
  inventoryButton?: boolean;
  sx?: SxProps<Theme>;
}

export const LocationCard: React.FC<LocationCardProps> = ({
  locationWithCapacity,
  onChangeLocation,
  inventoryButton,
  sx,
}) => {
  return (
    <AmtCard
      onClick={() => {
        if (onChangeLocation) onChangeLocation(locationWithCapacity.location);
      }}
      sx={{
        display: "flex",
        flexDirection: "row",
        minHeight: "205px",
        marginBottom: 3,
        ...sx,
      }}
      pop={!!onChangeLocation}
    >
      <LocationImageContainer>
        {locationWithCapacity.location.imageUrl && (
          <LocationImage
            src={locationWithCapacity.location.imageUrl}
            alt=""
            role="presentation"
          />
        )}
      </LocationImageContainer>
      <LocationInfo locationWithCapacity={locationWithCapacity} />
    </AmtCard>
  );
};

const LocationImageContainer = styled("div")(({ theme }) => ({
  overflow: "hidden",
  backgroundColor: theme.palette.shades.greyUltimateLight,
  borderRadius: "8px",
  width: "236px",
}));

const LocationImage = styled("img")({
  objectFit: "cover",
  width: "100%",
  height: "100%",
});
