import { styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDateFns } from "../../../../../hooks";
import { useCurrentUser } from "../../../../authentication/hooks";
import { LocationBookingOverview } from "../../../domain";

export interface BookingTooltipProps {
  booking: LocationBookingOverview;
}

export const BookingTooltip: React.FC<BookingTooltipProps> = ({ booking }) => {
  const { t } = useTranslation("floor-plan");
  const { formatRange } = useDateFns();
  const currentUser = useCurrentUser();

  return (
    <>
      {currentUser.isUser(booking.bookedFor)
        ? t("tooltip.location.booked-for-self")
        : t("tooltip.location.booked-for-other", {
            name: booking.bookedFor.name.full,
          })}
      :
      <br />
      {formatRange(booking.bookingDate)}
      <StyledDivider />
      {t("tooltip.booked-by", { name: booking.bookings[0].bookedBy.name.full })}
    </>
  );
};

const StyledDivider = styled("hr")(({ theme }) => ({
  borderColor: theme.palette.shades.darkLight,
  lineHeight: 0,
  borderTop: 0,
  margin: theme.spacing(0.5, 0),
}));
