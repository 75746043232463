import { Box, ButtonBase, styled } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { List, Map } from "react-feather";
import { MobileDisplayedPage, useMobileDisplayedPage } from "../../state";

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.green.regular,
  borderRadius: "17px",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5),
  boxShadow: "0px 1px 2px rgba(20, 17, 16, 0.2)",
  // position: "fixed",
  // bottom: 10,
  // left: 8,
  zIndex: 3,
}));

const Btn = styled(ButtonBase)(({ theme }) => ({
  color: "white",
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  padding: theme.spacing(0.5, 1.5),
  borderRadius: "32px",

  // @ts-ignore
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightBold,

  '&[aria-selected="true"]': {
    background: "white",
    color: theme.palette.shades.grey,
  },
}));

export const FloatingMobileButton: React.FC = () => {
  const [mobileDisplayedPage, onChangeMobileDisplayedPage] =
    useMobileDisplayedPage();

  const setMapView = useMemoizedFn(() => {
    onChangeMobileDisplayedPage(MobileDisplayedPage.MAPS);
  });
  const setListView = useMemoizedFn(() => {
    onChangeMobileDisplayedPage(MobileDisplayedPage.LIST);
  });

  return (
    <Container>
      <Btn
        onClick={setMapView}
        aria-selected={mobileDisplayedPage === MobileDisplayedPage.MAPS}
      >
        <Map size={16} />
        Mapa
      </Btn>
      <Btn
        onClick={setListView}
        aria-selected={mobileDisplayedPage === MobileDisplayedPage.LIST}
      >
        <List size={16} />
        Lista
      </Btn>
    </Container>
  );
};
