import { Box, BoxProps } from "@mui/material";
import React from "react";

export const AmtMasterBadge: React.FC<BoxProps> = ({ sx, ...props }) => {
  return (
    <Box
      sx={[
        {
          paddingX: 1,
          paddingY: 0.5,
          borderRadius: "4px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "shades.greyLight",
          color: "shades.grey",
          backgroundColor: "shades.greyUltimateLight",
          display: "flex",
          alignItems: "center",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};
