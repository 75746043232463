import { Divider, ListItemText, Menu, MenuItem, Switch } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import { useTranslation } from "react-i18next";
import { DeskInventoryFilters } from "../../../../booking/domain";
import { useSelectedInventoryFilters } from "../../../../booking/pages/BookingPage/state";

interface InventoryFilterMenuProps {
  popupState: PopupState;
  buttonState: string | null;
  inventoryFilters: DeskInventoryFilters[];
}

export const InventoryFilterMenu: React.FC<InventoryFilterMenuProps> = ({
  popupState,
  inventoryFilters,
}) => {
  const { t } = useTranslation("floor-plan");
  const { selectedInventoryFilters, setSelectedInventoryFilters } =
    useSelectedInventoryFilters();

  const allFilterNames = selectedInventoryFilters.flatMap(
    (filter) => filter.name
  );
  const allFilterQuantities = selectedInventoryFilters.flatMap(
    (filter) => filter.quantity
  );

  const handleSwitchChange = (filter: DeskInventoryFilters | null) => {
    if (filter === null) {
      setSelectedInventoryFilters([]);
    } else {
      const updatedFilters =
        allFilterNames.includes(filter.name) &&
        allFilterQuantities.includes(filter.quantity)
          ? selectedInventoryFilters.filter(
              (f) => f.name !== filter.name || f.quantity !== filter.quantity
            )
          : [...selectedInventoryFilters, filter];
      setSelectedInventoryFilters(updatedFilters);
    }
  };

  return (
    <Menu
      disableAutoFocusItem
      {...bindMenu(popupState)}
      PaperProps={{
        style: {
          minWidth: 247,
        },
      }}
    >
      <MenuItem onClick={() => handleSwitchChange(null)}>
        <Switch size="small" checked={selectedInventoryFilters.length === 0} />
        <ListItemText primary={t("inventory-filters.all")} />
      </MenuItem>
      <Divider />
      {inventoryFilters.map((filter) => (
        <MenuItem key={filter.name} onClick={() => handleSwitchChange(filter)}>
          <Switch
            size="small"
            checked={
              allFilterNames.includes(filter.name) &&
              allFilterQuantities.includes(filter.quantity)
            }
          />
          <ListItemText
            primary={` ${filter.name}`}
            secondary={t("inventory-filters.pieces", {
              count: filter.quantity,
            })}
          />
        </MenuItem>
      ))}
    </Menu>
  );
};
