import { Grid } from "@mui/material";
import React from "react";
import { AmtCenteredLoader } from "../../../../../components";
import { Floor, Location } from "../../../../../modules/assets/domain";
import { BackofficeFloorPlan } from "../../../../../modules/floor-plan/components/BackofficeFloorPlan";
import { DeskWithSpace, DeskWithStatus } from "../../../../domain";
import {
  useBackofficeDesks,
  useBackofficeFloors,
  useBackofficeLocations,
  useBackofficeNavigation,
  useBackofficeSelection,
} from "../../../../state";

export interface FloorPlanListProps {
  selectedDesk: DeskWithSpace | null;
  location: Location;
  floor: Floor | null;
  floors: Floor[];
  desksWithStatus: DeskWithStatus[];
  onChangeFloor: (newFloor: Floor | null) => void;
  onSelectDesk: (desk: DeskWithStatus | null) => void;
}

const FloorPlanListComponent: React.FC<FloorPlanListProps> = React.memo(
  ({
    selectedDesk,
    location,
    floor,
    floors,
    desksWithStatus,
    onChangeFloor,
    onSelectDesk,
  }) => {
    const selectedFloor = floors.length === 1 ? floors[0] : floor;

    return (
      <>
        <Grid
          container
          spacing={2}
          sx={(theme) => ({
            minHeight: "100%",
            [theme.breakpoints.down("md")]: {
              ...(selectedFloor === null && {
                paddingBottom: 7,
              }),
            },
          })}
        >
          {!selectedFloor &&
            floors.map((it) => (
              <Grid item xxs={12} xs={12} sm={6} key={it.id}>
                <BackofficeFloorPlan
                  square
                  interactive={false}
                  selectedDesk={selectedDesk}
                  floor={it}
                  desksWithStatus={desksWithStatus}
                  imageUrl={it.floorPlanImageUrl}
                  description={`${it.description} (${location?.address.addressLine1})`}
                  onClick={() => onChangeFloor(it)}
                  onSelectDesk={onSelectDesk}
                />
              </Grid>
            ))}
          {selectedFloor && (
            <Grid item xxs={12} xs={12} key={selectedFloor.id}>
              <BackofficeFloorPlan
                interactive
                selectedDesk={selectedDesk}
                floor={selectedFloor}
                floors={floors}
                desksWithStatus={desksWithStatus}
                imageUrl={selectedFloor.floorPlanImageUrl}
                description={`${selectedFloor.description} (${location?.address.addressLine1})`}
                onSelectDesk={onSelectDesk}
                onChangeFloor={onChangeFloor}
                sx={{
                  height: "100%",
                }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  }
);

export const FloorPlanList: React.FC = () => {
  const { location } = useBackofficeLocations();
  const { floor, floors } = useBackofficeFloors();
  const { desksWithStatus } = useBackofficeDesks();
  const { selectedDesk, onSelectDesk } = useBackofficeSelection();
  const { onChangeFloor } = useBackofficeNavigation();

  if (!location) {
    throw new Error("Location must be loaded");
  }
  if (!desksWithStatus || floors.length === 0) {
    return <AmtCenteredLoader />;
  }

  return (
    <FloorPlanListComponent
      selectedDesk={selectedDesk ? selectedDesk.desk : null}
      location={location.location}
      floor={floor}
      floors={floors}
      desksWithStatus={desksWithStatus}
      onChangeFloor={onChangeFloor}
      onSelectDesk={onSelectDesk}
    />
  );
};
