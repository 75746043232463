import { formatISO } from "date-fns";
import { DeskDto, FloorDto, LocationDto, SpaceDto } from "../../assets/api";
import { UserDto } from "../../authentication/api";
import { NotificationDto } from "../../notifications/api";

export enum BookingStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}

export interface BookingDto {
  id: string;
  assetId: string;
  bookingDate: string;
  bookedFor: UserDto;
  bookedBy: UserDto;
  bookedAt: string;
  status: BookingStatus;
}

export interface UpcomingDeskBookingDto {
  booking: BookingDto;
  location: LocationDto;
  floor?: FloorDto;
  space?: SpaceDto;
  desk?: DeskDto;
  notification: NotificationDto;
}

export interface CapacityDto {
  available: number;
  occupied: number;
  total: number;
  totalLocationBookings?: number;
}

export function formatISODate(date: Date): string {
  return formatISO(date, { representation: "date" });
}
