import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../../../authentication/domain";

export interface GroupBookingHeaderProps {
  isDeskBooking?: boolean;
  users: User[];
  isInvitation?: boolean;
}

export const GroupBookingHeader: React.FC<GroupBookingHeaderProps> = ({
  isDeskBooking,
  users,
  isInvitation,
}) => {
  const { t } = useTranslation("booking-dialog");

  return (
    <Box
      sx={(theme) => ({
        paddingX: 2,
        paddingY: 1.5,
        backgroundColor: "cyclamen.hover",
        color: "white",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        borderRadius: "8px",
        mb: 3,

        [theme.breakpoints.down("md")]: {
          mb: 1,
          padding: 1,
        },
      })}
    >
      <Typography variant="subtitle2">
        {isInvitation
          ? t("confirmation.invite-title.group")
          : isDeskBooking
          ? t("confirmation.booked-desk-title.group")
          : t("confirmation.booked-location-title.group")}
        :
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 0.5,
          flexWrap: "wrap",
        }}
      >
        {users.map((user) => (
          <Chip
            key={user.id}
            variant="outlined"
            label={user.name.full}
            sx={(theme) => ({
              color: "white",
              backgroundColor: "rgba(255,255,255,0.1)",
              borderColor: "rgba(255,255,255,0.6)",
              borderRadius: "16px",
              paddingX: 1,
              paddingY: 0,
              // @ts-ignore
              ...theme.typography.body2,
            })}
          />
        ))}
      </Box>
    </Box>
  );
};
