import { Box, Divider, Typography } from "@mui/material";
import {
  clamp,
  differenceInMinutes,
  endOfDay,
  isSameDay,
  startOfDay,
} from "date-fns";
import React from "react";
import { BoxCenter } from "../../../../../global/components";
import { useDateFns } from "../../../../../hooks";
import { useCurrentUser } from "../../../../authentication/hooks";
import { CalendarEvent } from "../../../domain";

export interface ContentColumnProps {
  day: Date;
  hours: number[];
  allEvents: CalendarEvent[];
}

export const ContentColumn: React.FC<ContentColumnProps> = ({
  day,
  hours,
  allEvents,
}) => {
  const { formatHourRange, format } = useDateFns();
  const currentUser = useCurrentUser();

  const isWithinDays = (targetDate: Date, startDate: Date, endDate: Date) => {
    return (
      isSameDay(targetDate, startDate) ||
      isSameDay(targetDate, endDate) ||
      (targetDate > startDate && targetDate < endDate)
    );
  };

  const minDate = startOfDay(day);
  const rowHeight = 50;
  const calendarFirstHour = 9;
  const interval = { start: startOfDay(day), end: endOfDay(day) };

  const events = allEvents.filter((event) =>
    isWithinDays(day, event.timeSlot.from, event.timeSlot.to)
  );
  const displayInfo = events.map((event) => {
    const start = clamp(event.timeSlot.from, interval);
    if (start.getHours() < calendarFirstHour) {
      start.setHours(calendarFirstHour);
      start.setMinutes(0);
    }
    const end = clamp(event.timeSlot.to, interval);
    const offsetMinutes =
      differenceInMinutes(start, minDate) - calendarFirstHour * 60;
    const height = (differenceInMinutes(end, start) * rowHeight) / 60;
    const offsetY = (offsetMinutes * rowHeight) / 60;
    const isAllDay = height >= (24 - calendarFirstHour) * rowHeight - 1;
    const eventColor =
      currentUser && currentUser.id === event.creator.id
        ? "orange.light"
        : "violet.light";
    return { isAllDay, event, offsetY, height, start, end, eventColor };
  });

  return (
    <BoxCenter
      column
      sx={{
        position: "relative",
      }}
    >
      {hours.map((hour) => {
        return (
          <Box
            sx={{
              height: `${rowHeight}px`,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
            key={hour}
          >
            <Divider sx={{ border: "1px solid shades.grey", width: "100%" }} />
          </Box>
        );
      })}
      {displayInfo.map((eventInfo, index) => {
        if (eventInfo.height <= 0)
          return <React.Fragment key={index}></React.Fragment>;
        return (
          <Box
            sx={{
              position: "absolute",
              top: `${eventInfo.offsetY + rowHeight / 2}px`,
              left: 0,
              height: eventInfo.isAllDay ? "100%" : `${eventInfo.height}px`,
              width: eventInfo.isAllDay ? "100%" : "90%",
              backgroundColor: eventInfo.eventColor,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 8px",
              zIndex: "10",
              borderRadius: "4px",
            }}
            key={index}
          >
            <Typography
              variant="body3"
              sx={{
                textOverflow: "ellipsis",
                maxWidth: "50%",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {eventInfo.event.creator.name.full}
            </Typography>
            {eventInfo.isAllDay ? (
              <>
                <Typography variant="overline" sx={{ color: "#6d6b6b" }}>
                  {format(day, "dd.MM.yyyy.")}
                </Typography>
              </>
            ) : (
              <Typography variant="overline" sx={{ color: "#6d6b6b" }}>
                {formatHourRange(eventInfo.start, eventInfo.end)}
              </Typography>
            )}
          </Box>
        );
      })}
    </BoxCenter>
  );
};
