import { Autocomplete } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import qs from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { Home, User, Users } from "react-feather";
import { useNavigate } from "react-router";
import { SearchInput, SearchMenu } from ".";
import { MemberMenuItemContent } from "../../../../global/components";
import { useIsMobile } from "../../../../hooks";
import { trackOptionSelect } from "../../../../tracking/search";
import { SearchBookingParam } from "../../api";
import { useSearchParamsQuery } from "../../hooks/useSearchParamsQuery";
import { SearchTab, useSearchState } from "../../state";
import "./styles.css";

interface SearchButtonProps {
  isSearchOpen: boolean;
  setSearchOpen: (isOpen: boolean) => void;
}

export const SearchButton: React.FC<SearchButtonProps> = ({
  isSearchOpen,
  setSearchOpen,
}) => {
  const { activeTab, getFilteredOptions, updateSearchParams } =
    useSearchState();
  const { data: searchBookingParams } = useSearchParamsQuery();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<{
    [x: string]: SearchBookingParam[];
  }>(Object.fromEntries(Object.values(SearchTab).map((tab) => [tab, []])));

  const filter = useMemoizedFn((inputValue: string) => {
    return getFilteredOptions(inputValue);
  });

  useEffect(() => {
    if (searchBookingParams)
      setFilteredOptions(
        Object.fromEntries(
          Object.values(SearchTab).map((tab) => [
            tab,
            searchBookingParams?.[tab] ?? [],
          ])
        )
      );
  }, [searchBookingParams]);

  useEffect(() => {
    setFilteredOptions(filter(inputValue));
  }, [inputValue, filter]);

  const handleOptionChange = (
    event: React.ChangeEvent<{}>,
    option: string | SearchBookingParam | null
  ) => {
    if (option && typeof option !== "string") {
      const updatedQueryParams = updateSearchParams(option);
      const queryString = qs.stringify(updatedQueryParams);

      trackOptionSelect();
      navigate(`/search-people?${queryString}`);
    }
  };

  const handleInputChange = (event: any) => {
    if (event) {
      setInputValue(event.target.value);
    }
  };

  const menuItemIcon = useMemo(() => {
    if (activeTab === SearchTab.USER || activeTab === SearchTab.FOLLOWING)
      return <User size={16} />;
    else if (activeTab === SearchTab.TEAM) return <Users size={16} />;
    else return <Home size={16} />;
  }, [activeTab]);

  return (
    <>
      <Autocomplete
        options={filteredOptions[activeTab]}
        freeSolo
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        sx={{
          paddingRight: "0px",
          width: isMobile ? "100%" : "280px",
          "&.MuiInputBase-root": {
            paddingRight: "0px",
          },
        }}
        value={""}
        inputValue={inputValue === "" || inputValue ? inputValue : ""}
        disableClearable
        PaperComponent={(props) => {
          return (
            <SearchMenu
              children={props.children}
              filteredOptions={filteredOptions}
            />
          );
        }}
        onChange={handleOptionChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <SearchInput
            params={params}
            isSearchOpen={isSearchOpen}
            setSearchOpen={setSearchOpen}
          />
        )}
        renderOption={(props, option) => (
          <MemberMenuItemContent
            elementProps={props}
            name={option.name}
            icon={menuItemIcon}
          />
        )}
      />
    </>
  );
};
