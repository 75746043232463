import React, { UIEventHandler, useCallback, useState } from "react";

export function useScroll<T extends HTMLElement>(): [
  { scrollLeft: number; scrollTop: number },
  { onScroll: UIEventHandler<T> }
] {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const onScroll = useCallback(
    (event: React.UIEvent<T> | Event) => {
      // @ts-ignore
      setScrollTop(event.target.scrollTop ?? 0);
      // @ts-ignore
      setScrollLeft(event.target.scrollLeft ?? 0);
    },
    [setScrollTop, setScrollLeft]
  );
  return [{ scrollTop, scrollLeft }, { onScroll }];
}
