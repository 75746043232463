import { Button } from "@mui/material";
import React from "react";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { useMarkAllAsRead } from "../hooks";

export interface MarkAllAsReadButtonProps {
  onClose: () => void;
}

export const MarkAllAsReadButton: React.FC<MarkAllAsReadButtonProps> = ({
  onClose,
}) => {
  const { t } = useTranslation("notifications");
  const { mutate } = useMarkAllAsRead();
  return (
    <Button
      component="li"
      onClick={() => {
        mutate();
        onClose();
      }}
      fullWidth
      startIcon={<Check size={16} />}
      variant="outlined"
      color="secondary"
      sx={{
        mb: 1,
      }}
    >
      {t("mark-all-as-read")}
    </Button>
  );
};
