export const PinBackoffice = `
  <g class="hover-shadow">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z"
    />
  </g>
  <path
    class="pin-path"
    stroke-width="4"
    stroke-linecap="round"
    stroke="transparent" 
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="transparent"
    d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z"
  />
  <g class="icon--dedicated">
    <rect x="32" y="3" width="8" height="8" rx="4" fill="#FFA500"/>
    <rect x="32" y="3" width="8" height="8" rx="4" stroke="white" stroke-width="2"/>
  </g>
  <g class="hover-shadow hover-rotate">
    <rect x="4.5" y="4.5" width="31" height="31" rx="15.5" fill="white"/>
    <path d="M20 20.6667C20.3682 20.6667 20.6667 20.3682 20.6667 20C20.6667 19.6318 20.3682 19.3333 20 19.3333C19.6319 19.3333 19.3334 19.6318 19.3334 20C19.3334 20.3682 19.6319 20.6667 20 20.6667Z" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M20 20.6667C20.3682 20.6667 20.6667 20.3682 20.6667 20C20.6667 19.6318 20.3682 19.3333 20 19.3333C19.6319 19.3333 19.3334 19.6318 19.3334 20C19.3334 20.3682 19.6319 20.6667 20 20.6667Z" stroke="white" stroke-opacity="0.04" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M24.6667 20.6667C25.0349 20.6667 25.3333 20.3682 25.3333 20C25.3333 19.6318 25.0349 19.3333 24.6667 19.3333C24.2985 19.3333 24 19.6318 24 20C24 20.3682 24.2985 20.6667 24.6667 20.6667Z" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M24.6667 20.6667C25.0349 20.6667 25.3333 20.3682 25.3333 20C25.3333 19.6318 25.0349 19.3333 24.6667 19.3333C24.2985 19.3333 24 19.6318 24 20C24 20.3682 24.2985 20.6667 24.6667 20.6667Z" stroke="white" stroke-opacity="0.04" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.3333 20.6667C15.7015 20.6667 16 20.3682 16 20C16 19.6318 15.7015 19.3333 15.3333 19.3333C14.9651 19.3333 14.6666 19.6318 14.6666 20C14.6666 20.3682 14.9651 20.6667 15.3333 20.6667Z" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.3333 20.6667C15.7015 20.6667 16 20.3682 16 20C16 19.6318 15.7015 19.3333 15.3333 19.3333C14.9651 19.3333 14.6666 19.6318 14.6666 20C14.6666 20.3682 14.9651 20.6667 15.3333 20.6667Z" stroke="white" stroke-opacity="0.04" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
  </g>
`;
