import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "../../../components";
import { User } from "../../authentication/domain";

interface ContainerProps {
  variant?: "cyclamen" | "orange" | "default";
}

export interface BookedForUserHeaderProps extends ContainerProps {
  isDeskBooking?: boolean;
  success?: boolean;
  isInvitation?: boolean;
  user: User;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 56,
  height: 56,
  flexShrink: 0,
  flexGrow: 0,
  border: "2px solid white",
  [theme.breakpoints.down("md")]: {
    width: 36,
    height: 36,
  },
}));

const StyledContainer = styled(Box)<ContainerProps>(({ theme, variant }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  ...(variant === "default" && {
    backgroundColor: theme.palette.shades.greyUltimateLight,
  }),
  ...(variant === "cyclamen" && {
    backgroundColor: theme.palette.cyclamen.hover,
  }),
  ...(variant === "orange" && {
    backgroundColor: theme.palette.orange.regular,
  }),
}));

export const BookedForUserHeader: React.FC<BookedForUserHeaderProps> = ({
  variant = "default",
  success,
  isInvitation,
  isDeskBooking,
  user,
}) => {
  const { t } = useTranslation("booking-dialog");

  return (
    <StyledContainer variant={variant} data-testid="dialog--user">
      <StyledAvatar src={user.avatarImageUrlSrc} />
      <Box sx={{ ml: 2, flexGrow: 1, lineHeight: 0 }}>
        <Typography
          variant="caption"
          sx={{
            color: "shades.grey",
            ...(variant !== "default" && {
              color: "shades.greyUltimateLight",
            }),
          }}
        >
          {isInvitation
            ? t("confirmation.invite-title.single")
            : !success
            ? t("booked-for")
            : isDeskBooking
            ? t("confirmation.booked-desk-title.single")
            : t("confirmation.booked-location-title.single")}
          :
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            color: "shades.dark",
            ...(variant !== "default" && {
              color: "white",
            }),
          }}
        >
          {user.name.full}
        </Typography>
      </Box>
    </StyledContainer>
  );
};
