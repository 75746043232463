import { Box, styled, Typography } from "@mui/material";
import { addBusinessDays, formatISO } from "date-fns";
import qs from "query-string";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useMatch, useSearchParams } from "react-router-dom";
import { useLastUrlPart } from "../../../hooks";
import { useBackofficePermissions } from "../../../modules/permissions/hooks/useBackofficePermissions";

interface DataMenuItemProps {
  content: string;
  icon: React.ReactNode;
  menuOpen: boolean;
}

export const DataMenuItem: React.FC<DataMenuItemProps> = ({
  content,
  icon,
  menuOpen,
}) => {
  const { isAdmin } = useBackofficePermissions();
  const { t } = useTranslation("common");
  const [searchParams] = useSearchParams();
  const lastUrlPart = useLastUrlPart();

  // To improve with enums: should not match with strings
  const analytics = content === "analytics";
  const isSearchPage = lastUrlPart === "search-people";

  const startDateParam = searchParams.get("date");
  const endDateParam = searchParams.get("date-end");
  const startDate = startDateParam ? new Date(startDateParam) : new Date();
  const endDate = endDateParam
    ? new Date(endDateParam)
    : addBusinessDays(new Date(), 9);

  const queryString = analytics
    ? qs.stringify({
        date: formatISO(startDate, { representation: "date" }),
        "date-end": formatISO(endDate, {
          representation: "date",
        }),
      })
    : "";

  const to = isAdmin
    ? `/backoffice/${content}${isSearchPage ? "?" + queryString : ""}`
    : `/search-people`;
  const match = useMatch(to);
  const active = isAdmin && isSearchPage ? analytics : match;

  return (
    <StyledButton active={!!active && menuOpen} to={to}>
      <IconContainer active={!!active} menuOpen={menuOpen}>
        {icon}
      </IconContainer>
      {menuOpen && (
        <Typography variant="body1">
          {t(`components.sidebars.${content}`)}
        </Typography>
      )}
    </StyledButton>
  );
};

const StyledButton = styled(NavLink)<{ active: boolean }>(
  ({ theme, active }) => ({
    width: "100%",
    minWidth: 0,
    margin: "4px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    textDecoration: "none",
    padding: 0,
    gap: 0,
    color: theme.palette.shades.dark,
    cursor: "pointer",
    background: active ? "white" : "inherit",
    borderTopLeftRadius: "24px",
    borderBottomLeftRadius: "24px",
    borderTop: active
      ? `1px solid ${theme.palette.shades.greyLight}`
      : "1px solid transparent",
    borderBottom: active
      ? `1px solid ${theme.palette.shades.greyLight}`
      : "1px solid transparent",
  })
);

const IconContainer = styled(Box)<{ active: boolean; menuOpen: boolean }>(
  ({ theme, active, menuOpen }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: active
      ? theme.palette.shades.dark
      : theme.palette.shades.greyLight,
    background: active ? theme.palette.shades.dark : "white",
    color: !active ? theme.palette.shades.dark : "white",
    width: "36px",
    height: "36px",
    marginRight: menuOpen ? theme.spacing(1) : 0,
    ...theme.typography.body2,
  })
);
