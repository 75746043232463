/**
 * @param {SVGGraphicsElement} element - Element to get the bounding box for
 * @param {boolean} [withoutTransforms=false] - If true, transforms will not be calculated
 * @param {SVGGraphicsElement} [toElement] - Element to calculate bounding box relative to
 * @returns {SVGRect} Coordinates and dimensions of the real bounding box
 */
export function getBBox(
  element: SVGGraphicsElement,
  withoutTransforms: boolean = false,
  toElement: SVGGraphicsElement | null = null
): DOMRect {
  const svg = element.ownerSVGElement;

  if (!svg) {
    return new DOMRect(0, 0, 0, 0);
  }

  const r = element.getBBox();

  if (withoutTransforms) {
    return new DOMRect(r.x, r.y, r.width, r.height);
  }

  const p = svg.createSVGPoint();

  const matrix = (toElement ?? svg)
    .getScreenCTM()!!
    .inverse()
    .multiply(element.getScreenCTM()!!);

  p.x = r.x;
  p.y = r.y;
  const a = p.matrixTransform(matrix);

  p.x = r.x + r.width;
  p.y = r.y;
  const b = p.matrixTransform(matrix);

  p.x = r.x + r.width;
  p.y = r.y + r.height;
  const c = p.matrixTransform(matrix);

  p.x = r.x;
  p.y = r.y + r.height;
  const d = p.matrixTransform(matrix);

  const minX = Math.min(a.x, b.x, c.x, d.x);
  const maxX = Math.max(a.x, b.x, c.x, d.x);
  const minY = Math.min(a.y, b.y, c.y, d.y);
  const maxY = Math.max(a.y, b.y, c.y, d.y);

  const width = maxX - minX;
  const height = maxY - minY;

  return new DOMRect(minX, minY, width, height);
}
