import { Box, Typography } from "@mui/material";
import { SelfServiceSettingsFlow } from "@ory/kratos-client";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { isAccountSettingsVisible } from "../../../../config";
import { api, getRedirectBrowserTo, isFlowInstance } from "../../api";
import { UiForm, UiMessages } from "../../components";
import { FullPageLoader } from "../../layout";
import { ChangeLanguageForm } from "./ChangeLanguageForm";

function isNotEmpty(str: string | null | undefined): str is string {
  return !!str && str.length > 0;
}

export const AccountSettingsPage: React.FC = () => {
  if (!isAccountSettingsVisible()) {
    return <Navigate to="/settings/notifications" />;
  }
  return <AccountSettingsPageComponent />;
};

const AccountSettingsPageComponent: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [flow, setFlow] = useState<SelfServiceSettingsFlow | null>(null);
  const [loading, setLoading] = useState(false);
  const flowId = searchParams.get("flow");

  useEffect(() => {
    if (loading || flow) {
      return;
    }

    function initiateSettingsFlow(): Promise<SelfServiceSettingsFlow> {
      return api
        .initializeSelfServiceSettingsFlowForBrowsers()
        .then((response) => response.data);
    }

    setLoading(true);
    let promise: Promise<SelfServiceSettingsFlow>;
    if (isNotEmpty(flowId)) {
      promise = api
        .getSelfServiceSettingsFlow(flowId)
        .then((response) => response.data, initiateSettingsFlow);
    } else {
      promise = initiateSettingsFlow();
    }
    promise
      .then((loadedFlow) => {
        setFlow(loadedFlow);
        setLoading(false);
        navigate(`/settings/account?flow=${loadedFlow.id}`);
      })
      .catch((err) => {
        // TODO: show error
        setLoading(false);
      });
  }, [loading, setLoading, flowId, flow, setFlow, navigate]);

  function handleSubmit(values: any): Promise<void> {
    return api
      .submitSelfServiceSettingsFlow(flow!.id, undefined, values)
      .then((response) => {
        setFlow(response.data);
      })
      .catch((err) => {
        const response = err.response?.data;
        const redirectUrl = getRedirectBrowserTo(err?.response.data);
        if (redirectUrl) {
          document.location = redirectUrl;
        } else if (isFlowInstance<SelfServiceSettingsFlow>(response)) {
          setFlow(response);
        }
      });
  }

  if (loading || !flow) {
    return <FullPageLoader />;
  }

  return (
    <Box>
      {flow.ui.messages && <UiMessages messages={flow.ui.messages} />}
      <UiForm handleSubmit={handleSubmit} ui={flow.ui} groups={["password"]}>
        <h2>Change password</h2>
      </UiForm>

      <UiForm handleSubmit={handleSubmit} ui={flow.ui} groups={["oidc"]}>
        <h2>External login</h2>
      </UiForm>

      <UiForm handleSubmit={handleSubmit} ui={flow.ui} groups={["totp"]}>
        <h2>Multi-Factor Authentication</h2>
        <Typography>
          Add a TOTP Authenticator App to your account to improve your account
          security. Popular Authenticator Apps are{" "}
          <a href="https://www.lastpass.com" target="_blank" rel="noreferrer">
            LastPass
          </a>{" "}
          and Google Authenticator (
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
            rel="noreferrer"
          >
            iOS
          </a>
          ,{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            target="_blank"
            rel="noreferrer"
          >
            Android
          </a>
          ).
        </Typography>
      </UiForm>

      <UiForm
        handleSubmit={handleSubmit}
        ui={flow.ui}
        groups={["lookup_secret"]}
      >
        <h2>Manage 2FA Backup Recovery Codes</h2>

        <Typography>
          Recovery codes can be used in panic situations where you have lost
          access to your 2FA device.
        </Typography>
      </UiForm>

      <ChangeLanguageForm />
    </Box>
  );
};
