import {
  Box,
  Button,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import useCreation from "ahooks/es/useCreation";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { ChangeEvent } from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  AmtDefaultBadge,
  AmtDefaultBadgeProps,
} from "../../../../../../components";
import { trackBookingAvailabilityFilter } from "../../../../../../tracking";
import {
  BookingDate,
  DeskAvailability,
  DeskBookings,
  DeskOverview,
} from "../../../../domain";
import {
  AvailabilityFilter,
  AvailabilityFilters,
  selectSortedLocationBookings,
  useBookingDate,
  useBookingFilters,
  useBookingState,
} from "../../state";

export interface StatusFilterProps {
  onClose?: () => void;
}

const StyledListMenuItem = styled(MenuItem)({
  paddingRight: 0,
});

const StyledMenuItem = styled(Box)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(1)})`,
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  alignItems: "center",

  // @ts-ignore
  ...theme.typography.body2,
  fontWeight: "inherit",
}));

interface AvailabilitySelectFieldProps {
  date: BookingDate;
  availability: AvailabilityFilter;
  onChange: (filter: AvailabilityFilter) => void;
  fullCapacity: number;
  availableCapacity: number;
  bookedCapacity: number;
  partiallyAvailableCapacity: number;
  notAvailableCapacity: number;
  onlyLocationBookingsCount: number;
}

interface FilterItem {
  translation: string;
  value: AvailabilityFilter;
  capacity: keyof Omit<
    AvailabilitySelectFieldProps,
    "date" | "availability" | "onChange"
  >;
  color: AmtDefaultBadgeProps["color"];
}

function getFilterItems(): FilterItem[] {
  return [
    {
      translation: "full-capacity",
      value: AvailabilityFilters.ANY,
      capacity: "fullCapacity",
      color: "default",
    },
    {
      translation: "only-available",
      value: AvailabilityFilters.ONLY_AVAILABLE,
      capacity: "availableCapacity",
      color: "primary",
    },
    {
      translation: "only-booked",
      value: AvailabilityFilters.ONLY_BOOKED,
      capacity: "bookedCapacity",
      color: "secondary",
    },
    {
      translation: "partially-available",
      value: AvailabilityFilters.PARTIALLY_AVAILABLE,
      capacity: "partiallyAvailableCapacity",
      color: "orange",
    },
    {
      translation: "not-available",
      value: AvailabilityFilters.NOT_AVAILABLE,
      capacity: "notAvailableCapacity",
      color: "grey",
    },
    {
      translation: "only-location-bookings",
      value: AvailabilityFilters.ONLY_LOCATION_BOOKINGS,
      capacity: "onlyLocationBookingsCount",
      color: "cyclamenlight",
    },
  ];
}

const AvailabilitySelectField: React.FC<AvailabilitySelectFieldProps> = ({
  date,
  availability,
  onChange,
  ...props
}) => {
  const { t } = useTranslation("booking", {
    keyPrefix: "filter.availability.items",
  });

  const items = useCreation(getFilterItems, []);

  const handleChange = useMemoizedFn((e: ChangeEvent<HTMLInputElement>) => {
    onChange(AvailabilityFilters.valueOf(e.target.value));
  });

  return (
    <TextField
      fullWidth
      select
      data-testid="book--filters--availability-input"
      value={(availability ?? "all").toString()}
      onChange={handleChange}
    >
      {items
        .filter((it) => it.value.isApplicableTo(date))
        .map((item) => (
          <StyledListMenuItem
            key={item.value.toString()}
            value={item.value.toString()}
          >
            <StyledMenuItem>
              {t(item.translation)}
              <AmtDefaultBadge color={item.color}>
                {props[item.capacity]}
              </AmtDefaultBadge>
            </StyledMenuItem>
          </StyledListMenuItem>
        ))}
    </TextField>
  );
};

export const StatusFilter: React.FC<StatusFilterProps> = ({ onClose }) => {
  const { date } = useBookingDate();
  const state = useBookingState();
  const [{ availability }, { changeAvailabilityFilter }] = useBookingFilters();
  const { t } = useTranslation("booking", {
    keyPrefix: "filter.availability",
  });

  const { bookings } = state;
  const fullCapacity = bookings.data
    ? getFullCapacity(bookings.data.deskBookings)
    : 0;
  const availableCapacity = bookings.data
    ? getAvailableCapacity(bookings.data.deskBookings)
    : 0;
  const bookedCapacity = bookings.data
    ? getBookedCapacity(bookings.data.deskBookings)
    : 0;
  const partiallyAvailableCapacity = bookings.data
    ? getPartiallyAvailableCapacity(bookings.data.deskBookings)
    : 0;
  const notAvailableCapacity = bookings.data
    ? getNotAvailableCapacity(bookings.data.deskBookings)
    : 0;
  const onlyLocationBookingsCount = selectSortedLocationBookings(state).length;

  const handleChangeAvailabilityFilter = useMemoizedFn(
    (filter: AvailabilityFilter) => {
      trackBookingAvailabilityFilter(filter);
      changeAvailabilityFilter(filter);
    }
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Typography variant="caption">{t("label")}</Typography>
      <Box flexGrow={1}>
        <AvailabilitySelectField
          date={date}
          availability={availability}
          onChange={handleChangeAvailabilityFilter}
          fullCapacity={fullCapacity}
          availableCapacity={availableCapacity}
          bookedCapacity={bookedCapacity}
          partiallyAvailableCapacity={partiallyAvailableCapacity}
          notAvailableCapacity={notAvailableCapacity}
          onlyLocationBookingsCount={onlyLocationBookingsCount}
        />
      </Box>
      <Button
        variant="icon"
        color="secondary"
        onClick={onClose}
        data-testid="book--filters--close"
      >
        <X size={16} />
      </Button>
    </Box>
  );
};

function getFullCapacity(overview: DeskBookings) {
  return overview.spaces.reduce((acc, space) => {
    return acc + space.desks.length;
  }, 0);
}

function getAvailableCapacity(overview: DeskBookings) {
  return sumIf(overview, (desk) => desk.availability.canBook);
}

function getBookedCapacity(overview: DeskBookings) {
  return sumIf(overview, (desk) => !desk.availability.canBook);
}

function getPartiallyAvailableCapacity(overview: DeskBookings) {
  return sumIf(
    overview,
    (desk) => desk.availability === DeskAvailability.PARTIALLY_AVAILABLE
  );
}

function getNotAvailableCapacity(overview: DeskBookings) {
  return sumIf(
    overview,
    (desk) => desk.availability === DeskAvailability.NOT_AVAILABLE
  );
}

function sumIf(
  overview: DeskBookings,
  fn: (desk: DeskOverview) => boolean
): number {
  return overview.spaces.reduce((acc, space) => {
    return (
      acc +
      space.desks.reduce((acc, desk) => {
        return acc + (fn(desk) ? 1 : 0);
      }, 0)
    );
  }, 0);
}
