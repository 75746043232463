import { Box, BoxProps, styled } from "@mui/material";

export const StyledCardHeader: React.FC<BoxProps> = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
    padding: theme.spacing(4),
    borderColor: "rgb(229, 228, 228)",
    borderWidth: "1px",
    borderRadius: "6px",
    borderStyle: "solid",
    // alignSelf: "flex-end"
  })
);
