import { useAssetForm } from "../../../state";
import { DeskManagementDialog } from "./DeskManagementDialog";

export const ConnectedDeskManagemenetDialog: React.FC = () => {
  const form = useAssetForm();

  if (!form.desk) {
    return null;
  }

  return <DeskManagementDialog />;
};
