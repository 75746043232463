import { getJson, request } from "../../../../../api";
import { NotificationSetting } from "../domain";

interface NotificationSettingsResponse {
  bookingConfirmationBySelfEnabled: boolean;
  bookingConfirmationByOthersEnabled: boolean;
  bookingConfirmationByFolloweeEnabled: boolean;
  bookingCancellationEnabled: boolean;
  bookingInvitationConfirmationByInviteeEnabled: boolean;
  bookingInvitationCancellationByInviteeEnabled: boolean;
  bookingInvitationConfirmationBySelfEnabled: boolean;
  bookingInvitationCancellationBySelfEnabled: boolean;
  allBookingByOthersEnabled: boolean;
  allBookingInvitationByInviteeEnabled: boolean;
}

interface NotificationSettingsChangeRequest {
  bookingConfirmationBySelfEnabled?: boolean;
  bookingConfirmationByOthersEnabled?: boolean;
  bookingConfirmationByFolloweeEnabled?: boolean;
  bookingCancellationEnabled?: boolean;
  bookingInvitationConfirmationByInviteeEnabled?: boolean;
  bookingInvitationCancellationByInviteeEnabled?: boolean;
  bookingInvitationConfirmationBySelfEnabled?: boolean;
  bookingInvitationCancellationBySelfEnabled?: boolean;
  allBookingByOthersEnabled?: boolean;
  allBookingInvitationByInviteeEnabled?: boolean;
}

export async function getNotificationSettings(
  userId: string
): Promise<NotificationSetting[]> {
  const notifications = await getJson<NotificationSettingsResponse>(
    `/api/users/${userId}/notification-preferences`
  );

  return [
    NotificationSetting.bookingConfirmationBySelf(
      notifications.bookingConfirmationBySelfEnabled
    ),
    NotificationSetting.allBookingsByOthers(
      notifications.allBookingByOthersEnabled
    ),
    NotificationSetting.bookingConfirmationByOthers(
      notifications.bookingConfirmationByOthersEnabled
    ),
    NotificationSetting.bookingInvitationConfirmationBySelf(
      notifications.bookingInvitationConfirmationBySelfEnabled
    ),
    NotificationSetting.bookingInvitationCancellationBySelf(
      notifications.bookingInvitationCancellationBySelfEnabled
    ),
    NotificationSetting.myBookingInvitations(
      notifications.allBookingInvitationByInviteeEnabled
    ),
    NotificationSetting.bookingInvitationConfirmationByInvitee(
      notifications.bookingInvitationConfirmationByInviteeEnabled
    ),
    NotificationSetting.bookingInvitationCancellationByInvitee(
      notifications.bookingInvitationCancellationByInviteeEnabled
    ),
    NotificationSetting.followingBooking(
      notifications.bookingConfirmationByFolloweeEnabled
    ),
    NotificationSetting.bookingCancellation(
      notifications.bookingCancellationEnabled
    ),
  ];
}

export async function changeNotificationSettings(
  userId: string,
  input: NotificationSettingsChangeRequest
): Promise<NotificationSetting[]> {
  const notifications = await request<NotificationSettingsResponse>(
    "PATCH",
    `/api/users/${userId}/notification-preferences`,
    input
  );

  return [
    NotificationSetting.bookingConfirmationBySelf(
      notifications.bookingConfirmationBySelfEnabled
    ),
    NotificationSetting.allBookingsByOthers(
      notifications.allBookingByOthersEnabled
    ),
    NotificationSetting.bookingConfirmationByOthers(
      notifications.bookingConfirmationByOthersEnabled
    ),
    NotificationSetting.bookingInvitationConfirmationBySelf(
      notifications.bookingInvitationConfirmationBySelfEnabled
    ),
    NotificationSetting.bookingInvitationCancellationBySelf(
      notifications.bookingInvitationCancellationBySelfEnabled
    ),
    NotificationSetting.myBookingInvitations(
      notifications.allBookingInvitationByInviteeEnabled
    ),
    NotificationSetting.bookingInvitationConfirmationByInvitee(
      notifications.bookingInvitationConfirmationByInviteeEnabled
    ),
    NotificationSetting.bookingInvitationCancellationByInvitee(
      notifications.bookingInvitationCancellationByInviteeEnabled
    ),
    NotificationSetting.followingBooking(
      notifications.bookingConfirmationByFolloweeEnabled
    ),
    NotificationSetting.bookingCancellation(
      notifications.bookingCancellationEnabled
    ),
  ];
}
