import { Box, DialogContent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtAlert, AmtDialog } from "../../../../components";
import { MuiSlideUpAnimation } from "../../../../theme/components/MuiSlideUpAnimation";
import { useGalleryForm, useGallerySelection } from "../../state";
import { DialogTitle, GalleryContent } from "./components";
import { DialogInfo } from "./components/DialogInfo";

export const GalleryDialog: React.FC = () => {
  const form = useGalleryForm();
  const { isOpen, closeDialog } = form;
  const { selectedSpaceWithImages, selectedFloor, selectedLocation } =
    useGallerySelection();
  const { t } = useTranslation("gallery");

  return (
    <>
      <AmtDialog
        open={isOpen}
        onClose={closeDialog}
        closeButton
        fullScreen
        TransitionComponent={MuiSlideUpAnimation}
      >
        <DialogContent
          sx={(theme) => ({
            width: "70%",
            margin: "auto",
            overflow: "hidden",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          })}
        >
          <DialogTitle />

          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "20px",
              },
            })}
          >
            <DialogInfo
              sx={(theme) => ({
                marginY: 3,
                [theme.breakpoints.down("md")]: {
                  marginY: 1,
                },
              })}
              spaceWithImages={selectedSpaceWithImages}
              floor={selectedFloor}
              location={selectedLocation}
            />
          </Box>
          {selectedSpaceWithImages &&
          selectedSpaceWithImages.images.length > 0 ? (
            <GalleryContent spaceImages={selectedSpaceWithImages.images} />
          ) : (
            <AmtAlert
              title={t("alert-title")}
              text={t("not-available")}
              variant="neutral"
            />
          )}
        </DialogContent>
      </AmtDialog>
    </>
  );
};
