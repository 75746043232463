import { getJson } from "../../../../../api";
import { mapUser, UserDto } from "../../../../authentication/api";
import { GoogleCalendarIntegrationSettings } from "../domain";

interface GoogleCalendarIntegrationSettingsDto {
  enabled: boolean;
  connectedUser: UserDto | null;
}

export function mapGoogleCalendarIntegrationSettings(
  dto: GoogleCalendarIntegrationSettingsDto
): GoogleCalendarIntegrationSettings {
  return new GoogleCalendarIntegrationSettings(
    dto.enabled,
    dto.connectedUser ? mapUser(dto.connectedUser) : null
  );
}

export async function getGoogleCalendarIntegrationSettings(
  organizationId: string
): Promise<GoogleCalendarIntegrationSettings> {
  const response = await getJson<GoogleCalendarIntegrationSettingsDto>(
    `/api/integrations/google-calendar/${organizationId}`
  );
  return mapGoogleCalendarIntegrationSettings(response);
}
