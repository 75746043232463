import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSearchParams } from "react-router-dom";
import { Floor, Location } from "../../modules/assets/domain";
import { AssetInventory } from "../../modules/booking/domain";

export interface BackofficeNavigation {
  onChangeFloor(floor: Floor | null): void;
  onChangeLocation(location: Location | null): void;
  onChangeInventory(inventory: AssetInventory | null): void;
}

export function useBackofficeNavigation(): BackofficeNavigation {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeFloor = useMemoizedFn((newFloor: Floor | null): void => {
    const updated = new URLSearchParams(searchParams);
    if (newFloor) {
      updated.set("location", newFloor.locationId);
      updated.set("floor", newFloor.id);
    } else {
      updated.delete("floor");
    }
    updated.delete("room");
    updated.delete("desk");
    updated.delete("booking");
    setSearchParams(updated);
  });

  const onChangeLocation = useMemoizedFn(
    (newLocation: Location | null): void => {
      const updated = new URLSearchParams(searchParams);
      if (!newLocation) {
        updated.delete("location");
      } else {
        updated.set("location", newLocation.id);
        updated.delete("floor");
        updated.delete("room");
        updated.delete("desk");
      }
      setSearchParams(updated);
    }
  );

  const onChangeInventory = useMemoizedFn(
    (newInventory: AssetInventory | null): void => {
      const updated = new URLSearchParams(searchParams);
      if (!newInventory) {
        updated.delete("inventory");
      } else {
        updated.set("inventory", newInventory.id);
      }
      setSearchParams(updated);
    }
  );

  return {
    onChangeLocation,
    onChangeFloor,
    onChangeInventory,
  };
}
