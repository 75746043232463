import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import {
  Home as HomeIcon,
  Layers as LayersIcon,
  User as UserIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { Icon, Info, Label, Row } from "../../../../../global/styles";
import {
  Desk,
  Floor,
  Location,
  Space,
} from "../../../../../modules/assets/domain";
import { User } from "../../../../../modules/authentication/domain";
import { DeskWithSpace } from "../../../../domain";

export interface DialogInfoProps {
  sx?: SxProps<Theme>;
  desk: DeskWithSpace | Desk | null;
  location: Location | null;
  floor?: Floor | null;
  space?: Space | null;
  changedBy?: User | null;
  confirmation?: boolean;
}

export const DialogInfo: React.FC<DialogInfoProps> = ({
  sx,
  desk,
  location,
  floor,
  space,
  changedBy,
  confirmation,
}) => {
  const { t } = useTranslation("asset-management");

  return (
    <Box sx={sx}>
      {location && (
        <Row>
          <span>
            <Icon>
              <HomeIcon size={16} />
            </Icon>
          </span>
          <Info data-testid="dialog--location">
            <span>
              <strong>{location.name}</strong>
              <br />
              {location.address.addressLine1}, {location.address.city}
            </span>
          </Info>
        </Row>
      )}
      {floor && (
        <Row>
          <Icon>
            <LayersIcon size={16} />
          </Icon>
          <Info data-testid="dialog--floor">{floor.description}</Info>
        </Row>
      )}
      {space && (
        <Row>
          <Icon />
          <Info data-testid="dialog--space">
            <Label>{t("dialog.space")}:</Label>
            {space.description}
          </Info>
        </Row>
      )}
      {desk && (
        <Row>
          <Icon />
          <Info data-testid="dialog--desk">
            <Label>{t("dialog.desk")}:</Label>
            <strong>{desk.identifier}</strong>
          </Info>
        </Row>
      )}
      {confirmation && !desk && (
        <Row>
          <Icon />
          <Info data-testid="dialog--desk">
            <Label>{t("dialog.desk")}:</Label>
            <strong>{t("dialog.all-desks")}</strong>
          </Info>
        </Row>
      )}
      {changedBy && (
        <Row>
          <Icon>
            <UserIcon size={16} />
          </Icon>
          <Info data-testid="dialog--changed-by">
            <Label>{t("dialog.changed-by")}:</Label>
            <strong>{changedBy.name.full}</strong>
          </Info>
        </Row>
      )}
    </Box>
  );
};
