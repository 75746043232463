import { Box, styled } from "@mui/material";
import React, { forwardRef, useDeferredValue, useMemo } from "react";
import { useScroll } from "../../../../../../hooks";
import { ConferenceRoom } from "../../../../../conference/domain";
import { useConferenceRooms } from "../../../../../conference/hooks";
import { LocationBookingOverview, SpaceOverview } from "../../../../domain";
import {
  selectFilteredConferenceRooms,
  selectFilteredDeskBookings,
  selectFilteredLocationBookings,
  useBookingFilters,
  useBookingState,
  useLocations,
} from "../../state";
import { ConferenceRoomItem } from "../ConferenceRoomItem";
import { DeskItem } from "../DeskItem";
import {
  LocationBookingAvailableItem,
  LocationBookingItem,
} from "../LocationBookingItem";
import { PresenceListConferenceRoomsSectionHeader } from "./PresenceListConferenceRoomsSectionHeader";
import { PresenceListLocationBookingsSectionHeader } from "./PresenceListLocationBookingsSectionHeader";
import { PresenceListSpaceHeader } from "./PresenceListSpaceHeader";

const PresenceListShadow: React.FC<{ scrollTop: number }> = React.memo(
  ({ scrollTop }) => (
    <Box
      sx={{
        height: 5,
        position: "absolute",
        top: -5,
        left: 0,
        right: 0,
        boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.1)",
        opacity: scrollTop === 0 ? 0 : 1,
        zIndex: 1000,
      }}
    />
  )
);

const PresenceListBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  padding: theme.spacing(0, 2),
  flex: "1 1 auto",
}));

const ConnectedPresenceListEmpty: React.FC = React.memo(() => {
  return null;
});

export interface PresenceListContainerComponentProps {
  onScroll: any;
  locationId: string | null;
  sortedDeskBookings: SpaceOverview[];
  sortedLocationBookings: LocationBookingOverview[];
  sortedConferenceRooms: ConferenceRoom[];
}

const PresenceListContainerComponent: React.FC<PresenceListContainerComponentProps> =
  React.memo(
    ({
      onScroll,
      locationId,
      sortedDeskBookings,
      sortedLocationBookings,
      sortedConferenceRooms,
    }) => {
      const deferredDeskBookings = useDeferredValue(sortedDeskBookings);
      const deferredLocationBookings = useDeferredValue(sortedLocationBookings);
      const deferredConferenceRooms = useDeferredValue(sortedConferenceRooms);

      const renderedDeskBookings = useMemo(() => {
        return deferredDeskBookings.map((spaceOverview) => (
          <React.Fragment key={spaceOverview.space.id}>
            <PresenceListSpaceHeader overview={spaceOverview} />
            {spaceOverview.desks.map((it) => (
              <DeskItem key={it.desk.id} space={spaceOverview} overview={it} />
            ))}
          </React.Fragment>
        ));
      }, [deferredDeskBookings]);

      const renderedLocationBookings = useMemo(
        () => (
          <>
            <PresenceListLocationBookingsSectionHeader key={locationId} />
            <LocationBookingAvailableItem />
            {deferredLocationBookings.map((item) => (
              <React.Fragment key={item.bookedFor.id}>
                <LocationBookingItem booking={item} />
              </React.Fragment>
            ))}
          </>
        ),
        // Intentional: PresenceListLocationBookingsSectionHeader must be rendered
        // with the key to force React to re-render component on change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deferredLocationBookings]
      );

      const renderedConferenceRooms = useMemo(
        () =>
          deferredConferenceRooms.length > 0 && (
            <>
              <PresenceListConferenceRoomsSectionHeader />
              {deferredConferenceRooms.map((conferenceRoom) => (
                <ConferenceRoomItem
                  key={conferenceRoom.id}
                  conferenceRoom={conferenceRoom}
                />
              ))}
            </>
          ),
        [deferredConferenceRooms]
      );

      return (
        <PresenceListBox
          onScroll={onScroll}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              paddingBottom: theme.spacing(7),
            },
          })}
        >
          <ConnectedPresenceListEmpty />
          {renderedDeskBookings}
          {renderedLocationBookings}
          {renderedConferenceRooms}
        </PresenceListBox>
      );
    }
  );

const PresenceListContainer: React.FC<{
  onScroll: any;
}> = ({ onScroll }) => {
  const state = useBookingState();
  const { locationId } = useLocations();
  const [filters] = useBookingFilters();
  const deskBookings = selectFilteredDeskBookings(state, filters);
  const locationBookings = selectFilteredLocationBookings(state, filters);
  const conferenceRoomsState = useConferenceRooms();
  const conferenceRooms = selectFilteredConferenceRooms(
    conferenceRoomsState,
    locationId
  );

  return (
    <PresenceListContainerComponent
      onScroll={onScroll}
      locationId={locationId}
      sortedDeskBookings={deskBookings}
      sortedLocationBookings={locationBookings}
      sortedConferenceRooms={conferenceRooms}
    />
  );
};

const PresenceListShadowContainer = styled(Box)({
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

export const PresenceListDesks = forwardRef((_, ref) => {
  const [{ scrollTop }, { onScroll }] = useScroll();

  return (
    <PresenceListShadowContainer ref={ref}>
      <PresenceListShadow scrollTop={scrollTop} />
      <PresenceListContainer onScroll={onScroll} />
    </PresenceListShadowContainer>
  );
});
