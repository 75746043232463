import React from "react";
import { StyledPageContentWrapper } from "../../../../global/styles";
import { BackofficePageContainer } from "../../../components";
import { AnalyticsHeader } from "../components";
import { AnalyticsChart } from "../components/AnalyticsChart/AnalyticsChart";
import { AnalyticsTable } from "../components/AnalyticsTable";

export const AnalyticsPage: React.FC = () => {
  return (
    <BackofficePageContainer>
      <AnalyticsHeader />
      <AnalyticsTable />
      <StyledPageContentWrapper
        sx={(theme) => ({
          borderLeft: "none",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <AnalyticsChart />
      </StyledPageContentWrapper>
    </BackofficePageContainer>
  );
};
