import { useBookingForm } from "../../state";
import { BookFormDialog } from "./BookFormDialog";

export const ConnectedBookFormDialog: React.FC = () => {
  const form = useBookingForm();

  if (!form.target) {
    return null;
  }

  return <BookFormDialog />;
};
