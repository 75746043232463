import { Box, Button, Tooltip } from "@mui/material";
import React from "react";

interface InfoBannerProps {
  tooltipTitle: string;
  handleClick: () => void;
  icon: React.ReactNode;
}

export const InfoBanner: React.FC<React.PropsWithChildren<InfoBannerProps>> = ({
  children,
  tooltipTitle,
  handleClick,
  icon,
}) => {
  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "green.light",
        borderBottom: "1px solid",
        borderColor: "shades.greyExtraLight",
        flexShrink: "0",
        height: 104,
        [theme.breakpoints.down("md")]: {
          height: 88,
        },
      })}
    >
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          left: "16px",
        }}
      >
        <Tooltip title={tooltipTitle}>
          <Button color={"secondary"} variant="icon" onClick={handleClick}>
            {icon}
          </Button>
        </Tooltip>
      </Box>
      {children}
    </Box>
  );
};
