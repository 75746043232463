import Fuse from "fuse.js";
import { stripDiacritics } from "../../../assets/helperFunctions";
import { SearchBookingParam } from "../../api";

const fuseGlobalSearchOptions: Fuse.IFuseOptions<SearchBookingParam> = {
  isCaseSensitive: false,
  findAllMatches: true,
  minMatchCharLength: 2,
  shouldSort: true,
  ignoreLocation: true,
  includeScore: true,
  threshold: 0.2,
  keys: [
    {
      name: "Any param",
      getFn(param: SearchBookingParam): string {
        const value = param.name ?? "";
        return stripDiacritics(value);
      },
    },
  ],
};

export function filterUsersTeamsAndLocationsBySearch(
  params: SearchBookingParam[],
  search: string
): SearchBookingParam[] {
  if (search.length < 2) {
    return params;
  }
  const normalizedSearch = stripDiacritics(search);
  const fuse = new Fuse(params, fuseGlobalSearchOptions);
  const result = fuse.search(normalizedSearch);
  return result.map((it) => it.item);
}
