import { Divider, ListItemText, Menu, MenuItem, Switch } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import { useTranslation } from "react-i18next";
import { CellType } from "../Table";

interface ReservationsMenuProps {
  popupState: PopupState;
  buttonState: string | null;
  onFilter: (cellType: CellType, value: string | null) => void;
}

export const ReservationsMenu: React.FC<ReservationsMenuProps> = ({
  popupState,
  buttonState,
  onFilter,
}) => {
  const { t } = useTranslation("common");

  return (
    <Menu
      disableAutoFocusItem
      {...bindMenu(popupState)}
      PaperProps={{
        style: {
          width: 247,
        },
      }}
    >
      <MenuItem onClick={() => onFilter(CellType.RESERVATIONS, null)}>
        <Switch size="small" checked={buttonState === null} />
        <ListItemText primary={t("components.table.reservations-menu.all")} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => onFilter(CellType.RESERVATIONS, "desk")}>
        <Switch size="small" checked={!!buttonState} />
        <ListItemText
          primary={`${t("components.table.reservations-menu.desk")}`}
          secondary={`${t("components.table.sort.desc")}`}
        />
      </MenuItem>
      <MenuItem onClick={() => onFilter(CellType.RESERVATIONS, "")}>
        <Switch size="small" checked={buttonState === ""} />
        <ListItemText
          primary={`${t("components.table.reservations-menu.drop-in")}`}
          secondary={`${t("components.table.sort.asc")}`}
        />
      </MenuItem>
    </Menu>
  );
};
