import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EditDeskSnapshot, useBookingForm, useLocations } from "../../state";
import { PresenceListSectionHeader } from "./PresenceListSubsectionHeader";

export const PresenceListLocationBookingsSectionHeader: React.FC = () => {
  const { t } = useTranslation("booking");
  const { locationId } = useLocations();
  const { isEditing, source } = useBookingForm();
  const headerRef = useRef<HTMLElement | null>(null);

  useEffect(
    () => {
      if (isEditing && !(source instanceof EditDeskSnapshot)) {
        headerRef.current?.scrollIntoView({
          block: "start",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locationId]
  );

  return (
    <PresenceListSectionHeader
      ref={headerRef}
      title={t("presence-list-locations-header")}
      titleTypographyProps={{ color: "shades.dark" }}
    />
  );
};
