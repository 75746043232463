import { isSameDay } from "date-fns";
import { useMyBookingsQuery } from ".";
import { Booking } from "../../../domain";

export function useTodaysBooking(): Booking | undefined {
  const { data: bookings } = useMyBookingsQuery();
  const booking = bookings?.find((booking) =>
    isSameDay(booking.booking.bookingDate, new Date())
  );
  return booking?.booking;
}
