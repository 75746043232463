import React from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveH6 } from "../../../../../../global/components";
import { EditDeskSnapshot, useBookingForm } from "../../state";

export const Title: React.FC = () => {
  const { t } = useTranslation("booking");
  const form = useBookingForm();

  const isEditingLocation =
    form.isEditing && !(form.source instanceof EditDeskSnapshot);
  const isEditingDesk =
    form.isEditing && form.source instanceof EditDeskSnapshot;

  return (
    <>
      {isEditingLocation && (
        <ResponsiveH6>{t("filter.title.edit-location")}</ResponsiveH6>
      )}
      {isEditingDesk && <ResponsiveH6>{t("filter.title.edit")}</ResponsiveH6>}
      {!form.isEditing && (
        <ResponsiveH6
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              color: "white",
            },
          })}
        >
          {t("filter.title.book")}
        </ResponsiveH6>
      )}
    </>
  );
};
