import { Box } from "@mui/material";
import React from "react";
import { DatePicker } from "../../../../global/components/DatePickers";
import { trackBackofficeDatePicker } from "../../../../tracking";
import { LocationPicker } from "../../../components";
import {
  useBackofficeLocations,
  useBackofficeNavigation,
} from "../../../state";

export const AnalyticsHeader: React.FC = () => {
  const { location, locations } = useBackofficeLocations();
  const { onChangeLocation } = useBackofficeNavigation();

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      })}
    >
      <LocationPicker
        location={location ? location.location : null}
        locations={locations}
        onChange={onChangeLocation}
        allAvailable
      />
      <DatePicker trackDateChange={trackBackofficeDatePicker} />
    </Box>
  );
};
