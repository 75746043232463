import mixpanel from "mixpanel-browser";
import { WeekChangeType } from "../modules/conference/components/ConferenceFormDialog/components";

export function trackOpenConferenceDialog() {
  try {
    mixpanel.track("Open conference dialog");
  } catch (e) {}
}

export function trackRedirectToGoogleCalendar() {
  try {
    mixpanel.track("Redirect to google calendar");
  } catch (e) {}
}

export function trackLoadMoreConference(weekChangeType: WeekChangeType) {
  try {
    mixpanel.track("Load more days conference", { weekChangeType });
  } catch (e) {}
}
