import * as React from "react";

export const SvgNotAvailableAvatar: React.FC = (props: any) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} fill="#fff" />
    <path
      d="m20 12-8 8"
      stroke="#141110"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m20 12-8 8"
      stroke="#fff"
      strokeOpacity={0.38}
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12 12 8 8"
      stroke="#141110"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12 12 8 8"
      stroke="#fff"
      strokeOpacity={0.38}
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke="#141110" />
    <rect
      x={0.5}
      y={0.5}
      width={31}
      height={31}
      rx={15.5}
      stroke="#fff"
      strokeOpacity={0.38}
    />
  </svg>
);
