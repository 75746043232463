import { Components, Theme } from "@mui/material";

export const MuiLinearProgress: Components<Theme>["MuiLinearProgress"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 6,
      borderRadius: 8,
      backgroundColor: theme.palette.shades.greyExtraLight,
    }),
    dashed: {
      backgroundColor: "transparent",
      backgroundImage: "none",
    },
    bar1Buffer: ({ theme }) => ({
      backgroundColor: theme.palette.cyclamen.regular,
    }),
    bar2Buffer: ({ theme }) => ({
      backgroundColor: theme.palette.cyclamen.medium,
    }),
  },
};
