import {
  Box,
  Button,
  DialogContent,
  DialogProps,
  Typography,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { Check, X } from "react-feather";
import { Trans, useTranslation } from "react-i18next";
import { AmtDialog, AmtToast } from "../../../../../components";

export interface ExitEditModeConfirmationDialogProps {
  open: boolean;
  onClose: DialogProps["onClose"];
  onConfirm: () => void;
}

export const ExitEditModeConfirmationDialog: React.FC<
  ExitEditModeConfirmationDialogProps
> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation("booking-dialog", {
    keyPrefix: "exit-edit-mode",
  });
  const handleClose = useMemoizedFn(() => {
    onClose?.({}, "escapeKeyDown");
  });
  return (
    <AmtDialog open={open} onClose={onClose} closeButton>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Box>
          <AmtToast text={t("title")} variant="warning" />
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            <Trans t={t} i18nKey="description" />
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4" align="center">
            {t("warning")}
          </Typography>
          <Typography variant="body2" align="center" color="shades.grey">
            {t("subtitle")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Button
            fullWidth
            color="cancel"
            variant="outlined"
            onClick={handleClose}
            startIcon={<X size={16} />}
          >
            {t("action.cancel")}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onConfirm}
            startIcon={<Check size={16} />}
          >
            {t("action.confirm")}
          </Button>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
