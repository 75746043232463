import { isSameDay } from "date-fns";
import mixpanel from "mixpanel-browser";
import { AmtError } from "../api";
import { Floor, Location } from "../modules/assets/domain";
import { CurrentUser } from "../modules/authentication/domain";
import { Booking, DeskOverview } from "../modules/booking/domain";
import { AvailabilityFilter } from "../modules/booking/pages/BookingPage/state";

export type SelectLocationSource = "dashboard" | "bookings-filter";
export type SelectDeskSource = "floor-plan" | "bookings-list";
export type SelectFloorSource =
  | "floor-plan"
  | "floor-plan-stairs"
  | "bookings-filter";

export function trackSelectDesk(desk: DeskOverview, source: SelectDeskSource) {
  try {
    mixpanel.track("selected_desk", {
      desk: `${desk.desk.identifier} ${desk.space.description} ${desk.floor.description} ${desk.location.address.addressLine1}`,
      source,
    });
  } catch (e) {}
}

export function trackFloorChange(floor: Floor, source: SelectFloorSource) {
  try {
    mixpanel.track("changed_floor", {
      floor: floor.description,
      source,
    });
  } catch (e) {}
}

export function trackSuccessfulBooking(meta: object) {
  try {
    mixpanel.track("new_booking", meta);
  } catch (e) {}
}

export function trackGroupBooking(meta: object) {
  try {
    mixpanel.track("new_group_booking", meta);
  } catch (e) {}
}

export function trackFailedBooking(currentUser: CurrentUser, err: AmtError) {
  try {
    mixpanel.track("failed_booking", {
      status: err.response.status,
    });
  } catch (e) {}
}

export function trackSelectedSpace(name: string | undefined) {
  try {
    mixpanel.track("selected_space", {
      name,
    });
  } catch (e) {}
}

export function trackBookingSearch() {
  try {
    mixpanel.track("booking_filter", {
      search: true,
    });
  } catch (e) {}
}

export function trackBookingAvailabilityFilter(filter: AvailabilityFilter) {
  try {
    mixpanel.track("booking_filter", {
      filter: filter.toString(),
    });
  } catch (e) {}
}

export function trackResetFilters() {
  try {
    mixpanel.track("reset_filters");
  } catch (e) {}
}

export function trackExitEditMode(source: "navigation" | "exit-button") {
  try {
    mixpanel.track("exit-edit-mode", {
      source,
    });
  } catch (e) {}
}

export function trackExperiment(variation: string) {
  try {
    mixpanel.track("experiment", {
      variation,
    });
  } catch (e) {}
}

export function trackLocationSelected(
  location: Location,
  source: SelectLocationSource
) {
  try {
    mixpanel.track("location_selected", {
      location: location.address.addressLine1,
      source,
    });
  } catch (e) {}
}

export function trackUnbook(booking: Booking) {
  try {
    mixpanel.track("unbook", bookingDetailsOf(booking));
  } catch (e) {}
}

export function trackSuccessfulBookingEdit(meta: object) {
  try {
    mixpanel.track("Edit Booking", meta);
  } catch (e) {}
}

export function trackOpenNotificationsDropdown() {
  try {
    mixpanel.track("Open Notifications Dropdown");
  } catch (e) {}
}

export function trackLocationCardLocationBookingsCountTooltipOpen() {
  try {
    mixpanel.track("Location Card Tooltip Shown");
  } catch (e) {}
}

function bookingDetailsOf(booking: Booking): object {
  return {
    forSelf: booking.bookedForSelf,
    forToday: isSameDay(booking.bookingDate, new Date()),
  };
}

export function trackToggleFavoriteLocation() {
  try {
    mixpanel.track("Toggle Favorite Location");
  } catch (e) {}
}
