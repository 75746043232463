import { Collapse, ListItemText, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import {
  BookedNotification,
  InvitationCancelledNotification,
  InvitationSentNotification,
  Notification,
  UnbookedNotification,
  UpdatedNotification,
} from "../domain";
import { MarkAllAsReadButton } from "./MarkAllAsReadButton";
import {
  BookedNotificationItem,
  UnbookedNotificationItem,
  UpdatedNotificationItem,
} from "./NotificationItems";
import { InvitationCancelledNotificationItem } from "./NotificationItems/InvitationCancelledNotificationItem";
import { InvitationSentNotificationItem } from "./NotificationItems/InvitationSentNotificationItem";

export interface NotificationListProps {
  notifications: Notification[];
  onClose: () => void;
}

export const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  onClose,
}) => {
  function renderNotification(notification: Notification) {
    if (notification instanceof BookedNotification) {
      return (
        <BookedNotificationItem notification={notification} onClose={onClose} />
      );
    }
    if (notification instanceof UnbookedNotification) {
      return (
        <UnbookedNotificationItem
          notification={notification}
          onClose={onClose}
        />
      );
    }
    if (notification instanceof UpdatedNotification) {
      return (
        <UpdatedNotificationItem
          notification={notification}
          onClose={onClose}
        />
      );
    }
    if (notification instanceof InvitationSentNotification) {
      return (
        <InvitationSentNotificationItem
          notification={notification}
          onClose={onClose}
        />
      );
    }
    if (notification instanceof InvitationCancelledNotification) {
      return (
        <InvitationCancelledNotificationItem
          notification={notification}
          onClose={onClose}
        />
      );
    }
    return null;
  }

  return (
    <TransitionGroup>
      {notifications.map((it) => (
        <Collapse key={it.id}>{renderNotification(it)}</Collapse>
      ))}
      <MarkAllAsReadButton onClose={onClose} />
    </TransitionGroup>
  );
};

export const NotificationsEmpty: React.FC = () => {
  const { t } = useTranslation("notifications");
  return (
    <MenuItem sx={{ width: "315px" }}>
      <ListItemText primary={t("empty-list")} />
    </MenuItem>
  );
};
