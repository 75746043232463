import { getJson } from "../../api";
import { mapUser, UserDto } from "../../modules/authentication/api";
import {
  Group,
  GroupMember,
  OrganizationGroups,
} from "../modules/asset-management/domain";

export interface OrganizationGroupMemberDto {
  id: string;
  user: UserDto;
  deskIsDedicatedToMember: boolean;
}

export interface OrganizationGroupDto {
  name: string;
  members: OrganizationGroupMemberDto[];
}

export interface OrganizationGroupsResponse {
  teams: OrganizationGroupDto[];
}

export function mapGroupMember(
  response: OrganizationGroupMemberDto,
  group: Group
): GroupMember {
  return new GroupMember(
    response.id,
    mapUser(response.user),
    response.deskIsDedicatedToMember,
    group.name
  );
}

export function mapGroup(response: OrganizationGroupDto): Group {
  const group = new Group(response.name, []);
  response.members.forEach((it) => {
    const member = mapGroupMember(it, group);
    group.members.push(member);
  });
  return group;
}

export function mapOrganizationGroups(
  response: OrganizationGroupsResponse
): OrganizationGroups {
  return new OrganizationGroups(response.teams.map(mapGroup));
}

export async function getDedicatedGroup(
  organizationId: string,
  deskId: string | undefined
): Promise<OrganizationGroups> {
  const response = await getJson<OrganizationGroupsResponse>(
    `/api/manage-assets/groups`,
    {
      "organization.id": organizationId,
      ...(deskId && { "desk.id": deskId }),
    }
  );
  return mapOrganizationGroups(response);
}
