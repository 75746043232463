import { useCurrentUser } from "../../authentication/hooks";

export function useBackofficePermissions(): {
  isAdmin: boolean;
  isSuperAdmin: boolean;
} {
  const currentUser = useCurrentUser();

  const isAdmin =
    currentUser &&
    !!currentUser.organizations.find(
      (organization) =>
        organization.role.name === "ADMINISTRATOR" ||
        organization.role.name === "SUPER_ADMINISTRATOR"
    );

  const isSuperAdmin =
    currentUser &&
    !!currentUser.organizations.find(
      (organization) => organization.role.name === "SUPER_ADMINISTRATOR"
    );

  return { isAdmin, isSuperAdmin };
}
