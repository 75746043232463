import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { BarChart2, Monitor } from "react-feather";
import { useTranslation } from "react-i18next";
import { Icon, Info, Label, Row } from "../../../../../../global/styles";
import { AssetInventory } from "../../../../../../modules/booking/domain";

export interface InventoryInfoProps {
  sx?: SxProps<Theme>;
  inventory: AssetInventory;
}

export const InventoryInfo: React.FC<InventoryInfoProps> = ({
  sx,
  inventory,
}) => {
  const { t } = useTranslation("inventory-management");

  return (
    <Box sx={sx}>
      <Row>
        <Icon>
          <Monitor size={16} />
        </Icon>
        <Info>
          <strong>
            {inventory.type.name} - {inventory.description}
          </strong>
        </Info>
      </Row>
      <Row>
        <Icon>
          <BarChart2 size={16} />
        </Icon>
        <Info data-testid="dialog--space">
          <Label>{t("dialog.label.quantity")}:</Label>
          {inventory.quantity}
        </Info>
      </Row>
    </Box>
  );
};
