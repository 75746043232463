import { Box } from "@mui/material";
import React from "react";
import { AmtCenteredLoader, Avatar } from "../../../components";
import { MainLayoutAppBar } from "../MainLayout";

export const MainLayoutSkeleton: React.FC = () => {
  return (
    <>
      <MainLayoutAppBar
        actions={
          <>
            <Avatar />
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AmtCenteredLoader />
      </Box>
    </>
  );
};
