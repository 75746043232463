import mixpanel from "mixpanel-browser";
import { DeskStatus } from "../backoffice/modules/asset-management/domain";

type SelectLocationSource = "Location dropdown picker" | "Location dashboard";
type SelectFloorSource = "Floor dropdown picker" | "Floorplan stairs";

export function trackOpenBackoffice() {
  try {
    mixpanel.track("Open backoffice");
  } catch (e) {}
}

export function trackSwitchTab(tab: string) {
  const formatted = capitalize(tab);
  try {
    mixpanel.track("Switch tab backoffice", { formatted });
  } catch (e) {}
}

export function trackBackofficeLocationChange(
  isSingleLocation: boolean,
  source: SelectLocationSource
) {
  try {
    mixpanel.track("Backoffice location change", { isSingleLocation, source });
  } catch (e) {}
}

export function trackBackofficeInventoryChange(isSingleInventory: boolean) {
  try {
    mixpanel.track("Backoffice inventory change", { isSingleInventory });
  } catch (e) {}
}

export function trackCreateInventoryType() {
  try {
    mixpanel.track("Create inventory type");
  } catch (e) {}
}

export function trackUpdateInventory() {
  try {
    mixpanel.track("Update inventory");
  } catch (e) {}
}

export function trackDeleteInventory() {
  try {
    mixpanel.track("Delete inventory");
  } catch (e) {}
}

export function trackAddAdmin() {
  try {
    mixpanel.track("Add admin user");
  } catch (e) {}
}

export function trackRemoveAdmin() {
  try {
    mixpanel.track("Remove admin");
  } catch (e) {}
}

export function trackBackofficeFloorChange(
  isSingleFloor: boolean,
  source: SelectFloorSource
) {
  try {
    mixpanel.track("Backoffice floor change", { isSingleFloor, source });
  } catch (e) {}
}

export function trackBackofficeCSVReports(isSingleDay: boolean) {
  try {
    mixpanel.track("Analytics CSV report", { isSingleDay });
  } catch (e) {}
}

export function trackBackofficeDatePicker(isSingleDay: boolean) {
  try {
    mixpanel.track("Analytics date picker", { isSingleDay });
  } catch (e) {}
}

export function trackBackofficeBookingDetails() {
  try {
    mixpanel.track("Check backoffice booking details");
  } catch (e) {}
}

export function trackFilterColumnsAnalytics(cellType: string) {
  try {
    mixpanel.track("Filter analytics table columns", { cellType });
  } catch (e) {}
}

export function trackApplyToAllDesks() {
  try {
    mixpanel.track("Apply to all desks checkbox");
  } catch (e) {}
}

export function trackUpdateDeskStatus(deskStatus: DeskStatus) {
  const formatted = capitalize(deskStatus);
  try {
    mixpanel.track("Update desk status", { formatted });
  } catch (e) {}
}

const capitalize = (string: string) => {
  const words = string.replace(/-/g, " ").split(" ");
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
