import { Box, Button, DialogContent, styled, Typography } from "@mui/material";
import React from "react";
import { Check, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtDialog } from "../../../../components";
import { Booking, BookingDate, DeskBooking } from "../../domain";
import {
  BookFormAvatar,
  FieldContainer,
} from "../../pages/BookingPage/Dialog/BookFormDialog/components";
import { BookingInfo } from "../../pages/BookingPage/Dialog/BookingInfo";
import { DialogTitle } from "./DialogTitle";

export interface ApproveInvitationDialogProps {
  open: boolean;
  onClose: () => void;
  booking: Booking;
  disabled?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
}

const HideMobile = styled("span")(({ theme }) => ({
  [theme.breakpoints.down(375)]: {
    display: "none",
  },
}));

export const ApproveInvitationDialog: React.FC<
  ApproveInvitationDialogProps
> = ({ open, onClose, booking, disabled, onConfirm, onCancel }) => {
  const { t } = useTranslation("booking-dialog");

  const isDeskBooking = booking instanceof DeskBooking;

  return (
    <AmtDialog open={open} onClose={onClose} closeButton>
      <DialogContent>
        <DialogTitle />
        <BookingInfo
          date={BookingDate.of(booking.bookingDate)}
          location={booking.location}
          {...(isDeskBooking && {
            floor: booking.floor,
            space: booking.space,
            desks: [booking.desk],
          })}
          bookedBy={booking.bookedBy}
        />
        <FieldContainer sx={{ marginY: 2 }}>
          <Typography variant="subtitle2">
            {t("approve-invite.booked-for")}:
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <BookFormAvatar src={booking.bookedFor.avatarImageUrlSrc} />
            <Box
              sx={{
                backgroundColor: "shades.greyExtraLight",
                border: "1px solid",
                borderColor: "shades.greyLight",
                borderRadius: 1,
                width: "100%",
                paddingX: 1.5,
                paddingY: 1,
              }}
            >
              <Typography variant="body2" color="shades.dark">
                {booking.bookedFor.name.full}
              </Typography>
            </Box>
          </Box>
        </FieldContainer>
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: 1,
            [theme.breakpoints.down("md")]: {
              gap: 2,
            },
          })}
        >
          <Button
            fullWidth
            color="cancel"
            variant="outlined"
            disabled={disabled}
            onClick={onCancel}
            startIcon={<X size={16} />}
            data-testid="dialog--cancel"
          >
            <HideMobile>{t("approve-invite.action.no")}</HideMobile>
          </Button>
          <Button
            fullWidth
            variant="contained"
            disabled={disabled}
            onClick={onConfirm}
            startIcon={<Check size={16} />}
            data-testid="dialog--submit"
          >
            <HideMobile>{t("approve-invite.action.yes")}</HideMobile>
          </Button>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
