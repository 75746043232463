import { Box } from "@mui/material";
import React from "react";
import { LocationCard } from ".";
import { FloorPicker, LocationPicker } from "../../../components";
import {
  useBackofficeFloors,
  useBackofficeLocations,
  useBackofficeNavigation,
} from "../../../state";
import { DeskManagementConfirmationDialog } from "./Dialogs/DeskManagementConfirmationDialog";
import { ConnectedDeskManagemenetDialog } from "./Dialogs/DeskManagementDialog";
import { ConnectedUpdateInventoryDialog } from "./Dialogs/UpdateInventoryDialog";
import { FloorPlanList } from "./FloorPlan";

export const LocationPreview: React.FC = () => {
  const { location, locations } = useBackofficeLocations();
  const { floor, floors } = useBackofficeFloors();
  const { onChangeLocation, onChangeFloor } = useBackofficeNavigation();

  if (!location) {
    throw new Error("Location must be loaded");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "hidden",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        })}
      >
        <LocationPicker
          location={location.location}
          locations={locations}
          onChange={onChangeLocation}
          allAvailable
        />
        <FloorPicker
          floor={floor}
          floors={floors}
          onChangeFloor={onChangeFloor}
        />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          marginTop: 2,
        }}
      >
        {(!floor || floors.length <= 1) && (
          <LocationCard
            locationWithCapacity={location}
            inventoryButton
            sx={{ marginBottom: 3 }}
          />
        )}
        <FloorPlanList />
      </Box>
      <ConnectedDeskManagemenetDialog />
      <DeskManagementConfirmationDialog />
      <ConnectedUpdateInventoryDialog />
    </Box>
  );
};
