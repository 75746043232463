import { useSearchParams } from "react-router-dom";
import { Floor } from "../../modules/assets/domain";
import { useFloorsQuery } from "../../modules/assets/hooks";
import { useOrganizationMembership } from "../../modules/authentication/hooks";
import { useBackofficeLocations } from "./useBackofficeLocations";

export interface UseFloorsResult {
  floors: Floor[];
  floor: Floor | null;
  floorId: string | null;
}

function getFloorFromSearchParams(
  searchParams: URLSearchParams,
  floors: Floor[]
): [Floor | null, string | null] {
  const floorId = searchParams.get("floor");
  const floor = floors.find((it) => it.id === floorId);
  if (floor) {
    return [floor, floor.id];
  }
  const preselectedFloor = floors.length === 1 ? floors[0] : null;
  return [preselectedFloor, preselectedFloor?.id ?? floorId];
}

export function useBackofficeFloors(): UseFloorsResult {
  const { organization } = useOrganizationMembership();
  const [searchParams] = useSearchParams();
  const { locationId } = useBackofficeLocations();
  const floorsQuery = useFloorsQuery(
    organization.id,
    { locationId: locationId ?? undefined },
    {
      enabled: !!locationId,
    }
  );
  const floors = floorsQuery.data ?? [];
  const [floor, floorId] = getFloorFromSearchParams(searchParams, floors);
  return { floors, floor, floorId };
}
