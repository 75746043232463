import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSearchParams } from "react-router-dom";
import { AssetInventoryType } from "../../modules/booking/domain";
import { DeskWithStatus } from "../domain";
import { useAssetForm } from "../modules/asset-management/state";
import { useBackofficeDesks } from "./useBackofficeDesks";

export interface UseSelectionResult {
  selectedDesk: DeskWithStatus | null;
  onSelectDesk(desk: DeskWithStatus | null): void;
  onSelectInventoryType(type: AssetInventoryType | null): void;
}

export function useBackofficeSelection(): UseSelectionResult {
  const form = useAssetForm();
  const { desksWithStatus } = useBackofficeDesks();
  const [searchParams, setSearchParams] = useSearchParams();

  const deskId = searchParams.get("desk");
  const selectedDesk =
    desksWithStatus.find((desk) => desk.desk.id === deskId) ?? null;

  const onSelectDesk = useMemoizedFn((newDesk: DeskWithStatus | null) => {
    const updated = new URLSearchParams(searchParams);
    if (newDesk === null) {
      updated.delete("desk");
    } else {
      updated.set("location", newDesk.desk.locationId);
      updated.set("floor", newDesk.desk.floorId);
      if (updated.get("room")) {
        updated.set("room", newDesk.desk.space.id);
      }
      updated.set("desk", newDesk.desk.id);
      form.openDialog(newDesk);
    }
    setSearchParams(updated);
  });

  const onSelectInventoryType = useMemoizedFn(
    (newType: AssetInventoryType | null) => {
      const updated = new URLSearchParams(searchParams);
      if (newType === null) {
        updated.delete("inventory-type");
      } else {
        updated.set("inventory-type", newType.id);
      }
      setSearchParams(updated);
    }
  );

  return {
    selectedDesk,
    onSelectDesk,
    onSelectInventoryType,
  };
}
