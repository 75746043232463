import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { DeskStatus } from "../../../domain";
import { StatusIcon } from "../../assets";

const StyledDialogTitle = styled(Box)<{ deskStatus: DeskStatus }>(
  ({ theme, deskStatus }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    borderRadius: "8px",
    backgroundColor:
      deskStatus === DeskStatus.DISABLED
        ? theme.palette.shades.grey
        : theme.palette.green.regular,
    color: "white",
  })
);

interface DialogTitleProps {
  deskStatus: DeskStatus;
}

export const DialogTitle: React.FC<DialogTitleProps> = ({ deskStatus }) => {
  const { t } = useTranslation("asset-management");

  return (
    <StyledDialogTitle deskStatus={deskStatus}>
      <StatusIcon deskStatus={deskStatus} confirmation />
      <Box
        sx={{
          marginLeft: 2,
        }}
      >
        <Typography variant="body3">{t("dialog.feedback.title")}:</Typography>
        <Typography variant="subtitle1">
          {t(`dialog.status.${deskStatus}`)}
        </Typography>
      </Box>
    </StyledDialogTitle>
  );
};
