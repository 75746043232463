export const GallerySvg = `
    <g class="hover-shadow hover-rotate">
        <rect x="4.5" y="4.5" width="31" height="31" rx="15.5" fill="white"/>
        <rect width="36" height="36" rx="18" fill="white"/>
        <g >
            <path d="M25.3332 22.6667C25.3332 23.0203 25.1927 23.3594 24.9426 23.6095C24.6926 23.8595 24.3535 24 23.9998 24H11.9998C11.6462 24 11.3071 23.8595 11.057 23.6095C10.807 23.3594 10.6665 23.0203 10.6665 22.6667V15.3333C10.6665 14.9797 10.807 14.6406 11.057 14.3905C11.3071 14.1405 11.6462 14 11.9998 14H14.6665L15.9998 12H19.9998L21.3332 14H23.9998C24.3535 14 24.6926 14.1405 24.9426 14.3905C25.1927 14.6406 25.3332 14.9797 25.3332 15.3333V22.6667Z" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.3332 22.6667C25.3332 23.0203 25.1927 23.3594 24.9426 23.6095C24.6926 23.8595 24.3535 24 23.9998 24H11.9998C11.6462 24 11.3071 23.8595 11.057 23.6095C10.807 23.3594 10.6665 23.0203 10.6665 22.6667V15.3333C10.6665 14.9797 10.807 14.6406 11.057 14.3905C11.3071 14.1405 11.6462 14 11.9998 14H14.6665L15.9998 12H19.9998L21.3332 14H23.9998C24.3535 14 24.6926 14.1405 24.9426 14.3905C25.1927 14.6406 25.3332 14.9797 25.3332 15.3333V22.6667Z" stroke="white" stroke-opacity="0.04" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.0002 21.3333C19.4729 21.3333 20.6668 20.1394 20.6668 18.6667C20.6668 17.1939 19.4729 16 18.0002 16C16.5274 16 15.3335 17.1939 15.3335 18.6667C15.3335 20.1394 16.5274 21.3333 18.0002 21.3333Z" stroke="#141110" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.0002 21.3333C19.4729 21.3333 20.6668 20.1394 20.6668 18.6667C20.6668 17.1939 19.4729 16 18.0002 16C16.5274 16 15.3335 17.1939 15.3335 18.6667C15.3335 20.1394 16.5274 21.3333 18.0002 21.3333Z" stroke="white" stroke-opacity="0.04" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </g>
`;
