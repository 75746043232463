import { SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { FieldContainer } from "../../../modules/booking/pages/BookingPage/Dialog/BookFormDialog/components";

export interface DialogInputSectionProps {
  label?: string;
  sx?: SxProps<Theme>;
}

export const DialogInputSection: React.FC<
  React.PropsWithChildren<DialogInputSectionProps>
> = React.memo(({ children, label, sx }) => {
  return (
    <FieldContainer sx={sx}>
      {label && <Typography variant="subtitle2">{label}:</Typography>}
      {children}
    </FieldContainer>
  );
});
