import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useFormikContext } from "formik";
import React from "react";
import { useCurrentUser } from "../../../../../../authentication/hooks";
import { BookingInfoComparison } from "../../../../../components";
import {
  DeskBookingRequest,
  EditDeskSnapshot,
  useBookingForm,
} from "../../../state";
import { BookingInfo } from "../../BookingInfo";
import { BookFormValues } from "../types";

const styles: SxProps<Theme> = (theme) => ({
  marginY: 3,
  [theme.breakpoints.down("md")]: {
    marginY: 1,
  },
});

export const BookingInfoDisplay: React.FC<{
  dedicatedDeskSelected?: boolean;
}> = ({ dedicatedDeskSelected }) => {
  const currentUser = useCurrentUser();
  const { target, source } = useBookingForm();
  const { values } = useFormikContext<BookFormValues>();
  const isGroupBooking = values.bookFor.length > 1;

  if (!target) {
    return null;
  }

  return (
    <>
      {source ? (
        <BookingInfoComparison
          sx={styles}
          variant="book-form"
          before={{
            date: source.date,
            location: source.location,
            bookedBy: source.booking.bookedBy,
            ...(source instanceof EditDeskSnapshot && {
              floor: source.floor,
              space: source.space,
              desk: source.desk,
            }),
          }}
          after={{
            date: target.date,
            location: target.location,
            bookedBy: currentUser,
            ...(target instanceof DeskBookingRequest && {
              floor: target.floor,
              space: target.space.space,
              desk: target.desk,
            }),
          }}
        />
      ) : (
        <BookingInfo
          sx={styles}
          date={target.date}
          location={target.location}
          {...(target instanceof DeskBookingRequest && {
            floor: target.floor,
            space: target.space.space,
            desks: isGroupBooking ? [] : [target.desk],
          })}
          dedicatedDeskSelected={dedicatedDeskSelected}
        />
      )}
    </>
  );
};
