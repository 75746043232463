import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { useSearchParams } from "react-router-dom";
import { DeskWithSpace } from "../../../domain";

type InventoryFormState =
  | {
      isOpen: false;
      desk?: DeskWithSpace;
    }
  | {
      isOpen: true;
      desk: DeskWithSpace;
    };

export type UseUpdateInventoryFormResult = InventoryFormState & {
  openDialog(desk: DeskWithSpace): void;
  closeDialog(): void;
};

const formStateAtom = atom<InventoryFormState>({ isOpen: false });

export function useUpdateInventoryForm(): UseUpdateInventoryFormResult {
  const [formState, setFormState] = useAtom(formStateAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const openDialog = useMemoizedFn((desk: DeskWithSpace) => {
    const updated = new URLSearchParams(searchParams);
    if (desk === null) {
      updated.delete("desk");
    } else {
      updated.set("desk", desk.id);
    }
    setSearchParams(updated);
    setFormState({
      isOpen: true,
      desk,
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isOpen: false,
    });
  });

  return {
    ...formState,
    openDialog,
    closeDialog,
  };
}
