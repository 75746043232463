import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";

export type UseInventoryFormResult = {
  isOpen: boolean;
  openDialog(): void;
  closeDialog(): void;
};

const isOpenAtom = atom<boolean>(false);

export function useInventoryForm(): UseInventoryFormResult {
  const [isOpen, setIsOpen] = useAtom(isOpenAtom);

  const openDialog = useMemoizedFn(() => {
    setIsOpen(true);
  });

  const closeDialog = useMemoizedFn(() => {
    setIsOpen(false);
  });

  return {
    isOpen,
    openDialog,
    closeDialog,
  };
}
