import { Box, Menu, styled } from "@mui/material";
import { bindMenu } from "material-ui-popup-state";
import { PopupState } from "material-ui-popup-state/core";
import { useEffect } from "react";
import { Calendar, Home, Layers, User } from "react-feather";
import { useTranslation } from "react-i18next";
import { BookingInfoMenuHeader } from ".";
import { Reservation } from "../../../../../backoffice/modules/analytics/api";
import { useDateFns } from "../../../../../hooks";
import { BookingDate } from "../../../../../modules/booking/domain";
import { trackBookingInfoMenu } from "../../../../../tracking";
import { Icon, Info } from "../../../../styles";

interface BookingInfoMenuProps {
  popupState: PopupState;
  reservation: Reservation;
}

const Label = styled("span")(({ theme }) => ({
  color: theme.palette.shades.grey,
}));

const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(0.5),
}));

export const BookingInfoMenu: React.FC<BookingInfoMenuProps> = ({
  popupState,
  reservation,
}) => {
  const { t } = useTranslation("analytics");
  const { formatRange } = useDateFns();

  useEffect(() => {
    if (popupState.isOpen) trackBookingInfoMenu();
  }, [popupState.isOpen]);

  const menuColors = () => {
    if (reservation.desk)
      return { backgroundColor: "cyclamen.regular", color: "white" };
    else
      return { backgroundColor: "cyclamen.light", color: "cyclamen.regular" };
  };

  return (
    <Menu
      disableAutoFocusItem
      {...bindMenu(popupState)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      PaperProps={{
        style: {
          minWidth: 247,
          padding: "8px",
          transform: "translateY(-10px)",
        },
      }}
    >
      <BookingInfoMenuHeader reservation={reservation} colors={menuColors()} />
      <Row>
        <Icon>
          <Calendar size={16} />
        </Icon>
        <Info data-testid="dialog--booking-date">
          <strong>
            {formatRange(BookingDate.of(new Date(reservation.bookingDate)))}
          </strong>
        </Info>
      </Row>
      <Row>
        <span>
          <Icon>
            <Home size={16} />
          </Icon>
        </span>
        <Info data-testid="dialog--location">
          <span>
            <strong>{reservation.location.name}</strong>
            <br />
            {reservation.location.address.addressLine1},{" "}
            {reservation.location.address.city}
          </span>
        </Info>
      </Row>
      {reservation.floor && (
        <Row>
          <Icon>
            <Layers size={16} />
          </Icon>
          <Info data-testid="dialog--floor">
            {reservation.floor.description}
          </Info>
        </Row>
      )}
      {reservation.space && (
        <Row>
          <Icon />
          <Info data-testid="dialog--space">
            <Label>
              {reservation.desk
                ? t("data-preview.space")
                : t("data-preview.conference-room")}
              :
            </Label>
            {reservation.space.description}
          </Info>
        </Row>
      )}

      {reservation.desk && (
        <Row>
          <Icon />
          <Info data-testid="dialog--desk">
            <Label>{t("data-preview.reservations-menu.label-desk")}:</Label>
            <strong>{reservation.desk.identifier}</strong>
          </Info>
        </Row>
      )}
      <Row>
        <Icon>
          <User size={16} />
        </Icon>
        <Info data-testid="dialog--booked-by">
          <Label>{t("data-preview.reservations-menu.label-booked-for")}:</Label>
          {reservation.bookedBy.name.full}
        </Info>
      </Row>
    </Menu>
  );
};
