import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import React from "react";
import { Monitor } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtInputAdornment } from "../../../../../components";
import { AssetInventoryType } from "../../../../../modules/booking/domain";
import { trackBackofficeInventoryChange } from "../../../../../tracking";

export interface InventoryPickerProps {
  inventoryType: AssetInventoryType | null;
  inventoryTypes: AssetInventoryType[];
  onChange: (newInventoryType: AssetInventoryType | null) => void;
}

export const InventoryPicker: React.FC<InventoryPickerProps> = React.memo(
  ({ inventoryType, inventoryTypes, onChange }) => {
    const { t } = useTranslation("backoffice");

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const newInventoryType = inventoryTypes.find(
        (it) => it.id === event.target.value
      );
      trackBackofficeInventoryChange(!!newInventoryType);
      onChange(newInventoryType ? newInventoryType : null);
    };

    const pickerValue = (): string => {
      return inventoryType ? inventoryType.id : "";
    };

    return (
      <Box
        sx={(theme) => ({
          display: "flex",
          width: "260px",
          [theme.breakpoints.down("md")]: {
            marginBottom: 1,
            width: "100%",
          },
        })}
      >
        <AmtInputAdornment placement="start">
          <Monitor size={16} />
        </AmtInputAdornment>
        <TextField
          fullWidth
          select
          className="MuiInput-leftAdornment"
          value={pickerValue()}
          onChange={(event) => handleChange(event)}
          SelectProps={{
            renderValue: () => (
              <ListItemText
                primary={
                  inventoryType
                    ? `${inventoryType.name}`
                    : t("picker.all-inventories")
                }
              />
            ),
            displayEmpty: true,
          }}
          data-testid="book--filters--location"
        >
          <MenuItem value={""}>
            <ListItemText primary={t("picker.all-inventories")} />
          </MenuItem>
          {inventoryTypes.map((inventoryType) => (
            <MenuItem key={inventoryType.id} value={inventoryType.id}>
              <ListItemText primary={inventoryType.name} />
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  }
);
