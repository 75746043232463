import { Box, DialogContent, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtDialog } from "../../../../../components";
import { Desk, Floor, Location, Space } from "../../../../assets/domain";
import { User } from "../../../../authentication/domain";
import { useCurrentUser } from "../../../../authentication/hooks";
import { BookingDate } from "../../../domain";
import { BookingInfo } from "./BookingInfo";
import { GroupBookingHeader } from "./GroupBookingHeader";

export interface GroupBookingConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  location: Location;
  isInvitation: boolean;
  floor?: Floor;
  space?: Space;
  date: BookingDate;
  bookings: {
    bookedFor: User;
    desk: Desk | null;
  }[];
}

const MobileHeading: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h6,
  },
});

export const GroupBookingConfirmationDialog: React.FC<
  GroupBookingConfirmationDialogProps
> = ({
  open,
  onClose,
  location,
  isInvitation,
  floor,
  space,
  bookings,
  date,
}) => {
  const { t } = useTranslation("booking-dialog");
  const bookedBy = useCurrentUser();
  const desks = bookings
    .map((it) => it.desk)
    .filter((desk) => !!desk) as Desk[];
  return (
    <AmtDialog open={open} onClose={onClose} closeButton>
      <DialogContent>
        <GroupBookingHeader
          isDeskBooking={desks.length > 1}
          users={bookings.map((it) => it.bookedFor)}
          isInvitation={isInvitation}
        />
        <BookingInfo
          date={date}
          location={location}
          floor={floor}
          space={space}
          desks={desks.length !== 0 ? desks : undefined}
          bookedBy={bookedBy}
          showDedicatedWarning={false}
        />
        <Box
          sx={(theme) => ({
            textAlign: "center",
            marginTop: 3,
            [theme.breakpoints.down("md")]: {
              marginTop: 1,
            },
          })}
        >
          <Typography variant="h4" color="primary.main" sx={MobileHeading}>
            {isInvitation
              ? t("confirmation.booked-for-others.invite.group")
              : t("confirmation.booked-for-others.title")}
          </Typography>
          <Typography variant="body2" color="shades.grey">
            {t("confirmation.booked-for-others.subtitle")}
          </Typography>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
