import { Components } from "@mui/material";

export const MuiPaper: Components["MuiPaper"] = {
  styleOverrides: {
    root: {
      boxShadow: "0px 4px 4px rgba(20, 17, 16, 0.05)",
      borderRadius: "16px",
    },
  },
};
