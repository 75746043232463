import { Box, Button, InputBase } from "@mui/material";
import React, { useState } from "react";
import { Search, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { trackBookingSearch } from "../../../../../../tracking";
import { useBookingFilters } from "../../state";

export interface SearchFilterProps {
  onClose: () => void;
}

const SearchInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ value, onChange }) => {
  const { t } = useTranslation("booking");
  const [focused, setFocus] = useState(false);

  const handleSearch = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    250
  );

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <Box
        sx={{
          border: "1px solid",
          borderColor: "shades.greyLight",
          borderRight: 0,
          borderRadius: "8px 0 0 8px",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          lineHeight: 0,
          padding: ({ spacing }) => spacing(1, 4, 1, 1.5),
          width: "calc(100% - 18px)",
          height: "36px",
          ...(focused && {
            border: "1px solid",
            borderColor: "primary.main",
          }),
        }}
      >
        <InputBase
          fullWidth
          placeholder={t("filter.search.placeholder")}
          defaultValue={value}
          autoFocus
          onClick={() => trackBookingSearch()}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={handleSearch}
          data-testid="book--filters--search-input"
          sx={(theme) => ({
            ...theme.typography.body2,
            lineHeight: 0,
            ".MuiInputBase-input": { padding: 0 },
          })}
        />
      </Box>
      <Button
        variant="icon"
        color="secondary"
        sx={{
          position: "absolute",
          right: 0,
          ...(focused && {
            borderColor: "primary.main",
            color: "primary.main",
          }),
        }}
      >
        <Search size={16} />
      </Button>
    </Box>
  );
};

export const SearchFilter: React.FC<SearchFilterProps> = ({ onClose }) => {
  const [{ search }, { changeSearchFilter }] = useBookingFilters();

  return (
    <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", gap: 1 }}>
      <Box flexGrow={1}>
        <SearchInput value={search} onChange={changeSearchFilter} />
      </Box>
      <Button
        variant="icon"
        color="secondary"
        data-testid="book--filters--close"
        onClick={onClose}
      >
        <X size={16} />
      </Button>
    </Box>
  );
};
