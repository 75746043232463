import { formatISO } from "date-fns";
import { getJson } from "../../../api";
import {
  FavoriteLocationDto,
  LocationDto,
  mapDesk,
  mapFloor,
  mapLocation,
  mapSpace,
} from "../../assets/api";
import { mapNotification } from "../../notifications/api";
import { Capacity, LocationCapacity, UpcomingBooking } from "../domain";
import { mapBooking, mapDeskBooking } from "./booking";
import { CapacityDto, formatISODate, UpcomingDeskBookingDto } from "./dto";

export interface LocationCapacityResponse {
  location: LocationDto;
  capacity: CapacityDto;
  newLocation: boolean;
}

function mapUpcomingDeskBooking(
  response: UpcomingDeskBookingDto
): UpcomingBooking {
  const location = mapLocation(response.location);
  const booking =
    response.desk && response.floor && response.space
      ? mapDeskBooking(
          response.booking,
          location,
          mapFloor(response.floor),
          mapSpace(response.space),
          mapDesk(response.desk)
        )
      : mapBooking(response.booking, location);
  return new UpcomingBooking(
    booking,
    response.notification ? mapNotification(response.notification) : null
  );
}

function mapLocationCapacity(
  response: LocationCapacityResponse
): LocationCapacity {
  return new LocationCapacity(
    mapLocation(response.location),
    new Capacity(
      response.capacity.available,
      response.capacity.occupied,
      response.capacity.total,
      response.capacity.totalLocationBookings ?? 0
    ),
    response.newLocation
  );
}

export async function getUpcomingBookings(
  membershipId: string,
  from: Date
): Promise<UpcomingBooking[]> {
  const isoDate = formatISO(from, { representation: "date" });
  const response = await getJson<UpcomingDeskBookingDto[]>(
    "/api/upcoming-desk-bookings",
    {
      "organization-membership.id": membershipId,
      from: isoDate,
    }
  );

  return response.map(mapUpcomingDeskBooking);
}

export async function getLocationCapacity(
  organizationId: string,
  date: Date
): Promise<LocationCapacity[]> {
  const isoDate = formatISODate(date);
  const response = await getJson<LocationCapacityResponse[]>(
    "/api/desk-bookings/capacity",
    {
      "organization.id": organizationId,
      date: isoDate,
    }
  );

  return response.map(mapLocationCapacity);
}

export async function getFavoriteLocations(
  organizationMembershipId: string
): Promise<FavoriteLocationDto[]> {
  const response = await getJson<FavoriteLocationDto[]>(
    "/api/favorite-locations/user",
    {
      "filter.organizationMembership.id": organizationMembershipId,
    }
  );
  return response;
}
