import { SVGSelection } from "./types";

const filterDefs = `
<!-- drop shadow -->
<filter id="filter0_d_757_16118" x="-5" y="-5" width="54" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feGaussianBlur stdDeviation="1"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.0666667 0 0 0 0 0.0627451 0 0 0 0.2 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_757_16118"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_757_16118" result="shape"/>
</filter>`;

export function addDefinitions($svg: SVGSelection) {
  // Add avatar clip path
  $svg
    .append("clipPath")
    .attr("id", "avatar-clip")
    .append("circle")
    .attr("cx", 16)
    .attr("cy", 16)
    .attr("r", 16);

  // Add Filter definitions
  $svg.append("defs").each(function defs() {
    this.innerHTML = filterDefs;
  });
}
