import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { formatISO } from "date-fns";
import qs from "query-string";
import { useNavigate } from "react-router-dom";

export interface BookingNavigationParams {
  date: Date;
  locationId: string;
  bookingId?: string;
  floorId?: string;
  deskId?: string;
  action?: "view" | "edit";
}

export type BookingNavigationFn = (params: BookingNavigationParams) => void;

export function useBookingNavigation(): BookingNavigationFn {
  const navigate = useNavigate();

  return useMemoizedFn((params: BookingNavigationParams) => {
    const query = qs.stringify({
      date: formatISO(params.date, {
        representation: "date",
      }),
      location: params.locationId,
      ...(params.bookingId && {
        booking: params.bookingId,
      }),
      ...(params.floorId && {
        floor: params.floorId,
      }),
      ...(params.deskId && {
        desk: params.deskId,
      }),
      action: params.action ?? "view",
    });

    navigate({
      pathname: "/book",
      search: `?${query}`,
    });
  });
}
