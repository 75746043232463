import FlagProvider, { IConfig } from "@unleash/proxy-client-react";
import { Provider } from "jotai";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Navigate } from "react-router";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApplicationTheme } from "./ApplicationTheme";
import { AdminManagementPage } from "./backoffice/modules/admin-management/pages";
import { AnalyticsPage } from "./backoffice/modules/analytics/pages";
import { AssetManagementPage } from "./backoffice/modules/asset-management/pages";
import { InventoryManagementPage } from "./backoffice/modules/inventory-management/pages";
import { MuiLocalizationProvider } from "./components";
import { Configuration } from "./config";
import { LocalizedDateFnsProvider } from "./hooks";
import "./hotjar";
import "./i18n";
import "./mixpanel";
import { ProtectedRoute } from "./modules/authentication/components";
import {
  AdminRoute,
  SuperAdminRoute,
} from "./modules/authentication/components/AdminRoute";
import { AccountSettingsPage, LoginPage } from "./modules/authentication/pages";
import { BookingPage, MyBookingsPage } from "./modules/booking/pages";
import { SearchPage } from "./modules/search/pages/ReservationHistoryPage/SearchPage";
import {
  NotificationSettingsPage,
  SageHrSettingsPage,
} from "./modules/settings/modules";
import { GoogleCalendarSettingsPage } from "./modules/settings/modules/google-calendar-integration/pages";
import { SettingsPage } from "./modules/settings/pages";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60_000,
    },
    mutations: {
      retry: false,
    },
  },
});

const unleashConfig: IConfig = {
  url: Configuration.unleash.url,
  clientKey: Configuration.unleash.clientKey,
  refreshInterval: Configuration.unleash.refreshInterval,
  appName: Configuration.unleash.appName,
  fetch: (url: URL | RequestInfo, params: RequestInit | undefined) => {
    return fetch(url, {
      ...params,
      credentials: "omit",
    });
  },
};

const ApplicationRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/*" element={<ProtectedRoute />}>
          <Route path="settings" element={<SettingsPage />}>
            <Route path="sage-hr" element={<SageHrSettingsPage />} />
            <Route path="account" element={<AccountSettingsPage />} />
            <Route
              path="google-calendar"
              element={<GoogleCalendarSettingsPage />}
            />
            <Route
              path="notifications"
              element={<NotificationSettingsPage />}
            />
            <Route
              index
              element={<Navigate to="/settings/account" replace={true} />}
            />
          </Route>
          <Route path="book" element={<BookingPage />} />
          <Route index element={<MyBookingsPage />} />
          <Route path="search-people" element={<SearchPage />} />
          <Route path="backoffice" element={<AdminRoute />}>
            <Route path="analytics" element={<AnalyticsPage />} />
            <Route path="asset-management" element={<AssetManagementPage />} />
            <Route
              path="inventory-management"
              element={<InventoryManagementPage />}
            />
            <Route path="admin-management" element={<SuperAdminRoute />}>
              <Route path="" element={<AdminManagementPage />} />
            </Route>
            <Route
              index
              element={<Navigate to="/backoffice/analytics" replace={true} />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root")!!);
root.render(
  <React.StrictMode>
    <MuiLocalizationProvider>
      <LocalizedDateFnsProvider>
        <ApplicationTheme>
          <Provider>
            <QueryClientProvider client={queryClient}>
              <FlagProvider config={unleashConfig}>
                <ApplicationRoutes />
              </FlagProvider>
            </QueryClientProvider>
          </Provider>
        </ApplicationTheme>
      </LocalizedDateFnsProvider>
    </MuiLocalizationProvider>
  </React.StrictMode>
);
