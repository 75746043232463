import { Location, Space } from "../../modules/assets/domain";
import { DeskStatus } from "../modules/asset-management/domain";

export class DeskWithStatus {
  constructor(
    public readonly desk: DeskWithSpace,
    public readonly deskStatus: DeskStatus,
    public readonly assetRuleId: string
  ) {}
}

export class LocationWithCapacity {
  constructor(
    public readonly location: Location,
    public readonly assetRuleCapacity: AssetRuleCapacity
  ) {}
}

export class AssetRuleCapacity {
  constructor(
    public readonly free: number,
    public readonly dedicated: number,
    public readonly disabled: number
  ) {}
}

export class DeskWithSpace {
  constructor(
    public readonly id: string,
    public readonly locationId: string,
    public readonly floorId: string,
    public readonly identifier: string,
    public readonly space: Space
  ) {}
}
