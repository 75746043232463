import { Undo } from "@mui/icons-material";
import { Box, BoxProps, Button, lighten, styled, Tooltip } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import React, { forwardRef, useState } from "react";
import { ArrowLeft, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "../../../../../hooks";
import { trackExitEditMode, trackResetFilters } from "../../../../../tracking";
import { useEditModeVariation } from "../../../../feature-flag/hooks";
import { ExitEditModeConfirmationDialog } from "../Dialog";
import { useBookingDispatch, useBookingForm } from "../state";
import { Title } from "./components";
import { DesktopBookingFiltersGrid } from "./DesktopBookingFiltersGrid";

const Bar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2, 2, 2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  boxShadow: "0px 4px 4px rgba(20, 17, 16, 0.05)",
  borderBottom: `1px solid ${lighten("#191A20", 0.8)}`,
  backgroundColor: theme.palette.green.light,
}));

export interface DesktopBookingFiltersProps extends BoxProps {
  isEditing: boolean;
}

export const DesktopBookingFilters: React.FC<DesktopBookingFiltersProps> =
  forwardRef(({ sx, isEditing, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { onResetFilters } = useBookingDispatch();
    const { source, exitEditMode } = useBookingForm();
    const navigate = useNavigate();
    const { t } = useTranslation("booking");
    const [redirectOnExitEditMode, setRedirectOnExitEditMode] =
      useState<boolean>(false);
    const variation = useEditModeVariation();

    const dialog = usePopupState({
      popupId: "bookingPageNavigationBlocker",
      variant: "dialog",
    });

    const handleBack = useMemoizedFn(() => {
      if (isEditing) {
        setRedirectOnExitEditMode(true);
        dialog.open();
      } else {
        navigate("/");
      }
    });

    const handleConfirmNavigation = useMemoizedFn(() => {
      dialog.close();
      exitEditMode();

      // Retrying inline will trigger the block again.
      setTimeout(() => {
        trackExitEditMode(
          redirectOnExitEditMode ? "navigation" : "exit-button"
        );
        if (redirectOnExitEditMode) {
          const redirectUrl = source?.redirectTo.getUrl();
          navigate(redirectUrl ?? "/");
        }
      }, 0);
    });

    const handleExitEditMode = useMemoizedFn(() => {
      setRedirectOnExitEditMode(false);
      dialog.open();
    });

    const handleResetFilters = useMemoizedFn(() => {
      trackResetFilters();
      onResetFilters();
    });

    return (
      <Bar
        ref={ref}
        {...props}
        sx={[
          (theme) => ({
            ...(!isEditing && {
              [theme.breakpoints.down("md")]: {
                backgroundColor: theme.palette.green.regular,
              },
            }),

            ...(isEditing && {
              backgroundColor: "shades.greyExtraLight",

              ...(!isMobile && {
                borderBottomColor: "shades.greyLight",
                boxShadow: "none",
              }),
            }),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <ExitEditModeConfirmationDialog
          onConfirm={handleConfirmNavigation}
          {...bindDialog(dialog)}
        />
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "36px",
          }}
        >
          <Box sx={{ position: "absolute", left: 0 }}>
            <Tooltip title={t("filter.title.back")}>
              <Button
                color={isMobile && !isEditing ? "dark" : "secondary"}
                variant="icon"
                onClick={handleBack}
              >
                <ArrowLeft size={16} />
              </Button>
            </Tooltip>
          </Box>
          <Title />
          <Box sx={{ position: "absolute", right: 0, display: "flex", gap: 1 }}>
            {variation.resetVisible && (
              <Tooltip title={t("filter.title.reset")}>
                <Button
                  color={isMobile && !isEditing ? "dark" : "secondary"}
                  variant="icon"
                  sx={{
                    fontSize: "16px",
                  }}
                  onClick={handleResetFilters}
                >
                  <Undo fontSize="inherit" />
                </Button>
              </Tooltip>
            )}
            {isEditing && variation.exitVisible && (
              <Tooltip title={t("filter.title.exit-edit")}>
                <Button
                  color="secondary"
                  variant="icon"
                  onClick={handleExitEditMode}
                >
                  <X size={16} />
                </Button>
              </Tooltip>
            )}
          </Box>
        </Box>
        <DesktopBookingFiltersGrid />
      </Bar>
    );
  });
