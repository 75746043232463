import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { approveInvitation } from "../api";
import { Booking } from "../domain";
import { useApproveInvitation, useInviteEvents } from "./useApproveInvitation";

export function useApproveInvitationMutation(): UseMutationResult<
  Booking,
  Error,
  Booking
> {
  const { t } = useTranslation("booking-dialog");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const inviteEvents = useInviteEvents();
  const { closeDialog } = useApproveInvitation();

  return useMutation(
    "approve-invite",
    async (booking: Booking) => {
      await approveInvitation(booking.id);
      return booking;
    },
    {
      onSuccess: (booking) => {
        inviteEvents.emit(booking);
        closeDialog();
      },
      onError: () => {
        snackbar.enqueueSnackbar(t("approve-invite.feedback.error"), {
          variant: "error",
        });
      },
      onSettled: () => {
        qc.invalidateQueries(["bookings"]);
        qc.invalidateQueries(["upcoming-bookings"]);
        qc.invalidateQueries(["booking-groups"]);
        qc.invalidateQueries(["notifications"]);
      },
    }
  );
}
