import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../api";
import { OrganizationMembership } from "../../authentication/domain";
import { useCurrentUser } from "../../authentication/hooks";
import { followMembership } from "../api/followMembership";

export function useFollowMembershipMutation(): UseMutationResult<
  OrganizationMembership | null,
  AmtError,
  string
> {
  const { t } = useTranslation("search");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const currentUser = useCurrentUser();

  return useMutation(
    ["create-group-booking"],
    async (membershipId: string) => {
      return followMembership(membershipId, currentUser.organizations[0].id);
    },
    {
      onError: (err: AmtError) => {
        console.log(err);
        snackbar.enqueueSnackbar(t("feedback.follow-membership-error"), {
          variant: "error",
        });
      },
      onSettled: () => {
        qc.invalidateQueries(["filtered-reservations"]);
        qc.invalidateQueries(["search-booking-params"]);
      },
    }
  );
}
