import { Box, Fade, Popper, styled } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Floor } from "../../../../assets/domain";
import { DeskOverview } from "../../../../booking/domain";
import { DeskTooltip, FloorTooltip } from "./Components";

export interface FloorPlanTooltipsHandle {
  showDesk?: (el: Element, desk: DeskOverview) => void;

  showFloor(el: Element, floor: Floor): void;

  hide(): void;
}

export interface FloorPlanTooltipsProps {}

const StyledTooltip = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  color: "white",
  backgroundColor: theme.palette.shades.darkExtra,
  borderRadius: "8px",
  maxWidth: 350,
  display: "flex",
  flexDirection: "column",
  gap: 0.5,

  // @ts-ignore
  ...theme.typography.subtitle2,
}));

const FloorPlanTooltipsComponent: React.ForwardRefRenderFunction<
  FloorPlanTooltipsHandle,
  FloorPlanTooltipsProps
> = (props, ref) => {
  const [state, setState] = useState<{
    desk?: DeskOverview;
    floor?: Floor;
    el?: Element;
    open: boolean;
  }>({ open: false });

  const showDesk = useMemoizedFn((el: Element, desk: DeskOverview) => {
    setState({
      el,
      desk,
      open: true,
    });
  });
  const showFloor = useMemoizedFn((el: Element, floor: Floor) => {
    setState({
      el,
      floor,
      open: true,
    });
  });
  const hide = useMemoizedFn(() => {
    setState({
      ...state,
      open: false,
    });
  });

  useImperativeHandle(ref, () => ({
    showDesk,
    showFloor,
    hide,
  }));

  if (!state.el) {
    return null;
  }

  return (
    <Popper open={state.open} anchorEl={state.el} placement="top" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <StyledTooltip>
            {state.desk && <DeskTooltip desk={state.desk} />}
            {state.floor && <FloorTooltip floor={state.floor} />}
          </StyledTooltip>
        </Fade>
      )}
    </Popper>
  );
};

export const FloorPlanTooltips = forwardRef(FloorPlanTooltipsComponent);
