import { Box, styled } from "@mui/material";

export const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(0.5),
}));

export const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexShrink: 0,
  flexGrow: 0,
  flexBasis: "24px",
  width: "24px",
  color: theme.palette.shades.grey,
  ...theme.typography.body2,
}));

export const Label = styled("span")(({ theme }) => ({
  color: theme.palette.shades.grey,
}));

export const Info = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexGrow: 1,
  ...theme.typography.body2,
}));
