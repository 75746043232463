import { Components, Theme } from "@mui/material";

export const MuiChip: Components<Theme>["MuiChip"] = {
  styleOverrides: {
    // @ts-ignore
    root: ({ theme }) => ({
      paddingX: 0.5,
      paddingY: 1.5,
      borderRadius: "8px",
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightRegular,
      width: "auto",
      height: "auto",
      display: "inline-flex",
      gap: 2,
    }),
    label: {
      padding: 0,
    },
    icon: {
      marginRight: "8px",
    },
    deleteIcon: ({ theme }) => ({
      margin: 0,
      marginRight: -6,
      backgroundColor: "rgba(20, 17, 16, 0.2)",
      color: theme.palette.cyclamen.light,
      borderRadius: 8,
      width: 16,
      height: 16,
      display: "flex",
      alignItems: "center",
      "&:hover": {
        backgroundColor: theme.palette.shades.dark,
        color: theme.palette.cyclamen.light,
      },
    }),
    outlined: ({ theme }) => ({
      border: `1px solid ${theme.palette.shades.greyLight}`,
      color: theme.palette.shades.grey,
      fontWeight: 600,
      padding: "4px 12px",

      "&.Mui-selected": {
        backgroundColor: theme.palette.shades.greyUltimateLight,
        color: theme.palette.shades.dark,
      },
    }),
    clickable: ({ theme }) => ({
      "&:hover": {
        color: theme.palette.shades.dark,
        border: `1px solid ${theme.palette.shades.grey}`,
        backgroundColor: "white !important",
      },
    }),
  },
};
