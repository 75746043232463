import { Box, Typography } from "@mui/material";
import React from "react";
import { ZoomIn } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtCardHoverOverlay } from "../../../../../components";
import { Floor } from "../../../../assets/domain";

export interface FloorCardHoverOverlayProps {
  className?: string;
  floor: Floor;
}

export const FloorCardHoverOverlay: React.FC<FloorCardHoverOverlayProps> = ({
  className,
  floor,
}) => {
  const { t } = useTranslation("floor-plan");
  return (
    <AmtCardHoverOverlay
      className={className}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          color: "white",
          backgroundColor: "shades.darkExtra",
          paddingX: 2,
          paddingY: 1,
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          gap: 1,
        }}
      >
        <Box sx={{ textAlign: "center", flex: "0 1 auto", lineHeight: 0 }}>
          <ZoomIn size={16} />
        </Box>
        <Typography variant="subtitle2" sx={{ flex: "0 1 auto" }}>
          {t("zoom", { floorName: floor.description })}
        </Typography>
      </Box>
    </AmtCardHoverOverlay>
  );
};
