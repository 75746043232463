import { useTranslation } from "react-i18next";
import { useDateFns } from "../../../../../hooks";
import { BookingDate } from "../../../domain";

export interface DropInTooltipProps {
  date: BookingDate;
}

const DropInAvailableTooltip: React.FC<DropInTooltipProps> = ({ date }) => {
  const { formatRange } = useDateFns();
  const { t } = useTranslation("floor-plan");

  return (
    <>
      {t(
        date.isSingleDay
          ? "tooltip.single-day.available-drop-in"
          : "tooltip.date-range.available-drop-in"
      )}
      :
      <br />
      {formatRange(date)}
    </>
  );
};

export const DropInTooltip: React.FC<DropInTooltipProps> = ({ date }) => {
  return <DropInAvailableTooltip date={date} />;
};
