import { createSelector } from "reselect";
import { Floor, Space } from "../../../../../assets/domain";
import { ConferenceRoom } from "../../../../../conference/domain";
import {
  DeskBookings,
  DeskOverview,
  LocationBookingOverview,
  LocationBookings,
  SpaceOverview,
} from "../../../../domain";
import {
  BookingFiltersState,
  filterDesksBySearch,
  filterLocationsBySearch,
} from "../useBookingFilters";
import { UseBookingFormResult } from "../useBookingForm";
import {
  sortConferenceRooms,
  sortLocationBookings,
  sortSpacesAndDesks,
} from "./sorted";
import { Spaces } from "./Spaces";
import { BookingState } from "./types";

export function selectDeskBookings(
  state: BookingState
): DeskBookings | undefined {
  return state.bookings.data?.deskBookings;
}

export function selectConferenceRooms(state: any): any | undefined {
  return state.data;
}

export function selectLocationBookings(
  state: BookingState
): LocationBookings | undefined {
  return state.bookings.data?.locationBookings;
}

export const selectTotalBooked = createSelector(
  selectDeskBookings,
  (deskBookings) => (deskBookings ? countTotalBooked(deskBookings) : 0)
);

export const selectIsFullCapacity = createSelector(
  selectDeskBookings,
  (deskBookings) => (deskBookings ? isFullCapacity(deskBookings) : false)
);

export const selectSortedDeskBookings = createSelector(
  selectDeskBookings,
  (deskBookings) => {
    const spaces = deskBookings?.spaces.slice() ?? [];
    return new DeskBookings(sortSpacesAndDesks(spaces));
  }
);

export const selectFilteredDeskBookings = createSelector(
  [selectSortedDeskBookings, (state, filters: BookingFiltersState) => filters],
  (deskBookings, filters) => {
    const availability = filters.availability;
    return deskBookings.spaces
      .map((it) => {
        const desks = it.desks.filter(availability.filterDesk);
        return new SpaceOverview(
          it.space,
          it.floor,
          it.location,
          filterDesksBySearch(desks, filters.search)
        );
      })
      .filter((it) => it.desks.length > 0);
  }
);

export const selectAllSpaces = createSelector(
  selectSortedDeskBookings,
  (deskBookings) => deskBookings.spaces.map((it) => it.space)
);

export const selectDisplayedSpaces = createSelector(
  selectFilteredDeskBookings,
  (deskBookings) => {
    const filteredSpaces: [Floor, Space][] = deskBookings.map((it) => [
      it.floor,
      it.space,
    ]);
    return Spaces.of(filteredSpaces);
  }
);

export const getSelectedFloor = createSelector(
  [
    (spaces: Spaces): Space[] => spaces.flat,
    (spaces: Spaces, spaceId: string | null): string | null => spaceId,
  ],
  (spaces: Space[], spaceId: string | null): Space | null => {
    return spaces.find((space) => space.id === spaceId) ?? null;
  }
);

export const selectSortedLocationBookings = createSelector(
  selectLocationBookings,
  (locationBookings): LocationBookingOverview[] => {
    if (!locationBookings) {
      return [];
    }
    return locationBookings.locations.flatMap((it) =>
      sortLocationBookings(it.userBookings)
    );
  }
);

export const selectFilteredLocationBookings = createSelector(
  [
    selectSortedLocationBookings,
    (state, filters: BookingFiltersState) => filters,
  ],
  (locationBookings, filters) => {
    const filteredByAvailability = locationBookings.filter((it) =>
      filters.availability.filterLocationBooking(it)
    );
    return filterLocationsBySearch(filteredByAvailability, filters.search);
  }
);

export const selectSortedConferenceRooms = createSelector(
  selectConferenceRooms,
  (conferenceRooms): ConferenceRoom[] => {
    if (!conferenceRooms) {
      return [];
    }
    return sortConferenceRooms(conferenceRooms);
  }
);

export const selectFilteredConferenceRooms = createSelector(
  [
    selectSortedConferenceRooms,
    (state, locationId: string | null) => locationId,
  ],
  (conferenceRooms, locationId) => {
    const filteredByLocation = conferenceRooms.filter(
      (it) => it.location.id === locationId
    );
    return filteredByLocation;
  }
);

/**
 * Desk can be booked if it is available,
 * or if edit mode is active + we're trying to edit the desk containing the
 * original booking.
 */
export function canBook(
  desk: DeskOverview,
  form: UseBookingFormResult
): boolean {
  return (
    desk.availability.canBook ||
    (desk.bookings.length === 1 &&
      form.isEditing &&
      desk.bookings[0].id === form.source.bookingId)
  );
}

function isFullCapacity(overview: DeskBookings): boolean {
  return !overview.spaces.some((space) =>
    space.desks.some((desk) => desk.bookings.length === 0)
  );
}

function countTotalBooked(overview: DeskBookings): number {
  return overview.spaces.reduce((acc, space) => {
    return (
      acc +
      space.desks.reduce((acc, desk) => {
        return acc + desk.bookings.length;
      }, 0)
    );
  }, 0);
}
