import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { useCurrentUser } from "../../../../authentication/hooks";
import { changeNotificationSettings, getNotificationSettings } from "../api";
import { NotificationSetting } from "../domain";

export function useNotificationSettings(): UseQueryResult<
  NotificationSetting[]
> {
  const user = useCurrentUser();
  return useQuery(
    ["notification-preferences", user.id],
    () => getNotificationSettings(user.id),
    {
      placeholderData: [
        NotificationSetting.bookingConfirmationBySelf(false),
        NotificationSetting.allBookingsByOthers(false),
        NotificationSetting.bookingConfirmationByOthers(false),
        NotificationSetting.bookingInvitationConfirmationBySelf(false),
        NotificationSetting.bookingInvitationCancellationBySelf(false),
        NotificationSetting.myBookingInvitations(false),
        NotificationSetting.bookingInvitationConfirmationByInvitee(false),
        NotificationSetting.bookingInvitationCancellationByInvitee(false),
        NotificationSetting.followingBooking(false),
        NotificationSetting.bookingCancellation(false),
      ],
    }
  );
}

export function useChangeNotificationSettings(): UseMutationResult<
  unknown,
  unknown,
  { key: string; enabled: boolean }
> {
  const user = useCurrentUser();
  const queryClient = useQueryClient();
  return useMutation(
    ["notification-preferences", user.id],
    ({ key, enabled }) => {
      return changeNotificationSettings(user.id, { [key]: enabled });
    },
    {
      onSuccess: (data: NotificationSetting[]) => {
        queryClient.setQueryData(["notification-preferences", user.id], data);
      },
    }
  );
}
