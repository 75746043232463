import { useSearchParams } from "react-router-dom";
import { useOrganizationMembership } from "../../modules/authentication/hooks";
import { LocationWithCapacity } from "../domain";
import { useLocationsWithCapacityQuery } from "../hooks";

export interface UseLocationsResult {
  locations: LocationWithCapacity[];
  location: LocationWithCapacity | null;
  locationId: string | null;
}

function getLocationFromSearchParams(
  searchParams: URLSearchParams,
  locations: LocationWithCapacity[]
): [LocationWithCapacity | null, string | null] {
  const locationId = searchParams.get("location");
  if (!locationId) return [null, null];
  const location = locations.find((it) => it.location.id === locationId);
  const selectedLocation = location ? location : null;
  return [selectedLocation, selectedLocation?.location.id ?? locationId];
}

export function useBackofficeLocations(): UseLocationsResult {
  const { organization } = useOrganizationMembership();
  const [searchParams] = useSearchParams();
  const locationsQuery = useLocationsWithCapacityQuery(organization.id);
  const locations = locationsQuery.data ?? [];
  const [location, locationId] = getLocationFromSearchParams(
    searchParams,
    locations
  );

  return {
    locations,
    location,
    locationId,
  };
}
