import { getJson } from "../../../api";
import { ConferenceRoom } from "../domain";
import { ConferenceRoomDto, mapConferenceRoom } from "./getCalendarEvents";

interface ConferenceRoomResponse {
  content: ConferenceRoomDto[];
}

export async function getConferenceRooms(
  organizationId: string
): Promise<ConferenceRoom[]> {
  const response = await getJson<ConferenceRoomResponse>(
    "/api/conference-rooms",
    {
      "filter.organization.id": organizationId,
    }
  );
  return response.content.map((room) => {
    return mapConferenceRoom(room);
  });
}
