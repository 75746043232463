import { Box, Button, styled, Tooltip, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { DropInTooltip } from "../FloatingLocationBookings/DropInTooltip";
import {
  LocationBookingRequest,
  useBookingDate,
  useBookingForm,
  useLocations,
} from "../state";

const StyledIconButton = styled(Box)(({ theme }) => ({
  width: 36,
  height: 36,
  borderRadius: "18px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  border: "1px solid",
  borderColor: "white",
  color: "white",
}));

const StyledFAB = styled(Button)(({ theme }) => ({
  position: "fixed",
  right: 24,
  bottom: 26,

  backgroundColor: theme.palette.cyclamen.regular,
  boxShadow: "0px 1px 2px rgba(20, 17, 16, 0.2)",
  color: "white",
  padding: theme.spacing(0, 0, 0, 2),
  borderRadius: "18px",

  "&:hover": {
    backgroundColor: theme.palette.cyclamen.hover,
    color: "white",
    ".Amt--BookLocationFAB--Icon": {
      backgroundColor: "white",
      borderColor: theme.palette.cyclamen.hover,
      color: theme.palette.cyclamen.hover,
    },
  },
  "&:focus, &:focus-visible": {
    color: "white",
  },
  "&:disabled": {
    backgroundColor: theme.palette.cyclamen.regular,
    color: "rgb(255,255,255,0.45)",
    opacity: 1,
    ".Amt--BookLocationFAB--Icon": {
      color: "rgb(255,255,255,0.45)",
      borderColor: "rgb(255,255,255,0.45)",
    },
  },

  [theme.breakpoints.down("md")]: {
    position: "relative",
    right: 0,
    bottom: 0,
  },
}));

export const BookLocationFAB: React.FC = () => {
  const { location } = useLocations();
  const { date } = useBookingDate();
  const { openDialog } = useBookingForm();
  const handleClick = useMemoizedFn(() => {
    if (location) {
      openDialog(new LocationBookingRequest(date, location));
    }
  });
  const { t } = useTranslation("booking");

  return (
    <Tooltip title={date && <DropInTooltip date={date} />} placement="top-end">
      <StyledFAB onClick={handleClick} disabled={!location}>
        <Typography variant="body2">{t("drop-in.title")}</Typography>
        <StyledIconButton className="Amt--BookLocationFAB--Icon">
          <Plus size={16} />
        </StyledIconButton>
      </StyledFAB>
    </Tooltip>
  );
};
