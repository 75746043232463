import { getJson } from "../../../api";
import { mapUser, UserDto } from "../../authentication/api";
import { BookingDate, Group, GroupMember, OrganizationGroups } from "../domain";
import { formatISODate } from "./dto";

interface OrganizationGroupMemberDto {
  id: string;
  user: UserDto;
  bookableForPeriod: boolean;
  team: string | null;
}

interface OrganizationGroupDto {
  name: string;
  members: OrganizationGroupMemberDto[];
}

interface OrganizationGroupsResponse {
  groups: OrganizationGroupDto[];
}

function mapGroupMember(
  response: OrganizationGroupMemberDto,
  group: Group
): GroupMember {
  return new GroupMember(
    response.id,
    mapUser(response.user),
    response.bookableForPeriod,
    group.name,
    response.team
  );
}

function mapGroup(response: OrganizationGroupDto): Group {
  const group = new Group(response.name, []);
  response.members.forEach((it) => {
    const member = mapGroupMember(it, group);
    group.members.push(member);
  });
  return group;
}

function mapOrganizationGroups(
  response: OrganizationGroupsResponse
): OrganizationGroups {
  return new OrganizationGroups(response.groups.map(mapGroup));
}

export async function getBookableGroups(
  organizationId: string,
  date: BookingDate,
  ignoreBookingId: string | null,
  deskId: string | undefined
): Promise<OrganizationGroups> {
  const response = await getJson<OrganizationGroupsResponse>(
    `/api/bookings/groups`,
    {
      "start-date": formatISODate(date.startDate),
      "end-date": formatISODate(date.endDate),
      "organization.id": organizationId,
      ...(ignoreBookingId !== null && {
        "filter.ignore-booking.id": ignoreBookingId,
      }),
      ...(deskId && { "desk.id": deskId }),
    }
  );
  return mapOrganizationGroups(response);
}
