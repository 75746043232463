import { Button, Typography } from "@mui/material";
import { bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { Reservation } from "../../../../backoffice/modules/analytics/api";
import { BookingInfoMenu } from "../Dropdowns/BookingInfoMenu";

interface BookingInfoButtonProps {
  reservation: Reservation;
}

export const BookingInfoButton: React.FC<BookingInfoButtonProps> = ({
  reservation,
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "roomFilterMenu",
  });

  return (
    <>
      <Button
        variant="iconLinkOutlined"
        sx={{
          flex: "0 0 auto",
          backgroundColor: "cyclamen.light",
          border: "1px",
          height: "36px",
          width: "28px",
          margin: "0px 5px",
          borderColor: "cyclamen.regular",
          color: "cyclamen.regular",
          borderStyle: reservation.desk ? "solid" : "dashed",
          "&:hover": {
            backgroundColor: "cyclamen.light",
            borderColor: "cyclamen.regular",
            color: "cyclamen.regular",
          },
          "&:focus": {
            backgroundColor: "cyclamen.light",
            borderColor: "cyclamen.regular",
            color: "cyclamen.regular",
          },
        }}
        {...bindTrigger(popupState)}
      >
        <Typography variant="overline">
          {reservation.location.address.cityShort}
        </Typography>
      </Button>
      <BookingInfoMenu popupState={popupState} reservation={reservation} />
    </>
  );
};
