import { atom, useAtom } from "jotai";
import { DeskInventoryFilters } from "../../../domain";

interface SelectedInventoryFiltersResult {
  selectedInventoryFilters: DeskInventoryFilters[];
  setSelectedInventoryFilters: (filters: DeskInventoryFilters[]) => void;
}

const selectedInventoryFiltersAtom = atom<DeskInventoryFilters[]>([]);

export function useSelectedInventoryFilters(): SelectedInventoryFiltersResult {
  const [selectedInventoryFilters, setSelectedInventoryFilters] = useAtom(
    selectedInventoryFiltersAtom
  );

  return { selectedInventoryFilters, setSelectedInventoryFilters };
}
