import { Button, ButtonProps, Tooltip } from "@mui/material";
import React from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";

export const ClosePreviewButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation("floor-plan");
  return (
    <Tooltip title={t("close-preview")}>
      <Button
        color="secondary"
        variant="icon"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          minWidth: "auto",
        }}
        {...props}
      >
        <X size={16} />
      </Button>
    </Tooltip>
  );
};
