import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import { fieldToTextField } from "formik-mui";
import React from "react";
import { TextInput } from ".";

export interface FormikTextInputProps
  extends FieldProps<string>,
    Omit<TextFieldProps, "name" | "value" | "error" | "onChange"> {
  inputType: "text" | "number";
}

export const FormikTextInput: React.FC<FormikTextInputProps> = ({
  inputType,
  ...props
}) => (
  <TextInput
    {...fieldToTextField(props)}
    inputType={inputType}
    value={props.field.value}
    onChange={(value: string) => {
      props.field.onChange({
        name: props.field.name,
        target: {
          name: props.field.name,
          value,
        },
      });
    }}
  />
);
