import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ContentCell } from ".";
import { AmtAlert } from "../../../../../components";
import { AssetInventory } from "../../../../../modules/booking/domain";
import { HeaderCell } from "../../../../components/Tables";
import { useDeleteInventoryForm } from "../../state";

interface InventoryTableProps {
  inventories: AssetInventory[];
}

export const InventoryTable: React.FC<InventoryTableProps> = ({
  inventories,
}) => {
  const { t } = useTranslation("inventory-management");
  const form = useDeleteInventoryForm();

  return (
    <Box
      sx={{
        marginTop: "36px",
        border: "1px solid",
        borderColor: "shades.greyExtraLight",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          maxHeight: "100vh",
          overflow: "auto",
          "&:focus": {
            boxShadow: "0 0 0.5em rgba(0, 0, 0, 0.5)",
            outline: 0,
          },
        }}
      >
        <Table
          sx={{
            whiteSpace: "nowrap",
            margin: "0",
            borderCollapse: "separate",
            borderSpacing: "0",
            width: "100%",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "&:hover": {
                  color: "red",
                },
              }}
            >
              <HeaderCell label={t("table.header.type")} />
              <HeaderCell label={t("table.header.description")} />
              <HeaderCell label={t("table.header.quantity")} />
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              textAlign: "center",
              position: "relative",
            }}
          >
            {inventories && inventories.length > 0 ? (
              inventories.map((inventory) => {
                return (
                  <TableRow
                    key={inventory.id}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "shades.greyExtraLight",
                      backgroundColor: "white",
                      "&:nth-of-type(even)": {
                        backgroundColor: "#FBFBFB",
                      },
                      "&:last-child": {
                        border: "none",
                        " > td": {
                          borderBottom: "none",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "green.light",
                      },
                    }}
                  >
                    <ContentCell content={inventory.type.name} />
                    <ContentCell content={inventory.description} />
                    <ContentCell
                      content={`${inventory.quantity}`}
                      deleteItem={() => form.openDialog(inventory)}
                    />
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                sx={{
                  padding: "4px 8px",
                  border: "none",
                  " > td": {
                    borderBottom: "none",
                  },
                }}
              >
                <TableCell colSpan={3}>
                  <AmtAlert
                    title={t("table.alert-title")}
                    text={t("table.not-available")}
                    variant="neutral"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
