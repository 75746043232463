import { Button, styled } from "@mui/material";
import React from "react";
import { MapPin } from "react-feather";
import { useMatch } from "react-router";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useIsMobile } from "../../../hooks";

const StyledButton = styled(Button)(({ theme }) => ({
  "&[aria-selected=true]": {
    backgroundColor: theme.palette.shades.darkExtra,
    color: "white",
    boxShadow: "none",
    borderColor: theme.palette.shades.darkExtra,
  },
})) as typeof Button;

export const MyBookingsButton: React.FC = () => {
  const isMobile = useIsMobile();
  const [searchParams] = useSearchParams();
  const match = useMatch({
    path: "/",
    end: true,
  });

  const active = match !== null && searchParams.has("my-bookings");

  if (!isMobile) {
    return null;
  }

  return (
    <StyledButton
      component={RouterLink}
      to="/?my-bookings"
      variant="icon"
      color="primary"
      aria-selected={active}
    >
      <MapPin size={16} />
    </StyledButton>
  );
};
