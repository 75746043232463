import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import { fieldToTextField } from "formik-mui";
import React from "react";
import { DeskStatus } from "../../../../../../domain";
import { StatusPicker } from "./StatusPicker";

export interface FormikStatusPickerProps
  extends FieldProps<DeskStatus>,
    Omit<TextFieldProps, "name" | "value" | "error" | "onChange"> {}

export const FormikStatusPicker: React.FC<FormikStatusPickerProps> = ({
  ...props
}) => (
  <StatusPicker
    {...fieldToTextField(props)}
    value={props.field.value}
    onChange={(value: DeskStatus) => {
      props.field.onChange({
        name: props.field.name,
        target: {
          name: props.field.name,
          value,
        },
      });
    }}
  />
);
