import { useSearchParams } from "react-router-dom";
import {
  AssetInventory,
  AssetInventoryType,
} from "../../modules/booking/domain";
import { useInventoriesQuery } from "../hooks";
import { useInventoryTypesQuery } from "../hooks/useInventoryTypesQuery";

export interface UseInventoriesResult {
  inventories: AssetInventory[];
  inventory: AssetInventory | null;
  inventoryId: string | null;
}

export interface UseInventoryTypesResult {
  inventoryTypes: AssetInventoryType[];
  inventoryType: AssetInventoryType | null;
  inventoryTypeId: string | null;
}

function getInventoryFromSearchParams(
  searchParams: URLSearchParams,
  inventories: AssetInventory[]
): [AssetInventory | null, string | null] {
  const inventoryId = searchParams.get("inventory");
  if (!inventoryId) return [null, null];
  const inventory = inventories.find((it) => it.id === inventoryId);
  const selectedInventory = inventory ? inventory : null;
  return [selectedInventory, selectedInventory?.id ?? inventoryId];
}

function getInventoryTypeFromSearchParams(
  searchParams: URLSearchParams,
  inventoryTypes: AssetInventoryType[]
): [AssetInventoryType | null, string | null] {
  const inventoryTypeId = searchParams.get("inventory-type");
  if (!inventoryTypeId) return [null, null];
  const inventoryType = inventoryTypes.find((it) => it.id === inventoryTypeId);
  const selectedInventoryType = inventoryType ? inventoryType : null;
  return [selectedInventoryType, selectedInventoryType?.id ?? inventoryTypeId];
}

export function useBackofficeInventories(): UseInventoriesResult {
  const [searchParams] = useSearchParams();
  const inventoriesQuery = useInventoriesQuery();
  const inventories = inventoriesQuery.data ?? [];
  const [inventory, inventoryId] = getInventoryFromSearchParams(
    searchParams,
    inventories
  );

  return {
    inventories,
    inventory,
    inventoryId,
  };
}

export function useBackofficeInventoryTypes(): UseInventoryTypesResult {
  const [searchParams] = useSearchParams();
  const inventoryTypesQuery = useInventoryTypesQuery();
  const inventoryTypes = inventoryTypesQuery.data ?? [];
  const [inventoryType, inventoryTypeId] = getInventoryTypeFromSearchParams(
    searchParams,
    inventoryTypes
  );

  return {
    inventoryTypes,
    inventoryType,
    inventoryTypeId,
  };
}
