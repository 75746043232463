import { Box } from "@mui/material";
import React from "react";
import { AmtCenteredLoader } from "../../../components";

export const FullPageLoader: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AmtCenteredLoader />
    </Box>
  );
};
