import { Box, styled } from "@mui/material";
import React from "react";
import { BookLocationFAB } from "../BookLocationFAB";
import {
  MobileDisplayedPage,
  selectSortedLocationBookings,
  useBookingState,
  useMobileDisplayedPage,
} from "../state";
import { FloatingMobileAvatars } from "./FloatingMobileAvatars";
import { FloatingMobileButton } from "./FloatingMobileButton";

export interface FloatingMobileMenuProps {}

const StyledContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  right: 0,
  left: 0,
  bottom: 0,
  width: "100%",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",

  boxShadow: "0px 1px 2px rgba(20, 17, 16, 0.2)",
  color: "white",
}));

export const FloatingMobileMenu: React.FC<FloatingMobileMenuProps> = () => {
  const [mobileDisplayedPage] = useMobileDisplayedPage();
  const state = useBookingState();
  const sortedLocationBookings = selectSortedLocationBookings(state);

  return (
    <StyledContainer
      sx={{
        backgroundColor:
          mobileDisplayedPage === MobileDisplayedPage.LIST &&
          sortedLocationBookings.length
            ? "white"
            : "transparent",
      }}
    >
      {sortedLocationBookings.length && (
        <FloatingMobileAvatars
          sortedLocationBookings={sortedLocationBookings}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FloatingMobileButton />
        <BookLocationFAB />
      </Box>
    </StyledContainer>
  );
};
