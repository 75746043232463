import { Box, Button, styled } from "@mui/material";
import React from "react";
import { X } from "react-feather";
import { useIsMobile } from "../../../hooks";
import { NotificationsButton } from "../../notifications/components";
import { useIsSearchOpen } from "../state";
import { MainLayoutAppBar } from "./MainLayoutAppBar";
import { MyBookingsButton } from "./MyBookingsButton";
import { SearchButton } from "./SearchButton";
import { SettingsMenu } from "./SettingsMenu";

interface ActionsProps {
  isSearchOpen: boolean;
}

const Actions = styled(Box)<ActionsProps>(({ theme, isSearchOpen }) => ({
  display: "flex",
  alignItems: "center",
  flex: isSearchOpen ? "1 1 auto" : "",
  gap: theme.spacing(1),
}));

export const Header: React.FC = () => {
  const isMobile = useIsMobile();
  const { isSearchOpen, setSearchOpen } = useIsSearchOpen();

  return (
    <MainLayoutAppBar
      actions={
        <Actions isSearchOpen={isSearchOpen}>
          {isMobile && isSearchOpen ? (
            <>
              <SearchButton
                isSearchOpen={isSearchOpen}
                setSearchOpen={setSearchOpen}
              />
              <Button
                variant="icon"
                color="primary"
                onClick={() => {
                  if (isMobile) setSearchOpen(false);
                }}
                sx={{
                  opacity: 1,
                  transition: "0.2sec all",
                }}
                className="buttonHover"
              >
                <X size={16} />
              </Button>
            </>
          ) : (
            <>
              <SearchButton
                isSearchOpen={isSearchOpen}
                setSearchOpen={setSearchOpen}
              />
              <MyBookingsButton />
              <NotificationsButton />
              <SettingsMenu />
            </>
          )}
        </Actions>
      }
    />
  );
};
