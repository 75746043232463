import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BoxCenter, ResponsiveH6 } from "../../../../../../global/components";
import { useReservationsQuery } from "../../../../hooks";
import { useSearchNavigation } from "../../../../state";
import { useSelectedItems } from "../../state";
import { InfoBannerChip } from "./InfoBannerChip";

export const InfoBannerContent: React.FC = () => {
  const { t } = useTranslation("analytics");
  const { data: searchBookings } = useReservationsQuery();
  const { onRemoveUser, onRemoveTeam, onRemoveLocation } =
    useSearchNavigation();
  const { selectedUsers, selectedTeams, selectedLocations } =
    useSelectedItems();

  return (
    <BoxCenter column>
      <ResponsiveH6 sx={{ mb: "12px" }}>
        {t("search.header.title")}:
      </ResponsiveH6>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {searchBookings && (
          <>
            {selectedUsers.map((user) => (
              <InfoBannerChip
                key={user.id}
                item={user}
                onDelete={onRemoveUser}
              />
            ))}
            {selectedTeams.map((team) => (
              <InfoBannerChip
                key={team.id}
                item={team}
                onDelete={onRemoveTeam}
              />
            ))}
            {selectedLocations.map((location) => (
              <InfoBannerChip
                key={location.id}
                item={location}
                onDelete={onRemoveLocation}
              />
            ))}
          </>
        )}
        {selectedUsers.length === 0 &&
          selectedTeams.length === 0 &&
          selectedLocations.length === 0 && (
            <Typography variant="subtitle2">
              {t("search.header.subtitle-all")}
            </Typography>
          )}
      </Box>
    </BoxCenter>
  );
};
