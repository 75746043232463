import { Box, styled } from "@mui/material";

export const Sidebar = styled(Box)(({ theme }) => ({
  background: "white",
  display: "flex",
  flexDirection: "column",
  borderRight: `1px solid ${theme.palette.shades.greyLight}`,
  minHeight: "112px",
  flexShrink: 0,
  [theme.breakpoints.down("md")]: {
    flexGrow: 1,
    flexBasis: "112px",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: "469px",
    width: "30%",
    minWidth: "295px",
    flex: "0 1 30%",
  },
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    pointerEvents: "none",
    boxShadow: "1px 0px 8px rgba(20, 17, 16, 0.05)",
  },
}));
