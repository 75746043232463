import { User } from "../../authentication/domain";
import { ConferenceRoom } from "./ConferenceRoom";
import { TimeSlot } from "./TimeSlot";

export class CalendarEvent {
  constructor(
    public readonly eventId: string,
    public readonly conferenceRoom: ConferenceRoom,
    public readonly creator: User,
    public readonly timeSlot: TimeSlot
  ) {}
}
