import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface DialogTitleProps {}

const StyledDialogTitle = styled(Box)<DialogTitleProps>(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.palette.shades.dark,
  textAlign: "center",
}));

export const DialogTitle: React.FC<
  React.PropsWithChildren<DialogTitleProps>
> = ({ ...props }) => {
  const { t } = useTranslation("booking");
  const translation = `form.occupancy`;

  return (
    <StyledDialogTitle {...props}>
      <Typography variant="h6">{t(translation)}</Typography>
    </StyledDialogTitle>
  );
};
