import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { trackApplyToAllDesks } from "../../../../../../../../../tracking";

export interface ApplyToRoomCheckboxProps {
  value: boolean;
  onChange: (applyToRoom: boolean) => void;
}

export const ApplyToRoomCheckbox: React.FC<ApplyToRoomCheckboxProps> =
  React.memo(({ value, onChange }) => {
    const { t } = useTranslation("asset-management");

    const handleChange = () => {
      trackApplyToAllDesks();
      onChange(!value);
    };

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={handleChange}
      >
        <Checkbox checked={value} />
        <Typography>{t("dialog.apply-to-all")}</Typography>
      </Box>
    );
  });
