import {
  Box,
  Button,
  ButtonProps,
  styled,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Minimize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useThrottledCallback } from "use-debounce";
import { DeskWithSpace, DeskWithStatus } from "../../../../backoffice/domain";
import { useUpdateInventoryForm } from "../../../../backoffice/modules/asset-management/state";
import { Floor } from "../../../assets/domain";
import { useCurrentUser } from "../../../authentication/hooks";
import { FloorCard, FloorNameBadge } from "../assets/components";
import { ZoomEvent } from "../assets/FloorPlanD3Renderer";
import { FloorPlanTooltips, FloorPlanTooltipsHandle } from "../assets/Tooltips";
import { ContextMenu } from "./ContextMenu";
import { FloorPlanD3Renderer } from "./FloorPlanD3Renderer";

interface BackofficeFloorPlanProps {
  imageUrl?: string | null;
  interactive?: boolean;
  tooltips?: boolean;
  floors?: Floor[];
  desksWithStatus: DeskWithStatus[];
  selectedDesk: DeskWithSpace | null;
  floor: Floor;
  description: string;
  square?: boolean;
  onClick?: () => void;
  onSelectDesk: (desk: DeskWithStatus | null) => void;
  onChangeFloor?: (floor: Floor) => void;
  sx?: SxProps<Theme>;
}

const FullContentContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const SvgContainer = styled(Box)({
  width: "100%",
  height: "100%",
  "& svg": {
    width: "100%",
    height: "100%",
  },
});

interface RendererRef {
  renderer: FloorPlanD3Renderer | null;
}

export const BackofficeFloorPlan: React.FC<BackofficeFloorPlanProps> = ({
  description,
  floor,
  interactive = false,
  tooltips = true,
  square = false,
  floors,
  desksWithStatus,
  selectedDesk,
  onClick,
  onSelectDesk,
  onChangeFloor,
  sx,
}) => {
  const imageUrl = floor.floorPlanImageUrl;
  const currentUser = useCurrentUser();
  const divRef = useRef<HTMLDivElement>(null);
  const fitToScreenRef = useRef<HTMLButtonElement>(null);
  const rendererRef = useRef<RendererRef>({
    renderer: null,
  });
  const tooltipsRef = useRef<FloorPlanTooltipsHandle>(null);
  const { openDialog } = useUpdateInventoryForm();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "contextMenu",
  });
  const [desk, setDesk] = useState<DeskWithStatus | null>(null);

  const memoShowFloorTooltip = useMemoizedFn((el: Element, floor: Floor) => {
    tooltipsRef.current?.showFloor(el, floor);
  });
  const memoHideTooltip = useMemoizedFn(() => {
    tooltipsRef.current?.hide();
  });

  function getRenderer(): FloorPlanD3Renderer | null {
    return rendererRef.current.renderer;
  }

  const onZoom = useThrottledCallback(({ zoomedToFit }: ZoomEvent) => {
    const style = fitToScreenRef.current?.style;
    if (!style) {
      return;
    }

    if (zoomedToFit) {
      style.opacity = "0";
    } else {
      style.opacity = "1";
    }
  }, 100);

  const memoOnZoom = useMemoizedFn(onZoom);
  const memoOnSelectDesk = useMemoizedFn(
    (desk: DeskWithStatus | null, event: MouseEvent | null) => {
      // onSelectDesk?.(desk);
      if (desk) {
        if (event) {
          popupState.setAnchorEl(event.target as HTMLElement); // Set the anchor element
        }
        setDesk(desk);
        popupState.setOpen(true);
      } else {
        popupState.setOpen(false);
        onSelectDesk(null);
      }
    }
  );
  const memoOnChangeFloor = useMemoizedFn((floor: Floor) => {
    onChangeFloor?.(floor);
  });

  useEffect(
    () => {
      if (!imageUrl || !divRef.current) {
        return;
      }

      const renderer = new FloorPlanD3Renderer(
        divRef.current,
        imageUrl,
        currentUser,
        {
          interactive,
          ...(tooltips && {
            tooltips: {
              showFloor: memoShowFloorTooltip,
              hide: memoHideTooltip,
            },
          }),
        }
      );
      renderer.onSelectDesk = memoOnSelectDesk;
      renderer.onChangeFloor = memoOnChangeFloor;
      renderer.onZoom = memoOnZoom;
      rendererRef.current.renderer = renderer;
      if (desksWithStatus) {
        renderer.setDesks(desksWithStatus);
      }

      return function () {
        renderer.tearDown();
        if (rendererRef.current.renderer === renderer) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          rendererRef.current.renderer = null;
        }
      };
    },
    // Intentional - do not run this if deskBookings or currentUser change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageUrl]
  );

  useEffect(() => {
    const renderer = getRenderer();
    if (desksWithStatus && renderer) {
      renderer.setDesks(desksWithStatus);
    }
  }, [desksWithStatus]);

  useEffect(() => {
    const renderer = getRenderer();
    if (renderer && floors) {
      renderer.floors = floors;
    }
  }, [floors]);

  useEffect(() => {
    const renderer = getRenderer();
    if (!renderer) {
      return;
    }

    if (selectedDesk) {
      renderer.focusDesk(selectedDesk.id);
    } else {
      renderer.unfocusDesk();
    }
  }, [selectedDesk]);

  return (
    <FloorCard
      onClick={onClick}
      interactive={interactive}
      square={square}
      floor={floor}
      sx={sx}
    >
      <ActualFloorPlan
        imageUrl={imageUrl}
        description={description}
        ref={divRef}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          position: "absolute",
          left: theme.spacing(1),
          top: theme.spacing(1),
        })}
      >
        <FloorNameBadge floor={floor} />
      </Box>
      {imageUrl && interactive && (
        <FitToScreenButton
          ref={fitToScreenRef}
          onClick={() => getRenderer()?.zoomToFit()}
        />
      )}
      <FloorPlanTooltips ref={tooltipsRef} />
      <ContextMenu
        popupState={popupState}
        onSelectInventoryManagement={openDialog}
        onSelectStatusManagement={onSelectDesk}
        desk={desk}
      />
    </FloorCard>
  );
};

const ActualFloorPlan = React.memo(
  React.forwardRef<
    HTMLDivElement,
    {
      imageUrl: string | null;
      description: string | null;
    }
  >(({ imageUrl, description }, ref) => {
    return (
      <FullContentContainer>
        {imageUrl && <SvgContainer ref={ref} />}
        {!imageUrl && (
          <Typography variant="h2" sx={{ padding: "20px" }}>
            {description}
          </Typography>
        )}
      </FullContentContainer>
    );
  })
);

interface FitToScreenButtonProps {
  onClick: ButtonProps["onClick"];
  shown?: boolean;
}

const FitToScreenButton = forwardRef<HTMLButtonElement, FitToScreenButtonProps>(
  ({ onClick }, ref) => {
    const { t } = useTranslation("floor-plan");

    return (
      <Tooltip title={t("fit-to-screen")} placement="left">
        <Button
          ref={ref}
          color="secondaryContained"
          variant="icon"
          onClick={onClick}
          sx={{
            position: "absolute",
            right: ({ spacing }) => spacing(1),
            top: ({ spacing }) => spacing(1),
            transition: "opacity 0.1s ease-in-out",
          }}
        >
          <Minimize2 size={16} />
        </Button>
      </Tooltip>
    );
  }
);
