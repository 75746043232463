import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { EventEmitter } from "../../events/domain";
import { Booking } from "../domain";

type ApproveInvitationState =
  | {
      open: false;
      booking?: Booking;
    }
  | {
      open: true;
      booking: Booking;
    };

interface ApproveInvitationDispatch {
  initiate(booking: Booking): void;
  closeDialog(): void;
}

type UseApproveInvitationResult = ApproveInvitationState &
  ApproveInvitationDispatch;

const stateAtom = atom<ApproveInvitationState>({
  open: false,
});

export function useApproveInvitation(): UseApproveInvitationResult {
  const [state, setState] = useAtom(stateAtom);

  const initiate = useMemoizedFn((booking: Booking) => {
    setState({
      open: true,
      booking,
    });
  });
  const closeDialog = useMemoizedFn(() => {
    setState((prev) => ({ ...prev, open: false }));
  });

  return { ...state, initiate, closeDialog };
}

const eventsAtom = atom<EventEmitter<Booking>>(new EventEmitter<Booking>());

export function useInviteEvents(): EventEmitter<Booking> {
  const [events] = useAtom(eventsAtom);
  return events;
}
