import { getJson } from "../../../api";

export class SearchBookingParam {
  constructor(public readonly id: string, public readonly name: string) {}
}

export interface SearchBookingParams {
  following: SearchBookingParam[];
  users: SearchBookingParam[];
  teams: SearchBookingParam[];
  locations: SearchBookingParam[];
}

export async function getSearchBookingParams(
  organizationId: string,
  membershipId: string
): Promise<SearchBookingParams> {
  const response = await getJson<SearchBookingParams>(
    "/api/search-booking-params",
    {
      "organization.id": organizationId,
      "membership.id": membershipId,
    }
  );
  return {
    following: response.following,
    users: response.users,
    teams: response.teams,
    locations: response.locations,
  };
}
