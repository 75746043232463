import { TableCell, Typography } from "@mui/material";
import React from "react";

interface HeaderCellProps {
  label: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({ label }) => {
  return (
    <TableCell
      sx={{
        backgroundColor: "#FBFBFB",
        borderBottom: "1px solid",
        borderRight: "1px solid",
        borderColor: "shades.greyExtraLight",
        padding: 1,
        position: "sticky",
        top: 0,
        "&:last-of-type": {
          borderRight: "none",
        },
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: "600" }}>
        {label}
      </Typography>
    </TableCell>
  );
};
