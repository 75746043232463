import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { Mail, User as UserIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { Icon, Info, Label, Row } from "../../../../../global/styles";
import { User } from "../../../../../modules/authentication/domain";

export interface UserInfoProps {
  sx?: SxProps<Theme>;
  admin: User;
}

export const UserInfo: React.FC<UserInfoProps> = ({ sx, admin }) => {
  const { t } = useTranslation("admin-management");

  return (
    <Box sx={sx}>
      <Row>
        <Icon>
          <UserIcon size={16} />
        </Icon>
        <Info>
          <strong>{admin.name.full}</strong>
        </Info>
      </Row>
      <Row>
        <Icon>
          <Mail size={16} />
        </Icon>
        <Info data-testid="dialog--space">
          <Label>{t("dialog.label.email")}:</Label>
          {admin.email.value}
        </Info>
      </Row>
    </Box>
  );
};
