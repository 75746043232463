import { Box, Button } from "@mui/material";
import { endOfDay, isSameDay, startOfDay } from "date-fns";
import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useIsMobile } from "../../../../../hooks";
import { useCurrentUser } from "../../../../authentication/hooks";
import { CalendarEvent } from "../../../domain";
import { ConferenceDateRange } from "../../../domain/ConferenceDateRange";
import { WeekChangeType } from "./ConferenceCalendar";
import { HeaderCell } from "./HeaderCell";

export interface HeaderProps {
  selectedDay: Date;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  handleClick: (weekType: WeekChangeType) => void;
  dateRange: ConferenceDateRange;
  allEvents: CalendarEvent[];
}

export const Header: React.FC<HeaderProps> = ({
  dateRange,
  selectedDay,
  setSelectedDay,
  handleClick,
  allEvents,
}) => {
  const isMobile = useIsMobile();
  const currentUser = useCurrentUser();

  const isWithinDays = (targetDate: Date, startDate: Date, endDate: Date) => {
    return (
      isSameDay(targetDate, startDate) ||
      isSameDay(targetDate, endDate) ||
      (targetDate > startDate && targetDate < endDate)
    );
  };

  const checkIsAllDay = (day: Date) => {
    const minDate = startOfDay(day);
    const maxDate = endOfDay(day);

    const events = allEvents.filter((event) =>
      isWithinDays(day, event.timeSlot.from, event.timeSlot.to)
    );
    const allDayEvent = events.find((event) => {
      return event.timeSlot.from <= minDate && event.timeSlot.to >= maxDate;
    });
    if (!allDayEvent) return "";
    return currentUser && currentUser.id === allDayEvent.creator.id
      ? "orange.light"
      : "violet.light";
  };

  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        gridTemplateColumns: "50px repeat(5, 1fr) 50px",
        alignItems: "center",
        gap: "15px",
        marginBottom: "5px",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "36px repeat(5, 1fr) 36px",
        },
      })}
    >
      <Button
        onClick={() => handleClick(WeekChangeType.PREV)}
        variant="icon"
        color="secondaryContained"
        disabled={isSameDay(dateRange.startDate, new Date())}
      >
        <ChevronLeft />
      </Button>
      {dateRange.workingDays.map((day) => {
        return (
          <HeaderCell
            key={day.getTime()}
            day={day}
            selected={isSameDay(selectedDay, day) && isMobile}
            setSelectedDay={setSelectedDay}
            allDayColor={checkIsAllDay(day)}
          />
        );
      })}
      <Button
        onClick={() => handleClick(WeekChangeType.NEXT)}
        variant="icon"
        color="secondaryContained"
      >
        <ChevronRight />
      </Button>
    </Box>
  );
};
