import { UseQueryResult } from "react-query";
import { FavoriteLocationDto } from "../../../../assets/api";
import { useOrganizationMembership } from "../../../../authentication/hooks";
import { useFavoriteLocation } from "../../../hooks";

export function useFavoriteLocationQuery(): UseQueryResult<
  FavoriteLocationDto[]
> {
  const organizationMembership = useOrganizationMembership();

  return useFavoriteLocation(organizationMembership.id);
}
