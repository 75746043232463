import useCreation from "ahooks/es/useCreation";
import { isAfter, isSameDay, isWeekend } from "date-fns";
import { CurrentUser, OrganizationRole } from "../../authentication/domain";
import {
  useCurrentUser,
  useOrganizationMembership,
} from "../../authentication/hooks";
import { Booking } from "../../booking/domain";

export class SageHRPermissions {
  constructor(private role: OrganizationRole) {}

  canConfigure = (): boolean => {
    return this.role === OrganizationRole.ADMINISTRATOR;
  };
}

export interface BookingPermissions {
  canViewBookingsOnDate(date: Date): boolean;
  canEditBooking(booking: Booking): boolean;
  canCancelBooking(booking: Booking): boolean;
}

export class BookingPermissionsImpl implements BookingPermissions {
  constructor(
    private currentUser: CurrentUser,
    private role: OrganizationRole
  ) {}

  canViewBookingsOnDate = (date: Date): boolean => {
    if (isWeekend(date)) {
      return false;
    }
    if (this.role === OrganizationRole.ADMINISTRATOR) {
      return true;
    }
    const today = new Date();
    return isSameDay(date, today) || isAfter(date, today);
  };

  canEditBooking = (booking: Booking) => this.canModifyBooking(booking);

  canCancelBooking = (booking: Booking) => this.canModifyBooking(booking);

  private canModifyBooking = (booking: Booking) => {
    return (
      this.role === OrganizationRole.ADMINISTRATOR ||
      booking.bookedFor.isUser(this.currentUser) ||
      booking.bookedBy.isUser(this.currentUser)
    );
  };
}

export class GoogleCalendarPermissions {
  constructor(private readonly role: OrganizationRole) {}

  canConfigure = (): boolean => {
    return this.role === OrganizationRole.ADMINISTRATOR;
  };
}

export interface Permissions {
  sageHr: SageHRPermissions;
  bookings: BookingPermissions;
}

export function usePermissions(): Permissions {
  const currentUser = useCurrentUser();
  const { role } = useOrganizationMembership();
  return useCreation(() => {
    return {
      sageHr: new SageHRPermissions(role),
      bookings: new BookingPermissionsImpl(currentUser, role),
    };
  }, [currentUser, role]);
}

export function useSageHRPermissions(): SageHRPermissions {
  const { role } = useOrganizationMembership();
  return useCreation(() => {
    return new SageHRPermissions(role);
  }, [role]);
}

export function useBookingPermissions(): BookingPermissions {
  const currentUser = useCurrentUser();
  const { role } = useOrganizationMembership();
  return useCreation(() => {
    return new BookingPermissionsImpl(currentUser, role);
  }, [currentUser, role]);
}

export function useGoogleCalendarPermissions(): GoogleCalendarPermissions {
  const { role } = useOrganizationMembership();
  return useCreation(() => new GoogleCalendarPermissions(role), [role]);
}
