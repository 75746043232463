export class Name {
  public readonly full: string;

  constructor(public first: string, public last: string) {
    this.full = `${this.first} ${this.last}`;
  }
}

export class Email {
  constructor(public value: string, public verified: boolean) {}
}

export class Organization {
  constructor(public id: string, public name: string, public domain: string) {}
}

export class OrganizationRole {
  private constructor(public name: String) {}

  toString() {
    return this.name;
  }

  public static MEMBER = new OrganizationRole("MEMBER");
  public static ADMINISTRATOR = new OrganizationRole("ADMINISTRATOR");
  public static SUPER_ADMINISTRATOR = new OrganizationRole(
    "SUPER_ADMINISTRATOR"
  );
  private static allValues: OrganizationRole[] = [
    this.MEMBER,
    this.ADMINISTRATOR,
    this.SUPER_ADMINISTRATOR,
  ];

  public static from(value: string): OrganizationRole {
    const result = this.allValues.find((it) => it.name === value);
    if (!!result) {
      return result;
    }
    console.error(`Invalid OrganizationRole ${value}. Defaulting to MEMBER.`);
    return this.MEMBER;
  }
}

export class OrganizationMembership {
  constructor(
    public id: string,
    public user: User,
    public organization: Organization,
    public role: OrganizationRole
  ) {}
}

export class OrganizationMembershipWithoutUser {
  constructor(
    public id: string,
    public organization: Organization,
    public role: OrganizationRole
  ) {}
}

export class User {
  constructor(
    public id: string,
    public name: Name,
    public email: Email,
    public avatarImageUrl: string | null
  ) {}

  get avatarImageUrlSrc(): string | undefined {
    return this.avatarImageUrl ?? undefined;
  }

  public equals(other: User): boolean {
    return this.id === other.id;
  }

  public isUser(other: User | CurrentUser): boolean {
    return this.id === other.id;
  }
}

export class OrganizationMember {
  constructor(
    public id: string,
    public user: User,
    public organization: Organization
  ) {}
}

export class CurrentUser extends User {
  constructor(
    id: string,
    name: Name,
    email: Email,
    avatarImageUrl: string | null,
    public organizations: OrganizationMembershipWithoutUser[]
  ) {
    super(id, name, email, avatarImageUrl);
  }
}
