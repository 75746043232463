/**
 * To improve hover effect without glitchiness, add a pin outline that stays in place (does not move upward on mouseover).
 * This pin is transparent but still reacts on mouseover.
 */
export const PinMouseoverOutlineSvg = `
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="transparent"
    d="M34.9599 33.2745C38.0956 29.7432 40 25.094 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 25.094 1.90444 29.7432 5.04018 33.2745L17.4706 48.5052C18.9765 50.4983 21.0235 50.4983 22.5294 48.5052L34.9599 33.2745Z"
  />
`;
