import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import qs from "query-string";
import { Map, MoreHorizontal } from "react-feather";
import { useTranslation } from "react-i18next";
import { Reservation } from "../../../../../backoffice/modules/analytics/api";

interface BookingInfoMenuHeaderProps {
  reservation: Reservation;
  colors: {};
}

export const BookingInfoMenuHeader: React.FC<BookingInfoMenuHeaderProps> = ({
  reservation,
  colors,
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "bookingActions",
  });
  const { t } = useTranslation("analytics");

  const redirectUrl = (): string => {
    const queryString = qs.stringify({
      location: reservation.location.id,
      ...(reservation.floor ? { floor: reservation.floor.id } : {}),
      ...(reservation.desk ? { desk: reservation.desk.id } : {}),
      date: reservation.bookingDate,
    });
    return `/book?${queryString}`;
  };

  return (
    <>
      <Box
        sx={{
          ...colors,
          paddingX: 2,
          paddingY: 1,
          borderRadius: "6px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "8px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ whiteSpace: "nowrap", marginRight: "5px" }}
        >
          {reservation.desk
            ? t("data-preview.reservations-menu.desk")
            : t("data-preview.reservations-menu.drop-in")}
        </Typography>
        {new Date(reservation.bookingDate) >= new Date() && (
          <Button
            variant="icon"
            color="secondary"
            {...bindTrigger(popupState)}
            data-testid="booking--actions"
            sx={{
              backgroundColor: "shades.greyUltimateLight",
            }}
          >
            <MoreHorizontal size={16} />
          </Button>
        )}
      </Box>
      <Menu
        disableAutoFocusItem
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          style: {
            padding: 1,
            minWidth: "210px",
          },
        }}
      >
        <MenuItem
          sx={{ margin: "0", paddingY: 1, paddingLeft: 2, paddingRight: 1 }}
        >
          <Button sx={{ padding: "0px" }} href={redirectUrl()}>
            <Map size={16} />
            <Typography>{t("search.booking-redirect")} </Typography>
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};
