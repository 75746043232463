import {
  Box,
  Button,
  ListItemAvatar,
  styled,
  TableCell,
  Typography,
} from "@mui/material";
import { isSameDay } from "date-fns";
import React, { useState } from "react";
import { CellType, GroupedReservations } from "..";
import { Reservation } from "../../../../backoffice/modules/analytics/api";
import { Avatar } from "../../../../components";
import { useIsXs } from "../../../../hooks";
import {
  useCurrentUser,
  useOrganizationMembership,
} from "../../../../modules/authentication/hooks";
import { useFollowMembershipMutation } from "../../../../modules/search/hooks/useFollowMembershipMutation";
import { trackFollowPeople } from "../../../../tracking";
import { useDateRange } from "../../../state";
import { HeartFilledSvg, HeartSvg } from "../../assets/";
import { BookingInfoButton } from "../Buttons";

interface DataContentCellProps {
  cellType: CellType;
  groupedReservations: GroupedReservations;
  shadow?: { boxShadow: string; borderRight: string };
}

const StyledListItemAvatar = styled(ListItemAvatar)({
  minWidth: 32,
  alignSelf: "flex-start",
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 36,
  height: 36,
  marginRight: "8px",
  color: theme.palette.shades.grey,
  borderColor: theme.palette.shades.greyMedium,
  backgroundColor: theme.palette.shades.greyExtraLight,
}));

export const DataContentCell: React.FC<DataContentCellProps> = ({
  groupedReservations,
  cellType,
  shadow,
}) => {
  const { dateRange } = useDateRange();
  const isSmall = useIsXs();
  const { organization } = useOrganizationMembership();
  const [isHovering, setIsHovering] = useState(false);
  const followMembership = useFollowMembershipMutation();
  const currentUser = useCurrentUser();

  const reservationsForDayExists = (day: Date): Reservation | undefined => {
    return groupedReservations.reservations.find((reservation) =>
      isSameDay(new Date(reservation.bookingDate), day)
    );
  };

  const nameCellStyles = () => {
    if (cellType === CellType.FULL_NAME)
      return {
        position: "sticky",
        left: 0,
        zIndex: 10,
        backgroundColor: "inherit",
        maxWidth: isSmall ? "0px" : "auto",
        ...shadow,
      };
  };

  const totalColumn = () => {
    if (cellType === CellType.TOTAL)
      return {
        position: "sticky",
        right: 0,
        zIndex: 30,
        backgroundColor: "inherit",
        borderLeft: "1px solid",
        borderLeftColor: "shades.greyExtraLight",
      };
  };

  const toggleFollow = (event: React.MouseEvent<HTMLElement>) => {
    trackFollowPeople();
    followMembership.mutateAsync(groupedReservations.bookedFor.membershipId);
  };

  return (
    <TableCell
      sx={{
        padding: "10px 0px",
        minHeight: "52px",
        ...nameCellStyles(),
        ...totalColumn(),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent:
            cellType === CellType.FAVORITE ? "center" : "flex-start",
          alignItems: "center",
        }}
      >
        {cellType === CellType.FULL_NAME && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "0px 8px",
              width: "100%",
            }}
          >
            <StyledListItemAvatar sx={{ minWidth: 32 }}>
              <StyledAvatar
                src={
                  groupedReservations.bookedFor.avatarImageUrl
                    ? groupedReservations.bookedFor.avatarImageUrl
                    : undefined
                }
              />
            </StyledListItemAvatar>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: "inherit",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontWeight: "700",
                }}
              >
                {groupedReservations.bookedFor.name.full}
              </Typography>
              <Typography variant="body3">
                {groupedReservations.bookedFor.email.value}
              </Typography>
            </Box>
          </Box>
        )}
        {cellType === CellType.EMAIL && (
          <Typography variant="body2" sx={{ paddingLeft: "8px" }}>
            {groupedReservations.bookedFor.email.value}
          </Typography>
        )}
        {cellType === CellType.FAVORITE && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px 8px",
              width: "100%",
            }}
          >
            <Button
              onClick={toggleFollow}
              disabled={currentUser.isUser(groupedReservations.bookedFor)}
              sx={(theme) => ({
                width: "36px",
                minWidth: "0",
                height: "36px",
                borderRadius: "50%",
                padding: "0 !important",
                border: "1px solid",
                borderColor: "transparent",
                color: "black",
                ...(false
                  ? {
                      color: "white",
                      backgroundColor: theme.palette.shades.dark,
                    }
                  : {}),
              })}
              className="Amt--FavoriteLocation--Icon"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              {isHovering || groupedReservations.bookedFor.doIFollow ? (
                <HeartFilledSvg />
              ) : (
                <HeartSvg />
              )}
            </Button>
          </Box>
        )}
        {cellType === CellType.TEAM && (
          <Typography variant="body2" sx={{ paddingLeft: "8px" }}>
            {groupedReservations.bookedFor.teams.length
              ? groupedReservations.bookedFor.teams.map((team, index) => {
                  if (index === groupedReservations.bookedFor.teams.length - 1)
                    return team.name;
                  return `${team.name}, `;
                })
              : organization.name}
          </Typography>
        )}
        {cellType === CellType.RESERVATIONS && (
          <Box
            sx={{
              padding: "0px 16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: dateRange.dateCellWidth,
            }}
          >
            {dateRange.workingDays.map((day: Date, index) => {
              const reservation = reservationsForDayExists(day);

              return (
                <React.Fragment key={index}>
                  {reservation ? (
                    <BookingInfoButton reservation={reservation} />
                  ) : (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "38px",
                      }}
                    >
                      <Typography variant="overline" color="shades.grey">
                        X
                      </Typography>
                    </Box>
                  )}
                </React.Fragment>
              );
            })}
          </Box>
        )}
      </Box>
      {cellType === CellType.TOTAL && (
        <Typography sx={{ textAlign: "center" }}>
          {groupedReservations.reservations.length}
        </Typography>
      )}
    </TableCell>
  );
};
