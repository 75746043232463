import { Components, Theme } from "@mui/material";

export const MuiInputBase: Components<Theme>["MuiInputBase"] = {
  styleOverrides: {
    // @ts-ignore
    input: ({ theme }) => ({
      ...theme.typography.body2,
    }),
  },
};
