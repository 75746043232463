import { Box, styled, Theme, Typography, TypographyProps } from "@mui/material";
import { SxProps } from "@mui/system";
import { isSameDay } from "date-fns";
import capitalize from "lodash.capitalize";
import React from "react";
import { AlertTriangle, PlusCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDateFns } from "../hooks";
import { useCurrentUser } from "../modules/authentication/hooks";
import { Booking, BookingDate } from "../modules/booking/domain";

export interface DeskOccupancyListProps {
  date: BookingDate;
  bookings: Booking[];
  bookedOnly?: boolean;
  TypographyProps?: TypographyProps;
  sx?: SxProps<Theme>;
}

const List = styled("ul")({
  display: "flex",
  flexDirection: "column",
  gap: 2,
  padding: 0,
  margin: 0,
});

const ListItem = styled("li")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  listStyleType: "none",
}));

const AvailableDeskLabel = styled("span")(({ theme }) => ({
  color: theme.palette.green.regular,
}));

const AlertTriangleIcon = (
  <Box
    component="span"
    sx={{
      color: "orange.regular",
    }}
  >
    <AlertTriangle size={16} style={{ verticalAlign: "text-bottom" }} />
  </Box>
);

const PlusIcon = (
  <Box
    component="span"
    sx={{
      color: "green.regular",
    }}
  >
    <PlusCircle size={16} style={{ verticalAlign: "text-bottom" }} />
  </Box>
);

interface BookedForProps {
  currentUser: boolean;
}

const BookedFor = styled("strong", {
  shouldForwardProp: (prop: string) => !["currentUser"].includes(prop),
})<BookedForProps>(({ theme, currentUser }) => ({
  ...(currentUser && {
    color: theme.palette.orange.regular,
  }),
}));

function getBookingFor(bookings: Booking[], date: Date): Booking | null {
  return bookings.find((it) => isSameDay(it.bookingDate, date)) ?? null;
}

export const DeskOccupancyList: React.FC<DeskOccupancyListProps> = ({
  date,
  bookings,
  bookedOnly = false,
  TypographyProps,
  sx,
}) => {
  const { t } = useTranslation("booking");
  const currentUser = useCurrentUser();
  const { format } = useDateFns();
  return (
    <List sx={sx}>
      {date.workingDays
        .filter((date) => {
          const bookedFor = getBookingFor(bookings, date)?.bookedFor;
          if (bookedOnly) {
            return !!bookedFor;
          }
          return true;
        })
        .map((date, index) => {
          const bookedFor = getBookingFor(bookings, date)?.bookedFor;
          const bookedForCurrentUser = bookedFor?.isUser(currentUser) ?? false;
          return (
            <ListItem key={index}>
              <Typography {...TypographyProps}>
                {!bookedFor && PlusIcon}
                {bookedFor && AlertTriangleIcon}
              </Typography>
              <Typography {...TypographyProps}>
                {capitalize(format(date, "eeee, dd.MM.yyyy"))}:{" "}
                {bookedFor && (
                  <BookedFor currentUser={bookedForCurrentUser}>
                    {bookedFor.name.full}
                  </BookedFor>
                )}
                {!bookedFor && (
                  <AvailableDeskLabel>
                    {t("occupancy-list.available")}
                  </AvailableDeskLabel>
                )}
              </Typography>
            </ListItem>
          );
        })}
    </List>
  );
};
