import { Box, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledCardHeader } from "../../../../../global/styles/StyledCardHeader";
import { useReservationsQuery } from "../../hooks";
import { OccupancyChart, OccupancyChartAverage } from "./OccupancyChart";

export const AnalyticsChart: React.FC = () => {
  const { data: reservations } = useReservationsQuery();
  const [aggregated, setIsAggregated] = useState(false);
  const { t } = useTranslation("analytics");
  return (
    <>
      {reservations && reservations.length > 0 && (
        <Box
          sx={(theme) => ({
            width: "75%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            border: "1px solid",
            borderColor: "rgb(229, 228, 228)",
          })}
        >
          <StyledCardHeader>
            {aggregated ? (
              <Typography>{t("weekday-chart.title")}</Typography>
            ) : (
              <Typography>{t("occupancy-chart.title")}</Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: "space-between",
              }}
            >
              {/* TODO after fixing the bug for OccupancyChartAverage change the disabled value to true */}
              <Switch
                checked={aggregated}
                onClick={() => {
                  setIsAggregated(!aggregated);
                }}
                disabled={true}
              />
            </Box>
          </StyledCardHeader>
          <Box
            sx={(theme) => ({
              justifyContent: "center",
              display: "flex",
            })}
          >
            {aggregated ? <OccupancyChartAverage /> : <OccupancyChart />}
          </Box>
        </Box>
      )}{" "}
    </>
  );
};
