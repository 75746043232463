import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const StyledDialogTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
  borderRadius: "8px",
  backgroundColor: theme.palette.green.regular,
  color: "white",
  marginBottom: theme.spacing(2),
}));

export const DialogTitle: React.FC<React.PropsWithChildren> = ({
  ...props
}) => {
  const { t } = useTranslation("booking-dialog");

  return (
    <StyledDialogTitle {...props}>
      <Typography variant="subtitle1">{t("approve-invite.title")}</Typography>
    </StyledDialogTitle>
  );
};
