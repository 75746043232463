import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from "@mui/material";
import React from "react";
import { User } from "react-feather";

const AvatarFallback: React.FC = () => {
  return <User size={16} />;
};

export const Avatar: React.FC<MuiAvatarProps> = ({
  children,
  sx = [],
  ...props
}) => {
  return (
    <MuiAvatar
      {...props}
      sx={[
        {
          backgroundColor: "shades.greyUltimateLight",
          borderWidth: children || props.src ? "0px" : "1px",
          borderStyle: "solid",
          borderColor: "white",
          color: "shades.grey",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children ?? <AvatarFallback />}
    </MuiAvatar>
  );
};
