import { Button, Dialog, DialogProps } from "@mui/material";
import React from "react";
import { X } from "react-feather";

export interface AmtDialogProps extends DialogProps {
  closeButton?: boolean;
}

export const AmtDialog: React.FC<AmtDialogProps> = ({
  closeButton = false,
  onClose,
  children,
  ...props
}) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      sx={(theme) => ({
        margin: props.fullScreen ? `56px 0px 0px 0px` : "0px",
        ".MuiPaper-root.MuiDialog-paper": {
          position: "relative",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.25)",
          overflow: "unset",
          border: "1px solid black",
          borderColor: "shades.greyLight",
          width: "100%",
        },
        ".MuiPaper-root": {
          margin: 0,
          [theme.breakpoints.down("md")]: {
            padding: "10px 0px",
          },
        },
        [theme.breakpoints.down("md")]: {
          margin: !props.fullScreen ? "20px" : "56px 0px 0px 0px",
        },
      })}
      onClose={onClose}
      {...props}
    >
      {closeButton && (
        <Button
          onClick={() => onClose?.({}, "escapeKeyDown")}
          variant="icon"
          color="secondaryContained"
          sx={{
            position: "absolute",
            right: !props.fullScreen ? "-16px" : "24px",
            top: !props.fullScreen ? "-16px" : "-48px",
          }}
          data-testid="dialog--close"
        >
          <X />
        </Button>
      )}
      {children}
    </Dialog>
  );
};
