import { Box, BoxProps } from "@mui/material";

interface BoxCenterProps {
  column?: boolean;
}

export const BoxCenter: React.FC<
  BoxProps & React.PropsWithChildren<BoxCenterProps>
> = ({ children, column, sx, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...(column ? { flexDirection: "column" } : null),
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
