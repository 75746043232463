import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import {
  Calendar as CalendarIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  User as UserIcon,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useDateFns } from "../../../../hooks";
import { Desk, Floor, Location, Space } from "../../../assets/domain";
import { User } from "../../../authentication/domain";
import { BookingDate } from "../../domain";
import { After, Before, Icon, Info, Label, Row } from "./components";

export interface LocationBookingInfo {
  date: BookingDate;
  location: Location;
  bookedBy: User;
}

export interface DeskBookingInfo extends LocationBookingInfo {
  floor: Floor;
  space: Space;
  desk: Desk;
}

export type BookingInfoType = LocationBookingInfo | DeskBookingInfo;

export interface BookingInfoComparisonProps {
  sx?: SxProps<Theme>;
  variant?: "default" | "book-form";
  before: BookingInfoType;
  after: BookingInfoType;
}

function isDeskBookingInfo(obj: any): obj is DeskBookingInfo {
  return (
    typeof obj.floor !== "undefined" &&
    typeof obj.space !== "undefined" &&
    typeof obj.desk !== "undefined"
  );
}

export const BookingInfoComparison: React.FC<BookingInfoComparisonProps> = ({
  sx,
  variant = "default",
  before,
  after,
}) => {
  const { formatRange } = useDateFns();
  const { t } = useTranslation("booking-details");

  return (
    <Box sx={sx}>
      <Row>
        <Icon>
          <CalendarIcon size={16} />
        </Icon>
        <Info data-testid="dialog--booking-date">
          {after.date.equals(before.date) ? (
            <strong>{formatRange(after.date)}</strong>
          ) : (
            <span>
              <Before variant={variant}>
                <strong>{formatRange(before.date)}</strong>
              </Before>
              <br />
              <After variant={variant}>
                <strong>{formatRange(after.date)}</strong>
              </After>
            </span>
          )}
        </Info>
      </Row>
      <Row>
        <span>
          <Icon>
            <HomeIcon size={16} />
          </Icon>
        </span>
        <Info data-testid="dialog--location">
          {before.location.equals(after.location) ? (
            <span>
              <strong>{after.location.name}</strong>
              <br />
              {after.location.address.addressLine1},{" "}
              {after.location.address.city}
            </span>
          ) : (
            <span>
              <Before variant={variant}>
                <strong>{before.location.name}</strong>
                <br />
                {before.location.address.addressLine1},{" "}
                {before.location.address.city}
              </Before>
              <br />
              <After variant={variant}>
                <strong>{after.location.name}</strong>
                <br />
                {after.location.address.addressLine1},{" "}
                {after.location.address.city}
              </After>
            </span>
          )}
        </Info>
      </Row>
      {(isDeskBookingInfo(before) || isDeskBookingInfo(after)) && (
        <>
          <Row>
            <Icon>
              <LayersIcon size={16} />
            </Icon>
            <Info data-testid="dialog--floor">
              {floorsEqual(before, after) ? (
                <span>{getFloorOrNull(after)?.description}</span>
              ) : (
                <span>
                  {getFloorOrNull(before) && (
                    <>
                      <Before variant={variant}>
                        {getFloorOrNull(before)?.description}
                      </Before>
                      <br />
                    </>
                  )}
                  <After variant={variant}>
                    {getFloorOrNull(after)?.description}
                  </After>
                </span>
              )}
            </Info>
          </Row>
          <Row>
            <Icon />
            <Info data-testid="dialog--space">
              <Label>{t("label.space")}:</Label>
              {spacesEqual(before, after) ? (
                <span>{getSpaceOrNull(after)?.description}</span>
              ) : (
                <>
                  <Before variant={variant}>
                    {getSpaceOrNull(before)?.description}
                  </Before>
                  <After variant={variant}>
                    {getSpaceOrNull(after)?.description}
                  </After>
                </>
              )}
            </Info>
          </Row>
          <Row>
            <Icon />
            <Info data-testid="dialog--desk">
              <Label>{t("label.desk")}:</Label>
              {desksEqual(before, after) ? (
                <strong>{getDeskOrNull(after)?.identifier}</strong>
              ) : (
                <>
                  <Before variant={variant}>
                    <strong>{getDeskOrNull(before)?.identifier}</strong>
                  </Before>
                  <After variant={variant}>
                    <strong>{getDeskOrNull(after)?.identifier}</strong>
                  </After>
                </>
              )}
            </Info>
          </Row>
        </>
      )}
      <Row>
        <Icon>
          <UserIcon size={16} />
        </Icon>
        <Info data-testid="dialog--booked-by">
          <Label>{t("label.booked-by")}:</Label>
          {before.bookedBy.equals(after.bookedBy) ? (
            <span>{after.bookedBy.name.full}</span>
          ) : (
            <>
              <Before variant={variant}>{before.bookedBy.name.full}</Before>
              <After variant={variant}>{after.bookedBy.name.full}</After>
            </>
          )}
        </Info>
      </Row>
    </Box>
  );
};

function floorsEqual(before: BookingInfoType, after: BookingInfoType): boolean {
  const beforeFloor = getFloorOrNull(before);
  const afterFloor = getFloorOrNull(after);
  if (beforeFloor && afterFloor) {
    return beforeFloor.equals(afterFloor);
  }
  return beforeFloor === afterFloor;
}

function spacesEqual(before: BookingInfoType, after: BookingInfoType): boolean {
  const beforeSpace = getSpaceOrNull(before);
  const afterSpace = getSpaceOrNull(after);
  if (beforeSpace && afterSpace) {
    return beforeSpace.equals(afterSpace);
  }
  return beforeSpace === afterSpace;
}

function desksEqual(before: BookingInfoType, after: BookingInfoType): boolean {
  const beforeDesk = getDeskOrNull(before);
  const afterDesk = getDeskOrNull(after);
  if (beforeDesk && afterDesk) {
    return beforeDesk.identifier === afterDesk.identifier;
  }
  return beforeDesk === afterDesk;
}

function getFloorOrNull(info: BookingInfoType): Floor | null {
  return isDeskBookingInfo(info) ? info.floor : null;
}

function getSpaceOrNull(info: BookingInfoType): Space | null {
  return isDeskBookingInfo(info) ? info.space : null;
}

function getDeskOrNull(info: BookingInfoType): Desk | null {
  return isDeskBookingInfo(info) ? info.desk : null;
}
