import { styled } from "@mui/material";

export const TooltipDivider = styled("hr")<{ width: string }>(
  ({ theme, width }) => ({
    width: width,
    height: 1,
    border: 0,
    backgroundColor: theme.palette.shades.darkLight,
    display: "block",
    margin: theme.spacing(0.5, 0),
  })
);
