import { useUnleashContext } from "@unleash/proxy-client-react";
import { useQuery, UseQueryResult } from "react-query";
import { trackUser } from "../../../tracking";
import { getCurrentUser } from "../api";
import { CurrentUser, OrganizationMembershipWithoutUser } from "../domain";

export function useSessionLoader(): UseQueryResult<CurrentUser> {
  const updateContext = useUnleashContext();

  return useQuery("session", getCurrentUser, {
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 3600_000,
    onSuccess(user: CurrentUser) {
      updateContext({ userId: user.id });
      trackUser(user);
    },
  });
}

export function useCurrentUser(): CurrentUser {
  const { isSuccess, data: currentUser } = useSessionLoader();
  if (!isSuccess || !currentUser) {
    throw new Error(
      "only use useCurrentUser when the session has been successfully loaded"
    );
  }
  return currentUser;
}

export function useOrganizationMembership(): OrganizationMembershipWithoutUser {
  const currentUser = useCurrentUser();
  if (currentUser.organizations.length === 0) {
    throw new Error("User not in organization");
  }
  return currentUser.organizations[0];
}
