import { Components } from "@mui/material";

export const MuiAppBar: Components["MuiAppBar"] = {
  styleOverrides: {
    root: {
      height: 56,
      boxShadow: "none",
      borderBottom: "1px solid #d9d9d9",
      backgroundColor: "white",
      borderRadius: 0,
    },
  },
};
