import {
  AutocompleteValue,
  FilterOptionsState,
} from "@mui/base/AutocompleteUnstyled/useAutocomplete";
import {
  Autocomplete,
  Box,
  MenuList,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import useCreation from "ahooks/es/useCreation";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { AlertCircle, Bell } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  MemberChip,
  MemberMenuItemContent,
} from "../../../../global/components";
import {
  StyledListSubheader,
  StyledPopper,
  StyledTeamName,
} from "../../../../global/styles";
import { stripDiacritics } from "../../../assets/helperFunctions";
import { useOrganizationMembership } from "../../../authentication/hooks";
import { GroupMember } from "../../domain";
import { useAutocompleteOptions, useBookingGroups } from "../../hooks";
import { useBookingForm, useSelection } from "../../pages/BookingPage/state";
import { createFuse } from "./search";

export type BookForAutocompleteProps = Omit<
  TextFieldProps,
  "value" | "onChange"
> & {
  multiple: boolean;
  capacityReached: boolean;
  value: GroupMember[];
  onChange?: (member: GroupMember[]) => void;
};

export const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexBasis: "24px",
  width: "24px",
}));

const CapacityErrorMessage: React.FC = () => {
  const { t } = useTranslation("booking");
  return (
    <Box
      component="li"
      role="presentation"
      sx={(theme) => ({
        position: "fixed",
        display: "flex",
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: "errors.light",
        borderRadius: "8px",
        paddingX: 1,
        paddingY: 0.5,
      })}
    >
      <Icon>
        <AlertCircle size={16} />
      </Icon>
      <Typography variant="body3" color="shades.dark">
        {t("form.feedback.capacity-reached")}
      </Typography>
    </Box>
  );
};

const DedicatedHeader: React.FC<{ hasSelected: boolean }> = ({
  hasSelected,
}) => {
  const { t } = useTranslation("booking");
  const { selectedDesk } = useSelection();

  return (
    <Box
      component="li"
      role="presentation"
      sx={(theme) => ({
        display: "flex",
        backgroundColor: "orange.light",
        borderRadius: "8px",
        paddingX: 1,
        paddingY: 0.5,
        width: "100%",
      })}
    >
      <Icon>
        <Bell size={16} />
      </Icon>
      <Typography variant="body3" color="shades.dark">
        {selectedDesk?.dedicatedFor.length && !hasSelected
          ? t("form.feedback.dedicated-desk")
          : t("form.feedback.dedicated-room")}
      </Typography>
    </Box>
  );
};

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & { capacityReached?: boolean }
>(function (props, ref) {
  const { children, capacityReached, ...other } = props;

  return (
    <MenuList
      component="div"
      ref={ref}
      {...other}
      sx={{
        position: "relative",
        padding: 1,
      }}
    >
      {children}
      {capacityReached && <CapacityErrorMessage />}
    </MenuList>
  );
});

function isOptionEqualToValue(option: GroupMember, value: GroupMember) {
  return option.id === value.id;
}

function groupByGroupName(option: GroupMember) {
  return option.groupName;
}

export const BookForAutocomplete: React.FC<BookForAutocompleteProps> = ({
  value,
  onChange,
  multiple,
  capacityReached,
  ...props
}) => {
  const { t } = useTranslation("booking");
  const { organization } = useOrganizationMembership();
  const { selectedDesk } = useSelection();
  const form = useBookingForm();
  if (!form.target) {
    throw new Error("Invalid usage of BookForAutocomplete");
  }

  const { isLoading } = useBookingGroups(
    organization.id,
    form.target.date,
    form.isEditing ? form.source.bookingId : null
  );

  const options: GroupMember[] = useAutocompleteOptions(value);

  const fuse = useCreation(() => createFuse(options), [options]);

  const filterOptions = useMemoizedFn(
    (
      searchOptions: GroupMember[],
      state: FilterOptionsState<GroupMember>
    ): GroupMember[] => {
      if (state.inputValue.length < 2) {
        return searchOptions;
      }
      const result = fuse.search(stripDiacritics(state.inputValue));
      return result.map((it) => it.item);
    }
  );

  const handleChange = useMemoizedFn(
    (
      event: React.SyntheticEvent,
      value: AutocompleteValue<GroupMember, any, unknown, unknown>
    ) => {
      event.stopPropagation();
      if (!Array.isArray(value)) {
        onChange?.(value ? [value] : []);
      } else {
        // If two people are selected, and one of them has the dedication for the selected desk, but the other one doesn't, and the dedicated user gets deleted,
        // the other person should be deleted as well (because the options are limited if nobody is selected)
        if (
          value.length === 1 &&
          selectedDesk?.isDedicated &&
          !selectedDesk.dedicatedFor.includes(value[0].user.id)
        ) {
          onChange?.([]);
        } else {
          onChange?.(value);
        }
      }
    }
  );

  return !isLoading ? (
    <Autocomplete
      fullWidth
      multiple={multiple}
      options={options}
      value={multiple ? value : value[0] ?? null}
      onChange={handleChange}
      groupBy={groupByGroupName}
      isOptionEqualToValue={isOptionEqualToValue}
      disableClearable
      autoHighlight
      disableCloseOnSelect={multiple}
      filterOptions={filterOptions}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      // @ts-ignore
      ListboxProps={{ capacityReached }}
      getOptionLabel={(option) => option.user.name.full}
      getOptionDisabled={(option) => {
        const isNotSelected =
          value.find((it) => it.id === option.id) === undefined;
        return isNotSelected && (capacityReached || !option.bookableForPeriod);
      }}
      noOptionsText={t("form.no-options")}
      renderInput={(params) => <TextField {...params} {...props} />}
      renderTags={(value, getTagProps) =>
        value.map((member, index) => (
          <MemberChip
            label={member.user.name.full}
            {...getTagProps({ index })}
          />
        ))
      }
      renderGroup={({ group, ...props }) => (
        <GroupHeader hasSelected={!!value.length} label={group} {...props} />
      )}
      renderOption={(props, option) => (
        <MemberMenuItemContent
          elementProps={props}
          avatarImageUrl={option.user.avatarImageUrlSrc}
          name={option.user.name.full}
          available={option.bookableForPeriod}
          team={
            option.team ? `${t("form.feedback.team")} ${option.team}` : null
          }
          errorText={t("form.feedback.booked")}
        />
      )}
      data-testid="organization-members-autocomplete"
    />
  ) : null;
};

interface GroupHeaderProps {
  hasSelected: boolean;
  label: string;
  children?: React.ReactNode;
}

const GroupHeader: React.FC<GroupHeaderProps> = ({
  hasSelected,
  label,
  children,
}) => {
  const { t } = useTranslation("booking");

  return (
    <div>
      {/* Important: Shouldn't use the string to compare to the dedicated group, bu rather an enum. Also, if possible, change the logic so backend returns something
      special rather than a group named a certain way */}
      <StyledListSubheader
        disableSticky
        borderBottom={label === "dedicatedGroup"}
        sx={{
          ...(label === "dedicatedGroup"
            ? {
                "&.MuiListSubheader-root": {
                  marginBottom: 0,
                  paddingBottom: 0,
                },
              }
            : {}),
        }}
      >
        {label === "dedicatedGroup" ? (
          <DedicatedHeader hasSelected={hasSelected} />
        ) : (
          <>
            <Typography variant="body3">{t("form.feedback.team")}</Typography>
            <StyledTeamName>{label}</StyledTeamName>
          </>
        )}
      </StyledListSubheader>
      <ul style={{ margin: 0, padding: 0 }}>{children}</ul>
    </div>
  );
};
