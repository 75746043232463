import { getJson } from "../../../api";
import {
  mapReservation,
  ReservationDto,
} from "../../../backoffice/modules/analytics/api";
import { GroupedReservations } from "../../../global/components/Tables";
import { UserDto } from "../../authentication/api";
import { Name } from "../../authentication/domain";
import { formatISODate } from "../../booking/api";
import { Team, UserWithTeam } from "../domain";

export interface UserWithTeamDto extends UserDto {
  membershipId: string;
  teams: TeamDto[];
  doIFollow: boolean;
}

export interface TeamDto {
  id: string;
  name: string;
}

export interface GroupedReservationsDto {
  bookedFor: UserWithTeamDto;
  bookings: ReservationDto[];
}

export interface ReservationsResponse {
  content: GroupedReservationsDto[];
}

export const mapGroupedReservations = (dto: GroupedReservationsDto) => {
  return new GroupedReservations(
    mapUserWithTeam(dto.bookedFor),
    mapReservations(dto.bookings)
  );
};

export const mapReservations = (dto: ReservationDto[]) => {
  return dto.map((it) => mapReservation(it));
};

export const mapUserWithTeam = (dto: UserWithTeamDto) => {
  return new UserWithTeam(
    dto.id,
    dto.membershipId,
    new Name(dto.name.first, dto.name.last),
    dto.email,
    dto.avatarImageUrl,
    mapTeams(dto.teams),
    dto.doIFollow
  );
};

export const mapTeams = (dtos: TeamDto[]) => {
  return dtos.map((dto) => new Team(dto.id, dto.name));
};

export async function getFilteredReservations(
  organizationId: string,
  membershipId: string,
  locationIds: string[] | null,
  userIds: string[] | null,
  teamIds: string[] | null,
  startDate: Date,
  endDate: Date
): Promise<GroupedReservations[]> {
  const response = await getJson<ReservationsResponse>(
    "/api/multi-search-bookings",
    {
      "organization.id": organizationId,
      "membership.id": membershipId,
      ...(locationIds
        ? { "filter.location.ids": locationIds.join(",") }
        : null),
      ...(userIds ? { "filter.booked-for.ids": userIds.join(",") } : null),
      ...(teamIds ? { "filter.team.ids": teamIds.join(",") } : null),
      "start-date": formatISODate(startDate),
      "end-date": formatISODate(endDate),
      size: 500,
    }
  );
  return response.content.map((reservation) =>
    mapGroupedReservations(reservation)
  );
}
