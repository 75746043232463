import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../../api";
import { deleteInventory } from "../../../api";
import { useDeleteInventoryForm } from "../state";

export function useDeleteInventoryMutation(): UseMutationResult<
  void,
  AmtError,
  string
> {
  const { t } = useTranslation("inventory-management");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const form = useDeleteInventoryForm();

  return useMutation(
    ["delete-inventory"],
    async (inventoryId: string) => {
      return deleteInventory(inventoryId);
    },
    {
      onSuccess: () => {
        snackbar.enqueueSnackbar(
          t("dialog.feedback.delete-inventory-success"),
          {
            variant: "success",
          }
        );
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.delete-inventory-error"), {
          variant: "error",
        });
        form.closeDialog();
      },
      onSettled: () => {
        qc.invalidateQueries(["inventories"]);
      },
    }
  );
}
