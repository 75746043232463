import { Desk, Floor, Location, Space } from "../../assets/domain";
import { User } from "../../authentication/domain";

export type DeskBookingNotificationContext = BookingNotificationContext & {
  readonly desk: Desk;
  readonly floor: Floor;
  readonly space: Space;
};

export function isDeskBookingNotification(
  context: BookingNotificationContext
): context is DeskBookingNotificationContext {
  return context.desk !== null;
}

export abstract class BookingNotificationContext {
  constructor(
    public readonly desk: Desk | null,
    public readonly floor: Floor | null,
    public readonly space: Space | null,
    public readonly location: Location,
    public readonly bookingDate: Date,
    public readonly bookedBy: User
  ) {}
}

export class NewBookingNotificationContext extends BookingNotificationContext {}

export class DeletedBookingNotificationContext extends NewBookingNotificationContext {
  constructor(
    desk: Desk | null,
    floor: Floor | null,
    space: Space | null,
    location: Location,
    bookingDate: Date,
    bookedBy: User,
    public readonly deletedBy: User
  ) {
    super(desk, floor, space, location, bookingDate, bookedBy);
  }
}

export class UpdatedBookingNotificationContext {
  constructor(
    public readonly before: NewBookingNotificationContext,
    public readonly after: NewBookingNotificationContext
  ) {}
}

export class Notification {
  constructor(public readonly id: string, public readonly createdAt: Date) {}
}

export class BookedNotification extends Notification {
  constructor(
    id: string,
    createdAt: Date,
    public readonly context: NewBookingNotificationContext
  ) {
    super(id, createdAt);
  }
}

export class UnbookedNotification extends Notification {
  constructor(
    id: string,
    createdAt: Date,
    public readonly context: DeletedBookingNotificationContext
  ) {
    super(id, createdAt);
  }
}

export class UpdatedNotification extends Notification {
  constructor(
    id: string,
    createdAt: Date,
    public readonly context: UpdatedBookingNotificationContext
  ) {
    super(id, createdAt);
  }
}

export class InvitationSentNotification extends Notification {
  constructor(
    id: string,
    createdAt: Date,
    public readonly context: NewBookingNotificationContext
  ) {
    super(id, createdAt);
  }
}

export class InvitationCancelledNotification extends Notification {
  constructor(
    id: string,
    createdAt: Date,
    public readonly context: DeletedBookingNotificationContext
  ) {
    super(id, createdAt);
  }
}

export class Notifications {
  constructor(
    public readonly content: Notification[],
    public readonly totalElements: number
  ) {}

  removeById(notificationId: string): Notifications {
    const newContent = this.content.filter((it) => it.id !== notificationId);
    const removedCount = Math.min(this.content.length - newContent.length, 1);
    return new Notifications(newContent, this.totalElements - removedCount);
  }
}
