import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { PresenceListSectionHeader } from "./PresenceListSubsectionHeader";

export const PresenceListConferenceRoomsSectionHeader: React.FC = () => {
  const { t } = useTranslation("booking");
  const headerRef = useRef<HTMLElement | null>(null);

  return (
    <PresenceListSectionHeader
      ref={headerRef}
      title={t("presence-list-conference-rooms-header")}
      titleTypographyProps={{ color: "shades.dark" }}
    />
  );
};
