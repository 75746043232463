import mixpanel from "mixpanel-browser";

export function trackOpenAccountSettings() {
  try {
    mixpanel.track("Open account settings");
  } catch (e) {}
}

export function trackOpenNotificationSettings() {
  try {
    mixpanel.track("Open notification settings");
  } catch (e) {}
}

export function trackOpenGoogleSettings() {
  try {
    mixpanel.track("Open google settings");
  } catch (e) {}
}

export function trackOpenSageHRSettings() {
  try {
    mixpanel.track("Open sage HR settings");
  } catch (e) {}
}
