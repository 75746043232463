import { Box, Typography } from "@mui/material";
import React from "react";
import { useDateFns, useIsMobile } from "../../../../../hooks";

export interface HeaderCellProps {
  day: Date;
  selected: boolean;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  allDayColor: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({
  day,
  selected,
  setSelectedDay,
  allDayColor,
}) => {
  const { formatDayName } = useDateFns();
  const isMobile = useIsMobile();

  const generateBackgroundColor = () => {
    if (selected) return "violet.regular";
    else if (allDayColor) return allDayColor;
    else return "shades.greyUltimateLight";
  };

  return (
    <Box
      sx={{
        backgroundColor: generateBackgroundColor(),
        borderRadius: "8px",
        padding: "4px 8px",
      }}
      onClick={() => isMobile && setSelectedDay(day)}
    >
      <Typography
        variant="overline"
        sx={{ color: selected ? "#fff" : "#6d6b6b" }}
      >
        {formatDayName(day)}
      </Typography>
      <Typography variant="body2" sx={{ color: selected ? "#fff" : "#000" }}>
        <strong>{("0" + day.getDate()).slice(-2)}</strong>
      </Typography>
    </Box>
  );
};
