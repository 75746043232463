const SvgDeskSad = (props: any) => (
  <svg
    width={70}
    height={70}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.133 60.5h9.734a3.5 3.5 0 0 0 3.464-3H26.67a3.501 3.501 0 0 0 3.464 3Z"
      fill="#C80048"
    />
    <path
      d="M30.133 60.5h9.734a3.5 3.5 0 0 0 3.464-3H26.67a3.501 3.501 0 0 0 3.464 3Z"
      fill="#fff"
      fillOpacity={0.68}
    />
    <path
      d="M30.133 60.5h9.734a3.5 3.5 0 0 0 3.464-3H26.67a3.501 3.501 0 0 0 3.464 3Z"
      stroke="#C80000"
    />
    <rect
      x={-0.5}
      y={0.5}
      width={69}
      height={49}
      rx={3.5}
      transform="matrix(-1 0 0 1 69 8)"
      fill="#fff"
      stroke="#C80000"
    />
    <path
      d="m29 31-6-6M46 31l-6-6M23 31l6-6M26.5 43l.728-.255A22.02 22.02 0 0 1 42.5 43v0M40 31l6-6"
      stroke="#C80000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgDeskSad;
