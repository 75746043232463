import {
  Alert,
  AlertColor,
  Box,
  Button,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  UiContainer,
  UiNode,
  UiNodeImageAttributes,
  UiNodeInputAttributes,
  UiNodeTextAttributes,
  UiText,
} from "@ory/kratos-client";
import { Field, Form, Formik, FormikErrors, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormikPasswordField } from "../form";
import { getInitialFormState } from "./formik";
import { getNodeLabel, getTranslatedNodeLabel } from "./ui";

type ButtonVariant =
  | "text"
  | "contained"
  | "outlined"
  | "icon"
  | "iconLinkOutlined";

interface UiNodeInputProps {
  node: UiNode;
  attributes: UiNodeInputAttributes;
  buttonVariant?: ButtonVariant;
}

const UiNodeInputDefaultComponent: React.FC<UiNodeInputProps> = ({
  node,
  attributes,
}) => {
  const { t } = useTranslation("kratos");
  const Component =
    attributes.type === "password" ? FormikPasswordField : TextField;
  return (
    <Field
      component={Component}
      fullWidth
      required={attributes.required}
      disabled={attributes.disabled}
      name={attributes.name}
      type={attributes.type}
      placeholder={getTranslatedNodeLabel(node, t)}
    />
  );
};

const UiNodeInputHiddenComponent: React.FC<UiNodeInputProps> = ({
  node,
  attributes,
}) => <input type="hidden" name={attributes.name} value={attributes.value} />;

const UiNodeInputButtonComponent: React.FC<UiNodeInputProps> = ({
  node,
  attributes,
  buttonVariant,
}) => {
  const { t } = useTranslation("kratos");
  const context = useFormikContext();

  return (
    <Button
      variant={buttonVariant || "contained"}
      fullWidth
      name={attributes.name}
      value={attributes.value}
      disabled={attributes.disabled || !context.isValid || context.isSubmitting}
      type={attributes.type as any}
    >
      {getTranslatedNodeLabel(node, t)}
    </Button>
  );
};

const UiNodeInputComponent: React.FC<{
  node: UiNode;
  attributes: UiNodeInputAttributes;
  buttonVariant?: ButtonVariant;
}> = ({ node, attributes, buttonVariant }) => {
  switch (attributes.type) {
    case "hidden":
      return <UiNodeInputHiddenComponent node={node} attributes={attributes} />;
    case "submit":
    case "button":
      return (
        <UiNodeInputButtonComponent
          node={node}
          attributes={attributes}
          buttonVariant={buttonVariant}
        />
      );
    default:
      return (
        <UiNodeInputDefaultComponent node={node} attributes={attributes} />
      );
  }
};

interface UiTextLookupSecrets extends UiText {
  context: {
    secrets: UiText[];
  };
}

function isLookupSecretText(text: UiText): text is UiTextLookupSecrets {
  return text.id === 1050015;
}

function isLookupSecretUsed(text: UiText): boolean {
  return text.id === 1050014;
}

const UiNodeTextComponent: React.FC<{
  node: UiNode;
  attributes: UiNodeTextAttributes;
}> = ({ node, attributes }) => {
  return (
    <>
      <Typography>{node.meta.label?.text}</Typography>
      {isLookupSecretText(attributes.text) ? (
        <Grid container spacing={2}>
          {attributes.text.context.secrets.map((secret) => (
            <Grid item xs={3}>
              <code>{isLookupSecretUsed(secret) ? "Used" : secret.text}</code>
            </Grid>
          ))}
        </Grid>
      ) : (
        <code>{attributes.text.text}</code>
      )}
    </>
  );
};

interface UiNodeImageProps {
  node: UiNode;
  attributes: UiNodeImageAttributes;
}

const UiNodeImageComponent: React.FC<UiNodeImageProps> = ({
  node,
  attributes,
}) => {
  return (
    <img
      src={attributes.src}
      width={attributes.width}
      height={attributes.height}
      alt={getNodeLabel(node)}
      data-testid={`node/image/${attributes.id}`}
    />
  );
};

interface UiNodeComponentProps {
  node: UiNode;
  buttonVariant?: ButtonVariant;
}

const UiNodeComponent: React.FC<UiNodeComponentProps> = ({
  node,
  buttonVariant,
}) => {
  switch (node.type) {
    case "input":
      return (
        <UiNodeInputComponent
          node={node}
          attributes={node.attributes as UiNodeInputAttributes}
          buttonVariant={buttonVariant}
        />
      );
    case "text":
      return (
        <UiNodeTextComponent
          node={node}
          attributes={node.attributes as UiNodeTextAttributes}
        />
      );
    case "img":
      return (
        <UiNodeImageComponent
          node={node}
          attributes={node.attributes as UiNodeImageAttributes}
        />
      );
    default:
      console.error(`Unsupported node type '${node.type}'`);
      return null;
  }
};

export interface UiMessagesProps {
  messages: UiText[];
}

export const UiMessages: React.FC<UiMessagesProps> = ({ messages }) => {
  return (
    <div>
      {messages.map((message, index) => (
        <Alert color={message.type as AlertColor} key={index}>
          {message.text}
        </Alert>
      ))}
    </div>
  );
};

export const UiNodes: React.FC<{
  nodes: UiNode[];
  buttonVariant?: ButtonVariant;
}> = ({ nodes, buttonVariant }) => {
  return (
    <>
      {nodes.map((node, index) => (
        <UiNodeComponent
          key={index}
          node={node}
          buttonVariant={buttonVariant}
        />
      ))}
    </>
  );
};

export interface UiFormProps<Values = any> {
  handleSubmit: (values: any) => Promise<void>;
  ui: UiContainer;
  groups?: string[];
  ignoreNodes?: string[];
  withoutDefault?: boolean;
  sx?: SxProps<Theme>;
  buttonVariant?: ButtonVariant;
  validate?: (
    values: Values
  ) => FormikErrors<Values> | Promise<FormikErrors<Values>>;
}

export const UiForm: React.FC<React.PropsWithChildren<UiFormProps>> = ({
  handleSubmit,
  ui,
  groups,
  ignoreNodes = [],
  withoutDefault = false,
  validate,
  sx,
  buttonVariant,
  children,
}) => {
  const groupNodes = groups
    ? filterNodes(ui.nodes, groups, ignoreNodes)
    : ui.nodes;
  if (groupNodes.length === 0) {
    return null;
  }

  const nodes = ([] as UiNode[])
    .concat(filterNodes(ui.nodes, withoutDefault ? [] : ["default"]))
    .concat(groupNodes);

  const { initialValues, initialErrors, initialTouched } =
    getInitialFormState(nodes);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      enableReinitialize
      validate={validate}
    >
      <Form>
        <Box sx={sx}>
          {children}
          <UiNodes nodes={nodes} buttonVariant={buttonVariant} />
        </Box>
      </Form>
    </Formik>
  );
};

function filterNodes(
  nodes: UiNode[],
  groups: string[],
  ignoreNodes: string[] = []
): UiNode[] {
  return nodes.filter((node) => {
    const nodeName = (node.attributes as any).name ?? "";
    return groups.includes(node.group) && !ignoreNodes.includes(nodeName);
  });
}
