import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface DialogTitleProps {}

const StyledDialogTitle = styled(Box)<DialogTitleProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
  borderRadius: "8px",
  marginBottom: theme.spacing(2),
  color: theme.palette.shades.dark,
  backgroundColor: theme.palette.green.light,
}));

export const DialogTitle: React.FC<
  React.PropsWithChildren<DialogTitleProps>
> = ({ ...props }) => {
  const { t } = useTranslation("inventory-management");

  return (
    <StyledDialogTitle {...props}>
      <Typography variant="h6">{t("dialog.title")}</Typography>
    </StyledDialogTitle>
  );
};
