import React from "react";
import { useDeleteInventoryForm } from "../../../state";
import { DeleteInventoryDialog } from "./DeleteInventoryDialog";

export const ConnectedDeleteInventoryDialog: React.FC = () => {
  const form = useDeleteInventoryForm();

  if (!form.inventory) return null;

  return <DeleteInventoryDialog />;
};
