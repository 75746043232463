import { Badge, Button, Collapse, Menu, styled } from "@mui/material";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useEffect } from "react";
import { Bell } from "react-feather";
import { TransitionGroup } from "react-transition-group";
import { trackOpenNotificationsDropdown } from "../../../tracking";
import { useNotifications } from "../hooks";
import { NotificationList, NotificationsEmpty } from "./NotificationList";

const StyledBadge = styled(Badge)(({ theme }) => ({
  ".MuiBadge-badge": {
    top: "10px",
    right: "3px",
    padding: "0 5px",
    height: "18px",
    minWidth: "18px",
    // @ts-ignore
    ...theme.typography.body3,
    textAlign: "center",
    backgroundColor: theme.palette.orange.regular,
    color: "white",
  },
}));

export const NotificationsButton: React.FC = () => {
  const { data: notifications, refetch } = useNotifications();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "notificationsMenu",
  });

  useEffect(() => {
    if (popupState.isOpen) {
      refetch();
      trackOpenNotificationsDropdown();
    }
  }, [refetch, popupState.isOpen]);

  const content = notifications?.content ?? [];
  return (
    <>
      <StyledBadge
        badgeContent={notifications?.totalElements ?? 0}
        color="primary"
      >
        <Button
          color="primary"
          variant="icon"
          {...bindTrigger(popupState)}
          data-testid="navigation--notifications"
        >
          <Bell size={16} />
        </Button>
      </StyledBadge>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <TransitionGroup>
          {content.length > 0 && (
            <Collapse>
              <NotificationList
                notifications={content}
                onClose={popupState.close}
              />
            </Collapse>
          )}
          {content.length === 0 && (
            <Collapse>
              <NotificationsEmpty />
            </Collapse>
          )}
        </TransitionGroup>
      </Menu>
    </>
  );
};
