import { UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../../../authentication/hooks";
import { LocationCapacity } from "../../../domain";
import { useLocationCapacity } from "../../../hooks";
import { useDashboardDate } from "./useDashboardDate";

export function useLocationCapacityQuery(): UseQueryResult<LocationCapacity[]> {
  const { organization } = useOrganizationMembership();
  const [from] = useDashboardDate();
  return useLocationCapacity(organization.id, from);
}
