import { parseISO } from "date-fns";
import { getJson, request } from "../../../../../api";
import { SageHrIntegration } from "../domain";

interface SageHrIntegrationResponse {
  organizationId: string;
  enabled: boolean;
  apiKeyConfigured: boolean;
  apiUrl: string | null;
  lastSyncedAt: string | null;
}

function mapIntegration(
  response: SageHrIntegrationResponse
): SageHrIntegration {
  const lastSyncedAt = response.lastSyncedAt
    ? parseISO(response.lastSyncedAt)
    : null;
  return new SageHrIntegration(
    response.organizationId,
    response.enabled,
    response.apiKeyConfigured,
    response.apiUrl,
    lastSyncedAt
  );
}

export async function getSageHrIntegration(
  organizationId: string
): Promise<SageHrIntegration> {
  const response = await getJson<SageHrIntegrationResponse>(
    `/api/integrations/sage-hr/${organizationId}`
  );

  return mapIntegration(response);
}

export interface SageHrConfigurationOptions {
  apiUrl?: string;
  apiKey?: string;
  enabled?: boolean;
}

export async function configureSageHr(
  organizationId: string,
  configuration: SageHrConfigurationOptions
): Promise<SageHrIntegration> {
  const response = await request<SageHrIntegrationResponse>(
    "POST",
    `/api/integrations/sage-hr/${organizationId}`,
    configuration
  );

  return mapIntegration(response);
}
