// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
// Give up on IE11 support for this feature
import Fuse from "fuse.js";
import { stripDiacritics } from "../../../../../../../../../modules/assets/helperFunctions";
import { GroupMember } from "../../../../../../domain";

const fuseSearchOptions: Fuse.IFuseOptions<GroupMember> = {
  isCaseSensitive: false,
  findAllMatches: true,
  minMatchCharLength: 2,
  shouldSort: false,
  threshold: 0.2,
  keys: [
    {
      name: "First name",
      getFn(member: GroupMember): string {
        return stripDiacritics(member.user.name.first);
      },
    },
    {
      name: "Last name",
      getFn(member: GroupMember): string {
        return stripDiacritics(member.user.name.last);
      },
    },
    {
      name: "Full name",
      getFn(member: GroupMember): string {
        return stripDiacritics(member.user.name.full);
      },
    },
    {
      name: "Reversed full name",
      getFn(member: GroupMember): string {
        const name = member.user.name;
        const value = `${name.last} ${name.first}`;
        return stripDiacritics(value);
      },
    },
    {
      name: "Group name",
      getFn(member: GroupMember): string {
        return stripDiacritics(member.groupName);
      },
    },
  ],
};

export function createFuse(members: GroupMember[]): Fuse<GroupMember> {
  const index = Fuse.createIndex<GroupMember>(
    fuseSearchOptions.keys!!,
    members
  );
  return new Fuse<GroupMember>(members, fuseSearchOptions, index);
}
