import {
  Box,
  Button,
  Divider,
  Typography,
  TypographyProps,
} from "@mui/material";
import React from "react";
import { Camera } from "react-feather";
import { trackOpenGallery } from "../../../../../../tracking";
import { SpaceWithImages } from "../../../../../gallery/domain";

export interface PresenceListSectionHeaderProps {
  title: string;
  subtitle?: string;
  spaceWithImages?: SpaceWithImages;
  titleTypographyProps?: Partial<TypographyProps>;
  subtitleTypographyProps?: Partial<TypographyProps>;
  onSelectSpaceWithImages?: (spaceWithImages: SpaceWithImages | null) => void;
}

export const PresenceListSectionHeader = React.forwardRef(
  (
    {
      title,
      subtitle,
      spaceWithImages,
      onSelectSpaceWithImages,
      titleTypographyProps,
      subtitleTypographyProps,
    }: PresenceListSectionHeaderProps,
    ref
  ) => (
    <>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "shades.grey",
          mb: 1,
          pr: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            variant="subtitle2"
            {...titleTypographyProps}
            sx={{ marginRight: "8px" }}
          >
            {title}
          </Typography>
          <Typography variant="subtitle2" {...subtitleTypographyProps}>
            {subtitle}
          </Typography>
        </Box>
        {spaceWithImages && onSelectSpaceWithImages && (
          <Button
            variant="icon"
            color="secondary"
            onClick={() => {
              trackOpenGallery("Presence list button");
              onSelectSpaceWithImages(spaceWithImages);
            }}
          >
            <Camera size={16} />
          </Button>
        )}
      </Box>
      <Divider
        sx={{
          mb: 1,
          mt: 0.5,
        }}
      />
    </>
  )
);
