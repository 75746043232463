import { Typography } from "@mui/material";
import React from "react";
import { Layers } from "react-feather";
import { AmtMasterBadge } from "../../../../../components";
import { Floor } from "../../../../assets/domain";

export interface FloorNameBadgeProps {
  floor: Floor;
}

export const FloorNameBadge: React.FC<FloorNameBadgeProps> = ({ floor }) => {
  return (
    <AmtMasterBadge sx={{ marginRight: 1 }}>
      <Layers size={16} />
      <Typography sx={{ ml: 1 }} variant="body2">
        <strong>{floor.description}</strong>
      </Typography>
    </AmtMasterBadge>
  );
};
