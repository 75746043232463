import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import React from "react";
import { ApplyToRoomCheckbox } from "./ApplyToRoomCheckbox";

export interface FormikCheckboxProps
  extends FieldProps<boolean>,
    Omit<TextFieldProps, "name" | "value" | "error" | "onChange"> {}

export const FormikCheckbox: React.FC<FormikCheckboxProps> = ({ ...props }) => (
  <ApplyToRoomCheckbox
    value={props.field.value}
    onChange={(applyToRoom: boolean) => {
      props.field.onChange({
        name: props.field.name,
        target: {
          name: props.field.name,
          value: applyToRoom,
        },
      });
    }}
  />
);
