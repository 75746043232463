import { Reservation } from "../../../backoffice/modules/analytics/api";
import { UserWithTeam } from "../../../modules/search/domain";

export enum CellType {
  FULL_NAME = "name",
  RESERVATIONS = "reservations",
  EMAIL = "email",
  TEAM = "team",
  FAVORITE = "favorite",
  TOTAL = "total",
}

export class GroupedReservations {
  constructor(
    public readonly bookedFor: UserWithTeam,
    public readonly reservations: Reservation[]
  ) {}
}
