import { Chip, ListSubheader, Popper, styled } from "@mui/material";

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.cyclamen.light,
  color: theme.palette.shades.dark,
  padding: theme.spacing(0, 1),
  borderRadius: "16px",

  // @ts-ignore
  ...theme.typography.body2,
}));

export const StyledTeamName = styled("span")(({ theme }) => ({
  textTransform: "uppercase",

  // @ts-ignore
  ...theme.typography.body3,
  fontWeight: theme.typography.fontWeightBold,
}));

export const StyledListSubheader = styled(ListSubheader)<{
  borderBottom?: boolean;
}>(({ theme, borderBottom }) => ({
  display: "flex",
  gap: theme.spacing(1),
  textTransform: "none",
  borderBottom: borderBottom ? "none" : "1px solid rgb(208, 207, 207)",
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw !important",
  },
}));
