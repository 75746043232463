import { Configuration, V0alpha2Api } from "@ory/kratos-client";
import { getJson } from "../../../api";
import {
  CurrentUser,
  Email,
  Name,
  Organization,
  OrganizationMember,
  OrganizationMembership,
  OrganizationRole,
  User,
} from "../domain";

export const api = new V0alpha2Api(
  new Configuration({
    basePath: document.location.origin,
  })
);

export interface UserDto {
  id: string;
  name: {
    first: string;
    last: string;
  };
  email: {
    value: string;
    verified: boolean;
  };
  avatarImageUrl: string | null;
}

export interface OrganizationDto {
  id: string;
  name: string;
  domain: string;
}

export interface OrganizationMembersDto {
  id: string;
  user: UserDto;
  organization: OrganizationDto;
  role: OrganizationRole;
}

interface UsersResponse {
  content: UserDto[];
}

interface OrganizationMembersResponse {
  content: OrganizationMembersDto[];
}

interface CurrentUserResponse {
  user: UserDto;
  organizations: {
    id: string;
    organization: OrganizationDto;
    role: "MEMBER" | "ADMINISTRATOR";
  }[];
}

export async function getCurrentUser(): Promise<CurrentUser> {
  const response = await getJson<CurrentUserResponse>("/api/current-user");
  return mapCurrentUser(response);
}

export async function getUsers(organizationId: string): Promise<User[]> {
  const response = await getJson<UsersResponse>("/api/users", {
    "filter.organization.id": organizationId,
  });
  return response.content.map(mapUser);
}

export async function getOrganizationMembers(
  organizationId: string
): Promise<OrganizationMember[]> {
  const response = await getJson<OrganizationMembersResponse>(
    "/api/organization-memberships",
    {
      "filter.organization.id": organizationId,
      size: 200,
      sort: "user.name",
    }
  );
  return response.content.map((it) => {
    return new OrganizationMember(
      it.id,
      mapUser(it.user),
      mapOrganization(it.organization)
    );
  });
}

function mapCurrentUser(response: CurrentUserResponse): CurrentUser {
  const memberships = response.organizations.map(
    (it) =>
      new OrganizationMembership(
        it.id,
        mapUser(response.user),
        mapOrganization(it.organization),
        OrganizationRole.from(it.role)
      )
  );

  return new CurrentUser(
    response.user.id,
    new Name(response.user.name.first, response.user.name.last),
    new Email(response.user.email.value, response.user.email.verified),
    response.user.avatarImageUrl,
    memberships
  );
}

export function mapUser(it: UserDto): User {
  return new User(
    it.id,
    new Name(it.name.first, it.name.last),
    new Email(it.email.value, it.email.verified),
    it.avatarImageUrl
  );
}

export function mapOrganization(it: OrganizationDto): Organization {
  return new Organization(it.id, it.name, it.domain);
}

export interface KratosErrorResponse {
  error: {
    id: string;
    code: number;
  };
}

export interface SessionRefreshRequiredResponse extends KratosErrorResponse {
  error: {
    id: "session_refresh_required";
    code: 403;
  };
  redirect_browser_to: string;
}

export interface BrowserLocationChangeRequiredResponse
  extends KratosErrorResponse {
  error: {
    id: "browser_location_change_required";
    code: 422;
  };
  redirect_browser_to: string;
}

export interface SessionAlreadyAvailableError extends KratosErrorResponse {
  error: {
    id: "session_already_available";
    code: 400;
  };
  redirect_browser_to: string;
}

function isSessionRefreshRequiredError(
  response: any
): response is SessionRefreshRequiredResponse {
  return (
    response &&
    response.error &&
    response.error.id &&
    response.error.id === "session_refresh_required"
  );
}

function isBrowserLocationChangeRequiredError(
  response: any
): response is BrowserLocationChangeRequiredResponse {
  return (
    response &&
    response.error &&
    response.error.id &&
    response.error.id === "browser_location_change_required"
  );
}

export function isSessionAlreadyAvailableError(
  response: any
): response is BrowserLocationChangeRequiredResponse {
  return (
    response &&
    response.error &&
    response.error.id &&
    response.error.id === "session_already_available"
  );
}

export function getRedirectBrowserTo(error: any): string | null {
  if (
    isSessionRefreshRequiredError(error) ||
    isBrowserLocationChangeRequiredError(error)
  ) {
    return error.redirect_browser_to;
  }
  return null;
}

export function isFlowInstance<T>(response: any): response is T {
  return response && response.ui;
}
