import React from "react";
import { useSearchParams } from "react-router-dom";
import { useIsMobile } from "../../../../hooks";
import {
  ConnectedBookingCancellationDialog,
  PageContainer,
} from "../../components";
import { ConnectedApproveInvitationDialog } from "../../components/ApproveInvitationDialog";
import { DeskBooking } from "../../domain";
import { DashboardLocations } from "./DashboardLocations";
import { FloorPlan } from "./FloorPlan";
import { useSelectedBooking } from "./hooks";
import { LocationBookingEmptyState } from "./LocationBookingEmptyState";
import {
  UpcomingBookingsMobile,
  UpcomingBookingsSidebar,
} from "./UpcomingBookings";
import { UpcomingBookingsContent } from "./UpcomingBookingsContent";

const MyBookingsPageComponent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const myBookings = searchParams.has("my-bookings");
  const [selectedBooking] = useSelectedBooking();
  const isMobile = useIsMobile();

  return (
    <PageContainer display="flex">
      {myBookings && isMobile && <UpcomingBookingsMobile />}
      {!myBookings && <DashboardLocations />}

      {myBookings && !isMobile && (
        <>
          <UpcomingBookingsSidebar />
          {selectedBooking !== null ? (
            selectedBooking.booking instanceof DeskBooking ? (
              <FloorPlan />
            ) : (
              <LocationBookingEmptyState booking={selectedBooking.booking} />
            )
          ) : (
            <UpcomingBookingsContent />
          )}
        </>
      )}
    </PageContainer>
  );
};

export const MyBookingsPage: React.FC = () => {
  return (
    <>
      <ConnectedBookingCancellationDialog />
      <ConnectedApproveInvitationDialog />
      <MyBookingsPageComponent />
    </>
  );
};
