import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../../api";
import { OrganizationMembership } from "../../../../modules/authentication/domain";
import { updateMembership } from "../../../api";
import { useDeleteAdminForm } from "../state";

export function useDeleteAdminMutation(): UseMutationResult<
  OrganizationMembership,
  AmtError,
  string
> {
  const { t } = useTranslation("admin-management");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const form = useDeleteAdminForm();

  return useMutation(
    ["delete-admin"],
    async (userId: string) => {
      return updateMembership(userId, "MEMBER");
    },
    {
      onSuccess: () => {
        snackbar.enqueueSnackbar(t("dialog.feedback.delete-admin-success"), {
          variant: "success",
        });
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.delete-admin-error"), {
          variant: "error",
        });
        form.closeDialog();
      },
      onSettled: () => {
        qc.invalidateQueries(["admin-users"]);
        qc.invalidateQueries(["admin-groups"]);
      },
    }
  );
}
