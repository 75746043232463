import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AmtAlert } from "../../../../../components";
import {
  CellType,
  GroupedReservations,
} from "../../../../../global/components/Tables";
import {
  DataContentCell,
  DataHeaderCell,
} from "../../../../../global/components/Tables/TableCells";
import { newUserWithTeam } from "../../../../../modules/search/domain";
import { useIsOverflow, useReservationsQuery } from "../../hooks";
import { ColumnFilter } from "./ColumnFilter";

export class ColumnItem {
  constructor(
    public readonly id: number,
    public readonly cellType: CellType,
    public readonly gridWidth: string
  ) {}
}

export class ReservationFilters {
  constructor(
    public readonly nameFilter: string | null,
    public readonly reservationsFilter: string | null
  ) {}
}

const menuContent = [new ColumnItem(3, CellType.RESERVATIONS, "10fr")];

export const AnalyticsTable: React.FC = () => {
  const { t } = useTranslation("analytics");
  const { data: reservations } = useReservationsQuery();
  const [selectedItems, setSelectedItems] = useState<ColumnItem[]>(
    menuContent.map((item) => item)
  );
  const [filteredReservations, setFilteredReservations] = useState<
    GroupedReservations[]
  >([]);
  const [reservationFilters, setReservationFilters] =
    useState<ReservationFilters>(new ReservationFilters(null, null));

  const filterReservations = useMemoizedFn((): GroupedReservations[] => {
    if (reservations) {
      let allReservations = [...reservations];
      if (reservationFilters.reservationsFilter !== null) {
        allReservations = allReservations.filter((reservation) => {
          return (
            (!!reservation.desk && reservationFilters.reservationsFilter) ||
            (!reservation.desk && !reservationFilters.reservationsFilter)
          );
        });
      }
      let uniqueUsers = Array.from(
        new Map(
          allReservations.map((reservation) => [
            reservation.bookedFor["id"],
            reservation.bookedFor,
          ])
        ).values()
      );
      return uniqueUsers.map((user) => {
        const userReservations = allReservations.filter((reservation) => {
          return user.id === reservation.bookedFor.id;
        });
        return new GroupedReservations(newUserWithTeam(user), userReservations);
      });
    }
    return [];
  });

  useEffect(() => {
    setFilteredReservations(filterReservations());
  }, [filterReservations, reservationFilters, reservations]);

  const sortItems = (a: ColumnItem, b: ColumnItem) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };

  const handleClickAll = () => {
    if (selectedItems.length === menuContent.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(menuContent.map((item) => item));
    }
  };

  const handleClickItem = (item: ColumnItem) => {
    const index = selectedItems.findIndex((element) => item.id === element.id);
    if (index === -1) {
      setSelectedItems((prev) => [...prev, item].sort(sortItems));
    } else {
      setSelectedItems((prev) => {
        const newList = [...prev];
        newList.splice(index, 1);
        return newList.sort(sortItems);
      });
    }
  };

  const ref = React.useRef<HTMLInputElement>(null);
  const isOverflow = useIsOverflow(ref);

  const cellShadow = () => {
    if (isOverflow)
      return {
        boxShadow: "3px 0px 3px rgba(20, 17, 16, 0.1)",
        borderRight: "1px solid",
        borderColor: "shades.greyExtraLight",
      };
  };

  return (
    <Box
      sx={{
        marginTop: "36px",
        border: "1px solid",
        borderColor: "shades.greyExtraLight",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        maxWidth: "86.5vw",
      }}
    >
      <ColumnFilter
        selectedItems={selectedItems}
        menuContent={menuContent}
        handleClickAll={handleClickAll}
        handleClickItem={handleClickItem}
      />

      <Box
        sx={{
          maxHeight: "100vh",
          overflow: "auto",
          "&:focus": {
            boxShadow: "0 0 0.5em rgba(0, 0, 0, 0.5)",
            outline: 0,
          },
        }}
        ref={ref}
      >
        <Table
          sx={{
            whiteSpace: "nowrap",
            margin: "0",
            borderCollapse: "separate",
            borderSpacing: "0",
            width: "100%",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "&:hover": {
                  color: "red",
                },
              }}
            >
              <DataHeaderCell
                cellType={CellType.FULL_NAME}
                reservationFilters={reservationFilters}
                setReservationFilters={setReservationFilters}
                shadow={cellShadow()}
              />
              {selectedItems.map((item) => (
                <DataHeaderCell
                  key={item.id}
                  cellType={item.cellType}
                  reservationFilters={reservationFilters}
                  setReservationFilters={setReservationFilters}
                />
              ))}
              <DataHeaderCell
                cellType={CellType.TOTAL}
                reservationFilters={reservationFilters}
                setReservationFilters={setReservationFilters}
                shadow={cellShadow()}
              />
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              textAlign: "center",
              position: "relative",
            }}
          >
            {filteredReservations && filteredReservations.length > 0 ? (
              filteredReservations.map((groupedReservations, index) => {
                return (
                  <TableRow
                    key={groupedReservations.bookedFor.id}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "shades.greyExtraLight",
                      backgroundColor: "white",
                      "&:nth-of-type(even)": {
                        backgroundColor: "#FBFBFB",
                      },
                      "&:last-child": {
                        border: "none",
                        " > td": {
                          borderBottom: "none",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "green.light",
                      },
                    }}
                  >
                    <DataContentCell
                      cellType={CellType.FULL_NAME}
                      groupedReservations={groupedReservations}
                      shadow={cellShadow()}
                    />
                    {selectedItems.map((item) => (
                      <DataContentCell
                        key={item.id}
                        cellType={item.cellType}
                        groupedReservations={groupedReservations}
                      />
                    ))}
                    <DataContentCell
                      cellType={CellType.TOTAL}
                      groupedReservations={groupedReservations}
                      shadow={cellShadow()}
                    />
                  </TableRow>
                );
              })
            ) : (
              <TableRow sx={{ padding: "4px 8px" }}>
                <TableCell colSpan={3}>
                  <AmtAlert
                    title={t("data-preview.alert-title")}
                    text={t("data-preview.not-available")}
                    variant="neutral"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
