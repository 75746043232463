import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../../../authentication/hooks";
import { getGoogleCalendarIntegrationSettings } from "../api";
import { GoogleCalendarIntegrationSettings } from "../domain";

export function useGoogleCalendarIntegrationSettings(): UseQueryResult<GoogleCalendarIntegrationSettings> {
  const { organization } = useOrganizationMembership();
  return useQuery(["google-calendar-integration", organization.id], () =>
    getGoogleCalendarIntegrationSettings(organization.id)
  );
}
