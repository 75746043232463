import { useQuery, UseQueryResult } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReservationDate } from "../../../backoffice/modules/analytics/state";
import { GroupedReservations } from "../../../global/components/Tables";
import {
  useCurrentUser,
  useOrganizationMembership,
} from "../../authentication/hooks";
import { getFilteredReservations } from "../api";

export function useReservationsQuery(): UseQueryResult<GroupedReservations[]> {
  const { organization } = useOrganizationMembership();
  const [searchParams] = useSearchParams();
  const { dateRange } = useReservationDate();
  const currentUser = useCurrentUser();

  const userIds = searchParams.getAll("userIds");
  const teamIds = searchParams.getAll("teamIds");
  const locationIds = searchParams.getAll("locationIds");

  return useQuery(
    [
      "filtered-reservations",
      organization.id,
      currentUser,
      userIds,
      teamIds,
      dateRange,
      locationIds,
    ],
    () =>
      getFilteredReservations(
        organization.id,
        currentUser.organizations[0].id,
        locationIds.length > 0 ? locationIds : null,
        userIds.length > 0 ? userIds : null,
        teamIds.length > 0 ? teamIds : null,
        dateRange.startDate,
        dateRange.endDate
      )
  );
}
