import { mapOrganizationGroups, OrganizationGroupsResponse } from ".";
import { getJson, request } from "../../api";
import {
  mapOrganization,
  mapUser,
  OrganizationMembersDto,
} from "../../modules/authentication/api";
import { OrganizationMembership } from "../../modules/authentication/domain";
import { OrganizationGroups } from "../modules/asset-management/domain";

export const mapOrganizationMembership = (
  response: OrganizationMembersDto
): OrganizationMembership => {
  return new OrganizationMembership(
    response.id,
    mapUser(response.user),
    mapOrganization(response.organization),
    response.role
  );
};

export async function getAdminUsers(
  organizationId: string
): Promise<OrganizationMembership[]> {
  const response = await getJson<OrganizationMembersDto[]>(
    `/api/organization-memberships/administrators`,
    {
      "organization.id": organizationId,
    }
  );
  return response.map(mapOrganizationMembership);
}

export async function getAdminGroups(
  organizationId: string
): Promise<OrganizationGroups> {
  const response = await getJson<OrganizationGroupsResponse>(
    `/api/organization-memberships/groups`,
    {
      "organization.id": organizationId,
    }
  );
  return mapOrganizationGroups(response);
}

export async function updateMembership(
  membershipId: string,
  role: string
): Promise<OrganizationMembership> {
  const response = await request<OrganizationMembersDto>(
    "PATCH",
    `/api/organization-memberships/${membershipId}`,
    {
      organizationRole: role,
    }
  );
  return mapOrganizationMembership(response);
}
