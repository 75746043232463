import { useEventListener } from "ahooks";
import React from "react";
import { useBookingForm } from "./state";

export const BookingPageNavigationBlocker: React.FC = () => {
  const { isEditing } = useBookingForm();

  useEventListener("beforeunload", (event) => {
    if (isEditing) {
      event.preventDefault();
    }
  });

  return null;
};
