import { Box, BoxProps } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import React from "react";
import { ArrowLeft } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { DataSidebar } from "../../global/components/Sidebars";
import { StyledPageContentWrapper } from "../../global/styles";
import { InfoBanner } from "./InfoBanner";
import { InfoBannerContent } from "./InfoBanner/components";

export const BackofficePageContainer: React.FC<
  BoxProps & React.PropsWithChildren<{}>
> = React.memo(({ sx, children, ...props }) => {
  const { t } = useTranslation("booking");
  const navigate = useNavigate();

  const handleBack = useMemoizedFn(() => {
    navigate("/");
  });

  return (
    <Box
      {...props}
      sx={[
        (theme) => ({
          height: "calc(100vh - 54px)",
          display: "flex",
          flexDirection: "column",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <InfoBanner
        tooltipTitle={t("filter.title.back")}
        handleClick={handleBack}
        icon={<ArrowLeft size={16} />}
      >
        <InfoBannerContent />
      </InfoBanner>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          width: "100%",
          overflow: "visible",
        }}
      >
        <DataSidebar />
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          })}
        >
          <StyledPageContentWrapper>{children}</StyledPageContentWrapper>
        </Box>
      </Box>
    </Box>
  );
});
