import React from "react";
import { AdminManagement } from "..";
import { BackofficePageContainer } from "../../../components";

export const AdminManagementPage: React.FC = () => {
  return (
    <BackofficePageContainer>
      <AdminManagement />
    </BackofficePageContainer>
  );
};
