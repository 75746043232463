import { Desk, Space } from "../../modules/assets/domain";
import { User } from "../../modules/authentication/domain";
import { DeskStatus } from "../modules/asset-management/domain";

export class AssetRule {
  constructor(
    public readonly assetRuleId: string,
    public readonly assetStatus: DeskStatus,
    public readonly desk: Desk | null,
    public readonly space: Space,
    public readonly dedicatedFor: User[],
    public readonly changedBy: User
  ) {}
}
