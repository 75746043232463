import { getJson } from "../../../api";
import { SpaceImage, SpaceWithImages } from "../domain/SpaceImages";

interface SpaceImageDto {
  id: string;
  spaceImageUrl: string;
  spaceId: string;
}

interface SpaceWithImagesDto {
  id: string;
  description: string;
  spaceType: string;
  locationId: string;
  floorId: string;
  images: SpaceImageDto[];
}

interface SpaceImagesResponse {
  content: SpaceWithImagesDto[];
}

export function mapSpaceWithImages(dto: SpaceWithImagesDto): SpaceWithImages {
  return new SpaceWithImages(
    dto.id,
    dto.description,
    dto.spaceType,
    dto.locationId,
    dto.floorId,
    dto.images.map((image) => mapSpaceImage(image))
  );
}

export function mapSpaceImage(dto: SpaceImageDto): SpaceImage {
  return new SpaceImage(dto.id, dto.spaceImageUrl, dto.spaceId);
}

export async function getSpacesWithImages(
  organizationId: string,
  locationId: string | null
): Promise<SpaceWithImages[]> {
  const response = await getJson<SpaceImagesResponse>("/api/space-images", {
    "filter.organization.id": organizationId,
    "filter.location.id": locationId,
  });
  return response.content.map((spaceWithImages) => {
    return mapSpaceWithImages(spaceWithImages);
  });
}
