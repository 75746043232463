export class DeskAvailability {
  private constructor(private name: string) {}

  public get canBook(): boolean {
    return this === DeskAvailability.AVAILABLE;
  }

  static AVAILABLE = new DeskAvailability("AVAILABLE");
  static PARTIALLY_AVAILABLE = new DeskAvailability("PARTIALLY_AVAILABLE");
  static PARTIALLY_DEDICATED = new DeskAvailability("PARTIALLY_DEDICATED");
  static NOT_AVAILABLE = new DeskAvailability("NOT_AVAILABLE");
  static DISABLED = new DeskAvailability("DISABLED");
  static DEDICATED = new DeskAvailability("DEDICATED");
  static PENDING = new DeskAvailability("PENDING");

  static readonly values: DeskAvailability[] = [
    DeskAvailability.AVAILABLE,
    DeskAvailability.PARTIALLY_AVAILABLE,
    DeskAvailability.PARTIALLY_DEDICATED,
    DeskAvailability.NOT_AVAILABLE,
    DeskAvailability.DISABLED,
    DeskAvailability.DEDICATED,
    DeskAvailability.PENDING,
  ];

  static valueOf(name: string): DeskAvailability {
    const resolved = DeskAvailability.values.find(
      (value) => value.name === name
    );
    if (resolved) {
      return resolved;
    }
    console.error(
      `Invalid DeskAvailability ${name}; Defaulting to NOT_AVAILABLE`
    );
    return DeskAvailability.NOT_AVAILABLE;
  }
}
