import { Box, styled } from "@mui/material";
import React from "react";
import { Avatar } from "../../../../../../../components";
import { GroupMember } from "../../../../../domain";

export interface SelectedMemberAvatarProps {
  bookFor: GroupMember[];
}

export const SelectedMemberAvatar: React.FC<SelectedMemberAvatarProps> = ({
  bookFor,
}) => {
  if (bookFor.length > 1) {
    return (
      <BookFormAvatarGroup>
        <BookFormAvatar src={bookFor[1].user.avatarImageUrlSrc} />
        <BookFormAvatar src={bookFor[0].user.avatarImageUrlSrc} />
      </BookFormAvatarGroup>
    );
  }
  if (bookFor.length === 1) {
    return <BookFormAvatar src={bookFor[0].user.avatarImageUrlSrc} />;
  }
  return <BookFormAvatar />;
};

export const BookFormAvatar = styled(Avatar)({
  flexShrink: 0,
  width: 36,
  height: 36,
  border: "1px solid white",
});

const BookFormAvatarGroup = styled(Box)({
  position: "relative",
  flexShrink: 0,
  width: 36,
  height: 36,
  ".MuiAvatar-root": {
    position: "absolute",
    width: 24,
    height: 24,
  },
  ".MuiAvatar-root:last-of-type": {
    left: 0,
    bottom: 0,
  },
  ".MuiAvatar-root:first-of-type": {
    right: 0,
    top: 0,
  },
});
