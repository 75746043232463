import { Box, Button } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import { useTranslation } from "react-i18next";
import { ConferenceRoom } from "../../../../../conference/domain";
import { useConferenceSelection } from "../../../../../conference/state";
import { BookingItemButton } from "../BookingItem";
import { ConferenceRoomBox } from "./ConferenceRoomBox";
import { ConferenceRoomItemContent } from "./ConferenceRoomItemContent";
import { SvgConferenceRoomAvatar } from "./icons";

export interface ConferenceRoomItemProps {
  conferenceRoom: ConferenceRoom;
}

export const ConferenceRoomItem: React.FC<ConferenceRoomItemProps> = ({
  conferenceRoom,
}) => {
  const { onSelectConferenceRoom } = useConferenceSelection();
  const handleOnClick = useMemoizedFn(() => {
    if (conferenceRoom) {
      onSelectConferenceRoom(conferenceRoom);
    }
  });
  const { t } = useTranslation("booking");

  return (
    <BookingItemButton
      onClick={handleOnClick}
      sx={{
        ".Item-visibleHover": {
          display: "none",
        },
        "&:hover": {
          backgroundColor: "shades.greyUltimateLight",
          cursor: "pointer",
          ".Item-visibleNoHover": {
            display: "none",
          },
          ".Item-visibleHover": {
            display: "flex",
            alignItems: "center",
          },
        },
      }}
    >
      <ConferenceRoomBox />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <ConferenceRoomItemContent
          primaryTypographyProps={{ color: "violet.regular" }}
          primary={conferenceRoom.name}
          secondary="Sala za sastanke"
          AvatarComponent={ConferenceRoomAvatar}
        />
        <Button className="Item-visibleHover" fullWidth>
          {t("item.action.schedule")}
        </Button>
      </Box>
    </BookingItemButton>
  );
};

const ConferenceRoomAvatar = () => {
  return (
    <Box
      sx={{ color: "violet.regular", display: "flex", alignItems: "center" }}
    >
      <SvgConferenceRoomAvatar />
    </Box>
  );
};
