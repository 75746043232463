import React from "react";
import {
  DeskAvailability,
  DeskOverview,
  SpaceOverview,
} from "../../../../domain";
import { DeskItemAvailable } from "./DeskItemAvailable";
import { DeskItemBooked } from "./DeskItemBooked";
import { DeskItemNotAvailable } from "./DeskItemNotAvailable";
import { DeskItemPending } from "./DeskItemPending";

export interface DeskItemProps {
  space: SpaceOverview;
  overview: DeskOverview;
}

export const DeskItem: React.FC<DeskItemProps> = React.memo(
  ({ space, overview }) => {
    switch (overview.availability) {
      case DeskAvailability.AVAILABLE:
        return <DeskItemAvailable overview={overview} />;
      case DeskAvailability.PENDING:
        return <DeskItemPending space={space} overview={overview} />;
      case DeskAvailability.NOT_AVAILABLE:
      default:
        if (overview.date.isSingleDay && !overview.isDisabled) {
          return <DeskItemBooked space={space} overview={overview} />;
        }
        return <DeskItemNotAvailable overview={overview} />;
    }
  }
);
