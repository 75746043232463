import { Box, BoxProps, styled, Typography } from "@mui/material";
import React from "react";

const Line = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
  borderBottom: "1px dashed",
  height: 1,
  borderColor: theme.palette.shades.grey,
}));

export const SectionDivider: React.FC<BoxProps> = ({ sx, children }) => (
  <Box
    sx={[
      {
        display: "flex",
        alignItems: "center",
        gap: 2,
        height: 40,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <Line />
    <Typography
      component="div"
      variant="body1"
      color="shades.grey"
      sx={{ flex: "0 0 auto" }}
    >
      {children}
    </Typography>
    <Line />
  </Box>
);
