import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { Home as HomeIcon, Layers as LayersIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { Icon, Info, Label, Row } from "../../../../../global/styles";
import { Floor, Location } from "../../../../assets/domain";
import { SpaceWithImages } from "../../../domain";

export interface DialogInfoProps {
  sx?: SxProps<Theme>;
  location: Location | null;
  floor: Floor | null;
  spaceWithImages: SpaceWithImages | null;
}

export const DialogInfo: React.FC<DialogInfoProps> = ({
  sx,
  location,
  floor,
  spaceWithImages,
}) => {
  const { t } = useTranslation("booking-details");

  return (
    <Box sx={sx}>
      {location && (
        <Row>
          <span>
            <Icon>
              <HomeIcon size={16} />
            </Icon>
          </span>
          <Info data-testid="dialog--location">
            <span>
              <strong>{location.name}</strong>
              <br />
              {location.address.addressLine1}, {location.address.city}
            </span>
          </Info>
        </Row>
      )}
      {floor && (
        <Row>
          <Icon>
            <LayersIcon size={16} />
          </Icon>
          <Info data-testid="dialog--floor">{floor.description}</Info>
        </Row>
      )}
      {spaceWithImages && (
        <Row>
          <Icon />
          <Info data-testid="dialog--space">
            <Label>{t("label.space")}</Label>
            {spaceWithImages.description}
          </Info>
        </Row>
      )}
    </Box>
  );
};
