export class NotificationSetting {
  constructor(
    public readonly translationKey: string,
    public readonly key: string,
    public subitem: boolean,
    public enabled: boolean
  ) {}

  static bookingConfirmationBySelf(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.booking-confirmation-by-self",
      "bookingConfirmationBySelfEnabled",
      false,
      enabled
    );
  }

  static allBookingsByOthers(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.bookings-by-others",
      "allBookingByOthersEnabled",
      false,
      enabled
    );
  }

  static bookingConfirmationByOthers(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.booking-confirmation-by-others",
      "bookingConfirmationByOthersEnabled",
      true,
      enabled
    );
  }

  static bookingInvitationConfirmationBySelf(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.booking-invitation-confirmation-by-self",
      "bookingInvitationConfirmationBySelfEnabled",
      true,
      enabled
    );
  }

  static bookingInvitationCancellationBySelf(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.booking-invitation-cancellation-by-self",
      "bookingInvitationCancellationBySelfEnabled",
      true,
      enabled
    );
  }

  static myBookingInvitations(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.my-booking-invitation",
      "allBookingInvitationByInviteeEnabled",
      false,
      enabled
    );
  }

  static bookingInvitationConfirmationByInvitee(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.booking-invitation-confirmation-by-invitee",
      "bookingInvitationConfirmationByInviteeEnabled",
      true,
      enabled
    );
  }

  static bookingInvitationCancellationByInvitee(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.booking-invitation-cancellation-by-invitee",
      "bookingInvitationCancellationByInviteeEnabled",
      true,
      enabled
    );
  }

  static followingBooking(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.following-booking",
      "bookingConfirmationByFolloweeEnabled",
      false,
      enabled
    );
  }

  static bookingCancellation(enabled: boolean) {
    return new NotificationSetting(
      "notifications.bookings.booking-cancellation",
      "bookingCancellationEnabled",
      false,
      enabled
    );
  }
}
