import { Box, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useTranslation } from "react-i18next";
import { DeskBooking } from "../../../domain";
import { useSelectedBooking } from "../hooks";
import { ClosePreviewButton } from "./components";

export const SelectedBookingLocationHeader: React.FC = () => {
  const { t } = useTranslation("floor-plan");
  const [selectedBooking, selectBooking] = useSelectedBooking();

  const unselectBooking = useMemoizedFn(() => {
    selectBooking(null);
  });

  if (!selectedBooking) {
    return null;
  }

  const { booking } = selectedBooking;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginY: 2,
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      })}
    >
      <Box>
        <Typography
          variant="subtitle1"
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              ...theme.typography.subtitle2,
            },
          })}
        >
          {t("preview")}
        </Typography>
        <Typography
          variant="body1"
          color="shades.grey"
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              ...theme.typography.body2,
            },
          })}
        >
          {booking.location.name}
          {booking instanceof DeskBooking && `, ${booking.floor.description}`}
        </Typography>
      </Box>
      <ClosePreviewButton onClick={unselectBooking} />
    </Box>
  );
};
