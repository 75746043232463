import { useFeatureFlag } from "./useFeatureFlag";

export class EditModeFeature {
  constructor(
    public readonly name: string,
    public readonly resetVisible: boolean,
    public readonly exitVisible: boolean
  ) {}
}

const EditModeVariationA = new EditModeFeature("edit-mode:all", true, true);
const EditModeVariationB = new EditModeFeature(
  "edit-mode:exit-only",
  false,
  true
);

export function useEditModeVariation(): EditModeFeature {
  return useFeatureFlag([EditModeVariationA, EditModeVariationB]);
}
