import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  ChevronsLeft,
  ChevronsRight,
  Home,
  Monitor,
  Table,
  User,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { DataMenuItem } from ".";
import { useIsMobile } from "../../../hooks";
import { useBackofficePermissions } from "../../../modules/permissions/hooks/useBackofficePermissions";

export const DataSidebar: React.FC = () => {
  const { t } = useTranslation("common");
  const isMobile = useIsMobile();
  const [menuOpen, setMenuOpen] = useState(!isMobile);
  const { isAdmin, isSuperAdmin } = useBackofficePermissions();

  const toggleSidemenu = () => {
    if (!isMobile) setMenuOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        paddingRight: menuOpen ? 0 : 2,
        width: menuOpen ? "227px" : "68px",
        backgroundColor: "shades.greyUltimateLight",
      }}
    >
      <Typography
        variant="overline"
        color="shades.grey"
        sx={(theme) => ({
          marginBottom: "10px",
          display: "flex",
          cursor: "pointer",
          [theme.breakpoints.down("md")]: {
            justifyContent: "center",
          },
        })}
        onClick={toggleSidemenu}
      >
        {!isMobile && (
          <Box>
            {menuOpen ? (
              <ChevronsLeft size={16} />
            ) : (
              <ChevronsRight size={16} />
            )}
          </Box>
        )}
        {t("components.sidebars.menu")}
      </Typography>
      {isAdmin ? (
        <>
          <DataMenuItem
            content={"analytics"}
            icon={<Table size={16} />}
            menuOpen={menuOpen}
          />
          <DataMenuItem
            content={"asset-management"}
            icon={<Home size={16} />}
            menuOpen={menuOpen}
          />
          <DataMenuItem
            content={"inventory-management"}
            icon={<Monitor size={16} />}
            menuOpen={menuOpen}
          />
          {isSuperAdmin && (
            <DataMenuItem
              content={"admin-management"}
              icon={<User size={16} />}
              menuOpen={menuOpen}
            />
          )}
        </>
      ) : (
        <DataMenuItem
          content={`people`}
          icon={<Table size={16} />}
          menuOpen={menuOpen}
        />
      )}
    </Box>
  );
};
