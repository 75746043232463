import { SxProps, Theme } from "@mui/material";
import React from "react";
import { AmtCard, AmtCardProps } from "../../../../../components";
import { Floor } from "../../../../assets/domain";
import { FloorCardHoverOverlay } from "./FloorCardHoverOverlay";

export interface FloorCardProps {
  onClick: AmtCardProps["onClick"];
  interactive: boolean;
  square: boolean;
  floor: Floor;
  sx?: SxProps<Theme>;
}

export const FloorCard: React.FC<React.PropsWithChildren<FloorCardProps>> = ({
  onClick,
  interactive,
  square,
  floor,
  sx,
  children,
}) => (
  <AmtCard
    onClick={onClick}
    sx={[
      {
        position: "relative",
        maxHeight: "calc(100vh - 170px)",
        ...(square && {
          "&:after": {
            content: '""',
            display: "block",
            paddingTop: "100%",
          },
        }),
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    pop={!interactive}
    HoverOverlayComponent={({ className }) => (
      <FloorCardHoverOverlay className={className} floor={floor} />
    )}
    data-testid="floor-card"
    aria-label={floor.description}
  >
    {children}
  </AmtCard>
);
