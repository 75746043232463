import { parseISO } from "date-fns";
import { getJson, Page, PageDto, request } from "../../../api";
import {
  DeskDto,
  FloorDto,
  LocationDto,
  mapDesk,
  mapFloor,
  mapLocation,
  mapSpace,
  SpaceDto,
} from "../../assets/api";
import { Desk, Floor, Space } from "../../assets/domain";
import { mapUser, UserDto } from "../../authentication/api";
import {
  BookedNotification,
  DeletedBookingNotificationContext,
  InvitationCancelledNotification,
  InvitationSentNotification,
  NewBookingNotificationContext,
  Notification,
  UnbookedNotification,
  UpdatedBookingNotificationContext,
  UpdatedNotification,
} from "../domain";

export interface NotificationDto {
  id: string;
  type: string;
  context: any;
  createdAt: string;
}

interface NotificationsResponse extends PageDto<NotificationDto> {}

interface NewBookingContextResponse {
  meta: {
    desk: DeskDto | null;
    space: SpaceDto | null;
    floor: FloorDto | null;
    location: LocationDto;
    bookedBy: UserDto;
    bookingDate: string;
  };
}

interface DeletedBookingContextResponse extends NewBookingContextResponse {
  deletedBy: UserDto;
}

interface UpdatedBookingContextResponse {
  before: {
    desk: DeskDto | null;
    space: SpaceDto | null;
    floor: FloorDto | null;
    location: LocationDto;
    bookedBy: UserDto;
    bookingDate: string;
  };
  after: {
    desk: DeskDto | null;
    space: SpaceDto | null;
    floor: FloorDto | null;
    location: LocationDto;
    bookedBy: UserDto;
    bookingDate: string;
  };
}

function mapNewBookingNotificationContext(
  context: NewBookingContextResponse
): NewBookingNotificationContext {
  const { meta } = context;
  return new NewBookingNotificationContext(
    meta.desk ? mapDesk(meta.desk) : null,
    meta.floor ? mapFloor(meta.floor) : null,
    meta.space ? mapSpace(meta.space) : null,
    mapLocation(meta.location),
    parseISO(meta.bookingDate),
    mapUser(meta.bookedBy)
  );
}

type MapperFn<Value, Result> = (value: Value) => Result;

function mapNullable<T, R>(
  mapper: MapperFn<T, R>
): MapperFn<T | null, R | null> {
  return function (dto: T | null): R | null {
    return dto ? mapper(dto) : null;
  };
}

const mapNullableDesk = mapNullable<DeskDto, Desk>(mapDesk);
const mapNullableSpace = mapNullable<SpaceDto, Space>(mapSpace);
const mapNullableFloor = mapNullable<FloorDto, Floor>(mapFloor);

function mapDeletedBookingNotificationContext(
  context: DeletedBookingContextResponse
): DeletedBookingNotificationContext {
  const { meta } = context;
  return new DeletedBookingNotificationContext(
    mapNullableDesk(meta.desk),
    mapNullableFloor(meta.floor),
    mapNullableSpace(meta.space),
    mapLocation(meta.location),
    parseISO(meta.bookingDate),
    mapUser(meta.bookedBy),
    mapUser(context.deletedBy)
  );
}

function mapUpdatedBookingNotificationContext(
  context: UpdatedBookingContextResponse
): UpdatedBookingNotificationContext {
  return new UpdatedBookingNotificationContext(
    {
      bookingDate: parseISO(context.before.bookingDate),
      bookedBy: mapUser(context.before.bookedBy),
      desk: mapNullableDesk(context.before.desk),
      floor: mapNullableFloor(context.before.floor),
      space: mapNullableSpace(context.before.space),
      location: mapLocation(context.before.location),
    },
    {
      bookingDate: parseISO(context.after.bookingDate),
      bookedBy: mapUser(context.after.bookedBy),
      desk: mapNullableDesk(context.after.desk),
      floor: mapNullableFloor(context.after.floor),
      space: mapNullableSpace(context.after.space),
      location: mapLocation(context.after.location),
    }
  );
}

export function mapNotification(
  response: NotificationDto
): Notification | null {
  try {
    switch (response.type) {
      case "DESK_BOOKED":
        return new BookedNotification(
          response.id,
          parseISO(response.createdAt),
          mapNewBookingNotificationContext(
            response.context as NewBookingContextResponse
          )
        );
      case "DESK_UNBOOKED":
        return new UnbookedNotification(
          response.id,
          parseISO(response.createdAt),
          mapDeletedBookingNotificationContext(
            response.context as DeletedBookingContextResponse
          )
        );
      case "DESK_UPDATED":
        return new UpdatedNotification(
          response.id,
          parseISO(response.createdAt),
          mapUpdatedBookingNotificationContext(
            response.context as UpdatedBookingContextResponse
          )
        );
      case "INVITATION_SENT":
        return new InvitationSentNotification(
          response.id,
          parseISO(response.createdAt),
          mapNewBookingNotificationContext(
            response.context as NewBookingContextResponse
          )
        );
      case "INVITATION_CANCELLED":
        return new InvitationCancelledNotification(
          response.id,
          parseISO(response.createdAt),
          mapDeletedBookingNotificationContext(
            response.context as DeletedBookingContextResponse
          )
        );
      default:
        return null;
    }
  } catch (e) {
    // TODO: log
    return null;
  }
}

export async function getNotifications(
  recipientUserId: string
): Promise<Page<Notification>> {
  const response = await getJson<NotificationsResponse>(
    `/api/users/${recipientUserId}/notifications`
  );

  return new Page(
    response.content
      .map(mapNotification)
      .filter((it) => it !== null) as Notification[],
    response.totalPages,
    response.totalElements
  );
}

export async function markAllAsRead(recipientUserId: string): Promise<void> {
  await request<NotificationsResponse>(
    "DELETE",
    `/api/users/${recipientUserId}/notifications`
  );
}

export async function markAsRead(notificationId: string): Promise<void> {
  await request<NotificationsResponse>(
    "DELETE",
    `/api/notifications/${notificationId}`
  );
}
