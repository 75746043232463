import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { AssetInventory } from "../../../../modules/booking/domain";

type DeleteInventoryFormState =
  | {
      isOpen: false;
      inventory?: AssetInventory;
    }
  | {
      isOpen: true;
      inventory: AssetInventory;
    };

export type UseDeleteInventoryFormResult = DeleteInventoryFormState & {
  openDialog(inventory: AssetInventory): void;
  closeDialog(): void;
};

const formStateAtom = atom<DeleteInventoryFormState>({ isOpen: false });

export function useDeleteInventoryForm(): UseDeleteInventoryFormResult {
  const [formState, setFormState] = useAtom(formStateAtom);

  const openDialog = useMemoizedFn((inventory: AssetInventory) => {
    setFormState({
      isOpen: true,
      inventory,
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isOpen: false,
    });
  });

  return {
    ...formState,
    openDialog,
    closeDialog,
  };
}
