import { useQuery, UseQueryResult } from "react-query";
import { getDeskInventoryFiltersByFloor } from "../../booking/api";
import { DeskInventoryFilters } from "../../booking/domain";
import { useFloors } from "../../booking/pages/BookingPage/state";

export function useInventoryFiltersQuery(): UseQueryResult<
  DeskInventoryFilters[]
> {
  const { floorId } = useFloors();

  return useQuery(
    ["desk-inventory-filters", floorId],
    () => {
      if (floorId) {
        return getDeskInventoryFiltersByFloor(floorId);
      }
      return Promise.resolve([]);
    },
    {
      enabled: !!floorId,
    }
  );
}
