import { AppBar, Box, Link, Toolbar } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useIsMobile } from "../../../hooks";
import { LogoHeaderSvg } from "../assets";
import { useIsSearchOpen } from "../state";

export interface MainLayoutAppBarProps {
  actions: React.ReactNode;
}

const MainLayoutToolbar: React.FC<MainLayoutAppBarProps> = React.memo(
  ({ actions }) => {
    const isMobile = useIsMobile();
    const { isSearchOpen } = useIsSearchOpen();

    return (
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          backgroundColor: "green.regular",
        }}
      >
        {(!isMobile || (isMobile && !isSearchOpen)) && (
          <Box flexGrow={1}>
            <Box sx={{ display: "inline-block" }}>
              <Link
                underline="none"
                component={RouterLink}
                to="/"
                data-testid="navigation--home"
              >
                <LogoHeaderSvg />
              </Link>
            </Box>
          </Box>
        )}
        {actions}
      </Toolbar>
    );
  }
);

export const MainLayoutAppBar: React.FC<MainLayoutAppBarProps> = ({
  actions,
}) => (
  <AppBar>
    <MainLayoutToolbar actions={actions} />
  </AppBar>
);
