import { Box, styled } from "@mui/material";

export const StyledPageContentWrapper = styled(Box)(({ theme }) => ({
  borderLeft: "1px solid #d0cfce",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  padding: "24px 14px",
  backgroundColor: "#ffffff",
  overflow: "hidden",
}));
