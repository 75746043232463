import useCreation from "ahooks/es/useCreation";
import { getDayOfYear, startOfDay } from "date-fns";

export function useDashboardDate(): [Date] {
  const from = useCreation(
    () => startOfDay(new Date()),
    [getDayOfYear(new Date())]
  );
  return [from];
}
