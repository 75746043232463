import { Box } from "@mui/material";
import React from "react";
import { X } from "react-feather";
import { StyledChip } from "../../../../../../global/styles";
import { SelectedItem } from "../../state";

interface InfoBannerChipProps {
  item: SelectedItem;
  onDelete: (id: string) => void;
}

export const InfoBannerChip: React.FC<InfoBannerChipProps> = ({
  item,
  onDelete,
}) => {
  return (
    <StyledChip
      key={item.id}
      label={item.name}
      onDelete={() => onDelete(item.id)}
      deleteIcon={
        <Box sx={{}}>
          <X size={16} />
        </Box>
      }
      sx={{ margin: 0.5 }}
    />
  );
};
