import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BoxCenter, ResponsiveH6 } from "../../../../global/components";
import { useLastUrlPart } from "../../../../hooks";

export const InfoBannerContent: React.FC = () => {
  const lastUrlPart = useLastUrlPart();
  const { t } = useTranslation(lastUrlPart);

  return (
    <BoxCenter column>
      <ResponsiveH6 sx={{ mb: "12px" }}>{t("header.title")}</ResponsiveH6>
      <Typography>{t("header.subtitle")}</Typography>
    </BoxCenter>
  );
};
