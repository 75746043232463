import {
  Box,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { Avatar } from "../../components";

const StyledMenuItem = styled(MenuItem)({
  minHeight: "auto !important",
  '&.MuiAutocomplete-option[aria-disabled="true"]': {
    opacity: 1,

    ".MuiListItemAvatar-root, .MuiListItemText-primary": {
      opacity: 0.45,
    },
  },
  borderRadius: "0",
  marginTop: "8px",
});

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  alignSelf: "flex-start",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: "24px",
  height: "24px",
  backgroundColor: theme.palette.shades.greyExtraLight,
  marginRight: "8px",
  color: theme.palette.shades.greyMedium,
}));

const StyledAvatar = styled(Avatar)({
  width: 32,
  height: 32,
});

export const MemberMenuItemContent: React.FC<{
  elementProps: React.HTMLAttributes<HTMLLIElement>;
  name: string;
  icon?: React.ReactNode;
  available?: boolean;
  avatarImageUrl?: string;
  team?: string | null;
  errorText?: string;
}> = React.memo(
  ({
    elementProps,
    name,
    icon,
    available,
    avatarImageUrl,
    team,
    errorText,
  }) => {
    return (
      <StyledMenuItem {...elementProps}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {icon && (
            <StyledListItemAvatar sx={{ minWidth: 24 }}>
              {icon}
            </StyledListItemAvatar>
          )}
          {!icon && (
            <StyledListItemAvatar sx={{ minWidth: 32 }}>
              <StyledAvatar src={avatarImageUrl} />
            </StyledListItemAvatar>
          )}
          <ListItemText
            primary={name}
            secondary={
              available === false && errorText ? (
                <Typography variant="body3" color="errors.regular">
                  {errorText}
                </Typography>
              ) : (
                team !== null &&
                team !== undefined && (
                  <Typography variant="body3" color="shades.grey">
                    {team}
                  </Typography>
                )
              )
            }
          />
        </Box>
      </StyledMenuItem>
    );
  }
);
