import { useQuery, UseQueryResult } from "react-query";
import {
  useCurrentUser,
  useOrganizationMembership,
} from "../../authentication/hooks";
import { getSearchBookingParams, SearchBookingParams } from "../api";

export function useSearchParamsQuery(): UseQueryResult<SearchBookingParams> {
  const { organization } = useOrganizationMembership();
  const currentUser = useCurrentUser();

  return useQuery(["search-booking-params", organization.id], () =>
    getSearchBookingParams(organization.id, currentUser.organizations[0].id)
  );
}
