import { TypographyOptions } from "@mui/material/styles/createTypography";

export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const Raleway = {
  fontFamily: "'Raleway', 'sans-serif'",
  fontFeatureSettings: "'pnum' on, 'lnum' on",
  letterSpacing: "normal",
};

export const Nunito = {
  fontFamily: "'Nunito', 'sans-serif'",
  fontFeatureSettings: "'pnum' on, 'lnum' on",
  letterSpacing: "normal",
};

export const typography: TypographyOptions = {
  fontWeightRegular: fontWeight.regular,
  fontWeightMedium: fontWeight.medium,
  fontWeightBold: fontWeight.bold,
  body1: {
    ...Nunito,
    fontWeight: fontWeight.regular,
    fontSize: 16,
    lineHeight: "24px",
  },
  body2: {
    ...Nunito,
    fontWeight: fontWeight.medium,
    fontSize: 14,
    lineHeight: "20px",
  },
  // @ts-ignore
  body3: {
    display: "block",
    ...Nunito,
    fontWeight: fontWeight.medium,
    fontSize: 12,
    lineHeight: "18px",
  },
  button: {
    ...Nunito,
    fontWeight: fontWeight.bold,
    fontSize: 16,
    lineHeight: "24px",
  },
  h1: {
    ...Raleway,
    fontWeight: fontWeight.medium,
    fontSize: 96,
    lineHeight: "120px",
  },
  h2: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 60,
    lineHeight: "79px",
  },
  h3: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 48,
    lineHeight: "64px",
  },
  h4: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 34,
    lineHeight: "44px",
  },
  h5: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 24,
    lineHeight: "32px",
  },
  h6: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 20,
    lineHeight: "28px",
  },
  subtitle1: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 16,
    lineHeight: "24px",
  },
  subtitle2: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 14,
    lineHeight: "20px",
  },
  caption: {
    ...Raleway,
    fontWeight: fontWeight.medium,
    fontSize: 12,
    lineHeight: "16px",
  },
  overline: {
    ...Raleway,
    fontWeight: fontWeight.semiBold,
    fontSize: 10,
    lineHeight: "16px",
    textTransform: "uppercase",
  },
};
