import { Components, Theme } from "@mui/material";
import { lightenDark, lightenPrimary } from "../palette";

export const MuiMenuItem: Components<Theme>["MuiMenuItem"] = {
  styleOverrides: {
    // @ts-ignore
    root: ({ theme }) => ({
      borderRadius: "6px",
      padding: "8px 8px 8px 16px",
      margin: "8px 0",
      fontWeight: 500,
      "&:hover": {
        backgroundColor: lightenDark(0.96),
        ".MuiListItemText-root .MuiTypography-root": {
          fontWeight: 600,
        },
      },
      "&:active, &:focus": {
        backgroundColor: lightenPrimary(0.87),
        ".MuiListItemText-root .MuiTypography-root": {
          fontWeight: 600,
        },
      },
      ".MuiListItemIcon-root": {
        minWidth: "24px",
      },
    }),
    // @ts-ignore
    selected: ({ theme }) => ({
      backgroundColor: lightenPrimary(0.87),
    }),
  },
};
