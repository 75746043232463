import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSearchParams } from "react-router-dom";
import { UpcomingBooking } from "../../../domain";
import { useMyBookingsQuery } from "./useMyBookingsQuery";

export type SelectBookingFn = (booking: UpcomingBooking | null) => void;

type UseSelectedBookingResult = [UpcomingBooking | null, SelectBookingFn];

export function useSelectedBooking(): UseSelectedBookingResult {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: myBookings } = useMyBookingsQuery();
  const selectedBookingId = searchParams.get("booking");

  const selectBooking = useMemoizedFn((booking: UpcomingBooking | null) => {
    const updated = new URLSearchParams(searchParams);
    if (booking?.booking.id) {
      updated.set("booking", booking.booking.id);
    } else {
      updated.delete("booking");
    }
    setSearchParams(updated);
  });

  return [
    myBookings?.find(({ booking }) => booking.id === selectedBookingId) ?? null,
    selectBooking,
  ];
}
