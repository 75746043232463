import { getJson, request } from "../../api";
import { DeskDto } from "../../modules/assets/api";
import { AssetInventoryTypeDto } from "../../modules/booking/api";
import {
  AssetInventory,
  AssetInventoryType,
  DeskInventory,
} from "../../modules/booking/domain";
import { DeskInventoryChange } from "../modules/asset-management/components/Dialogs/UpdateInventoryDialog";

export interface AssetInventoryDto {
  id: string;
  description: string;
  type: AssetInventoryTypeDto;
  quantity: number;
  desks: DeskInventoryDto[];
}

interface DeskInventoryDto {
  id: DeskInventoryIdDto;
  quantity: number;
  desk: DeskDto;
}

interface DeskInventoryIdDto {
  deskId: string;
  assetInventoryId: string;
}

export function mapAssetInventory(response: AssetInventoryDto): AssetInventory {
  return new AssetInventory(
    response.id,
    response.description,
    response.type,
    response.quantity
    // response.desks.map(mapDeskInventory)
  );
}

function mapDeskInventory(response: DeskInventoryDto): DeskInventory {
  return new DeskInventory(
    response.id.deskId,
    response.id.assetInventoryId,
    response.quantity,
    response.desk
  );
}

export async function getInventories(
  organizationId: string,
  locationId?: string | undefined,
  floorId?: string | undefined,
  spaceId?: string | undefined,
  deskId?: string | undefined
): Promise<AssetInventory[]> {
  const response = await getJson<{ content: AssetInventoryDto[] }>(
    `/api/asset-inventories`,
    {
      "filter.organization.id": organizationId,
      ...(locationId && { "filter.location.id": locationId }),
      ...(floorId && { "filter.floor.id": floorId }),
      ...(spaceId && { "filter.space.id": spaceId }),
      ...(deskId && { "filter.desk.id": deskId }),
    }
  );
  return response.content.map(mapAssetInventory);
}

export async function getInventoryTypes(
  organizationId: string
): Promise<AssetInventoryType[]> {
  const response = await getJson<{ content: AssetInventoryTypeDto[] }>(
    `/api/asset-inventory-types`,
    {
      "filter.organization.id": organizationId,
    }
  );
  return response.content.map(
    (type) => new AssetInventoryType(type.id, type.name)
  );
}

export async function createInventoryType(
  organizationId: string,
  name: string
): Promise<AssetInventoryType> {
  const response = await request<AssetInventoryTypeDto>(
    "POST",
    `/api/asset-inventory-types`,
    {
      organizationId,
      name,
    }
  );

  return new AssetInventoryType(response.id, response.name);
}

export async function createInventory(
  organizationId: string,
  assetInventoryTypeId: string,
  description: string
): Promise<AssetInventory> {
  const response = await request<AssetInventoryDto>(
    "POST",
    `/api/asset-inventories`,
    {
      organizationId,
      assetInventoryTypeId,
      description,
    }
  );

  return new AssetInventory(
    response.id,
    response.description,
    new AssetInventoryType(response.type.id, response.type.name),
    response.quantity
    // response.desks.map(mapDeskInventory)
  );
}

export async function createDeskInventory(
  organizationId: string,
  deskId: string,
  deskInventories: DeskInventoryChange[]
): Promise<DeskInventory[]> {
  const response = await request<DeskInventoryDto[]>(
    "POST",
    `/api/desk-inventories`,
    {
      organizationId,
      deskId,
      deskInventories,
    }
  );

  return response.map(mapDeskInventory);
}

export async function deleteInventory(inventoryId: string): Promise<void> {
  await request<void>("DELETE", `/api/asset-inventories/${inventoryId}`);
}
