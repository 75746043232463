import { Box, Divider, Grid, styled, Switch, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useChangeNotificationSettings,
  useNotificationSettings,
} from "../hooks";

interface ItemProps {
  title: string;
  description: string;
  value: boolean;
  disabled?: boolean;
  onChange?: (newSetting: boolean) => void;
}

const Item: React.FC<ItemProps> = ({
  title,
  description,
  value,
  disabled,
  onChange,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ flexGrow: 1 }}>
        {title && <Typography variant="subtitle1">{title}</Typography>}
        {description && (
          <Typography variant="body2" color="shades.grey">
            {description}
          </Typography>
        )}
      </Box>
      <Box sx={{ flexShrink: 0, ml: 2 }}>
        <Switch
          checked={value}
          disabled={disabled}
          onChange={(_, enabled) => onChange?.(enabled)}
        />
      </Box>
    </Box>
  );
};

interface SectionProps {
  title: string;
}

const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({
  title,
  children,
}) => {
  return (
    <Box sx={{ paddingY: 4, paddingX: 4 }}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item md={9} xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

const ItemDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const NotificationSettingsPage: React.FC = () => {
  const { t } = useTranslation("settings");
  const { data: settings, isLoading } = useNotificationSettings();
  const { mutate } = useChangeNotificationSettings();

  return (
    <Box sx={{ mt: 2 }}>
      <Paper sx={{ borderRadius: "8px" }}>
        <Section title={t("notifications.bookings.title")}>
          {settings?.map((it, index) => (
            <React.Fragment key={it.key}>
              <Item
                title={t(`${it.translationKey}.title`)}
                description={t(`${it.translationKey}.description`)}
                value={it.enabled}
                disabled={isLoading}
                onChange={(enabled) => mutate({ key: it.key, enabled })}
              />
              {index !== settings.length - 1 && !settings[index + 1].subitem ? (
                <ItemDivider />
              ) : null}
            </React.Fragment>
          ))}
        </Section>
      </Paper>
    </Box>
  );
};
