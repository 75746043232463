import { Box, styled, Typography } from "@mui/material";
import React from "react";
import { Bell } from "react-feather";
import { useTranslation } from "react-i18next";
import { ContainedDeskOccupancyList } from "../../../../../../components";
import { useDateFns } from "../../../../../../hooks";
import { useCurrentUser } from "../../../../../authentication/hooks";
import { DeskAvailability, DeskOverview } from "../../../../../booking/domain";
import {
  canBook,
  useBookingForm,
} from "../../../../../booking/pages/BookingPage/state";
import { TooltipDivider } from "./TooltipDivider";

export interface DeskTooltipProps {
  desk: DeskOverview;
}

export interface DedicatedDeskInfoProps {
  divider?: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  backgroundColor: theme.palette.errors.light,
  color: theme.palette.errors.regular,
  borderRadius: "8px",
  padding: theme.spacing(0.5, 1),
}));

const ErrorMessage = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.errors.light,
  color: theme.palette.errors.regular,
}));

const DeskEditMessage = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.shades.grey,
  color: "white",
  marginBottom: theme.spacing(0.5),
}));

const BellIcon = (
  <Box
    sx={{
      color: "orange.regular",
      display: "flex",
      alignItems: "center",
      flexBasis: "24px",
      width: "24px",
    }}
  >
    <Bell size={16} />
  </Box>
);

const DedicatedDeskInfo: React.FC<DedicatedDeskInfoProps> = ({ divider }) => {
  const { t } = useTranslation("floor-plan");

  return (
    <Box>
      {divider && <TooltipDivider width="100%" />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginY: 0.5,
        }}
      >
        <Typography variant="body2">{BellIcon}</Typography>
        <Typography variant="body2">{t("tooltip.dedicated-desk")}</Typography>
      </Box>
    </Box>
  );
};

const DeskInviteTooltip: React.FC<DeskTooltipProps> = ({ desk }) => {
  const { t } = useTranslation("floor-plan");
  const currentUser = useCurrentUser();
  const bookedForCurrentUser =
    desk.bookings.filter((it) => it.bookedFor.isUser(currentUser)).length > 0;

  return (
    <>
      <ErrorMessage sx={{ whiteSpace: "pre-line" }}>
        {bookedForCurrentUser && t("tooltip.invite.waiting-for-you")}
        {!bookedForCurrentUser && (
          <Typography variant="body2" component="span">
            {t("tooltip.invite.waiting-for")}{" "}
            <strong>{desk.bookings[0].bookedFor.name.full}</strong>{" "}
            {t("tooltip.invite.to-approve")}
          </Typography>
        )}
      </ErrorMessage>
      {desk.isDedicated && <DedicatedDeskInfo />}
    </>
  );
};

const DeskAvailableTooltip: React.FC<DeskTooltipProps> = ({ desk }) => {
  const form = useBookingForm();
  const { formatRange } = useDateFns();
  const { t } = useTranslation("floor-plan");

  const isEditMode = form.isEditing;

  return (
    <>
      {isEditMode && (
        <DeskEditMessage>{t("tooltip.edit-choose-desk")}</DeskEditMessage>
      )}
      {t(
        desk.date.isSingleDay
          ? "tooltip.single-day.available-desk"
          : "tooltip.date-range.available-desk"
      )}
      :
      <br />
      {formatRange(desk.date)}
      {desk.isDedicated && <DedicatedDeskInfo divider />}
    </>
  );
};

const DeskBookedSingleDay: React.FC<DeskTooltipProps> = ({ desk }) => {
  const currentUser = useCurrentUser();
  const { formatRange } = useDateFns();
  const { t } = useTranslation("floor-plan");
  const form = useBookingForm();

  const bookedForCurrentUser =
    desk.bookings.filter((it) => it.bookedFor.isUser(currentUser)).length > 0;

  const editable = canBook(desk, form);
  const message = editable ? (
    <DeskEditMessage>Izmeni ime.</DeskEditMessage>
  ) : null;

  if (bookedForCurrentUser) {
    return (
      <>
        {message}
        {t("tooltip.single-day.booked-for-self")}
        {desk.isDedicated && <DedicatedDeskInfo divider />}
      </>
    );
  }
  return (
    <>
      {message}
      {t("tooltip.single-day.booked-for-other", {
        name: desk.bookings[0].bookedFor.name.full,
      })}
      :
      <br />
      {formatRange(desk.date)}
      {desk.isDedicated ? (
        <DedicatedDeskInfo divider />
      ) : (
        <TooltipDivider width="70%" />
      )}
      {t("tooltip.single-day.booked-by")}: {desk.bookings[0].bookedBy.name.full}
    </>
  );
};

export const DeskBooked: React.FC<DeskTooltipProps> = ({ desk }) => {
  const { formatRange } = useDateFns();
  const { t } = useTranslation("floor-plan");
  if (desk.availability === DeskAvailability.NOT_AVAILABLE) {
    return (
      <>
        <ErrorMessage>
          {t("tooltip.date-range.not-available")}:<br />
          {formatRange(desk.date)}
        </ErrorMessage>
        {desk.isDedicated && <DedicatedDeskInfo />}
      </>
    );
  }
  return (
    <>
      <ErrorMessage sx={{ marginBottom: 0.5 }}>
        <Typography variant="inherit" color="inherit">
          {t("tooltip.date-range.partially-available")}:<br />
          {formatRange(desk.date)}
        </Typography>
        <Typography variant="inherit" color="inherit">
          {t("tooltip.date-range.notice")}
        </Typography>
      </ErrorMessage>
      {desk.isDedicated && <DedicatedDeskInfo />}
      <ContainedDeskOccupancyList
        bookings={desk.bookings}
        date={desk.date}
        TypographyProps={{ variant: "inherit" }}
        dedicated={desk.availability === DeskAvailability.PARTIALLY_DEDICATED}
      />
    </>
  );
};

export const DeskTooltip: React.FC<DeskTooltipProps> = ({ desk }) => {
  if (desk.isDisabled) return <DeskBooked desk={desk} />;
  if (desk.availability === DeskAvailability.PENDING)
    return <DeskInviteTooltip desk={desk} />;
  if (desk.availability === DeskAvailability.AVAILABLE) {
    return <DeskAvailableTooltip desk={desk} />;
  }
  if (desk.date.isSingleDay && !desk.isDisabled) {
    return <DeskBookedSingleDay desk={desk} />;
  }
  return <DeskBooked desk={desk} />;
};
