import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../modules/authentication/hooks";
import { AssetInventoryType } from "../../modules/booking/domain";
import { getInventoryTypes } from "../api";

export function useInventoryTypesQuery(): UseQueryResult<AssetInventoryType[]> {
  const { organization } = useOrganizationMembership();

  return useQuery(
    ["inventory-types", organization.id],
    () => getInventoryTypes(organization.id),
    {
      refetchOnMount: "always",
    }
  );
}
