import { Desk, Floor, Location, Space } from "../../../assets/domain";
import { User } from "../../../authentication/domain";
import { BookingStatus } from "../../api";
import { BookedLocation } from "./BookingAsset";

export class Booking {
  constructor(
    public readonly id: string,
    public readonly bookingDate: Date,
    public readonly bookedFor: User,
    public readonly bookedBy: User,
    public readonly bookedAt: Date,
    public readonly location: Location,
    public readonly status: BookingStatus
  ) {}

  get bookedForSelf(): boolean {
    return this.bookedFor.equals(this.bookedBy);
  }
}

export class DeskBooking extends Booking {
  constructor(
    id: string,
    bookingDate: Date,
    bookedFor: User,
    bookedBy: User,
    bookedAt: Date,
    location: Location,
    status: BookingStatus,
    public readonly floor: Floor,
    public readonly space: Space,
    public readonly desk: Desk
  ) {
    super(id, bookingDate, bookedFor, bookedBy, bookedAt, location, status);
  }
}

export class BookingAssetPair {
  constructor(public booking: Booking, public asset: BookedLocation) {}
}
