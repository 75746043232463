import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useSearchParams } from "react-router-dom";
import { Floor, Location } from "../../assets/domain";
import { useFloors, useLocations } from "../../booking/pages/BookingPage/state";
import { SpaceWithImages } from "../domain/SpaceImages";
import { useSpaceImages } from "../hooks";
import { useGalleryForm } from "./useGalleryForm";

export interface UseSelectionResult {
  selectedSpaceWithImages: SpaceWithImages | null;
  selectedFloor: Floor | null;
  selectedLocation: Location | null;
  onSelectSpaceWithImages(spaceWithImages: SpaceWithImages | null): void;
}

export function useGallerySelection(): UseSelectionResult {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: spacesWithImages } = useSpaceImages();
  const { locations } = useLocations();
  const { floors } = useFloors();

  const galleryForm = useGalleryForm();

  const spaceImagesId = searchParams.get("room");
  const locationId = searchParams.get("location");
  const floorId = searchParams.get("floor");

  const selectedSpaceWithImages =
    spacesWithImages?.find((spaceImages) => spaceImages.id === spaceImagesId) ??
    null;

  const selectedFloor = floors?.find((floor) => floor.id === floorId) ?? null;

  const selectedLocation =
    locations?.find((location) => location.id === locationId) ?? null;

  const onSelectSpaceWithImages = useMemoizedFn(
    (spaceWithImages: SpaceWithImages | null) => {
      const updated = new URLSearchParams(searchParams);
      updated.delete("desk");
      updated.delete("conference");
      if (spaceWithImages === null) {
        updated.delete("room");
      } else {
        updated.set("location", spaceWithImages.locationId);
        updated.set("floor", spaceWithImages.floorId);
        updated.set("room", spaceWithImages.id);

        galleryForm.openDialog();
      }
      setSearchParams(updated);
    }
  );

  return {
    selectedSpaceWithImages,
    selectedFloor,
    selectedLocation,
    onSelectSpaceWithImages,
  };
}
