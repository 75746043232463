import { Box, Button } from "@mui/material";
import React from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  useBackofficeInventoryTypes,
  useBackofficeSelection,
} from "../../../../state";
import { useInventoryForm, useInventoryTypeForm } from "../../state";
import { InventoryPicker } from "./InventoryPicker";

export const Header: React.FC = () => {
  const { inventoryType, inventoryTypes } = useBackofficeInventoryTypes();
  const { onSelectInventoryType } = useBackofficeSelection();
  const { t } = useTranslation("inventory-management");
  const inventoryTypeForm = useInventoryTypeForm();
  const inventoryForm = useInventoryForm();

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
        },
      })}
    >
      <InventoryPicker
        inventoryType={inventoryType ? inventoryType : null}
        inventoryTypes={inventoryTypes}
        onChange={onSelectInventoryType}
      />
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={inventoryForm.openDialog}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              mb: 1,
              width: "100%",
            },
          })}
        >
          <Plus size={16} />
          {t("create-inventory")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={inventoryTypeForm.openDialog}
          sx={(theme) => ({
            ml: 2,
            [theme.breakpoints.down("md")]: {
              ml: 0,
              width: "100%",
            },
          })}
        >
          <Plus size={16} />
          {t("create-inventory-type")}
        </Button>
      </Box>
    </Box>
  );
};
