import { TabContext, TabList } from "@mui/lab";
import { Box, styled, Tab } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatch } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { isAccountSettingsVisible } from "../../../config";
import {
  useGoogleCalendarPermissions,
  useSageHRPermissions,
} from "../../permissions/hooks";

const SettingsContainer = styled(Box)(({ theme }) => ({
  maxWidth: "600px",
  padding: theme.spacing(0, 2),
  margin: theme.spacing(4, "auto", 0, "auto"),
  [theme.breakpoints.down("md")]: {
    minWidth: "100%",
  },
}));

export const SettingsPage: React.FC = () => {
  const { t } = useTranslation("settings");
  const sageHrPermissions = useSageHRPermissions();
  const googleCalendarPermissions = useGoogleCalendarPermissions();
  const match = useMatch<"page", string>("/settings/:page")!;

  return (
    <SettingsContainer>
      <TabContext value={match?.params.page ?? "account"}>
        <TabList
          onChange={() => {
            // ignore onChange
            // tabs will navigate the browser onto the appropriate page
          }}
        >
          {isAccountSettingsVisible() && (
            <Tab
              label={t("tabs.account")}
              component={RouterLink}
              value="account"
              to="/settings/account"
            />
          )}
          <Tab
            label={t("tabs.notifications")}
            value="notifications"
            component={RouterLink}
            to="/settings/notifications"
          />
          {sageHrPermissions.canConfigure() && (
            <Tab
              label={t("tabs.sagehr")}
              value="sage-hr"
              component={RouterLink}
              to="/settings/sage-hr"
            />
          )}
          {googleCalendarPermissions.canConfigure() && (
            <Tab
              label={t("tabs.google-calendar")}
              value="google-calendar"
              component={RouterLink}
              to="/settings/google-calendar"
            />
          )}
        </TabList>
        <Outlet />
      </TabContext>
    </SettingsContainer>
  );
};
