import { Box, styled } from "@mui/material";

export const FieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  background: theme.palette.shades.greyUltimateLight,
  borderRadius: "10px",
  padding: theme.spacing(1, 2, 2, 2),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1),
  },
}));
