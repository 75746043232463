import { Box, DialogContent, Typography } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { AmtDialog } from "../../../../../components";
import { ConfirmDialogActions } from "./ConfirmDialogActions";

export interface ConfirmDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  closeDialog,
}) => {
  const { t } = useTranslation("booking");

  return (
    <AmtDialog open={isOpen} onClose={closeDialog} closeButton>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">
            {t("form.conference-booking.book")}
          </Typography>
          <Trans>
            <Typography
              variant="body2"
              color="#6d6b6b"
              sx={{ textAlign: "center", marginTop: 1 }}
            >
              {t("form.conference-booking.info")}
            </Typography>
          </Trans>
          <ConfirmDialogActions onCancel={closeDialog} />
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
