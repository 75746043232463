import { DialogContent } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtError } from "../../../../../../api";
import { AmtAlert, AmtDialog } from "../../../../../../components";
import { trackCreateInventoryType } from "../../../../../../tracking";
import {
  DialogActions,
  DialogInputSection,
  SimpleDialogTitle,
} from "../../../../../components/Dialogs";
import { FormikTextInput } from "../../../../../components/Formik/TextInput";
import { useInventoryTypesQuery } from "../../../../../hooks/useInventoryTypesQuery";
import { useCreateInventoryMutation } from "../../../hooks";
import { useInventoryForm } from "../../../state";
import { FormikInventoryTypePicker } from "./InventoryTypePicker";

export interface CreateInventoryFormValues {
  typeId: string;
  description: string;
}

export const CreateInventoryDialog: React.FC = () => {
  const form = useInventoryForm();
  const { isOpen, closeDialog } = form;
  const createInventory = useCreateInventoryMutation();
  const { t } = useTranslation("inventory-management");
  const { data: inventoryTypes } = useInventoryTypesQuery();

  const doSubmit = useMemoizedFn((values: CreateInventoryFormValues) => {
    return createInventory.mutateAsync(values);
  });

  const handleSubmit = useMemoizedFn(
    (
      values: CreateInventoryFormValues,
      formik: FormikHelpers<CreateInventoryFormValues>
    ) => {
      trackCreateInventoryType();
      return doSubmit(values).catch((err: AmtError) => {
        formik.setFieldError("bookFor", t("dialog.feedback.create-type-error"));
      });
    }
  );

  const validate = useMemoizedFn(
    async (
      values: CreateInventoryFormValues
    ): Promise<FormikErrors<CreateInventoryFormValues>> => {
      const errors: FormikErrors<CreateInventoryFormValues> = {};

      if (!values.description) {
        errors.description = "";
      }
      if (!values.typeId) {
        errors.typeId = "";
      }
      return errors;
    }
  );

  return (
    <AmtDialog open={isOpen} onClose={closeDialog} closeButton>
      <Formik
        enableReinitialize
        initialValues={{
          typeId:
            inventoryTypes && inventoryTypes.length ? inventoryTypes[0].id : "",
          description: "",
        }}
        validate={validate}
        validateOnMount
        // @ts-ignore
        initialTouched={{ status: true, dedicatedValues: true }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => {
          return (
            <DialogContent>
              <SimpleDialogTitle title={t("dialog.create-inventory-title")} />
              <Form style={{ margin: 0 }}>
                {inventoryTypes && inventoryTypes.length ? (
                  <DialogInputSection
                    label={t("dialog.inventory-type-picker-placeholder")}
                  >
                    <Field
                      name="typeId"
                      component={FormikInventoryTypePicker}
                      inventoryTypes={inventoryTypes}
                      placeholder={t(
                        "dialog.inventory-type-picker-placeholder"
                      )}
                    />
                  </DialogInputSection>
                ) : (
                  <AmtAlert
                    title={t("dialog.feedback.no-inventory-types-alert-title")}
                    text={t("dialog.feedback.no-inventory-types-alert-text")}
                  />
                )}
                <DialogInputSection
                  label={t("dialog.inventory-description")}
                  sx={{ marginTop: 2 }}
                >
                  <Field
                    name="description"
                    component={FormikTextInput}
                    inputType="text"
                    placeholder={t("dialog.inventory-type-name-placeholder")}
                  />
                </DialogInputSection>
                <DialogActions
                  onCancel={closeDialog}
                  isSubmitting={isSubmitting}
                  isInvalid={values.typeId === "" || values.description === ""}
                />
              </Form>
            </DialogContent>
          );
        }}
      </Formik>
    </AmtDialog>
  );
};
