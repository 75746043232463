import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { trackUnbook } from "../../../tracking";
import { cancelBooking, declineInvitation } from "../api";
import { BookingStatus } from "../api/dto";
import { Booking, DeskBooking } from "../domain";

export function useBookingCancelMutation(): UseMutationResult<
  Booking,
  Error,
  Booking
> {
  const { t } = useTranslation("booking-dialog");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  return useMutation(
    "booking-cancellation",
    async (booking: Booking) => {
      if (booking.status === BookingStatus.PENDING)
        await declineInvitation(booking.id);
      else await cancelBooking(booking.id);
      return booking;
    },
    {
      onSuccess: (booking) => {
        trackUnbook(booking);
        const translation =
          booking instanceof DeskBooking
            ? "cancellation.feedback.success-desk"
            : "cancellation.feedback.success-location";
        snackbar.enqueueSnackbar(t(translation), {
          variant: "success",
        });
      },
      onError: () => {
        snackbar.enqueueSnackbar(t("cancellation.feedback.error"), {
          variant: "error",
        });
      },
      onSettled: () => {
        qc.invalidateQueries(["bookings"]);
        qc.invalidateQueries(["upcoming-bookings"]);
        qc.invalidateQueries(["booking-groups"]);
        qc.invalidateQueries(["notifications"]);
      },
    }
  );
}
