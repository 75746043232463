import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDateFns } from "../../../../hooks";
import {
  BookingNotificationContext,
  DeletedBookingNotificationContext,
  isDeskBookingNotification,
} from "../../domain";

interface DetailProps {
  label: string;
}

const Detail: React.FC<React.PropsWithChildren<DetailProps>> = ({
  label,
  children,
}) => (
  <Box sx={{ display: "flex", marginTop: "1px", marginBottom: "1px" }}>
    <Typography
      variant="body2"
      color="shades.grey"
      sx={{ width: "95px", flexBasis: "95px", flexGrow: 0, flexShrink: 0 }}
    >
      {label}:
    </Typography>
    <Typography variant="body2" color="shades.dark" sx={{ flexGrow: 1 }}>
      {children}
    </Typography>
  </Box>
);

export interface DeskBookingDetailsProps {
  context: BookingNotificationContext;
}

export const BookingDetails: React.FC<DeskBookingDetailsProps> = ({
  context,
}) => {
  const { t } = useTranslation("booking-details");
  const { format } = useDateFns();
  return (
    <Box>
      <Detail label={t("label.date")}>
        <strong>{format(context.bookingDate, "eeee, dd.MM.yyyy.")}</strong>
      </Detail>
      <Detail label={t("label.location")}>
        <strong>{context.location.name}</strong>,<br />
        {context.location.address.addressLine1}, {context.location.address.city}
      </Detail>
      {isDeskBookingNotification(context) && (
        <>
          <Detail label={t("label.floor")}>{context.floor.description}</Detail>
          <Detail label={t("label.space")}>{context.space.description}</Detail>
          <Detail label={t("label.desk")}>
            <strong>{context.desk.identifier}</strong>
          </Detail>
        </>
      )}
      <Detail label={t("label.booked-by")}>{context.bookedBy.name.full}</Detail>
      {context instanceof DeletedBookingNotificationContext && (
        <Detail label={t("label.unbooked-by")}>
          {context.deletedBy.name.full}
        </Detail>
      )}
    </Box>
  );
};
