import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../../api";
import { OrganizationMembership } from "../../../../modules/authentication/domain";
import { updateMembership } from "../../../api";
import { AddAdminFormValues } from "../components/AddAdminDialog";
import { useAddAdminForm } from "../state";

export function useAddAdminMutation(): UseMutationResult<
  OrganizationMembership,
  AmtError,
  AddAdminFormValues
> {
  const { t } = useTranslation("admin-management");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const form = useAddAdminForm();

  return useMutation(
    ["add-admin"],
    async (formValues: AddAdminFormValues) => {
      if (!formValues.newAdminUser)
        return Promise.reject("User value must be present in state");
      return updateMembership(formValues.newAdminUser.id, "ADMINISTRATOR");
    },
    {
      onSuccess: (response: OrganizationMembership) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.add-admin-success"), {
          variant: "success",
        });
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.add-admin-error"), {
          variant: "error",
        });
        form.closeDialog();
      },
      onSettled: () => {
        qc.invalidateQueries(["admin-users"]);
        qc.invalidateQueries(["admin-groups"]);
      },
    }
  );
}
