import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../../api";
import { useOrganizationMembership } from "../../../../modules/authentication/hooks";
import { AssetInventory } from "../../../../modules/booking/domain";
import { createInventory } from "../../../api";
import { CreateInventoryFormValues } from "../components/Dialogs/CreateInventoryDialog/CreateInventoryDialog";
import { useInventoryForm } from "../state";

export function useCreateInventoryMutation(): UseMutationResult<
  AssetInventory,
  AmtError,
  CreateInventoryFormValues
> {
  const { t } = useTranslation("inventory-management");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const form = useInventoryForm();
  const { organization } = useOrganizationMembership();

  return useMutation(
    ["create-inventory"],
    async (formValues: CreateInventoryFormValues) => {
      return createInventory(
        organization.id,
        formValues.typeId,
        formValues.description
      );
    },
    {
      onSuccess: (response: AssetInventory) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.create-type-success"), {
          variant: "success",
        });
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.create-type-error"), {
          variant: "error",
        });
        form.closeDialog();
      },
      onSettled: () => {
        qc.invalidateQueries(["inventories"]);
      },
    }
  );
}
