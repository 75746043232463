import { getJson } from "../../api";
import { mapSpace, SpaceDto } from "../../modules/assets/api";
import { DeskWithSpace, DeskWithStatus } from "../domain";
import { DeskStatus } from "../modules/asset-management/domain";

export interface DeskWithStatusDto {
  desk: DeskWithSpaceDto;
  deskStatus: string;
  assetRuleId: string;
}

export interface DeskWithSpaceDto {
  id: string;
  locationId: string;
  floorId: string;
  space: SpaceDto;
  identifier: string;
}

export function mapDeskWithStatus(
  deskWithStatus: DeskWithStatusDto
): DeskWithStatus {
  return new DeskWithStatus(
    mapDeskWithSpace(deskWithStatus.desk),
    mapDeskStatus(deskWithStatus.deskStatus),
    deskWithStatus.assetRuleId
  );
}

export function mapDeskStatus(deskStatus: string): DeskStatus {
  switch (deskStatus) {
    case "DEDICATED":
      return DeskStatus.DEDICATED;
    case "DISABLED":
      return DeskStatus.DISABLED;
    case "FREE":
    default:
      return DeskStatus.FREE;
  }
}

export function mapDeskWithSpace(desk: DeskWithSpaceDto): DeskWithSpace {
  return new DeskWithSpace(
    desk.id,
    desk.locationId,
    desk.floorId,
    desk.identifier,
    mapSpace(desk.space)
  );
}

export async function getDesksWithStatus(
  organizationId: string,
  filters?: {
    locationId?: string;
    floorId?: string;
    spaceId?: string;
  }
): Promise<DeskWithStatus[]> {
  const response = await getJson<DeskWithStatusDto[]>(
    "/api/manage-assets/desks",
    {
      "filter.organization.id": organizationId,
      ...(filters?.locationId && { "filter.location.id": filters.locationId }),
      ...(filters?.floorId && { "filter.floor.id": filters.floorId }),
      ...(filters?.spaceId && { "filter.space.id": filters.spaceId }),
    }
  );
  return response.map(mapDeskWithStatus);
}
