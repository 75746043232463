import {
  AutocompleteValue,
  FilterOptionsState,
} from "@mui/base/AutocompleteUnstyled/useAutocomplete";
import {
  Autocomplete,
  Box,
  MenuList,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import useCreation from "ahooks/es/useCreation";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  MemberChip,
  MemberMenuItemContent,
} from "../../../../../../global/components";
import {
  StyledListSubheader,
  StyledPopper,
  StyledTeamName,
} from "../../../../../../global/styles";
import { stripDiacritics } from "../../../../../../modules/assets/helperFunctions";
import { createFuse } from "../../../../asset-management/components/Dialogs/DeskManagementDialog/components/DialogInputSection.tsx";
import { GroupMember } from "../../../../asset-management/domain";
import { useAdminOptions } from "../../../hooks";

export type AdminAutocompleteProps = Omit<
  TextFieldProps,
  "value" | "onChange"
> & {
  value: GroupMember | undefined;
  onChange?: (member: GroupMember | undefined) => void;
};

export const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexBasis: "24px",
  width: "24px",
}));

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & { capacityReached?: boolean }
>(function (props, ref) {
  const { children, capacityReached, ...other } = props;

  return (
    <MenuList
      component="div"
      ref={ref}
      {...other}
      sx={{
        position: "relative",
        padding: 1,
      }}
    >
      {children}
    </MenuList>
  );
});

function isOptionEqualToValue(option: GroupMember, value: GroupMember) {
  return option.id === value.id;
}

function groupByGroupName(option: GroupMember) {
  return option.groupName;
}

export const AdminAutocomplete: React.FC<AdminAutocompleteProps> = ({
  value,
  onChange,
  ...props
}) => {
  const { t } = useTranslation("admin-management");

  const options: GroupMember[] = useAdminOptions(value);

  const fuse = useCreation(() => createFuse(options), [options]);

  const filterOptions = useMemoizedFn(
    (
      searchOptions: GroupMember[],
      state: FilterOptionsState<GroupMember>
    ): GroupMember[] => {
      if (state.inputValue.length < 2) {
        return searchOptions;
      }
      const result = fuse.search(stripDiacritics(state.inputValue));
      return result.map((it) => it.item);
    }
  );

  const handleChange = useMemoizedFn(
    (
      event: React.SyntheticEvent,
      value: AutocompleteValue<GroupMember, undefined, true, undefined>
    ) => {
      event.stopPropagation();
      onChange?.(value);
    }
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box
        sx={(theme) => ({
          display: "flex",
          width: "100%",
          [theme.breakpoints.down("md")]: {
            marginBottom: 1,
          },
        })}
      >
        <Autocomplete
          fullWidth
          options={options}
          value={value}
          onChange={handleChange}
          groupBy={groupByGroupName}
          isOptionEqualToValue={isOptionEqualToValue}
          disableClearable
          autoHighlight
          filterOptions={filterOptions}
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          getOptionLabel={(option) => option.user.name.full}
          noOptionsText={t("dialog.form.no-options")}
          renderInput={(params) => <TextField {...params} {...props} />}
          renderTags={(value, getTagProps) =>
            value.map((member, index) => (
              <MemberChip
                label={member.user.name.full}
                {...getTagProps({ index })}
              />
            ))
          }
          renderGroup={({ group, ...props }) => (
            <GroupHeader label={group} {...props} />
          )}
          renderOption={(props, option) => (
            <MemberMenuItemContent
              elementProps={props}
              avatarImageUrl={option.user.avatarImageUrlSrc}
              name={option.user.name.full}
            />
          )}
          data-testid="organization-members-autocomplete"
        />
      </Box>
    </Box>
  );
};

interface GroupHeaderProps {
  label: string;
  children?: React.ReactNode;
}

const GroupHeader: React.FC<GroupHeaderProps> = ({ label, children }) => {
  const { t } = useTranslation("admin-management");

  return (
    <div>
      <StyledListSubheader disableSticky>
        <Typography variant="body3">{t("dialog.form.team")}</Typography>
        <StyledTeamName>{label}</StyledTeamName>
      </StyledListSubheader>
      <ul style={{ margin: 0, padding: 0 }}>{children}</ul>
    </div>
  );
};
