import { Box, styled, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

export interface SimpleDialogTitleProps {
  title: string;
  sx?: SxProps<Theme>;
}

const StyledSimpleDialogTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 2),
  borderRadius: "8px",
  marginBottom: theme.spacing(2),
  color: theme.palette.shades.dark,
  backgroundColor: theme.palette.green.light,
}));

export const SimpleDialogTitle: React.FC<
  React.PropsWithChildren<SimpleDialogTitleProps>
> = ({ title, sx }) => {
  return (
    <StyledSimpleDialogTitle sx={sx}>
      <Typography variant="h6">{title}</Typography>
    </StyledSimpleDialogTitle>
  );
};
