import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useRef } from "react";
import { Edit2, MoreHorizontal, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { usePermissions } from "../../permissions/hooks";
import { Booking } from "../domain";

export interface ItemActionsProps {
  booking: Booking;
  onConfirm?: (overview: Booking) => void;
  onCancel?: (overview: Booking) => void;
  children?: React.ReactNode | undefined;
  isInvite?: boolean;
  isMyInvitation?: boolean;
}

export const ItemActions: React.FC<ItemActionsProps> = ({
  booking,
  onConfirm,
  onCancel,
  children,
  isInvite,
  isMyInvitation,
}) => {
  const { t } = useTranslation("booking");
  const { bookings } = usePermissions();
  const popupState = usePopupState({
    variant: "popover",
    popupId: `booking-menu`,
  });
  const ref = useRef<HTMLButtonElement>(null);

  const handleOpenMenu = useMemoizedFn((e: React.MouseEvent) => {
    e.stopPropagation();
    popupState.open(ref.current);
  });

  const handleBackdropClick = useMemoizedFn((e: React.MouseEvent) => {
    e.stopPropagation();
    popupState.close();
  });

  const canEditBooking = bookings.canEditBooking(booking);
  const canCancelBooking = bookings.canCancelBooking(booking);

  if (!canEditBooking && !canCancelBooking) {
    return null;
  }

  return (
    <>
      <Button
        ref={ref}
        variant="icon"
        color="secondary"
        {...bindTrigger(popupState)}
        onClick={handleOpenMenu}
        data-testid="booking--actions"
      >
        <MoreHorizontal size={16} />
      </Button>
      <Menu
        {...bindMenu(popupState)}
        MenuListProps={{ sx: { minWidth: "210px" } }}
        componentsProps={{
          backdrop: {
            onClick: handleBackdropClick,
          },
        }}
      >
        {children}
        {((isInvite && isMyInvitation) || !isInvite) && [
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onConfirm?.(booking);
              popupState.close();
            }}
            disabled={!canEditBooking}
            data-testid="booking--actions--edit"
          >
            <ListItemIcon>
              <Edit2 size={16} />
            </ListItemIcon>
            <ListItemText
              primary={
                isInvite ? t("item.action.approve") : t("item.action.edit")
              }
            />
          </MenuItem>,
        ]}

        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            onCancel?.(booking);
            popupState.close();
          }}
          disabled={!canCancelBooking}
          data-testid="booking--actions--cancel"
        >
          <ListItemIcon>
            <X size={16} />
          </ListItemIcon>
          <ListItemText
            primary={
              isInvite ? t("item.action.decline") : t("item.action.cancel")
            }
          />
        </MenuItem>
      </Menu>
    </>
  );
};
