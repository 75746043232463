import { getJson } from "../../../../api";
import {
  DeskDto,
  FloorDto,
  LocationDto,
  mapDesk,
  mapFloor,
  mapLocation,
  mapSpace,
  SpaceDto,
} from "../../../../modules/assets/api";
import {
  Desk,
  Floor,
  Location,
  Space,
} from "../../../../modules/assets/domain";
import { mapUser, UserDto } from "../../../../modules/authentication/api";
import { User } from "../../../../modules/authentication/domain";
import { formatISODate } from "../../../../modules/booking/api";

export class Reservation {
  constructor(
    public readonly id: string,
    public readonly bookingDate: Date,
    public readonly bookedFor: User,
    public readonly bookedBy: User,
    public readonly location: Location,
    public readonly floor: Floor | null,
    public readonly space: Space | null,
    public readonly desk: Desk | null
  ) {}
}

export interface ReservationDto {
  id: string;
  bookingDate: Date;
  bookedFor: UserDto;
  bookedBy: UserDto;
  location: LocationDto;
  floor: FloorDto;
  space: SpaceDto;
  desk: DeskDto;
}

export interface ReservationsResponse {
  content: ReservationDto[];
}

export const mapReservation = (dto: ReservationDto) => {
  return new Reservation(
    dto.id,
    dto.bookingDate,
    mapUser(dto.bookedFor),
    mapUser(dto.bookedBy),
    mapLocation(dto.location),
    dto.floor ? mapFloor(dto.floor) : null,
    dto.space ? mapSpace(dto.space) : null,
    dto.desk ? mapDesk(dto.desk) : null
  );
};

export async function getFilteredReservations(
  organizationId: string,
  locationId: string | null,
  startDate: Date,
  endDate: Date
): Promise<Reservation[]> {
  const response = await getJson<ReservationsResponse>(
    "/api/filter-bookings/v2",
    {
      "organization-id": organizationId,
      "location-id": locationId,
      "start-date": formatISODate(startDate),
      "end-date": formatISODate(endDate),
      size: 500,
    }
  );
  return response.content.map((reservation) => mapReservation(reservation));
}
