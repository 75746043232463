import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Bell } from "react-feather";
import { useTranslation } from "react-i18next";

const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  flexBasis: "36px",
  width: "36px",
}));

export const DedicatedWarning: React.FC = () => {
  const { t } = useTranslation("asset-management");

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        backgroundColor: "orange.light",
        borderRadius: "8px",
        paddingX: 1,
        paddingY: 0.5,
        width: "100%",
        marginTop: 1,
      })}
    >
      <Icon>
        <Bell size={16} />
      </Icon>
      <Typography variant="body2" color="shades.dark">
        {t("dialog.dedicated-warning")}
      </Typography>
    </Box>
  );
};
