import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

export const Caption: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      variant="caption"
      sx={{
        display: "block",
        color: "shades.grey",
      }}
    >
      {children}
    </Typography>
  );
};
