import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { useEffect } from "react";
import { EventEmitter, SubscriberFn } from "../domain";

export function useSubscription<Event>(
  eventEmitter: EventEmitter<Event>,
  fn: SubscriberFn<Event>
): void {
  const listener = useMemoizedFn(fn);
  useEffect(
    () => {
      return eventEmitter.subscribe(listener);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
