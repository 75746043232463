import mixpanel from "mixpanel-browser";

export function trackSearchLocationUsage(isSingleLocation: boolean) {
  try {
    mixpanel.track("Search location usage", { isSingleLocation });
  } catch (e) {}
}

export function trackSearchCSVReports(isSingleDay: boolean) {
  try {
    mixpanel.track("Search CSV report", { isSingleDay });
  } catch (e) {}
}

export function trackSearchDatePicker(isSingleDay: boolean) {
  try {
    mixpanel.track("Search date picker", { isSingleDay });
  } catch (e) {}
}

export function trackOptionSelect() {
  try {
    mixpanel.track("Search option selected");
  } catch (e) {}
}

export function trackFollowPeople() {
  try {
    mixpanel.track("Follow people");
  } catch (e) {}
}
