import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { addBusinessDays, formatISO } from "date-fns";
import { atom, useAtom } from "jotai";
import { useLocation, useSearchParams } from "react-router-dom";
import { SearchBookingParam, SearchBookingParams } from "../api";
import { useSearchParamsQuery } from "../hooks/useSearchParamsQuery";
import { filterUsersTeamsAndLocationsBySearch } from "../MainLayout/SearchButton/search";

export enum SearchTab {
  FOLLOWING = "following",
  USER = "users",
  TEAM = "teams",
  LOCATION = "locations",
}

interface SearchTabProps {
  activeTab: SearchTab;
  getFilteredOptions: (inputValue: string) => {
    [x: number]: SearchBookingParam[];
  };
  onChangeTab: (newPage: SearchTab) => void;
  updateSearchParams: (
    option: SearchBookingParam
  ) => Record<string, string | number | null | string[]>;
}

const activeTabAtom = atom<SearchTab>(SearchTab.USER);

export function useSearchState(): SearchTabProps {
  const { data: searchBookingParams } = useSearchParamsQuery();
  const [activeTab, setActiveTab] = useAtom(activeTabAtom);
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const searchTabValues = Object.values(SearchTab);

  const onChangeTab = useMemoizedFn((newTab: SearchTab) => {
    setActiveTab(newTab);
  });

  const createResults = (
    params?: SearchBookingParams,
    filter?: (array: SearchBookingParam[]) => SearchBookingParam[]
  ) => {
    return Object.fromEntries(
      searchTabValues.map((tab) => [
        tab,
        filter && params
          ? filter(params?.[tab])
          : !filter
          ? params?.[tab] ?? []
          : [],
      ])
    );
  };

  const getFilteredOptions = (
    inputValue: string
  ): {
    [x: number]: SearchBookingParam[];
  } => {
    if (!searchBookingParams || !inputValue) {
      return createResults(searchBookingParams);
    }

    const filterArray = (array: SearchBookingParam[]) =>
      filterUsersTeamsAndLocationsBySearch(array, inputValue);

    const filteredResults = createResults(searchBookingParams, filterArray);

    if (searchTabValues.every((tab) => !filteredResults[tab].length)) {
      return createResults();
    }

    if (!filteredResults[activeTab].length) {
      for (const tab of searchTabValues) {
        if (tab !== activeTab && filteredResults[tab].length) {
          setActiveTab(tab);
          break;
        }
      }
    }

    return filteredResults;
  };

  const updateSearchParams = (option: SearchBookingParam) => {
    const searchType =
      activeTab === SearchTab.LOCATION
        ? "location"
        : activeTab === SearchTab.TEAM
        ? "team"
        : "user";
    const searchIdKey = `${searchType}Ids`;

    const currentIds = searchParams.getAll(searchIdKey);
    if (!currentIds.includes(option.id)) {
      searchParams.append(searchIdKey, option.id);
    }

    const redirectParams = new URLSearchParams(searchParams);
    ["userIds", "teamIds", "locationIds"].forEach((idKey) =>
      redirectParams.delete(idKey)
    );

    const updatedParamsObj = {
      userIds: searchParams.getAll("userIds"),
      teamIds: searchParams.getAll("teamIds"),
      locationIds: searchParams.getAll("locationIds"),
    };

    return {
      "search-tab-applied": activeTab,
      "redirect-from":
        location.pathname === "/search-people" &&
        searchParams.get("redirect-from") !== null
          ? searchParams.get("redirect-from")
          : `${location.pathname}?${redirectParams}`,
      date: formatISO(new Date(), { representation: "date" }),
      "date-end": formatISO(addBusinessDays(new Date(), 9), {
        representation: "date",
      }),
      ...updatedParamsObj,
    };
  };

  return {
    activeTab,
    getFilteredOptions,
    onChangeTab,
    updateSearchParams,
  };
}
