import { useQuery, UseQueryResult } from "react-query";
import { getDesksWithStatus } from "../api";
import { DeskWithStatus } from "../domain";

export function useDesksWithStatusQuery(
  organizationId: string,
  filters?: { locationId?: string; floorId?: string }
): UseQueryResult<DeskWithStatus[]> {
  return useQuery(
    [
      "desks-with-status",
      organizationId,
      filters?.locationId,
      filters?.floorId,
    ],
    () => getDesksWithStatus(organizationId, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
}
