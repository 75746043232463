import { Desk } from "../../../assets/domain";
import { User } from "../../../authentication/domain";
import { BookingDate } from "./BookingDate";

export class GroupDeskBooking {
  constructor(public readonly user: User, public readonly desk: Desk | null) {}
}

export class GroupDeskBookings {
  constructor(
    public readonly date: BookingDate,
    public readonly desks: GroupDeskBooking[]
  ) {}
}
