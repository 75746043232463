import { getJson, request } from "../../../api";
import { Desk, Floor, Location, LocationAddress, Space } from "../domain";

export interface LocationAddressDto {
  addressLine1: string;
  addressLine2: string | null;
  zipCode: string;
  city: string;
  cityShort: string;
  country: string;
}

export interface LocationDto {
  id: string;
  description: string | null;
  name: string;
  address: LocationAddressDto;
  imageUrl: string | null;
}

interface LocationResponse {
  content: LocationDto[];
}

export interface FloorDto {
  id: string;
  locationId: string;
  floorPlanImageUrl: string | null;
  level: number;
  description: string;
}

interface FloorsResponse {
  content: FloorDto[];
}

export interface SpaceDto {
  id: string;
  locationId: string;
  floorId: string;
  description: string;
}

interface SpacesResponse {
  content: SpaceDto[];
}

export interface DeskDto {
  id: string;
  locationId: string;
  floorId: string;
  spaceId: string;
  identifier: string;
  dedicatedFor: string[];
}

interface DesksResponse {
  content: DeskDto[];
}

export interface FavoriteLocationDto {
  id: string;
  locationId: string;
  organizationMembershipId: string;
}

export function mapLocation(location: LocationDto): Location {
  return new Location(
    location.id,
    new LocationAddress(
      location.address.addressLine1,
      location.address.addressLine2,
      location.address.city,
      location.address.cityShort,
      location.address.zipCode,
      location.address.country
    ),
    location.description,
    location.name,
    location.imageUrl
  );
}

export function mapFloor(floor: FloorDto): Floor {
  return new Floor(
    floor.id,
    floor.locationId,
    floor.level,
    floor.description,
    floor.floorPlanImageUrl
  );
}

export function mapSpace(space: SpaceDto): Space {
  return new Space(
    space.id,
    space.locationId,
    space.floorId,
    space.description
  );
}

export function mapDesk(desk: DeskDto): Desk {
  return new Desk(
    desk.id,
    desk.locationId,
    desk.floorId,
    desk.spaceId,
    desk.identifier
  );
}

export async function getLocations(
  organizationId: string
): Promise<Location[]> {
  const response = await getJson<LocationResponse>("/api/locations", {
    "filter.organization.id": organizationId,
    sort: "createdAt,asc",
  });
  return response.content.map(mapLocation);
}

export async function updateLocation(
  locationId: string,
  params: { address?: LocationAddress }
): Promise<Location> {
  const req: Record<string, unknown> = {};
  if (params.address) {
    req.address = {
      addressLine1: params.address.addressLine1,
      addressLine2: params.address.addressLine2,
      city: params.address.city,
      zipCode: params.address.zipCode,
      country: params.address.country,
    };
  }
  const response = await request<LocationDto>(
    "PATCH",
    `/api/locations/${locationId}`,
    req
  );
  return mapLocation(response);
}

export async function getFloors(
  organizationId: string,
  filters?: {
    locationId?: string;
  },
  page?: {
    size?: number;
    number?: number;
  }
): Promise<Floor[]> {
  const response = await getJson<FloorsResponse>("/api/floors", {
    "filter.organization.id": organizationId,
    ...(filters?.locationId && { "filter.location.id": filters.locationId }),
    ...(typeof page?.size !== "undefined" && { size: page.size }),
    ...(typeof page?.number !== "undefined" && { page: page.number }),
    sort: "level",
  });
  return response.content.map(mapFloor);
}

export async function updateFloor(
  floorId: string,
  params: { description?: string }
): Promise<Floor> {
  const req: Record<string, unknown> = {};
  if (params.description) {
    req.description = params.description;
  }
  const response = await request<FloorDto>(
    "PATCH",
    `/api/floors/${floorId}`,
    req
  );
  return mapFloor(response);
}

export async function getSpaces(
  organizationId: string,
  filters?: {
    locationId?: string;
    floorId?: string;
  },
  page?: {
    size?: number;
    number?: number;
  }
): Promise<Space[]> {
  const response = await getJson<SpacesResponse>("/api/spaces", {
    "filter.organization.id": organizationId,
    ...(filters?.locationId && { "filter.location.id": filters.locationId }),
    ...(filters?.floorId && { "filter.floor.id": filters.floorId }),
    ...(typeof page?.size !== "undefined" && { size: page.size }),
    ...(typeof page?.number !== "undefined" && { page: page.number }),
  });
  return response.content.map(mapSpace);
}

export async function getDesks(
  organizationId: string,
  filters?: {
    locationId?: string;
    floorId?: string;
    spaceId?: string;
  },
  page?: {
    size?: number;
    number?: number;
    unpaged?: boolean;
  }
): Promise<Desk[]> {
  const response = await getJson<DesksResponse>("/api/desks", {
    "filter.organization.id": organizationId,
    ...(filters?.locationId && { "filter.location.id": filters.locationId }),
    ...(filters?.floorId && { "filter.floor.id": filters.floorId }),
    ...(filters?.spaceId && { "filter.space.id": filters.spaceId }),
    ...(typeof page?.size !== "undefined" && { size: page.size }),
    ...(typeof page?.number !== "undefined" && { page: page.number }),
    ...(typeof page?.unpaged !== "undefined" && { page: page.unpaged }),
  });
  return response.content.map(mapDesk);
}

export async function updateSpace(
  spaceId: string,
  params: { description?: string }
): Promise<Space> {
  const req: Record<string, unknown> = {};
  if (params.description) {
    req.description = params.description;
  }
  const response = await request<SpaceDto>(
    "PATCH",
    `/api/spaces/${spaceId}`,
    req
  );
  return mapSpace(response);
}

export async function updateDesk(
  deskId: string,
  params: { identifier?: string }
): Promise<Desk> {
  const req: Record<string, unknown> = {};
  if (params.identifier) {
    req.identifier = params.identifier;
  }
  const response = await request<DeskDto>("PATCH", `/api/desks/${deskId}`, req);
  return mapDesk(response);
}

export async function toggleFavorite(
  locationId: string,
  organizationMembershipId: string
): Promise<FavoriteLocationDto[]> {
  const response = await request<FavoriteLocationDto[]>(
    "POST",
    "/api/favorite-locations",
    {
      locationId: locationId,
      organizationMembershipId: organizationMembershipId,
    }
  );

  return response;
}

export async function getLocationsSortedByFavorite(
  organizationMembershipId: string
): Promise<Location[]> {
  const response = await getJson<LocationResponse>("/api/favorite-locations", {
    "filter.organizationMembership.id": organizationMembershipId,
  });

  return response.content.map(mapLocation);
}
