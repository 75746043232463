import { FloorplanElement } from "../../floor-plan/components/assets/FloorPlanD3Renderer/styles";

export class LocationAddress {
  constructor(
    public readonly addressLine1: string,
    public readonly addressLine2: string | null,
    public readonly city: string,
    public readonly cityShort: string,
    public readonly zipCode: string,
    public readonly country: string
  ) {}
}

export class Location {
  constructor(
    public readonly id: string,
    public readonly address: LocationAddress,
    public readonly description: string | null,
    public readonly name: string,
    public readonly imageUrl: string | null
  ) {}

  equals(other: Location): boolean {
    return this.id === other.id;
  }
}

export class Floor {
  constructor(
    public readonly id: string,
    public readonly locationId: string,
    public readonly level: number,
    public readonly description: string,
    public readonly floorPlanImageUrl: string | null
  ) {}

  equals(other: Floor): boolean {
    return this.id === other.id;
  }
}

export class Space {
  constructor(
    public readonly id: string,
    public readonly locationId: string,
    public readonly floorId: string,
    public readonly description: string
  ) {}

  equals(other: Space): boolean {
    return this.id === other.id;
  }
}

export class Desk implements FloorplanElement {
  constructor(
    public readonly id: string,
    public readonly locationId: string,
    public readonly floorId: string,
    public readonly spaceId: string,
    public readonly identifier: string
  ) {}
}
