import { getJson } from "../../api";
import { LocationDto, mapLocation } from "../../modules/assets/api";
import { AssetRuleCapacity, LocationWithCapacity } from "../domain";

export interface AssetRuleCapacityDto {
  free: number;
  dedicated: number;
  disabled: number;
}

export interface LocationWithCapacityDto {
  location: LocationDto;
  capacity: AssetRuleCapacityDto;
}

export function mapLocationWithCapacity(
  locationWithCapacity: LocationWithCapacityDto
): LocationWithCapacity {
  return new LocationWithCapacity(
    mapLocation(locationWithCapacity.location),
    mapAssetRuleCapacity(locationWithCapacity.capacity)
  );
}

export function mapAssetRuleCapacity(
  assetRuleCapacity: AssetRuleCapacityDto
): AssetRuleCapacity {
  return new AssetRuleCapacity(
    assetRuleCapacity.free,
    assetRuleCapacity.dedicated,
    assetRuleCapacity.disabled
  );
}

export async function getLocationsWithCapacityQuery(
  organizationId: string
): Promise<LocationWithCapacity[]> {
  const response = await getJson<LocationWithCapacityDto[]>(
    "/api/manage-assets/locations",
    {
      "filter.organization.id": organizationId,
    }
  );

  return response.map(mapLocationWithCapacity);
}
