import { Box, DialogContent, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtDialog } from "../../../../../components";
import { Desk, Floor, Location, Space } from "../../../../assets/domain";
import { User } from "../../../../authentication/domain";
import { useCurrentUser } from "../../../../authentication/hooks";
import { BookedForUserHeader } from "../../../components";
import { BookingDate } from "../../../domain";
import { BookingInfo } from "./BookingInfo";

export interface BookingConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  edited: boolean;
  date: BookingDate;
  location: Location;
  floor?: Floor;
  space?: Space;
  isInvitation?: boolean;
  isApproveInvite?: boolean;
  desk?: Desk;
  bookedFor: User;
}

const MobileHeading: SxProps<Theme> = (theme) => ({
  [theme.breakpoints.down("md")]: {
    ...theme.typography.h6,
  },
});

export const BookingConfirmationDialog: React.FC<
  BookingConfirmationDialogProps
> = ({
  open,
  onClose,
  edited,
  location,
  floor,
  space,
  isInvitation,
  isApproveInvite,
  desk,
  bookedFor,
  date,
}) => {
  const { t } = useTranslation("booking-dialog");
  const currentUser = useCurrentUser();
  const bookedForSelf = currentUser.isUser(bookedFor);
  const bookedBy = useCurrentUser();

  return (
    <AmtDialog open={open} onClose={onClose} closeButton>
      <DialogContent>
        <BookedForUserHeader
          success
          variant={bookedForSelf || isApproveInvite ? "orange" : "cyclamen"}
          isDeskBooking={!!desk}
          user={bookedFor}
          isInvitation={isInvitation}
        />
        <BookingInfo
          date={date}
          location={location}
          floor={floor}
          space={space}
          desks={desk ? [desk] : undefined}
          showDedicatedWarning={false}
          bookedBy={bookedForSelf ? undefined : bookedBy}
        />
        <Box
          sx={(theme) => ({
            textAlign: "center",
            marginTop: 3,
            [theme.breakpoints.down("md")]: {
              marginTop: 1,
            },
          })}
        >
          <Typography variant="h4" color="primary.main" sx={MobileHeading}>
            {bookedForSelf || isApproveInvite
              ? t("confirmation.booked-for-self.title")
              : isInvitation
              ? t("confirmation.booked-for-others.invite.single")
              : t("confirmation.booked-for-others.title")}
          </Typography>
          <Typography variant="body2" color="shades.grey">
            {bookedForSelf &&
              (edited
                ? t("confirmation.booked-for-self.edited-subtitle")
                : t("confirmation.booked-for-self.subtitle"))}
            {!bookedForSelf &&
              (edited
                ? t("confirmation.booked-for-others.edited-subtitle")
                : t("confirmation.booked-for-others.subtitle"))}
          </Typography>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
