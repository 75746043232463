import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { Bell, Calendar, LogOut, Settings, Users, Zap } from "react-feather";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Avatar } from "../../../components";
import { isAccountSettingsVisible } from "../../../config";
import {
  trackOpenAccountSettings,
  trackOpenBackoffice,
  trackOpenGoogleSettings,
  trackOpenNotificationSettings,
  trackOpenSageHRSettings,
} from "../../../tracking";
import { useCurrentUser, useLogout } from "../../authentication/hooks";
import {
  useGoogleCalendarPermissions,
  useSageHRPermissions,
} from "../../permissions/hooks";
import { useBackofficePermissions } from "../../permissions/hooks/useBackofficePermissions";

export const SettingsMenu: React.FC = () => {
  const currentUser = useCurrentUser();
  const { isAdmin } = useBackofficePermissions();
  const sageHrPermissions = useSageHRPermissions();
  const googleCalendarPermissions = useGoogleCalendarPermissions();
  const { t } = useTranslation("header");
  const performLogout = useLogout();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "profileMenu",
  });

  return (
    <>
      <Tooltip placement="bottom-end" title={currentUser.name.full}>
        <IconButton
          {...bindTrigger(popupState)}
          sx={{ padding: 0 }}
          data-testid="navigation--current-user"
        >
          <Avatar src={currentUser.avatarImageUrl ?? undefined} />
        </IconButton>
      </Tooltip>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {isAdmin && (
          <MenuItem
            component={NavLink}
            to="/backoffice"
            onClick={() => {
              trackOpenBackoffice();
              popupState.close();
            }}
            data-testid="navigation--backoffice"
          >
            <ListItemIcon>
              <Zap size={16} />
            </ListItemIcon>
            <ListItemText primary={t("menu.backoffice")}></ListItemText>
          </MenuItem>
        )}
        {isAdmin && <Divider />}

        {isAccountSettingsVisible() && (
          <MenuItem
            component={NavLink}
            to="/settings"
            onClick={() => {
              trackOpenAccountSettings();
              popupState.close();
            }}
            data-testid="navigation--account-settings"
          >
            <ListItemIcon>
              <Settings size={16} />
            </ListItemIcon>
            <ListItemText primary={t("menu.account-settings")}></ListItemText>
          </MenuItem>
        )}
        <MenuItem
          component={NavLink}
          to="/settings/notifications"
          onClick={() => {
            trackOpenNotificationSettings();
            popupState.close();
          }}
          data-testid="navigation--notification-settings"
        >
          <ListItemIcon>
            <Bell size={16} />
          </ListItemIcon>
          <ListItemText primary={t("menu.notification-settings")} />
        </MenuItem>
        {sageHrPermissions.canConfigure() && (
          <MenuItem
            component={NavLink}
            to="/settings/sage-hr"
            onClick={() => {
              trackOpenSageHRSettings();
              popupState.close();
            }}
            data-testid="navigation--sage-hr"
          >
            <ListItemIcon>
              <Users size={16} />
            </ListItemIcon>
            <ListItemText primary={t("menu.sagehr-integration")} />
          </MenuItem>
        )}
        {googleCalendarPermissions.canConfigure() && (
          <MenuItem
            component={NavLink}
            to="/settings/google-calendar"
            onClick={() => {
              trackOpenGoogleSettings();
              popupState.close();
            }}
            data-testid="navigation--google-calendar"
          >
            <ListItemIcon>
              <Calendar size={16} />
            </ListItemIcon>
            <ListItemText primary={t("menu.google-calendar-integration")} />
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={() => {
            performLogout().then(() => {
              console.log("Logged out");
            });
            popupState.close();
          }}
          sx={{ minWidth: "214px" }}
          data-testid="navigation--logout"
        >
          <ListItemIcon>
            <LogOut size={16} />
          </ListItemIcon>
          <ListItemText>{t("menu.logout")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
