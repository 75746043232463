import { Box, DialogContent, Typography } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogTitle } from ".";
import { DialogInfo } from "..";
import { AmtDialog } from "../../../../../../components";
import { Desk, Space } from "../../../../../../modules/assets/domain";
import { User } from "../../../../../../modules/authentication/domain";
import { useSubscription } from "../../../../../../modules/events/hooks";
import {
  useBackofficeFloors,
  useBackofficeLocations,
} from "../../../../../state";
import { DeskStatus } from "../../../domain";
import { useAssetEvents } from "../../../state";

interface DeskManagementConfirmationDialogState {
  open: boolean;
  context?: {
    desk: Desk | null;
    space: Space;
    changedBy: User;
    dedicatedFor: User[];
    assetStatus: DeskStatus;
  };
}

export const DeskManagementConfirmationDialog: React.FC = () => {
  const events = useAssetEvents();
  const { location } = useBackofficeLocations();
  const { floor } = useBackofficeFloors();
  const { t } = useTranslation("asset-management");

  const [state, setState] = useState<DeskManagementConfirmationDialogState>({
    open: false,
  });

  useSubscription(events.deskStatusChange$, (event) => {
    setState({
      open: true,
      context: {
        desk: event.desk,
        space: event.space,
        changedBy: event.changedBy,
        dedicatedFor: event.dedicatedFor,
        assetStatus: event.assetStatus,
      },
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setState({ ...state, open: false });
  });

  return (
    <AmtDialog open={state.open} onClose={closeDialog} closeButton>
      <DialogContent>
        <DialogTitle
          deskStatus={
            state.context ? state.context.assetStatus : DeskStatus.FREE
          }
        />
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "20px",
            },
          })}
        >
          <DialogInfo
            sx={(theme) => ({
              marginY: 3,
              [theme.breakpoints.down("md")]: {
                marginY: 1,
              },
            })}
            desk={state.context ? state.context.desk : null}
            location={location ? location.location : null}
            floor={floor}
            space={state.context ? state.context.space : null}
            changedBy={state.context ? state.context.changedBy : null}
            confirmation
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h4" color="green.regular">
              {t("dialog.feedback.saved")}!
            </Typography>
            <Typography variant="body2" color={"shades.grey"}>
              {t("dialog.feedback.saved-subtitle")}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </AmtDialog>
  );
};
