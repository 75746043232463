import { Typography, TypographyProps } from "@mui/material";

export const ResponsiveH6: React.FC<TypographyProps> = ({ sx, ...props }) => (
  <Typography
    variant="h6"
    sx={[
      (theme) => ({
        [theme.breakpoints.down("md")]: {
          ...theme.typography.subtitle1,
        },
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);
