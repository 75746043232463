import { DialogContent } from "@mui/material";
import { useMemoizedFn } from "ahooks";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtError } from "../../../../../api";
import { AmtAlert, AmtDialog } from "../../../../../components";
import { trackAddAdmin } from "../../../../../tracking";
import {
  DialogActions,
  DialogInputSection,
  SimpleDialogTitle,
} from "../../../../components/Dialogs";
import { GroupMember } from "../../../asset-management/domain";
import { useAddAdminMutation } from "../../hooks";
import { useAddAdminForm } from "../../state";
import { FormikAdminAutocomplete } from "./FormikAdminAutocomplete";

export interface AddAdminFormValues {
  newAdminUser: GroupMember | undefined;
}

export const AddAdminDialog: React.FC = () => {
  const { isOpen, closeDialog } = useAddAdminForm();
  const addAdminMutation = useAddAdminMutation();
  const { t } = useTranslation("admin-management");

  const doSubmit = useMemoizedFn((values: AddAdminFormValues) => {
    return addAdminMutation.mutateAsync(values);
  });

  const handleSubmit = useMemoizedFn(
    (values: AddAdminFormValues, formik: FormikHelpers<AddAdminFormValues>) => {
      trackAddAdmin();
      return doSubmit(values).catch((err: AmtError) => {
        formik.setFieldError(
          "newAdminUser",
          t("dialog.feedback.add-admin-error")
        );
      });
    }
  );

  const validate = useMemoizedFn(
    async (
      values: AddAdminFormValues
    ): Promise<FormikErrors<AddAdminFormValues>> => {
      const errors: FormikErrors<AddAdminFormValues> = {};

      if (!values.newAdminUser) {
        errors.newAdminUser = "";
      }
      return errors;
    }
  );

  return (
    <AmtDialog open={isOpen} onClose={closeDialog} closeButton>
      <Formik
        enableReinitialize
        initialValues={{
          newAdminUser: undefined,
        }}
        validate={validate}
        validateOnMount
        initialTouched={{ newAdminUser: true }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => {
          return (
            <DialogContent>
              <SimpleDialogTitle title={t("dialog.add-admin-title")} />
              <Form style={{ margin: 0 }}>
                <DialogInputSection
                  label={t("dialog.choose-person")}
                  sx={{ marginTop: 1 }}
                >
                  <Field
                    name="newAdminUser"
                    component={FormikAdminAutocomplete}
                  />
                </DialogInputSection>
                <AmtAlert
                  sx={{ mt: 2 }}
                  text={t("dialog.add-admin-warning.text")}
                  title={t("dialog.add-admin-warning.title")}
                />
                <DialogActions
                  onCancel={closeDialog}
                  isSubmitting={isSubmitting}
                  isInvalid={!values.newAdminUser}
                />
              </Form>
            </DialogContent>
          );
        }}
      </Formik>
    </AmtDialog>
  );
};
