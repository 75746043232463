import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { useCurrentUser } from "../../authentication/hooks";
import { getNotifications, markAllAsRead, markAsRead } from "../api";
import { Notifications } from "../domain";

export function useNotifications(): UseQueryResult<Notifications> {
  const currentUser = useCurrentUser();
  return useQuery(
    ["notifications", currentUser.id],
    async () => {
      const page = await getNotifications(currentUser.id);
      return new Notifications(page.content, page.totalElements);
    },
    {
      refetchInterval: 30_000,
    }
  );
}

export function useMarkAsRead(): UseMutationResult<
  void,
  Error,
  { notificationId: string },
  { previousNotifications?: Notifications }
> {
  const currentUser = useCurrentUser();
  const qc = useQueryClient();
  const queryKey = ["notifications", currentUser.id];

  return useMutation(
    queryKey,
    ({ notificationId }) => markAsRead(notificationId),
    {
      onMutate: ({ notificationId }) => {
        const previousNotifications = qc.getQueryData<Notifications>(queryKey);
        if (previousNotifications) {
          qc.setQueryData(
            queryKey,
            previousNotifications.removeById(notificationId)
          );
        }
        return { previousNotifications };
      },
      onError: (_error, _, context) => {
        if (context) {
          qc.setQueryData(queryKey, context.previousNotifications);
        }
      },
      onSettled: () => {
        qc.invalidateQueries(queryKey);
        qc.invalidateQueries(["upcoming-bookings"]);
      },
    }
  );
}

export function useMarkAllAsRead(): UseMutationResult<void, Error, void> {
  const currentUser = useCurrentUser();
  const qc = useQueryClient();
  const queryKey = ["notifications", currentUser.id];
  return useMutation(queryKey, () => markAllAsRead(currentUser.id), {
    onMutate: () => {
      const previousNotifications = qc.getQueryData(queryKey);
      qc.setQueryData(queryKey, new Notifications([], 0));
      return { previousNotifications };
    },
    onError: (_error, _, context) => {
      if (context) {
        qc.setQueryData(queryKey, context.previousNotifications);
      }
    },
    onSettled: () => {
      qc.invalidateQueries(queryKey);
      qc.invalidateQueries(["upcoming-bookings"]);
    },
  });
}
