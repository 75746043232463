import {
  Box,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import clsx from "clsx";
import { isSameDay } from "date-fns";
import React, { ReactNode } from "react";
import { ArrowLeft, Map } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDateFns, useIsMobile } from "../../../../../../hooks";
import { useCurrentUser } from "../../../../../authentication/hooks";
import { BookingStatus } from "../../../../api";
import { ItemActions } from "../../../../components";
import { Booking, DeskBooking, UpcomingBooking } from "../../../../domain";
import { useApproveInvitation } from "../../../../hooks";
import { useCancelBooking, useMyBookingsNavigation } from "../../hooks";
import { BookingListItemDetails } from "./BookingListItemDetails";

// @ts-ignore
const StyledDate = styled<Box>(({ className, ...props }) => (
  <Box className={clsx("Amt-StyledDate", className)} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.orange.light,
  borderRadius: 8,
  padding: theme.spacing(0.5, 1),
  lineHeight: 0,
  minWidth: 42,
  textAlign: "right",

  [theme.breakpoints.down("md")]: {
    width: 4,
    height: 42,
    minWidth: "auto",
    padding: 0,
  },
}));

export interface StyledListItemButtonProps extends ListItemButtonProps {
  bookingType: "location" | "desk";
  booking: Booking;
}

const StyledListItem = styled(ListItemButton, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "bookingType",
})<StyledListItemButtonProps>(({ theme, bookingType, booking }) => ({
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  alignItems: "flex-start",
  borderRadius: "6px",
  ".Amt-StyledDate": {
    ...(bookingType === "location" && {
      backgroundColor: theme.palette.cyclamen.light,
      ".MuiTypography-root": {
        color: theme.palette.shades.dark,
      },
    }),
    ...(bookingType === "desk" && {
      backgroundColor:
        booking.status === BookingStatus.PENDING
          ? theme.palette.cyclamen.light
          : isSameDay(booking.bookingDate, new Date())
          ? theme.palette.green.light
          : theme.palette.orange.light,
      ".MuiTypography-root": {
        color: theme.palette.shades.dark,
      },
    }),
  },
  "&.MuiListItemButton-root:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.shades.greyUltimateLight,
    ".Amt-StyledDate": {
      backgroundColor: theme.palette.shades.greyExtraLight,
      ".MuiTypography-root": {
        color: "black",
      },
    },
  },
  "&.Mui-selected": {
    ...(bookingType === "location" && {
      backgroundColor: theme.palette.cyclamen.light,
      ".Amt-StyledDate": {
        backgroundColor: theme.palette.cyclamen.regular,
        ".MuiTypography-root": {
          color: "white",
        },
      },
    }),
    ...(bookingType === "desk" && {
      backgroundColor:
        booking.status === BookingStatus.PENDING
          ? theme.palette.cyclamen.light
          : isSameDay(booking.bookingDate, new Date())
          ? theme.palette.green.light
          : theme.palette.orange.light,
      ".Amt-StyledDate": {
        backgroundColor:
          booking.status === BookingStatus.PENDING
            ? theme.palette.cyclamen.regular
            : isSameDay(booking.bookingDate, new Date())
            ? theme.palette.green.regular
            : theme.palette.orange.regular,
        ".MuiTypography-root": {
          color: "white",
        },
      },
    }),
  },
}));

export interface BookingListItemProps {
  booking: UpcomingBooking;
  selected?: boolean;
  onSelect?: (booking: UpcomingBooking) => void;
  onViewFloorPlan?: (booking: UpcomingBooking) => void;
  onUnselect?: () => void;
}

interface ConnectedItemActionsProps {
  booking: UpcomingBooking;
  children?: ReactNode | undefined;
  isMyInvitation?: boolean;
}

const ConnectedItemActions: React.FC<ConnectedItemActionsProps> =
  React.memo<ConnectedItemActionsProps>(
    ({ booking, children, isMyInvitation }) => {
      const cancelBooking = useCancelBooking();
      const { initiate: approveInvitation } = useApproveInvitation();
      const { editBooking } = useMyBookingsNavigation();

      const handleEdit = useMemoizedFn(() => {
        editBooking(booking);
      });

      const handleCancel = useMemoizedFn(() => {
        cancelBooking(booking);
      });

      const approveBooking = useMemoizedFn(() => {
        console.log("approveBooking");
        approveInvitation(booking.booking);
      });

      if (booking.booking.status === BookingStatus.PENDING)
        return (
          <ItemActions
            booking={booking.booking}
            onConfirm={approveBooking}
            onCancel={handleCancel}
            children={children}
            isInvite
            isMyInvitation={isMyInvitation}
          />
        );

      return (
        <ItemActions
          booking={booking.booking}
          onConfirm={handleEdit}
          onCancel={handleCancel}
          children={children}
        />
      );
    }
  );

interface CalendarDayIconProps {
  date: Date;
  status: BookingStatus;
}

const CalendarDayIcon: React.FC<CalendarDayIconProps> = React.memo(
  ({ date, status }) => {
    const { format } = useDateFns();
    return (
      <StyledDate status={status}>
        <Box
          sx={(theme) => ({
            minWidth: "auto",
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
          })}
        >
          <Typography
            variant="caption"
            textTransform="uppercase"
            color="shades.grey"
          >
            {format(date, "E")}
          </Typography>
          <Typography variant="body2" color="shades.dark">
            <strong>{format(date, "dd")}</strong>
          </Typography>
        </Box>
      </StyledDate>
    );
  }
);

export const BookingListItem: React.FC<BookingListItemProps> = React.memo(
  ({ booking, selected = false, onSelect, onViewFloorPlan, onUnselect }) => {
    const { t } = useTranslation("dashboard");
    const isMobile = useIsMobile();
    const handleSelect = useMemoizedFn(() => onSelect?.(booking));
    const handleViewFloorPlan = useMemoizedFn((e) => {
      e.stopPropagation();
      onViewFloorPlan?.(booking);
    });
    const handleUnselect = useMemoizedFn((e) => {
      e.stopPropagation();
      onUnselect?.();
    });
    const currentUser = useCurrentUser();
    const bookedForSelf = booking.booking.bookedFor.id === currentUser.id;

    const bookingType: StyledListItemButtonProps["bookingType"] =
      booking.booking instanceof DeskBooking ? "desk" : "location";
    return (
      <StyledListItem
        onClick={handleSelect}
        disableRipple
        selected={selected}
        bookingType={bookingType}
        data-testid="my-bookings--list-item"
        booking={booking.booking}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
          }}
        >
          <CalendarDayIcon
            date={booking.booking.bookingDate}
            status={booking.booking.status}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <BookingListItemDetails
              upcomingBooking={booking}
              expanded={selected && (!isMobile || !!onViewFloorPlan)}
            />
          }
        />
        <Box sx={{ alignItems: "flex-start" }}>
          <ConnectedItemActions
            booking={booking}
            isMyInvitation={bookedForSelf}
          >
            {onViewFloorPlan && booking.booking instanceof DeskBooking && (
              <MenuItem onClick={handleViewFloorPlan}>
                <ListItemIcon>
                  <Map size={16} />
                </ListItemIcon>
                <ListItemText>
                  {t("desk.actions.view-on-floor-plan")}
                </ListItemText>
              </MenuItem>
            )}
            {onUnselect && (
              <MenuItem onClick={handleUnselect}>
                <ListItemIcon>
                  <ArrowLeft size={16} />
                </ListItemIcon>
                <ListItemText>{t("desk.actions.view-bookings")}</ListItemText>
              </MenuItem>
            )}
          </ConnectedItemActions>
        </Box>
      </StyledListItem>
    );
  }
);
