import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContainedDeskOccupancyList } from "../../../../../../components";
import { useCurrentUser } from "../../../../../authentication/hooks";
import { DeskAvailability, DeskOverview } from "../../../../domain";
import { Caption } from "../Caption";
import { DeskItemButton } from "./DeskItemButton";
import { SvgNotAvailableAvatar, SvgPartiallyAvailableAvatar } from "./icons";

export interface DeskItemPartiallyAvailableProps {
  overview: DeskOverview;
}

const NotAvailableAvatar: React.FC<DeskItemPartiallyAvailableProps> = ({
  overview,
}) => {
  return overview.availability === DeskAvailability.PARTIALLY_AVAILABLE ||
    overview.availability === DeskAvailability.PARTIALLY_DEDICATED ? (
    <SvgPartiallyAvailableAvatar />
  ) : (
    <SvgNotAvailableAvatar />
  );
};

const NotAvailableCaption: React.FC<DeskItemPartiallyAvailableProps> = ({
  overview,
}) => {
  const { t } = useTranslation("booking");
  const currentUser = useCurrentUser();

  if (overview.isDisabled) return <>{t("item.disabled.title")}</>;

  const allBookingsForCurrentUser = overview.bookings.every((it) =>
    it.bookedFor.isUser(currentUser)
  );
  return (
    <>
      {(overview.availability === DeskAvailability.PARTIALLY_AVAILABLE ||
        overview.availability === DeskAvailability.PARTIALLY_DEDICATED) &&
        t("item.partially-available.title")}
      {!allBookingsForCurrentUser &&
        overview.availability === DeskAvailability.NOT_AVAILABLE &&
        t("item.not-available.title")}
      {allBookingsForCurrentUser &&
        overview.availability === DeskAvailability.NOT_AVAILABLE &&
        t("item.not-available-booked-for-you.title")}
    </>
  );
};

const NotAvailableDescription: React.FC<DeskItemPartiallyAvailableProps> = ({
  overview,
}) => {
  const { t } = useTranslation("booking");
  const currentUser = useCurrentUser();
  const allBookingsForCurrentUser = overview.bookings.every((it) =>
    it.bookedFor.isUser(currentUser)
  );
  return (
    <Typography variant="body3" color="errors.regular">
      {overview.availability === DeskAvailability.PARTIALLY_AVAILABLE &&
        t("item.partially-available.description")}
      {overview.availability === DeskAvailability.NOT_AVAILABLE &&
        !allBookingsForCurrentUser &&
        t("item.not-available.description")}
      {overview.availability === DeskAvailability.NOT_AVAILABLE &&
        allBookingsForCurrentUser &&
        t("item.not-available-booked-for-you.description")}
    </Typography>
  );
};

export const DeskItemNotAvailable: React.FC<
  DeskItemPartiallyAvailableProps
> = ({ overview }) => {
  const { t } = useTranslation("booking");
  return (
    <DeskItemButton overview={overview}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          flexGrow: 1,
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <NotAvailableAvatar overview={overview} />
          <Box sx={{ lineHeight: 0 }}>
            <Caption>{t("item.booked-for")}</Caption>
            <Typography variant="body2">
              <NotAvailableCaption overview={overview} />
            </Typography>
          </Box>
        </Box>
        <Box
          className="Amt--DeskItem--NotAvailableDetails"
          sx={(theme) => ({
            display: "none",
            flexDirection: "column",
            gap: 1,

            [theme.breakpoints.down("md")]: {
              display: "flex",
            },
          })}
        >
          <NotAvailableDescription overview={overview} />
          <ContainedDeskOccupancyList
            variant="contained"
            TypographyProps={{ variant: "body3" }}
            bookings={overview.bookings}
            date={overview.date}
          />
        </Box>
      </Box>
    </DeskItemButton>
  );
};
