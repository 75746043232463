import { Components } from "@mui/material";

export const MuiListItemText: Components["MuiListItemText"] = {
  defaultProps: {
    primaryTypographyProps: { variant: "body2" },
  },
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
