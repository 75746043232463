import { Box, Button, styled } from "@mui/material";
import React from "react";
import { Check, X } from "react-feather";
import { useTranslation } from "react-i18next";

export const DialogActionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(1),
  marginTop: theme.spacing(3),

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(1),
  },
}));

export interface DialogActionsProps {
  onCancel: () => void;
  isSubmitting: boolean;
  isInvalid: boolean;
}

export const DialogActions: React.FC<DialogActionsProps> = ({
  onCancel,
  isSubmitting,
  isInvalid,
}) => {
  const { t } = useTranslation("asset-management");

  return (
    <DialogActionsWrapper>
      <Button
        fullWidth
        disabled={isSubmitting}
        color="cancel"
        variant="outlined"
        onClick={onCancel}
        startIcon={<X size={16} />}
        data-testid="book-form--cancel"
      >
        {t("dialog.actions.cancel")}
      </Button>
      <Button
        fullWidth
        type="submit"
        disabled={isSubmitting || isInvalid}
        color="primary"
        variant="contained"
        startIcon={<Check size={16} />}
        data-testid="book-form--submit"
      >
        {t("dialog.actions.save")}
      </Button>
    </DialogActionsWrapper>
  );
};
