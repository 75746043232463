import { useMemo } from "react";
import { api } from "../api";

export type PerformLogoutFn = () => Promise<void>;

export function useLogout(): PerformLogoutFn {
  return useMemo(() => {
    return async function () {
      try {
        const response = await api.createSelfServiceLogoutFlowUrlForBrowsers();
        document.location = response.data.logout_url;
      } catch (err) {
        // This should never happen, though if it does then a couple of possible reasons are:
        // * the session has expired,
        // * the network is down,
        // * ORY kratos is down.
        document.location.reload();
      }
    };
  }, []);
}
