import { Box, styled } from "@mui/material";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { trackSelectedSpace } from "../../../../../../../tracking";
import { Space } from "../../../../../../assets/domain";
import {
  getSelectedFloor,
  selectDisplayedSpaces,
  Spaces,
  useBookingFilters,
  useBookingNavigation,
  useBookingState,
  useSpaces,
} from "../../../state";
import { SelectableChip, SelectableRoomChip } from "./SelectableChip";
import { SpacesMenu } from "./SpacesMenu";

interface PresenceListRoomFilterInternalProps {
  spaces: Spaces;
  selectedSpace: Space | null;
  onChangeSpace: (space: Space | null) => void;
}

const FilterContainer = styled(Box)(({ theme }) => ({
  flex: "0 0 76px",
  height: "76px",
  padding: theme.spacing(2.5, 2),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    flex: "0 0 44px",
    height: "44px",
    padding: theme.spacing(1, 2),
  },
}));

const PresenceListRoomFilterComponent: React.FC<PresenceListRoomFilterInternalProps> =
  React.memo(({ spaces, selectedSpace, onChangeSpace }) => {
    const { t } = useTranslation("booking");
    const popupState = usePopupState({
      variant: "popover",
      popupId: "roomFilterMenu",
    });

    function handleSelectAllRooms() {
      onChangeSpace(null);
      popupState.close();
    }

    const handleChangeSpace = useMemoizedFn((space: Space | null) => {
      onChangeSpace(space);
      trackSelectedSpace(space?.description);
    });

    return (
      <FilterContainer>
        <ScrollContainer
          vertical={false}
          style={{
            overflow: "hidden",
            height: "30px",
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          <SelectableChip
            selected={selectedSpace === null}
            label={t("filter.all-rooms")}
            onClick={handleSelectAllRooms}
          />
          {spaces.flat.map((space) => (
            <SelectableRoomChip
              key={space.id}
              selected={selectedSpace?.id === space.id}
              space={space}
              onSelect={handleChangeSpace}
            />
          ))}
        </ScrollContainer>
        <SpacesMenu
          spaces={spaces}
          selectedSpace={selectedSpace}
          onChangeSpace={handleChangeSpace}
        />
      </FilterContainer>
    );
  });

export const PresenceListRoomFilter: React.FC = () => {
  const { spaceId } = useSpaces();
  const { onChangeSpace } = useBookingNavigation();
  const state = useBookingState();
  const [filters] = useBookingFilters();

  const spaces = selectDisplayedSpaces(state, filters);
  const selectedSpace = getSelectedFloor(spaces, spaceId);

  return (
    <PresenceListRoomFilterComponent
      spaces={spaces}
      selectedSpace={selectedSpace}
      onChangeSpace={onChangeSpace}
    />
  );
};
