import { Box, Button, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { Notification } from "../../domain";
import { useMarkAsRead } from "../../hooks";

export interface NotificationItemProps {
  onClick: () => void;
  onClose: () => void;
  notification: Notification;
  badge: React.ReactNode;
  content: React.ReactNode;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  badge,
  content,
  onClick,
  onClose,
}) => {
  const { t } = useTranslation("notifications");
  const { mutate } = useMarkAsRead();

  function markAsRead(e?: React.MouseEvent) {
    e?.stopPropagation();
    mutate({ notificationId: notification.id });
  }

  return (
    <MenuItem
      onClick={() => {
        onClick();
        onClose();
        markAsRead();
      }}
      sx={{ width: "315px" }}
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            height: 36,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {badge}
          <Tooltip title={t("mark-as-read")}>
            <Button
              onClick={markAsRead}
              onMouseDown={(e) => e.stopPropagation()}
              variant="iconLinkOutlined"
            >
              <Check size={16} />
            </Button>
          </Tooltip>
        </Box>
        {content}
      </Box>
    </MenuItem>
  );
};
