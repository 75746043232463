import useMemoizedFn from "ahooks/es/useMemoizedFn";
import React from "react";
import { useBookingCancel, useBookingCancelMutation } from "../../hooks";
import { BookingCancellationDialog } from "./BookingCancellationDialog";

export const ConnectedBookingCancellationDialog: React.FC = () => {
  const { open, booking, closeDialog } = useBookingCancel();
  const mutation = useBookingCancelMutation();

  const onConfirm = useMemoizedFn(() => {
    if (booking) {
      mutation.mutate(booking, {
        onSuccess: closeDialog,
      });
    }
  });

  return (
    <>
      {booking && (
        <BookingCancellationDialog
          open={open}
          onClose={closeDialog}
          booking={booking}
          disabled={mutation.isLoading}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};
