import { Components, Theme } from "@mui/material";
import { MuiAppBar } from "./MuiAppBar";
import { MuiAutocomplete } from "./MuiAutocomplete";
import { MuiButton } from "./MuiButton";
import { MuiChip } from "./MuiChip";
import { MuiDialog } from "./MuiDialog";
import { MuiDialogContent } from "./MuiDialogContent";
import { MuiFormHelperText } from "./MuiFormHelperText";
import { MuiIconButton } from "./MuiIconButton";
import { MuiInputAdornment } from "./MuiInputAdornment";
import { MuiInputBase } from "./MuiInputBase";
import { MuiLinearProgress } from "./MuiLinearProgress";
import { MuiList } from "./MuiList";
import { MuiListItemText } from "./MuiListItemText";
import { MuiListSubheader } from "./MuiListSubheader";
import { MuiMenu } from "./MuiMenu";
import { MuiMenuItem } from "./MuiMenuItem";
import { MuiPaper } from "./MuiPaper";
import { MuiSelect } from "./MuiSelect";
import { MuiSkeleton } from "./MuiSkeleton";
import { MuiTextField } from "./MuiTextField";
import { MuiToolbar } from "./MuiToolbar";
import { MuiTooltip } from "./MuiTooltip";

export const components: Partial<Components<Theme>> = {
  MuiAppBar,
  MuiAutocomplete,
  MuiButton,
  MuiChip,
  MuiDialog,
  MuiDialogContent,
  MuiFormHelperText,
  MuiIconButton,
  MuiInputAdornment,
  MuiInputBase,
  MuiLinearProgress,
  MuiList,
  MuiListItemText,
  MuiListSubheader,
  MuiMenu,
  MuiMenuItem,
  MuiPaper,
  MuiSelect,
  MuiSkeleton,
  MuiTextField,
  MuiToolbar,
  MuiTooltip,
};
