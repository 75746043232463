import { Box, styled } from "@mui/material";

export const Row = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(0.5),
}));

export const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: 1,
  flexShrink: 0,
  flexGrow: 0,
  flexBasis: "24px",
  width: "24px",
  color: theme.palette.shades.grey,
  // @ts-ignore
  ...theme.typography.body2,
}));

export const Label = styled("span")(({ theme }) => ({
  color: theme.palette.shades.grey,
}));

export const Info = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexGrow: 1,
  // @ts-ignore
  ...theme.typography.body2,
}));

interface BeforeAfterProps {
  variant: string;
}

export const Before = styled("span", {
  shouldForwardProp: (propName) => propName !== "variant",
})<BeforeAfterProps>(({ theme }) => ({
  color: theme.palette.shades.grey,
  textDecoration: "line-through",
}));

export const After = styled("span", {
  shouldForwardProp: (propName) => propName !== "variant",
})<BeforeAfterProps>(({ theme, variant }) => ({
  backgroundColor: theme.palette.shades.greyExtraLight,
  ...(variant === "book-form" && {
    backgroundColor: theme.palette.green.light,
  }),
}));
