import { Box, Button, styled, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import { ArrowLeft } from "react-feather";

export interface MobilePageHeaderProps {
  title: React.ReactNode;
  onBack?: () => void;
  sx?: SxProps<Theme>;
}

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.shades.greyExtraLight,
  backgroundColor: theme.palette.shades.greyUltimateLight,
  flexShrink: 0,
  flexGrow: 0,
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const ButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
});

export const MobilePageHeader: React.FC<MobilePageHeaderProps> = ({
  title,
  onBack,
  sx,
}) => (
  <Header sx={sx}>
    <ButtonContainer>
      {onBack && (
        <Button variant="icon" color="secondary" onClick={onBack}>
          <ArrowLeft size={16} />
        </Button>
      )}
    </ButtonContainer>
    <Typography
      variant="subtitle1"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {title}
    </Typography>
    <ButtonContainer />
  </Header>
);
