import { Box } from "@mui/material";
import React from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { InfoBanner } from "../../../../backoffice/components/InfoBanner";
import { DataSidebar } from "../../../../global/components/Sidebars";
import { StyledPageContentWrapper } from "../../../../global/styles";
import { SearchPageContainer } from "../../components";
import { SearchHeader, SearchTable } from "./components";
import { InfoBannerContent } from "./components/InfoBanner";

export const SearchPageComponent: React.FC = () => {
  const { t } = useTranslation("analytics");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleCloseButton = () => {
    navigate(`${searchParams.get("redirect-from")}`);
  };

  return (
    <SearchPageContainer>
      <InfoBanner
        tooltipTitle={t("search.clear")}
        handleClick={handleCloseButton}
        icon={<X size={16} />}
      >
        <InfoBannerContent />
      </InfoBanner>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <DataSidebar />
        <StyledPageContentWrapper>
          <SearchHeader />
          <SearchTable />
        </StyledPageContentWrapper>
      </Box>
    </SearchPageContainer>
  );
};
