import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";

export interface AmtDefaultBadgeProps {
  sx?: SxProps<Theme>;
  color:
    | "default"
    | "primary"
    | "secondary"
    | "orange"
    | "cyclamen"
    | "red"
    | "grey"
    | "orangelight"
    | "cyclamenlight";
}

export const AmtDefaultBadge: React.FC<
  React.PropsWithChildren<AmtDefaultBadgeProps>
> = ({ color, sx, children }) => {
  return (
    <Box
      sx={[
        (theme) => ({
          display: "inline-block",
          padding: theme.spacing(0, 1),
          borderRadius: "3px",
          border: "1px solid",
          // @ts-ignore
          ...theme.typography.body2,
          fontWeight: theme.typography.fontWeightBold,
          ...(color === "default" && {
            color: "shades.dark",
            background: "white",
            borderColor: "shades.greyLight",
          }),
          ...(color === "primary" && {
            color: "white",
            backgroundColor: "green.regular",
            borderColor: "green.regular",
          }),
          ...(color === "secondary" && {
            color: "white",
            backgroundColor: "cyclamen.regular",
            borderColor: "cyclamen.regular",
          }),
          ...(color === "orange" && {
            color: "white",
            backgroundColor: "orange.regular",
            borderColor: "orange.regular",
          }),
          ...(color === "cyclamen" && {
            color: "white",
            backgroundColor: "cyclamen.regular",
            borderColor: "cyclamen.regular",
          }),
          ...(color === "red" && {
            color: "white",
            backgroundColor: "errors.regular",
            borderColor: "errors.regular",
          }),
          ...(color === "grey" && {
            color: "white",
            backgroundColor: "shades.grey",
            borderColor: "shades.grey",
          }),
          ...(color === "orangelight" && {
            color: "shades.dark",
            backgroundColor: "orange.light",
            borderColor: "orange.light",
          }),
          ...(color === "cyclamenlight" && {
            color: "shades.dark",
            backgroundColor: "cyclamen.light",
            borderColor: "cyclamen.light",
          }),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
