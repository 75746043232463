import { Box, TextField } from "@mui/material";
import React from "react";

export interface TextInputProps {
  inputType: "text" | "number";
  value: string;
  onChange: (typeName: string) => void;
}

export const TextInput: React.FC<TextInputProps> = React.memo(
  ({ inputType = "text", value, onChange }) => {
    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onChange(event.target.value);
    };

    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={(theme) => ({
            display: "flex",
            flex: "1 1 auto",
            [theme.breakpoints.down("md")]: {
              marginBottom: 1,
            },
          })}
        >
          <TextField
            fullWidth
            value={value}
            onChange={(event) => handleChange(event)}
            type={inputType}
          />
        </Box>
      </Box>
    );
  }
);
