import analytics from "./analytics.json";
import anonymous from "./anonymous.json";
import assetManagement from "./asset-management.json";
import backoffice from "./backoffice.json";
import bookingDetails from "./booking-details.json";
import bookingDialog from "./booking-dialog.json";
import booking from "./booking.json";
import dashboard from "./dashboard.json";
import floorPlan from "./floor-plan.json";
import gallery from "./gallery.json";
import header from "./header.json";
import notifications from "./notifications.json";
import settings from "./settings.json";

const enUS = {
  anonymous,
  booking,
  "booking-details": bookingDetails,
  "booking-dialog": bookingDialog,
  dashboard,
  "floor-plan": floorPlan,
  header,
  notifications,
  settings,
  analytics,
  gallery,
  backoffice,
  "asset-management": assetManagement,
};

export default enUS;
