import { Box } from "@mui/material";
import { UiContainer } from "@ory/kratos-client";
import React from "react";
import { UiForm, UiFormProps } from "../../../components";

export interface OidcFormProps {
  handleSubmit: UiFormProps["handleSubmit"];
  ui: UiContainer;
}

export const OidcForm: React.FC<OidcFormProps> = ({ handleSubmit, ui }) => {
  return (
    <Box>
      <UiForm
        handleSubmit={handleSubmit}
        ui={ui}
        groups={["oidc"]}
        withoutDefault
      />
    </Box>
  );
};
