import { Components, Theme } from "@mui/material";

export const MuiTooltip: Components<Theme>["MuiTooltip"] = {
  styleOverrides: {
    // @ts-ignore
    tooltip: ({ theme }) => ({
      backgroundColor: theme.palette.shades.dark,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: theme.palette.shades.darkExtra,
      padding: theme.spacing(1),
      borderRadius: 8,
      color: "white",
      ...theme.typography.subtitle2,
    }),
  },
};
