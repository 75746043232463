import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AmtMasterBadge } from "../../../../components";
import { Location } from "../../../../modules/assets/domain";
import { LocationWithCapacity } from "../../../domain";

interface LocationInfoProps {
  locationWithCapacity: LocationWithCapacity;
}

export const LocationInfo: React.FC<LocationInfoProps> = ({
  locationWithCapacity,
}) => {
  const { t } = useTranslation("asset-management");

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <City location={locationWithCapacity.location} />
      <LocationName location={locationWithCapacity.location} />
      <Address location={locationWithCapacity.location} />
      <OccupancyText>{t("location.capacity")}</OccupancyText>
      <CapacityInfo
        label={t("location.available")}
        capacityNumber={locationWithCapacity.assetRuleCapacity.free}
        dotColor={"green.regular"}
      />
      <CapacityInfo
        label={t("location.dedicated")}
        capacityNumber={locationWithCapacity.assetRuleCapacity.dedicated}
        dotColor={"orange.regular"}
      />
      <CapacityInfo
        label={t("location.disabled")}
        capacityNumber={locationWithCapacity.assetRuleCapacity.disabled}
        dotColor={"shades.grey"}
      />
    </Box>
  );
};

interface AddressProps {
  location: Location;
}

const Address: React.FC<AddressProps> = ({ location }) => {
  return (
    <Typography
      variant="caption"
      sx={{
        fontWeight: 600,
      }}
    >
      {location.address.addressLine1}
    </Typography>
  );
};

interface CityProps {
  location: Location;
}

const City: React.FC<CityProps> = ({ location }) => {
  return (
    <AmtMasterBadge
      sx={{
        display: "inline-block",
        backgroundColor: "orange.regular",
        borderColor: "orange.regular",
        color: "white",
        paddingY: 0,
        paddingX: 1,
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {location.address.city}
      </Typography>
    </AmtMasterBadge>
  );
};

interface LocationNameProps {
  location: Location;
}

const LocationName: React.FC<LocationNameProps> = ({ location }) => (
  <Typography
    variant="subtitle1"
    sx={{
      paddingTop: 0.5,
      paddingBottom: 0,
    }}
  >
    {location.name}
  </Typography>
);

const OccupancyText: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Box
    sx={{
      marginTop: 2,
    }}
  >
    <Typography
      variant="caption"
      color="shades.grey"
      sx={{
        display: "block",
        paddingX: 0.5,
        paddingBottom: 0.5,
        marginBottom: 1,
        borderBottom: "1px dashed",
        borderBottomColor: "shades.greyLight",
      }}
    >
      {children}
    </Typography>
  </Box>
);

const CapacityInfo: React.FC<{
  label: string;
  capacityNumber: number;
  dotColor: string;
}> = ({ label, capacityNumber, dotColor }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      marginY: 0.5,
    }}
  >
    <Box
      sx={{
        backgroundColor: dotColor,
        display: "block",
        borderRadius: "50%",
        width: "6px",
        height: "6px",
        marginRight: 0.5,
      }}
    ></Box>
    <Typography variant="caption" color="black">
      {label}: {capacityNumber}
    </Typography>
  </Box>
);
