import { ListItemText, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const ChangeLanguageForm: React.FC = () => {
  const { t, i18n } = useTranslation("settings");
  const [lang, setLang] = useState(i18n.resolvedLanguage);

  return (
    <TextField
      fullWidth
      sx={{ mt: 5, mb: 5 }}
      select
      label={t("languages.label")}
      value={lang}
      onChange={(e) => {
        const newLang = e.target.value;
        localStorage.setItem("lang", newLang);
        setLang(newLang);
        i18n.changeLanguage(newLang).then(() => {
          console.log("success!");
        });
      }}
    >
      <MenuItem value="en">
        <ListItemText>{t("languages.english")}</ListItemText>
      </MenuItem>
      <MenuItem value="rs">
        <ListItemText>{t("languages.hooloovoo")}</ListItemText>
      </MenuItem>
    </TextField>
  );
};
