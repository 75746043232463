import { Box } from "@mui/material";
import React from "react";

export const ConferenceRoomBox: React.FC = () => {
  return (
    <Box
      className="Amt--ConferenceRoomItem--Box"
      sx={{
        width: 42,
        height: 42,
        padding: "3px",
        borderRadius: "8px",
        backgroundColor: "violet.light",
      }}
    />
  );
};
