import { Box, Button } from "@mui/material";
import React from "react";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { useBackofficeAdmins } from "../../state/useBackofficeAdmins";
import { AddAdminDialog } from "./components/AddAdminDialog";
import { AdminTable } from "./components/AdminTable";
import { ConnectedRemoveAdminDialog } from "./components/RemoveAdminDialog";
import { useAddAdminForm } from "./state";

export const AdminManagement: React.FC = () => {
  const { adminUsers } = useBackofficeAdmins();
  const form = useAddAdminForm();
  const { t } = useTranslation("admin-management");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => form.openDialog()}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              mb: 1,
              width: "100%",
            },
          })}
        >
          <Plus size={16} />
          {t("add-admin")}
        </Button>
      </Box>
      <AdminTable adminUsers={adminUsers} />
      <ConnectedRemoveAdminDialog />
      <AddAdminDialog />
    </Box>
  );
};
