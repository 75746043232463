import { useQuery, UseQueryResult } from "react-query";
import { useOrganizationMembership } from "../../authentication/hooks";
import { useLocations } from "../../booking/pages/BookingPage/state";
import { getSpacesWithImages } from "../api";
import { SpaceWithImages } from "../domain/SpaceImages";

export function useSpaceImages(): UseQueryResult<SpaceWithImages[]> {
  const { organization } = useOrganizationMembership();
  const { locationId } = useLocations();
  return useQuery(["space-images", organization.id, locationId], () =>
    getSpacesWithImages(organization.id, locationId)
  );
}
