import { Box, BoxProps } from "@mui/material";
import React from "react";
import { MainLayoutPadding } from "../../layout";

export const PageContainer: React.FC<BoxProps> = React.memo(
  ({ sx, ...props }) => {
    return (
      <Box
        {...props}
        sx={[
          (theme) => ({
            height: `calc(100vh - ${MainLayoutPadding.Top} - ${MainLayoutPadding.Bottom})`,
            [theme.breakpoints.down("md")]: {
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    );
  }
);
