import { Box } from "@mui/material";
import React from "react";
import { PageContainer } from "../../components";
import { BookingFilters } from "./BookingFilters";
import { BookingPageSkeleton } from "./BookingPageSkeleton";
import { FloatingMobileMenu } from "./FloatingMobileMenu";
import { FloorPlanList } from "./FloorPlan";
import { PresenceList } from "./Sidebar";
import {
  MobileDisplayedPage,
  useLocations,
  useMobileDisplayedPage,
} from "./state";

export const BookingPageMobileComponent: React.FC = React.memo(() => {
  const { location } = useLocations();
  const [mobileDisplayedPage] = useMobileDisplayedPage();

  if (!location) {
    return <BookingPageSkeleton />;
  }

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          flex: "0 0 auto",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        <BookingFilters />
      </Box>

      <Box
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          ...(mobileDisplayedPage === MobileDisplayedPage.LIST && {
            background: "white",
          }),
        }}
      >
        {mobileDisplayedPage === MobileDisplayedPage.LIST && <PresenceList />}
        {mobileDisplayedPage === MobileDisplayedPage.MAPS && (
          <Box
            sx={{
              padding: 2,
              height: "100%",
              overflow: "auto",
            }}
          >
            <FloorPlanList />
          </Box>
        )}

        <FloatingMobileMenu />
      </Box>
    </PageContainer>
  );
});
