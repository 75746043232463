import React from "react";
import { useIsMobile } from "../../../../hooks";
import { ConferenceFormDialog } from "../../../conference/components/ConferenceFormDialog";
import { GalleryDialog } from "../../../gallery/components/GalleryDialog";
import { ConnectedBookingCancellationDialog } from "../../components";
import { ConnectedApproveInvitationDialog } from "../../components/ApproveInvitationDialog";
import { BookingPageDesktopComponent } from "./BookingPageDesktopComponent";
import { BookingPageMobileComponent } from "./BookingPageMobileComponent";
import { BookingPageNavigationBlocker } from "./BookingPageNavigationBlocker";
import { BookLocationFAB } from "./BookLocationFAB";
import { BookingConfirmationDialogs, ConnectedBookFormDialog } from "./Dialog";
import { FloatingLocationBookings } from "./FloatingLocationBookings";
import { BookingContextProvider } from "./state";

export const BookingPage: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <BookingContextProvider>
      <ConnectedBookFormDialog />
      <ConferenceFormDialog />
      <GalleryDialog />
      <ConnectedBookingCancellationDialog />
      <ConnectedApproveInvitationDialog />
      <BookingConfirmationDialogs />
      <BookingPageNavigationBlocker />
      {!isMobile && (
        <>
          <BookingPageDesktopComponent />
          <BookLocationFAB />
          <FloatingLocationBookings />
        </>
      )}
      {isMobile && <BookingPageMobileComponent />}
    </BookingContextProvider>
  );
};
