import { UiContainer } from "@ory/kratos-client";
import React from "react";
import { UiForm, UiFormProps } from "../../../components";

export interface PasswordFormProps {
  handleSubmit: UiFormProps["handleSubmit"];
  ui: UiContainer;
}

export const PasswordForm: React.FC<PasswordFormProps> = ({
  handleSubmit,
  ui,
}) => {
  return (
    <UiForm
      handleSubmit={handleSubmit}
      ui={ui}
      groups={["password"]}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        ".MuiButton-outlinedPrimary": {
          color: "shades.dark",
        },
      }}
      buttonVariant="outlined"
    />
  );
};
