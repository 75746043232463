import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { isSameDay } from "date-fns";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useCurrentUser } from "../../../../authentication/hooks";
import { BookingStatus } from "../../../api";
import {
  BookingDate,
  DeskOverview,
  LocationBookingOverview,
  SpaceOverview,
} from "../../../domain";
import { useApproveInvitation } from "../../../hooks";
import {
  canBook,
  selectDeskBookings,
  selectLocationBookings,
  useBookingState,
} from "./useBookingContext";
import { useBookingDate } from "./useBookingDate";
import {
  DeskBookingRequest,
  EditDeskSnapshot,
  EditLocationSnapshot,
  useBookingForm,
} from "./useBookingForm";

export interface UseSelectionResult {
  selectedDesk: DeskOverview | null;
  selectedLocationBooking: LocationBookingOverview | null;
  selectedSpace: SpaceOverview | null;

  onSelectDesk(desk: DeskOverview | null): void;
  onSelectLocationBooking(
    locationBooking: LocationBookingOverview | null
  ): void;
}

const selectDesk = createSelector(
  [selectDeskBookings, (state, deskId: string | null) => deskId],
  (deskBookings, deskId): DeskOverview | null => {
    return deskBookings?.desks.find((desk) => desk.desk.id === deskId) ?? null;
  }
);

const selectSpace = createSelector(
  [selectDeskBookings, (state, deskId: string | null) => deskId],
  (deskBookings, deskId): SpaceOverview | null => {
    return (
      deskBookings?.spaces.find((space) => {
        const selectedDesk = space.desks.find(
          (desk) => desk.desk.id === deskId
        );
        return !!selectedDesk;
      }) ?? null
    );
  }
);

const selectLocationBooking = createSelector(
  [selectLocationBookings, (state, bookingId: string | null) => bookingId],
  (locationBookings, bookingId): LocationBookingOverview | null => {
    return (
      locationBookings?.bookings.find((locationOverview) =>
        locationOverview.bookings.find((booking) => booking.id === bookingId)
      ) ?? null
    );
  }
);

export function useSelection(): UseSelectionResult {
  const state = useBookingState();
  const form = useBookingForm();
  const { date } = useBookingDate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { initiate: approveInvitation } = useApproveInvitation();
  const currentUser = useCurrentUser();

  const deskId = searchParams.get("desk");
  const bookingId = searchParams.get("booking");

  const selectedDesk = selectDesk(state, deskId);
  const selectedLocationBooking = selectLocationBooking(state, bookingId);
  const selectedSpace = selectSpace(state, deskId);

  const onSelectDesk = useMemoizedFn((newDesk: DeskOverview | null) => {
    const updated = new URLSearchParams(searchParams);
    updated.delete("booking");
    updated.delete("conference");
    if (newDesk === null) {
      updated.delete("desk");
    } else {
      updated.set("location", newDesk.location.id);
      updated.set("floor", newDesk.floor.id);
      if (updated.get("room")) {
        updated.set("room", newDesk.space.id);
      }
      updated.set("desk", newDesk.desk.id);

      if (canBook(newDesk, form)) {
        const space = state.bookings.data?.deskBookings.spaces.find(
          (it) => it.space.id === newDesk.space.id
        );
        if (space) {
          form.openDialog(
            new DeskBookingRequest(
              date,
              space.location,
              space.floor,
              space,
              newDesk.desk
            )
          );
        }
      } else {
        const booking = newDesk.bookings.find((booking) =>
          isSameDay(booking.bookingDate, date.startDate)
        );
        if (
          booking?.status === BookingStatus.PENDING &&
          currentUser.isUser(booking.bookedFor)
        )
          approveInvitation(booking);
      }
    }
    setSearchParams(updated);
  });

  const onSelectLocationBooking = useMemoizedFn(
    (newLocationBooking: LocationBookingOverview | null) => {
      const updated = new URLSearchParams(searchParams);
      updated.delete("desk");
      if (newLocationBooking === null) {
        updated.delete("booking");
      } else {
        updated.set("booking", newLocationBooking.bookings[0].id);
      }
      setSearchParams(updated);
    }
  );

  const action = searchParams.get("action");

  const handleEnterEditModeFromAction = useMemoizedFn(() => {
    if (!action || (!selectedDesk && !selectedLocationBooking)) {
      return;
    }

    if (selectedDesk && action === "edit") {
      const booking = selectedDesk.bookings[0];
      if (booking) {
        form.enterEditMode(
          new EditDeskSnapshot(
            BookingDate.of(booking.bookingDate),
            selectedDesk.location,
            booking,
            selectedDesk.floor,
            selectedDesk.space,
            selectedDesk.desk
          )
        );
      }
    } else if (selectedLocationBooking && action === "edit") {
      const booking = selectedLocationBooking.bookings[0];
      if (booking) {
        form.enterEditMode(
          new EditLocationSnapshot(
            BookingDate.of(booking.bookingDate),
            selectedLocationBooking.location,
            booking
          )
        );
      }
    }

    const updated = new URLSearchParams(searchParams);
    updated.delete("action");
    setSearchParams(updated);
  });

  useEffect(() => {
    handleEnterEditModeFromAction();
  }, [
    handleEnterEditModeFromAction,
    selectedDesk,
    selectedLocationBooking,
    action,
  ]);

  return {
    selectedDesk,
    selectedLocationBooking,
    selectedSpace,
    onSelectDesk,
    onSelectLocationBooking,
  };
}
