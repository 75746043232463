import * as React from "react";

export const SvgAvailableAvatar: React.FC = (props: any) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} fill="#fff" />
    <path
      d="M21.333 22v-1.333A2.667 2.667 0 0 0 18.667 18h-5.334a2.667 2.667 0 0 0-2.666 2.667V22M16 15.333A2.667 2.667 0 1 0 16 10a2.667 2.667 0 0 0 0 5.333Z"
      stroke="#00CC99"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke="#00CC99" />
  </svg>
);
