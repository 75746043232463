import { useSearchParams } from "react-router-dom";
interface HeaderSearchOpen {
  isSearchOpen: boolean;
  setSearchOpen: (isOpen: boolean) => void;
}

export function useIsSearchOpen(): HeaderSearchOpen {
  const [searchParams, setSearchParams] = useSearchParams();
  const isSearchOpen = searchParams.get("search-open") ? true : false;

  const setSearchOpen = (isOpen: boolean) => {
    const updated = new URLSearchParams(searchParams);
    if (isOpen) {
      updated.set("search-open", "true");
    } else {
      updated.delete("search-open");
    }
    setSearchParams(updated);
  };

  return {
    isSearchOpen,
    setSearchOpen,
  };
}
