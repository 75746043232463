import { formatISO } from "date-fns";
import querystring from "query-string";
import { Desk, Floor, Location, Space } from "../../../../../assets/domain";
import {
  Booking,
  BookingDate,
  DeskOverview,
  SpaceOverview,
} from "../../../../domain";
import { SpaceCapacity } from "./SpaceCapacity";

export class RedirectTo {
  constructor(public path: string, public query?: Record<string, string>) {}

  getUrl() {
    return querystring.stringifyUrl({
      url: this.path,
      query: this.query,
    });
  }

  static dashboard(): RedirectTo {
    return new RedirectTo("/", {});
  }

  static book(): RedirectTo {
    return new RedirectTo("/book", {});
  }

  static desk(overview: DeskOverview): RedirectTo {
    return new RedirectTo("/book", {
      location: overview.location.id,
      floor: overview.floor.id,
      date: formatISO(overview.date.startDate, { representation: "date" }),
      ...(overview.date.isDateRange && {
        "date-end": formatISO(overview.date.endDate, {
          representation: "date",
        }),
      }),
      desk: overview.desk.id,
    });
  }
}

export class LocationBookingRequest {
  constructor(
    public readonly date: BookingDate,
    public readonly location: Location
  ) {}

  get locationId(): string {
    return this.location.id;
  }
}

export class DeskBookingRequest extends LocationBookingRequest {
  constructor(
    date: BookingDate,
    location: Location,
    public readonly floor: Floor,
    public readonly space: SpaceOverview,
    public readonly desk: Desk
  ) {
    super(date, location);
  }

  get capacity(): SpaceCapacity {
    const disabled = this.space.desks.reduce(
      (acc, desk) => acc + (desk.isDisabled ? 1 : 0),
      0
    );
    const total = this.space.desks.length - disabled;
    const reserved = this.space.desks.reduce(
      (acc, desk) => acc + (desk.bookings.length > 0 ? 1 : 0),
      0
    );
    return new SpaceCapacity(total, reserved);
  }

  get spaceId(): string {
    return this.space.space.id;
  }

  get deskId(): string {
    return this.desk.id;
  }
}

export class EditLocationSnapshot extends LocationBookingRequest {
  constructor(
    date: BookingDate,
    location: Location,
    public readonly booking: Booking,
    public readonly redirectTo: RedirectTo = RedirectTo.dashboard()
  ) {
    super(date, location);
  }

  get bookingId(): string {
    return this.booking.id;
  }

  equals(other: LocationBookingRequest) {
    return (
      !(other instanceof DeskBookingRequest) &&
      other.location.id === this.location.id &&
      other.date.equals(this.date)
    );
  }
}

export class EditDeskSnapshot extends EditLocationSnapshot {
  constructor(
    date: BookingDate,
    location: Location,
    booking: Booking,
    public readonly floor: Floor,
    public readonly space: Space,
    public readonly desk: Desk,
    redirectTo: RedirectTo = RedirectTo.dashboard()
  ) {
    super(date, location, booking, redirectTo);
  }

  equals(other: LocationBookingRequest) {
    return (
      other instanceof DeskBookingRequest &&
      other.location.id === this.location.id &&
      other.desk.id === this.desk.id &&
      other.date.equals(this.date)
    );
  }
}
