import { Box, ChipProps } from "@mui/material";
import { X } from "react-feather";
import { StyledChip } from "../styles";

export const MemberChip: React.FC<ChipProps> = (props) => (
  <StyledChip
    {...props}
    deleteIcon={
      <Box sx={{}}>
        <X size={16} />
      </Box>
    }
  />
);
