import { ConferenceRoom } from "../../../../../conference/domain";
import { LocationBookingOverview, SpaceOverview } from "../../../../domain";

export function sortSpacesAndDesks(data: SpaceOverview[]): SpaceOverview[] {
  const spaces: SpaceOverview[] = data.map((space) => {
    const desks = space.desks.slice();
    desks.sort((left, right) =>
      left.desk.identifier.localeCompare(right.desk.identifier)
    );
    return new SpaceOverview(space.space, space.floor, space.location, desks);
  });
  spaces.sort((left, right) =>
    left.space.description.localeCompare(right.space.description)
  );
  return spaces;
}

export function sortLocationBookings(
  data: LocationBookingOverview[]
): LocationBookingOverview[] {
  const newData = data.slice();
  newData.sort((left, right) => {
    return left.bookedFor.name.full.localeCompare(right.bookedFor.name.full);
  });
  return newData;
}

export function sortConferenceRooms(data: ConferenceRoom[]): ConferenceRoom[] {
  const newData = data.slice();
  newData.sort((left, right) => {
    return left.name.localeCompare(right.name);
  });
  return newData;
}
