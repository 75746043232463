export const SvgConferenceRoomAvatar: React.FC = (props: any) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} fill="#fff" />
    <path
      transform="translate(14.55 11.95)"
      d="M1 1V5L3.66667 6.33333"
      stroke="currentColor"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      transform="translate(7.95 7.95)"
      d="M8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00001C14.6667 4.31811 11.6819 1.33334 8.00004 1.33334C4.31814 1.33334 1.33337 4.31811 1.33337 8.00001C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667Z"
      stroke="currentColor"
      strokeWidth={1.333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={0.5}
      y={0.5}
      width={31}
      height={31}
      rx={15.5}
      stroke="currentColor"
    />
  </svg>
);
