import { Box, styled } from "@mui/material";
import React from "react";
import {
  useBookingDate,
  useBookingNavigation,
  useFloors,
  useLocations,
} from "../state";
import { BookingDatePicker } from "./BookingDatePicker";
import { FloorSelector } from "./FloorSelector";
import { LocationPicker } from "./LocationPicker";

const FieldContainer = styled(Box)(({ theme }) => ({
  minWidth: 188,
  flex: "1 1 auto",

  [theme.breakpoints.down("md")]: {
    minWidth: "auto",
    maxWidth: "none",
  },
}));

export const DesktopBookingFiltersGrid: React.FC = () => {
  const { location, locations } = useLocations();
  const { floor, floors } = useFloors();
  const { date } = useBookingDate();
  const { onChangeLocation, onChangeFloor, onChangeDate } =
    useBookingNavigation();

  if (!location) {
    throw new Error("Location must be loaded");
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        gap: 2,
        flexWrap: "wrap",

        [theme.breakpoints.down("md")]: {
          display: "grid",
          gap: 1,
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        },
      })}
    >
      <FieldContainer
        sx={(theme) => ({
          maxWidth: 260,
          [theme.breakpoints.down("md")]: {
            gridColumn: "span 1",
          },
        })}
      >
        <LocationPicker
          location={location}
          locations={locations}
          onChange={onChangeLocation}
        />
      </FieldContainer>
      <FieldContainer
        sx={(theme) => ({
          maxWidth: 220,
          [theme.breakpoints.down("md")]: {
            gridColumn: "span 1",
          },
        })}
      >
        <FloorSelector
          floors={floors}
          floor={floor}
          onChangeFloor={onChangeFloor}
        />
      </FieldContainer>
      <FieldContainer
        sx={(theme) => ({
          maxWidth: 260,
          [theme.breakpoints.down("md")]: {
            gridRow: 1,
            gridColumn: "span 2",
          },
        })}
      >
        <BookingDatePicker date={date} onChange={onChangeDate} />
      </FieldContainer>
    </Box>
  );
};
