import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { useTransition } from "react";
import { ActiveFilter } from "./ActiveFilter";
import { AvailabilityFilter, AvailabilityFilters } from "./AvailabilityFilter";

const InactiveFilters: BookingFiltersState = {
  state: ActiveFilter.INACTIVE,
  search: "",
  availability: AvailabilityFilters.ANY,
};

export interface BookingFiltersState {
  state: ActiveFilter;
  search: string;
  availability: AvailabilityFilter;
}

export interface BookingFiltersDispatch {
  resetFilter: () => void;
  openSearchFilter: () => void;
  openAvailabilityFilter: () => void;
  changeSearchFilter: (search: string) => void;
  changeAvailabilityFilter: (availability: AvailabilityFilter) => void;
}

export type UseBookingFiltersResult = [
  BookingFiltersState,
  BookingFiltersDispatch
];

const stateAtom = atom<BookingFiltersState>(InactiveFilters);

export function useBookingFilters(): UseBookingFiltersResult {
  const [filters, setFilters] = useAtom(stateAtom);

  const [, startTransition] = useTransition();

  const resetFilter = useMemoizedFn(() => {
    setFilters(InactiveFilters);
  });

  const openSearchFilter = useMemoizedFn(() => {
    setFilters({
      ...InactiveFilters,
      state: ActiveFilter.SEARCH,
    });
  });

  const openAvailabilityFilter = useMemoizedFn(() => {
    setFilters({
      ...InactiveFilters,
      state: ActiveFilter.AVAILABILITY,
    });
  });

  const changeSearchFilter = useMemoizedFn((search: string) => {
    startTransition(() => {
      setFilters({
        availability: AvailabilityFilters.ANY,
        state: ActiveFilter.SEARCH,
        search,
      });
    });
  });

  const changeAvailabilityFilter = useMemoizedFn(
    (availability: AvailabilityFilter) => {
      startTransition(() => {
        setFilters({
          search: "",
          state: ActiveFilter.AVAILABILITY,
          availability,
        });
      });
    }
  );

  return [
    filters,
    {
      resetFilter,
      openSearchFilter,
      openAvailabilityFilter,
      changeSearchFilter,
      changeAvailabilityFilter,
    },
  ];
}
