import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface InviteCheckboxProps {
  value: boolean;
  onChange: (invite: boolean) => void;
}

export const InviteCheckbox: React.FC<InviteCheckboxProps> = React.memo(
  ({ value, onChange }) => {
    const { t } = useTranslation("booking");

    const handleChange = () => {
      onChange(!value);
    };

    return (
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={handleChange}
      >
        <Checkbox checked={value} />
        <Typography>{t("form.desk-booking.invite")}</Typography>
      </Box>
    );
  }
);
