import React from "react";
import { BackofficePageContainer } from "../../../components";
import { InventoryManagement } from "../InventoryManagement";

export const InventoryManagementPage: React.FC = () => {
  return (
    <BackofficePageContainer>
      <InventoryManagement />
    </BackofficePageContainer>
  );
};
