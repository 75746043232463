import { Box, styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import { CheckCircle } from "react-feather";

const AmtSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.SnackbarItem-contentRoot": {
    ...theme.typography.body1,
    fontWeight: 600,
    padding: theme.spacing(2, 3),
    boxShadow: "none",
    minWidth: "auto",
  },
  ".SnackbarItem-message": {
    padding: 0,
  },
  "&.SnackbarItem-variantSuccess": {
    background: theme.palette.green.regular,
  },
}));

export const StyledSnackbarProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <AmtSnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      iconVariant={{
        success: (
          <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
            <CheckCircle size={24} />
          </Box>
        ),
      }}
    >
      {children}
    </AmtSnackbarProvider>
  );
};
