import { AssetInventoryDto, mapAssetInventory } from ".";
import { getJson } from "../../api";
import { DeskAssetInventory } from "../../modules/booking/domain";

export interface DeskAssetInventoryDto {
  assetInventory: AssetInventoryDto;
  quantityOnDesk: number;
}

export function mapDeskAssetInventory(
  response: DeskAssetInventoryDto
): DeskAssetInventory {
  return new DeskAssetInventory(
    mapAssetInventory(response.assetInventory),
    response.quantityOnDesk
  );
}

export async function getDeskInventories(
  organizationId: string,
  deskId: string | undefined
): Promise<DeskAssetInventory[]> {
  const response = await getJson<DeskAssetInventoryDto[]>(
    `/api/asset-inventories/desks/${deskId}`,
    {
      "filter.organization.id": organizationId,
    }
  );
  return response.map(mapDeskAssetInventory);
}
