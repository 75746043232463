// import useEventEmitter from "ahooks/es/useEventEmitter";
import useMemoizedFn from "ahooks/es/useMemoizedFn";
import { atom, useAtom } from "jotai";
import { useQueryClient } from "react-query";
import { trackExperiment } from "../../../../../../tracking";
import { Desk } from "../../../../../assets/domain";
import { User } from "../../../../../authentication/domain";
import { EventEmitter } from "../../../../../events/domain";
import { useSubscription } from "../../../../../events/hooks";
import { useEditModeVariation } from "../../../../../feature-flag/hooks";
import {
  EditLocationSnapshot,
  LocationBookingRequest,
} from "./DeskOverviewSnapshot";

type BookingFormState =
  | {
      isOpen: false;
      target?: LocationBookingRequest;
    }
  | {
      isOpen: true;
      target: LocationBookingRequest;
    };

type BookingFormEditState =
  | {
      isEditing: false;
      source?: EditLocationSnapshot;
    }
  | {
      isEditing: true;
      source: EditLocationSnapshot;
    };

export interface FormEvents {
  singleBooking$: EventEmitter<SingleBookingEvent>;
  groupBooking$: EventEmitter<GroupBookingEvent>;
}

export type UseBookingFormResult = BookingFormState &
  BookingFormEditState & {
    openDialog(target: LocationBookingRequest): void;
    closeDialog(): void;
    enterEditMode(oldDesk: EditLocationSnapshot): void;
    exitEditMode(): void;
  };

export interface SingleBookingEvent {
  edited: boolean;
  request: LocationBookingRequest;
  bookedFor: User;
  isInvitation: boolean;
}

export interface GroupBookingEvent {
  request: LocationBookingRequest;
  booking: {
    desk: Desk | null;
    bookedFor: User;
  }[];
  isInvitation: boolean;
}

const eventsAtom = atom<FormEvents>({
  singleBooking$: new EventEmitter<SingleBookingEvent>(),
  groupBooking$: new EventEmitter<GroupBookingEvent>(),
});

const formStateAtom = atom<BookingFormState>({
  isOpen: false,
});
const editModeAtom = atom<BookingFormEditState>({
  isEditing: false,
});

export function useBookingForm(): UseBookingFormResult {
  const qc = useQueryClient();
  const [formState, setFormState] = useAtom(formStateAtom);

  const openDialog = useMemoizedFn((request: LocationBookingRequest) => {
    setFormState({
      isOpen: true,
      target: request,
    });
    if (!editState.isEditing) {
      setEditState({
        isEditing: false,
      });
    }
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isOpen: false,
    });
  });

  const variation = useEditModeVariation();
  const [editState, setEditState] = useAtom(editModeAtom);
  const enterEditMode = useMemoizedFn((source: EditLocationSnapshot) => {
    trackExperiment(variation.name);
    setEditState({
      isEditing: true,
      source: source,
    });
  });

  const exitEditMode = useMemoizedFn(() => {
    setEditState({
      isEditing: false,
    });
  });

  const invalidateQueries = useMemoizedFn(() => {
    qc.invalidateQueries(["booking-groups"]);
    qc.invalidateQueries(["upcoming-bookings"]);
  });

  const events = useBookingEvents();
  useSubscription(events.singleBooking$, invalidateQueries);
  useSubscription(events.groupBooking$, invalidateQueries);

  return {
    ...formState,
    ...editState,
    openDialog,
    closeDialog,
    enterEditMode,
    exitEditMode,
  };
}

export function useBookingEvents(): FormEvents {
  const [events] = useAtom(eventsAtom);
  return events;
}
