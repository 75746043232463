import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Minus, Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { AmtAlert } from "../../../../../../../components";
import { DeskAssetInventory } from "../../../../../../../modules/booking/domain";
import { HeaderCell } from "../../../../../../components/Tables";

interface InventoryTableProps {
  inventories: DeskAssetInventory[];
  modifiedQuantities: Map<string, number>;
  setModifiedQuantities: (value: Map<string, number>) => void;
}

export const InventoryTable: React.FC<InventoryTableProps> = ({
  inventories,
  modifiedQuantities,
  setModifiedQuantities,
}) => {
  const { t } = useTranslation("inventory-management");

  const handleIncreaseQuantity = (inventory: DeskAssetInventory) => {
    const updatedQuantities = new Map(modifiedQuantities);
    const currentQuantity =
      updatedQuantities.get(inventory.assetInventory.id) ||
      inventory.quantityOnDesk;
    updatedQuantities.set(inventory.assetInventory.id, currentQuantity + 1);
    setModifiedQuantities(updatedQuantities);
  };

  const handleDecreaseQuantity = (inventory: DeskAssetInventory) => {
    const updatedQuantities = new Map(modifiedQuantities);
    const currentQuantity =
      updatedQuantities.get(inventory.assetInventory.id) ||
      inventory.quantityOnDesk;
    if (currentQuantity > 0) {
      updatedQuantities.set(inventory.assetInventory.id, currentQuantity - 1);
      setModifiedQuantities(updatedQuantities);
    }
  };

  return (
    <Box
      sx={{
        marginTop: "36px",
        border: "1px solid",
        borderColor: "shades.greyExtraLight",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          maxHeight: "100vh",
          overflow: "auto",
          "&:focus": {
            boxShadow: "0 0 0.5em rgba(0, 0, 0, 0.5)",
            outline: 0,
          },
        }}
      >
        <Table
          sx={{
            whiteSpace: "nowrap",
            margin: "0",
            borderCollapse: "separate",
            borderSpacing: "0",
            width: "100%",
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                "&:hover": {
                  color: "red",
                },
              }}
            >
              <HeaderCell label={t("table.header.inventory")} />
              <HeaderCell label={t("table.header.quantity")} />
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              textAlign: "center",
              position: "relative",
            }}
          >
            {inventories && inventories.length > 0 ? (
              inventories.map((inventory, index) => {
                return (
                  <TableRow
                    key={inventory.assetInventory.id}
                    sx={{
                      borderBottom: "1px solid",
                      borderColor: "shades.greyExtraLight",
                      backgroundColor: "white",
                      "&:nth-of-type(even)": {
                        backgroundColor: "#FBFBFB",
                      },
                      "&:last-child": {
                        border: "none",
                        " > td": {
                          borderBottom: "none",
                        },
                      },
                      "&:hover": {
                        backgroundColor: "green.light",
                      },
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: "10px 0px",
                        minHeight: "52px",
                      }}
                    >
                      <Typography variant="body2" sx={{ paddingLeft: "8px" }}>
                        {`${inventory.assetInventory.type.name} ${inventory.assetInventory.description}`}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "10px 00px",
                        minHeight: "52px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="iconLinkOutlined"
                        sx={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        onClick={() => handleIncreaseQuantity(inventory)}
                      >
                        <Plus size={16} />
                      </Button>
                      <Typography variant="body2" sx={{ mx: "8px" }}>
                        {modifiedQuantities.has(inventory.assetInventory.id)
                          ? modifiedQuantities.get(inventory.assetInventory.id)
                          : inventory.quantityOnDesk}
                      </Typography>
                      <Button
                        variant="iconLinkOutlined"
                        sx={{
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                        }}
                        disabled={
                          modifiedQuantities.has(inventory.assetInventory.id)
                            ? modifiedQuantities.get(
                                inventory.assetInventory.id
                              ) === 0
                            : inventory.quantityOnDesk === 0
                        }
                        onClick={() => handleDecreaseQuantity(inventory)}
                      >
                        <Minus size={16} />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow
                sx={{
                  padding: "4px 8px",
                  border: "none",
                  " > td": {
                    borderBottom: "none",
                  },
                }}
              >
                <TableCell colSpan={3}>
                  <AmtAlert
                    title={t("table.alert-title")}
                    text={t("table.not-available")}
                    variant="neutral"
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
