import mixpanel from "mixpanel-browser";
import { CurrentUser } from "../modules/authentication/domain";

export function trackUser(user: CurrentUser) {
  try {
    mixpanel.identify(user.id);
  } catch (e) {}
}

export function trackBookingInfoMenu() {
  try {
    mixpanel.track("Booking info menu");
  } catch (e) {}
}
