import { Logout } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router";
import { FullPageError } from "../../layout/FullPageError";
import { useBackofficePermissions } from "../../permissions/hooks/useBackofficePermissions";
import { useCurrentUser, useLogout } from "../hooks";

const NoPermissionError: React.FC = () => {
  const performLogout = useLogout();
  return (
    <FullPageError
      message="You don't have the permission to view this page."
      actions={
        <Button
          variant="contained"
          startIcon={<Logout />}
          onClick={performLogout}
          sx={{ mt: 2 }}
        >
          Logout
        </Button>
      }
    />
  );
};

export const AdminRoute: React.FC = () => {
  const currentUser = useCurrentUser();
  const { isAdmin } = useBackofficePermissions();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!isAdmin) {
    return <NoPermissionError />;
  }

  return <Outlet />;
};

export const SuperAdminRoute: React.FC = () => {
  const currentUser = useCurrentUser();
  const { isSuperAdmin } = useBackofficePermissions();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!isSuperAdmin) {
    return <NoPermissionError />;
  }

  return <Outlet />;
};
