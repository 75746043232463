import { useMutation, UseMutationResult } from "react-query";
import { useOrganizationMembership } from "../../authentication/hooks";
import { FavoriteLocationDto, toggleFavorite } from "../api";

export function useToggleFavoriteLocation(): UseMutationResult<
  FavoriteLocationDto[]
> {
  const organizationMembership = useOrganizationMembership();

  return useMutation(["toggle-favorite-location"], async (locationId: any) => {
    return toggleFavorite(locationId, organizationMembership.id);
  });
}
