import { Badge, Box, styled, Tooltip, TooltipProps } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { trackLocationCardLocationBookingsCountTooltipOpen } from "../../../../../../tracking";

interface TotalLocationBookingsTooltipProps {
  count: number;
  children?: React.ReactNode;
}

export const TotalLocationBookingsTooltip: React.FC<
  TotalLocationBookingsTooltipProps
> = ({ count, children }) => {
  const { t } = useTranslation("dashboard");

  if (count === 0) {
    return <>{children}</>;
  }

  return (
    <StyledTooltip
      placement="bottom-start"
      onOpen={trackLocationCardLocationBookingsCountTooltipOpen}
      title={
        <TooltipContainer>
          <Trans
            t={t}
            i18nKey="location.usage"
            values={{ count }}
            components={{
              Count: <TooltipCountBox />,
            }}
          />
        </TooltipContainer>
      }
    >
      <StyledBadge badgeContent="">{children}</StyledBadge>
    </StyledTooltip>
  );
};

const TooltipContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

const TooltipCountBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  borderRadius: "8px",
  backgroundColor: theme.palette.cyclamen.light,
  color: theme.palette.shades.dark,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  ".MuiBadge-badge": {
    backgroundColor: theme.palette.cyclamen.medium,
    top: 7,
    right: 2,
    borderRadius: 3,
    border: "1px solid white",
    height: 6,
    width: 6,
    minWidth: "auto",
    padding: 0,
  },
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} {...props} />
))({
  '&.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip': {
    marginBottom: 0,
  },
  '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
    marginTop: 0,
  },
});
