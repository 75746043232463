import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { AmtError } from "../../../../api";
import { useOrganizationMembership } from "../../../../modules/authentication/hooks";
import { DeskInventory } from "../../../../modules/booking/domain";
import { createDeskInventory } from "../../../api";
import { UpdateInventoryFormValues } from "../components/Dialogs/UpdateInventoryDialog";
import { useUpdateInventoryForm } from "../state";

export function useUpdateInventoryMutation(): UseMutationResult<
  DeskInventory[],
  AmtError,
  UpdateInventoryFormValues
> {
  const { t } = useTranslation("asset-management");
  const snackbar = useSnackbar();
  const qc = useQueryClient();
  const form = useUpdateInventoryForm();
  const { organization } = useOrganizationMembership();

  return useMutation(
    ["update-inventory"],
    async (formValues: UpdateInventoryFormValues) => {
      if (!form.desk) {
        return Promise.reject("Expected desk to be selected");
      }

      return createDeskInventory(
        organization.id,
        formValues.deskId,
        formValues.deskInventories
      );
    },
    {
      onSuccess: () => {
        snackbar.enqueueSnackbar(
          t("dialog.feedback.update-inventory-success"),
          {
            variant: "success",
          }
        );
        form.closeDialog();
      },
      onError: (err: AmtError) => {
        snackbar.enqueueSnackbar(t("dialog.feedback.update-inventory-error"), {
          variant: "error",
        });
        form.closeDialog();
      },
      onSettled: () => {
        qc.invalidateQueries(["inventories"]);
        qc.invalidateQueries(["bookings"]);
        qc.invalidateQueries(["desk-inventories"]);
      },
    }
  );
}
