import { FloorplanElement } from "../../floor-plan/components/assets/FloorPlanD3Renderer/styles";

export class SpaceWithImages implements FloorplanElement {
  constructor(
    public readonly id: string,
    public readonly description: string,
    public readonly spaceType: string,
    public readonly locationId: string,
    public readonly floorId: string,
    public readonly images: SpaceImage[]
  ) {}
}

export class SpaceImage {
  constructor(
    public readonly id: string,
    public readonly spaceImageUrl: string,
    public readonly spaceId: string
  ) {}
}
